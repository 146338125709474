import React, { Fragment, useEffect } from 'react';
import Header from 'layout/header';
import Sidebar from 'layout/sidebar';
import { getMeUser } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../routes';

const App = ({ children }) => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.User);

	useEffect(() => {
		dispatch(getMeUser());
	}, [dispatch]);

	useEffect(() => {
		if (!currentUser.getMeSucceded && currentUser.getMeError) {
			navigate(ROUTES.LOGIN, { replace: true });
		}
	}, [currentUser, navigate]);

	return (
		<Fragment>
			<div className="page-wrapper compact-wrapper" id="pageWrapper">
				<Header />
				<div className="page-body-wrapper sidebar-icon">
					<Sidebar />
					<div className="page-body">{children}</div>
				</div>
			</div>
			<ToastContainer />
		</Fragment>
	);
};

export default App;
