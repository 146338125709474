import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Check, X } from 'react-feather';
import './militanciaTab.scss';
import { useState } from 'react';
import { axiosInstance } from 'utils/axiosInstance';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';
import moment from 'moment';
import InternalLoader from 'layout/internal-loader';

const IsAffiliate = ({ isAffiliate }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'row', gap: '4' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '18px',
					height: '18px',
					borderRadius: '50%',
					backgroundColor: '#FFEFEF',
					marginRight: '10px'
				}}
			>
				{isAffiliate ? <Check color="#DF1212" size={10} /> : <X color="#DF1212" size={10} />}
			</div>
			<span
				style={{
					display: 'inherit',
					alignItems: 'center'
				}}
			>
				{isAffiliate ? 'Afiliado a partido' : 'No Afiliado'}
			</span>
		</div>
	);
};

const convertAffiliationDate = (date) => {
	const dateMomentObject = moment(date, 'DD/MM/YYYY');
	if (date) {
		return new Date(dateMomentObject.toDate()).toLocaleString('es-ES', {
			month: 'long',
			day: '2-digit',
			year: 'numeric'
		});
	} else {
		return '-';
	}
};

const convertLastConsultDate = (date) => {
	if (!date) return '-';

	return new Date(date).toLocaleString('es-ES', {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
		hour12: true,
		hour: '2-digit',
		minute: '2-digit'
	});
};

export default function MilitanciaTab({ dataCredentials, fullName }) {
	// const { id: voterId } = dataCredentials;

	const [affiliateDetails, setAffiliateDetails] = useState(dataCredentials);
	const [loadingAffiliation, setLoadingAffiliation] = useState(false);

	const handleSearchAffiliation = async () => {
		setLoadingAffiliation(true);
		axiosInstance({
			method: 'PUT',
			url: `/get-affiliates/${dataCredentials?.id}/`
		})
			.then(({ data }) => {
				setAffiliateDetails(data);
			})
			.catch(() => setAffiliateDetails(null))
			.finally(() => setLoadingAffiliation(false));
	};

	const downloadCBVO = async (certified_pdf) => {
		if (certified_pdf) {
			const splittedURL = certified_pdf.split('/');
			const cbvoFileName = splittedURL[splittedURL.length - 1];

			const res = await fetch(certified_pdf);
			const data = await res.blob();

			const blobUrl = URL.createObjectURL(data);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = `${fullName} - ${cbvoFileName}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	if (loadingAffiliation) return <InternalLoader />;

	return (
		<div className="details-view-container">
			<Container fluid>
				<Row>
					<Col lg="4">
						<Card style={{ width: '100%' }}>
							<CardHeader className="p-3 militancy-info-header">Militancia actual</CardHeader>
							<CardBody className="p-3">
								<Row className="py-2 event-info-line ">
									<Col>
										<Row className="event-info-titles">
											<span>Militancia</span>
										</Row>
										<Row>
											{affiliateDetails?.affiliate_details ? (
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														gap: '16px',
														alignContent: 'center'
													}}
												>
													<img
														src={affiliateDetails.affiliate_details.img_political_party}
														style={{ width: '48px', height: '48px', borderRadius: '50%' }}
														alt="militancy"
													/>
													<span style={{ display: 'inherit', alignItems: 'center' }}>
														{affiliateDetails.affiliate_details.partido_politico}
													</span>
												</div>
											) : (
												<span>No tiene</span>
											)}
										</Row>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col lg="8">
						<Card style={{ width: '100%' }}>
							<CardHeader className="p-3 militancy-info-header">Detalle de afiliación</CardHeader>
							<CardBody className="p-3">
								<Row className="py-2 event-info-line ">
									<Col>
										<Row className="event-info-titles">
											<span>Estado</span>
										</Row>
										<Row>
											<IsAffiliate isAffiliate={affiliateDetails?.affiliate_details} />
										</Row>
									</Col>
								</Row>
								{affiliateDetails?.affiliate_details && (
									<>
										<Row className="py-2 event-info-line">
											<Col>
												<Row className="event-info-titles">
													<span>Entidad</span>
												</Row>
												<Row>
													<span>{affiliateDetails.affiliate_details.entidad}</span>
												</Row>
											</Col>
										</Row>
										<Row className="py-2 event-info-line">
											<Col>
												<Row className="event-info-titles">
													<span>Fecha de afiliación</span>
												</Row>
												<Row>
													<span>
														{affiliateDetails
															? convertAffiliationDate(
																	affiliateDetails?.affiliate_details
																		?.fecha_afiliacion
															  )
															: '-'}
													</span>
												</Row>
											</Col>
										</Row>
									</>
								)}
								<Row className="py-2 event-info-line ">
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											width: '100%'
										}}
									>
										<span className="event-info-titles">CVBO</span>
										<Button
											color="primary"
											disabled={!affiliateDetails?.certified_pdf}
											onClick={() => downloadCBVO(affiliateDetails?.certified_pdf)}
										>
											Descargar CBVO
										</Button>
									</div>
								</Row>
								<Row className="py-2 event-info-line ">
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											width: '100%'
										}}
									>
										<Col>
											<Row className="event-info-titles">
												<span>Última consulta</span>
											</Row>
											<Row>
												<span>{convertLastConsultDate(affiliateDetails?.date_consult)}</span>
											</Row>
										</Col>
										<Button
											color="primary"
											onClick={handleSearchAffiliation}
											disabled={Object.keys(dataCredentials).length === 0}
										>
											Consultar ahora
										</Button>
									</div>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<LoadingAffiliation isOpen={loadingAffiliation} />
		</div>
	);
}
