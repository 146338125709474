import { Button, Col, Container, Row } from 'reactstrap';
import './detailNotFound.scss';
import { useNavigate } from 'react-router-dom';

const DetailNotFound = ({ moduleTitle }) => {
	const navigate = useNavigate();

	return (
		<Container fluid={true} className="p-0">
			<Row>
				<Col xs="12">
					<div className="not-found-container">
						<p style={{ fontSize: '128px', color: '#F04A5E', margin: '0px' }}>{'¡OH!'}</p>
						<p
							style={{
								fontSize: '30px',
								fontWeight: '500',
								lineHeight: '35.55px',
								margin: '5px 0px'
							}}
						>
							No existe detalle de {moduleTitle}
						</p>
						<div>
							<Button color="primary" className="btn-block" onClick={() => navigate(-1)}>
								Volver
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
export default DetailNotFound;
