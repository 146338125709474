import {graphQlClient} from 'store/graphql';
import {gql, useQuery} from '@apollo/client';
import {useSearchParams} from 'react-router-dom';

const getUsersQuery = gql`
	query userPagination($page: Int, $perPage: Int, $filter: FilterPaginationUsersInputInput) {
		userPagination(page: $page, perPage: $perPage, filter: $filter) {
			count
			items {
				blocked
				created_at
				email
				email_verified
				name
				nickname
				picture
				password
				updated_at
				user_id
				app_metadata {
					roles
				}
			}
			pageInfo {
				currentPage
				perPage
				pageCount
				itemCount
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;

export function useGetUsersWithPagination({perPage, page = {}, filter }) {
    const [searchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page') || page || 1);
    const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

    const {loading, data, error, refetch} = useQuery(getUsersQuery, {
        fetchPolicy: 'network-only',
        client: graphQlClient,
        variables: {
            perPage: currentPerPage,
            page: currentPage,
            filter: filter
        }
    });

    if (data?.inePagination) {
        data?.inePagination.items.sort(function compare(a, b) {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA;
        });
    }



    return {loading, data: data?.userPagination || {}, error, refetch};
}
