import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Clock } from 'react-feather';
import ApexCharts from 'react-apexcharts';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import CountUp from 'react-countup';
import { columnCharts } from './chartsData/apex-charts-data';
import InternalLoader from '../../../layout/internal-loader';
import { useDispatch, useSelector } from 'react-redux';
import { getWeeklyReport, getCurrentGoal, fetchGetCurrentGoalSuccess } from '../../../redux/actions';
import { useSubscription, gql } from '@apollo/client';

import DataTable from 'react-data-table-component';
import { datatableCustomStyle } from 'utils/datatableCustomStyle';
import { usePyramids } from 'utils/fetch/pyramids';

import moment from 'moment';
import '../../../pages/pyramid/pyramidList.scss';
import { Link } from 'react-router-dom';

const kpiSubscription = gql`
	subscription kpiSubscription {
		kpiSubscription
	}
`;

const Dashboard = () => {
	/* Datatable states */
	const [piramides, setPiramides] = useState([]);
	const [count, setCount] = useState(0);

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const { loading: loadingPiramides, data: dataPiramides } = usePyramids({ page: page, perPage: perPage });

	const { data } = useSubscription(kpiSubscription, {});

	const dispatch = useDispatch();
	const [daytimes, setDayTimes] = useState();
	const today = new Date();
	const curHr = today.getHours();
	const curMi = today.getMinutes();
	const [meridiem, setMeridiem] = useState('AM');
	const userData = useSelector((state) => state.User?.user);
	const loading = useSelector((state) => state.UI?.isLoading);
	const weeklyReport = useSelector((state) => state.Metrics?.weeklyReport);
	const currentGoal = useSelector((state) => state.Metrics?.currentGoal);

	useEffect(() => {
		if (dataPiramides) {
			setCount(dataPiramides.count);
			setPiramides(dataPiramides.results);
		}
	}, [dataPiramides]);

	useEffect(() => {
		dispatch(getWeeklyReport());
		dispatch(getCurrentGoal());
	}, [dispatch]);

	useEffect(() => {
		if (data) {
			const message = 'Received notification through GraphQL subscription.';
			console.info(message, data);
			dispatch(fetchGetCurrentGoalSuccess(data.kpiSubscription));
		}
	}, [data, dispatch]);

	useEffect(() => {
		if (curHr < 12) {
			setDayTimes('Buenos días');
		} else if (curHr < 18) {
			setDayTimes('Buenas tardes');
		} else {
			setDayTimes('Buenas noches');
		}

		if (curHr >= 12) {
			setMeridiem('PM');
		} else {
			setMeridiem('AM');
		}
	}, [curHr]);

	const columns = [
		{
			name: 'Piramide',
			selector: (row) => row.name,
			center: true
		},
		{
			name: 'Descripción',
			center: true,
			selector: (row) => row.description
		},
		{
			name: 'Niveles',
			center: true,
			selector: (row) => row.levels_number
		},
		{
			name: 'Fecha de registro',
			center: true,
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY')
		},
		{
			cell: (row) => (
				<Link className="actionButton" to={row.id ? `/piramides/${row.id}` : '#'}>
					<i className="fa fa-angle-right"></i>
				</Link>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	if (loading) return <InternalLoader />;

	return (
		<>
			<Row>
				<Col lg={6} sm={12}>
					<Row className="px-3">
						<Card className="o-hidden profile-greeting">
							<CardBody className="box-card">
								<div className="media">
									<div className="badge-groups w-100">
										<div className="badge f-12">
											<Clock style={{ width: '16px', height: '16px' }} className="mr-1" />
											<span id="txt">
												{curHr}:{curMi < 10 ? '0' + curMi : curMi} {meridiem}
											</span>
										</div>
									</div>
								</div>
								<div className="greeting-user text-center">
									<div className="profile-vector">
										{!userData && (
											<div className="spinner-border mt-2 text-secondary" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										)}
										{userData?.picture && (
											<img
												className="img-fluid"
												src={userData?.picture}
												alt="profile"
												style={{ borderRadius: 200, height: 150 }}
											/>
										)}
									</div>
									<h4 className="f-w-600">
										<span id="greeting">{daytimes}</span>{' '}
										<span className="right-circle">
											<i className="fa fa-check-circle f-14 middle"></i>
										</span>
									</h4>
								</div>
							</CardBody>
						</Card>
					</Row>
					<Row>
						<Col xl="6" lg="6" md="6" className="box-col-6">
							<Card className="o-hidden" style={{ width: '100%' }}>
								<CardBody>
									<div className="media">
										<div className="media-body">
											<p className="f-w-500 font-roboto">
												Total de electores escaneados
												<span className="badge pill-badge-primary ml-3">Tiempo Real</span>
											</p>
											<div className="progress-box">
												<h4 className="f-w-500 mb-0 f-26">
													<span className="counter" style={{ fontSize: '2em' }}>
														<CountUp end={weeklyReport?.totalCount || 0} />
													</span>
												</h4>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="3" lg="3" md="3" className="box-col-6">
							<Card className="o-hidden">
								<CardBody>
									<div className="media">
										<div className="media-body">
											<p className="f-w-500 font-roboto">Escaneados Hoy</p>
											<div className="progress-box">
												<h4 className="f-w-500 mb-0 f-26 text-center">
													<span className="counter" style={{ fontSize: '2em' }}>
														<CountUp end={weeklyReport?.dailyCount || 0} />
													</span>
												</h4>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="3" lg="3" md="3" className="box-col-6">
							<Card className="o-hidden">
								<CardBody>
									<div className="media">
										<div className="media-body">
											<p className="f-w-500 font-roboto">Escaneados esta semana</p>
											<div className="progress-box">
												<h4 className="f-w-500 mb-0 f-26 text-center">
													<span className="counter" style={{ fontSize: '2em' }}>
														<CountUp end={weeklyReport?.weeklyCount || 0} />
													</span>
												</h4>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col xl="6" lg="6" md="6" className="box-col-6">
							<Card className="o-hidden">
								<CardHeader className="card-no-border">
									<div className="card-header-right">
										<ul className="list-unstyled card-option">
											<li>
												<i className="fa fa-spin fa-cog"></i>
											</li>
											<li>
												<i className="view-html fa fa-code"></i>
											</li>
											<li>
												<i className="icofont icofont-maximize full-card"></i>
											</li>
											<li>
												<i className="icofont icofont-minus minimize-card"></i>
											</li>
											<li>
												<i className="icofont icofont-refresh reload-card"></i>
											</li>
											<li>
												<i className="icofont icofont-error close-card"></i>
											</li>
										</ul>
									</div>
									<div className="media">
										<div className="media-body">
											<p>
												<span className="f-w-500 font-roboto">Total Escaneos esta semana</span>
												<span className="f-w-700 font-primary ml-2">
													{weeklyReport?.weeklyCount || 0}
												</span>
											</p>
										</div>
									</div>
								</CardHeader>
								<CardBody className="pt-0">
									<div className="monthly-visit">
										{weeklyReport?.weeklyReport && (
											<ApexCharts
												id="column-chart"
												options={columnCharts(weeklyReport.weeklyReport)?.options}
												series={columnCharts(weeklyReport.weeklyReport)?.series}
												type="bar"
												height={105}
											/>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
						<Col xl="6" lg="6" md="6" className="box-col-6">
							{currentGoal?.meta > 0 && (
								<Card className="o-hidden">
									<CardBody>
										<div className="media">
											<div className="media-body">
												<p className="f-w-500 font-roboto" style={{ fontSize: '1.5em' }}>
													Meta actual: {currentGoal?.meta}
												</p>
												<div className="progress-box">
													<h4 className="f-w-500 mb-0 f-26">
														<span className="counter" style={{ fontSize: '2em' }}>
															<CountUp end={currentGoal?.current || 0} />
														</span>
													</h4>
													<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
														<div
															className="progress-gradient-primary"
															role="progressbar"
															style={{ width: '35%' }}
															aria-valuenow="75"
															aria-valuemin="0"
															aria-valuemax="100"
														>
															<span
																className="font-primary"
																style={{ fontSize: '2em' }}
															>{`${currentGoal?.percent?.toFixed(2)}%`}</span>
															<span className="animate-circle"></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							)}
							{currentGoal?.meta === 0 && (
								<Card className="o-hidden">
									<CardBody>
										<div className="media">
											<div className="media-body">
												<p className="f-w-500 font-roboto" style={{ fontSize: '1.5em' }}>
													No hay metas definidas.
												</p>
											</div>
										</div>
									</CardBody>
								</Card>
							)}
						</Col>
					</Row>
				</Col>
				<Col lg={6} sm={12}>
					<Row
						className="create-event-title"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '10px'
						}}
					>
						<span>Lista de pirámides</span>
					</Row>
					<Row>
						<div className="table-responsive product-table">
							<DataTable
								noHeader
								customStyles={datatableCustomStyle}
								columns={columns}
								data={piramides}
								progressComponent={<InternalLoader />}
								progressPending={loadingPiramides}
								highlightOnHover={true}
								paginationComponentOptions={{
									rowsPerPageText: 'Pirámides por página:',
									rangeSeparatorText: 'de'
								}}
								paginationRowsPerPageOptions={[10, 15, 30, 50]}
								pagination
								paginationServer
								paginationTotalRows={count}
								onChangeRowsPerPage={(e) => setPerPage(e)}
								onChangePage={(e) => setPage(e)}
								paginationDefaultPage={page}
								paginationPerPage={perPage}
								noDataComponent={'No se han encontrado pirámides'}
							/>
						</div>
					</Row>
				</Col>
			</Row>
		</>
	);
};

const Default = () => {
	return (
		<Fragment>
			<Breadcrumb parent="Tablas" title="Resumen" />
			<Container fluid={true}>
				<Dashboard />
			</Container>
		</Fragment>
	);
};

export default Default;
