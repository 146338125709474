import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { X } from 'react-feather';

const orderByOptions = [
	{ label: 'Nombre A-Z', value: 'aToZ' },
	{ label: 'Nombre Z-A', value: 'zToA' },
	{ label: 'Más Reciente', value: 'newer' },
	{ label: 'Más Antiguo', value: 'older' }
];

const levelsOptions = [
	{ label: '2 niveles', value: '2' },
	{ label: '3 niveles', value: '3' }
];

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const FilterPyramidModal = ({ isOpen, toggle, filterStates, filterOptions, filterHandlers }) => {
	const {
		selectedOrder,
		setSelectedOrder,
		selectedLevels,
		setSelectedLevels,
		selectedEntity,
		setSelectedEntity,
		selectedLDistricts,
		setSelectedLDistricts,
		selectedFDistricts,
		setSelectedFDistricts,
		selectedSection,
		setSelectedSection,
		selectedMunicipalities,
		setSelectedMunicipalities
	} = filterStates;

	const {
		loadingSections,
		entitiesOptions,
		localeDistrictsOptions,
		federalDistrictsOptions,
		municipalityOptions,
		sectionsOptions
	} = filterOptions;

	const { handleFilterPyramid, handleSelectedEntityChange } = filterHandlers;

	return (
		<Modal isOpen={isOpen} toggle={toggle} centered>
			<ModalBody className="electors-filter-modal">
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Ordenar por'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedOrder"
								id="selectedOrder"
								options={orderByOptions}
								onChange={(option) => {
									setSelectedOrder(option);
								}}
								placeholder={orderByOptions[0].label}
								value={selectedOrder}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Niveles'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedLevels"
								id="selectedLevels"
								options={levelsOptions}
								onChange={(option) => {
									setSelectedLevels(option);
								}}
								placeholder={'Todos los niveles'}
								value={selectedLevels}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Estado'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isSearchable
								isClearable
								name="selectedState"
								id="selectedState"
								options={entitiesOptions}
								onChange={handleSelectedEntityChange}
								placeholder={'Todos los estados'}
								value={selectedEntity || ''}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'D. Federal'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedFDistricts}
								closeMenuOnSelect={false}
								options={federalDistrictsOptions}
								placeholder="Todas los D. Federales"
								onChange={(e) => setSelectedFDistricts(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'D. Local'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedLDistricts}
								closeMenuOnSelect={false}
								options={localeDistrictsOptions}
								placeholder="Todas los D. Locales"
								onChange={(e) => setSelectedLDistricts(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Municipio'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedMunicipalities}
								closeMenuOnSelect={false}
								options={municipalityOptions}
								placeholder="Todos los municipios"
								onChange={(e) => setSelectedMunicipalities(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Sección'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedSection}
								closeMenuOnSelect={false}
								options={sectionsOptions}
								placeholder="Todas las secciones"
								onChange={(e) => setSelectedSection(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						/**
						 * Clears parameter of filter
						 */
						setSelectedOrder(null);
						setSelectedLevels();
						setSelectedEntity();
						setSelectedFDistricts([]);
						setSelectedLDistricts([]);
						setSelectedMunicipalities([]);
						setSelectedSection([]);

						/**
						 * Clears multiselect options
						 */
						localeDistrictsOptions([]);
						federalDistrictsOptions([]);
						municipalityOptions([]);
						sectionsOptions([]);
					}}
					disabled={loadingSections}
				>
					Restablecer
				</Button>{' '}
				<Button color="primary" onClick={() => handleFilterPyramid()} disabled={loadingSections}>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default FilterPyramidModal;
