import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getLocalStorageItem } from '../services/localStorageService';
import { TOKEN_DATA } from '../constant/localStorage';
import { onError } from '@apollo/client/link/error';
import { ErrorHandler } from 'redux/errorHandler';
// import { split, HttpLink } from '@apollo/client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// http://52.5.52.201/
// http://localhost:4000/
const HOST = process.env.REACT_APP_APOLLO_URL;

const wsLink = new GraphQLWsLink(
	createClient({
		url: `wss://${HOST}`
	})
);

const httpLink = createHttpLink({
	uri: `https://${HOST}`
});

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
				? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
				: ''
		}
	};
});

const errorLink = onError((error) => {
	console.error({ error });
	ErrorHandler(error.graphQLErrors?.[0]);
});

export const graphQlClient = new ApolloClient({
	link: from([errorLink, authLink.concat(httpLink)]),
	cache: new InMemoryCache()
});

export const wsGraphQlClient = new ApolloClient({
	link: authLink.concat(wsLink),
	cache: new InMemoryCache()
});
