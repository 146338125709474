import {Col, Container, Row} from 'reactstrap';
import Twitter from 'assets/images/logo/twitter.png';
import Tiktok from 'assets/images/logo/tiktok.png';
import Facebook from 'assets/images/logo/facebook.png';
import Instagram from 'assets/images/logo/instagram.png';
import './socialMedia.scss';

export default function SocialMediaTab({socialMedia}) {

    const {facebook, tiktok, twitter, instagram} = socialMedia;

    return (
        <Container fluid className="bg-white">
            <Row className="titleContainer">
                <Col sm="12">
                    <h3 className="font-primary f-16 m-0">Redes sociales</h3>
                </Col>
            </Row>
            <Row className="logosContainer">
                <Col sm="3">
                    <div className="social-media">
                        <div className="social-logo">
                            <img src={Twitter} alt="twitter"/>
                        </div>
                        <div className="social-name">Twitter</div>
                        <a className={"btn" + twitter && twitter?.split("com/")[1] ? "" : "disabled"} target="_black" href={twitter}>{twitter && twitter.split("com/")[1] ? twitter.split("com/")[1] : '-'}</a>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="social-media">
                        <div className="social-logo">
                            <img src={Instagram} alt="Instagram"/>
                        </div>
                        <div className="social-name">Instagram</div>
                        <a className={"btn" + instagram && instagram?.split("com/")[1] ? "" : "disabled"} target="_black" href={instagram}>{instagram && instagram.split("com/")[1] ? instagram.split("com/")[1] : '-'}</a>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="social-media">
                        <div className="social-logo">
                            <img src={Tiktok} alt="TikTok"/>
                        </div>
                        <div className="social-name">TikTok</div>
                        <a className={"btn" + tiktok && tiktok?.split("com/@")[1] ? "" : "disabled"} target="_black" href={tiktok}>{tiktok && tiktok.split("com/@")[1] ? tiktok.split("com/")[1] : '-'}</a>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="social-media">
                        <div className="social-logo">
                            <img src={Facebook} alt="facebook"/>
                        </div>
                        <div className="social-name">Facebook</div>
                        <a className={"btn btn-link" + facebook && facebook?.split("com/")[1] ? "" : "disabled"} target="_black" href={facebook}>{facebook && facebook.split("com/")[1] ? facebook.split("com/")[1] : '-'}</a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
