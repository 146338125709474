import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Loader from '../layout/loader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as ROUTES from '../routes';
import { getLocalStorageItem } from '../services/localStorageService';
import { getMeUser } from '../redux/actions';
import { TOKEN_DATA } from '../constant/localStorage';

const Initial = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getMeSucceded = useSelector((state) => state.User.getMeSucceded);

	useEffect(() => {
		dispatch(getMeUser());
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (getMeSucceded === true || getLocalStorageItem(TOKEN_DATA)) {
			if (window.location.pathname !== '/tablas/inicio') {
				// pending note ->test if its working
				navigate(ROUTES.DASHBOARD, { replace: true });
			}
		} else {
			navigate(ROUTES.LOGIN, { replace: true });
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let secondsStandBy = 0;
		let countDown = setInterval(() => {
			secondsStandBy += 1;
			if (secondsStandBy > 10) {
				localStorage.removeItem('tokenData');
				window.location.reload();
			}
		}, 1000);
		return () => {
			clearTimeout(countDown);
		};
	}, []);

	return (
		<Container fluid={true} className="p-0">
			<Loader />
		</Container>
	);
};

export default Initial;
