import React, { useEffect, useState } from 'react';
import { Container, Row, Col, TabPane, Button } from 'reactstrap';
import './pyramidLevels.scss';

import InternalLoader from 'layout/internal-loader';

import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import { useTab } from 'components/tab/useTab';
import CreateLevelForm from './CreateLevelForm';
import { Link, useParams } from 'react-router-dom';
import { useStructure } from 'utils/fetch/pyramids';
import { createLevel, updateLevel, useLevels } from 'utils/fetch/pyramidLevels';
import { toast } from 'react-toastify';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';
import { Eye } from 'react-feather';
import PyramidDetailsPreviewModal from './PyramidDetailsPreviewModal';

export default function PyramidLevels(props) {
	const { pyramidId } = useParams();
	const { data, loading } = useStructure(pyramidId);
	const { data: levelsByStructure, loading: loadingLevels } = useLevels(pyramidId);

	const levels = Array.from({ length: data?.levels_number }, (_, i) => `Nivel ${i + 1}`);
	const { activeTab, handleChangeTab } = useTab(levels?.[0], { replaceUrl: false });
	const activeTabIndex = levels.findIndex((item) => item === activeTab);
	const [levelsData, setLevelsData] = useState([]);

	const [pyramidPreviewIsOpen, setPyramidPreviewIsOpen] = useState(false);

	const [loadingUpdateCreate, setLoadingUpdateCreate] = useState(false);

	const togglePyramidPreviewIsOpen = () => setPyramidPreviewIsOpen(!pyramidPreviewIsOpen);

	useEffect(() => {
		if (levelsByStructure) {
			const levels = levelsByStructure.map((level) => ({
				...level,
				recruiters:
					level.recruiters_names.length > 0
						? level.recruiters_names.map((rec) => ({
								value: rec.id,
								label: rec.full_name,
								recruiter: rec
						  }))
						: []
			}));
			setLevelsData(levels);
		}
	}, [levelsByStructure]);

	if (loading || loadingLevels) {
		return <InternalLoader />;
	}

	if (!data) {
		return <h1>not found</h1>;
	}

	const handleLevelChange = (levelKey, keyValues = {}) => {
		const newLevels = levelsData.slice();
		newLevels[levelKey] = {
			...(levelsData[levelKey] || {}),
			...keyValues
		};
		setLevelsData(newLevels);
	};

	const levelOptions = Object.values(levelsData)
		.map(({ id, name }) => (id ? { value: id, label: name } : null))
		.filter(Boolean);

	const handleSubmit = async () => {
		setLoadingUpdateCreate(true);
		try {
			const formData = { ...(levelsData?.[activeTabIndex] || {}) };
			const structureId = data.id;
			formData.estructure = structureId;
			if (formData.recruiters) {
				formData.recruiters = Array.isArray(formData.recruiters)
					? formData.recruiters.map((item) => item.value)
					: [formData.recruiters.value];
			}
			const createOrUpdate = levelsData?.[activeTabIndex]?.id ? updateLevel : createLevel;

			const { data: response } = await createOrUpdate(formData);

			const newLevels = levelsData.slice();
			newLevels[activeTabIndex] = {
				...response,
				recruiters:
					response.recruiters_names.length > 0
						? response.recruiters_names.map((rec) => ({
								value: rec.id,
								label: rec.full_name,
								recruiter: rec
						  }))
						: []
			};

			if (!newLevels[0].parent_lvl && newLevels[0].recruiters_names?.length === 0) {
				throw new Error('recruiters_names_error');
			}

			setLevelsData(newLevels);
			toast.success(
				`Se ha ${levelsData?.[activeTabIndex]?.id ? 'actualizado' : 'guardado'} el nivel ${
					activeTabIndex + 1
				} correctamente`
			);
		} catch (error) {
			const errorMessage = Object.entries(error?.response?.data || []).join();
			console.error(error);
			if (error?.message?.includes('recruiters_names_error')) {
				toast.error('Debe asignar al menos 1 reclutador al nivel 1');
				return;
			}
			toast.error(errorMessage, {
				autoClose: 20000
			});
			toast.error('Ups, error al guardar el nivel');
		} finally {
			setLoadingUpdateCreate(false);
		}
	};

	const selectedRecruiters = () => {
		const recruiters = levelsData
			.filter((level) => level.recruiters)
			.map((levels) => {
				return levels.recruiters.map((recruiters) => recruiters.value);
			})
			.reduce((acc, current) => acc.concat(current), []);

		return recruiters;
	};

	return (
		<div className="create-pyramid-page">
			<Container fluid>
				<Row className="">
					<Col className="mr-auto">
						<h3>Crear pirámide</h3>
					</Col>
					<Col sm="auto" className="ml-auto">
						<h3 className="font-primary">Paso 2 de 3</h3>
					</Col>
				</Row>

				<div className="containerForm">
					<Row>
						<Col sm="12" xs="12">
							<h4 className="title">Asignar parámetros por nivel</h4>
						</Col>
					</Row>
					<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={levels} small gray />
					<div className="pt-3">
						<TabContentContainer activeTab={activeTab} noPadding>
							{levels.map((level, idx) => {
								return (
									<TabPane tabId={level} key={level}>
										{levelsData.length >= idx ? (
											<CreateLevelForm
												structrure={data}
												level={idx}
												levels={levelOptions}
												formData={levelsData?.[idx]}
												handleLevelChange={handleLevelChange}
												selectedRecruiters={selectedRecruiters}
												levelsData={levelsData}
											/>
										) : (
											<h3>Llene el nivel anterior</h3>
										)}
									</TabPane>
								);
							})}
						</TabContentContainer>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							padding: '20px 10px',
							width: '100%',
							height: '100%',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<Button outline color="primary">
							Cancelar
						</Button>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								height: '100%',
								justifyContent: 'flex-end',
								gap: '16px',
								alignItems: 'center'
							}}
						>
							<Button
								outline
								color="primary"
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px'
								}}
								onClick={() => setPyramidPreviewIsOpen(true)}
							>
								<span>Vista previa</span>
								<Eye size={14} />
							</Button>
							<Button color="primary" onClick={handleSubmit}>
								{levelsData?.[activeTabIndex]?.id ? 'Actualizar' : 'Guardar'}
							</Button>
						</div>
					</div>
					{levelsData &&
						levelsData.length === data?.levels_number &&
						levelsData?.every((item) => item.id) &&
						(levelsData?.some((item) => item?.number_managers === 0) ||
							levelsData[0].recruiters_names?.length === 0) && (
							<Row>
								<Col sm="12" className="d-flex justify-content-end mt-5">
									Debe asignar un numero de participantes por nivel
								</Col>
							</Row>
						)}
					{levelsData &&
						levelsData.length === data?.levels_number &&
						levelsData?.every((item) => item.id && item.number_managers) &&
						levelsData[0].recruiters_names?.length > 0 && (
							<Row>
								<Col sm="12" className="d-flex justify-content-end mt-5">
									<Link to={`/piramides/${pyramidId}/affiliates/`}>
										<Button size="md" className="ml-auto" color="secondary">
											Ir al paso 3
										</Button>
									</Link>
								</Col>
							</Row>
						)}
				</div>
			</Container>
			<LoadingAffiliation isOpen={loadingUpdateCreate} />
			<PyramidDetailsPreviewModal
				isOpen={pyramidPreviewIsOpen}
				toggle={togglePyramidPreviewIsOpen}
				levels={levelsData}
			/>
		</div>
	);
}
