export const createUserErrorHandler = (error) => {
	let errorHandled = null;
	switch (error) {
		case 'Conflict: The user already exists.':
			errorHandled = 'Ya existe un usuario asignado a ese correo electrónico';
			break;
		case 'Bad Request: PasswordStrengthError: Password is too weak':
			errorHandled =
				'La contraseña asignada es muy débil, debe tener al menos 8 caracteres, entre mayúsculas, números y caracteres especiales';
			break;
		default:
			break;
	}

	return errorHandled;
};
