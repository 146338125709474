export const ROLE_OPTIONS = [
    'BRIGADISTA',
    'SUPERVISOR',
    'ADMIN',
    'Electores',
    'Eventos',
    'Piramides',
    'SMS',
    'Necesidades',
    'Mapas',
    'Apoyos'];
