import { IS_LOADING } from '../actionTypes';

const initial_state = {
	isLoading: false
};

 const uiReducer = (state = initial_state, action) => {
	switch (action.type) {
		case IS_LOADING:
			return { ...state, isLoading: action.payload };
		default:
			return { ...state };
	}
};

export default uiReducer