import { User, Users } from 'react-feather';
import './pyramid.scss';

const layersPyramid = ['first', 'second', 'three', 'four', 'five', 'six'];
const backgrounds = [
	'linear-gradient(152.24deg, #60ACFF -19.43%, #59B3FF 10.04%, #46C8FF 54.25%, #2AE8FF 103.37%)',
	'linear-gradient(129.07deg, #FF5B91 -27.06%, #FF5398 7.11%, #FF3EAB 57.05%, #FF25C3 104.36%)',
	'linear-gradient(118.76deg, #FFB23D -30.6%, #FFAA36 1.99%, #FF9523 50.87%, #FF7607 105.18%)',
	'linear-gradient(112.51deg, #FF6967 -32.95%, #FF6169 1.71%, #FF4C70 54.41%, #FF317A 105.71%)',
	'linear-gradient(108.52deg, #CA60FF -34.56%, #C25AFF -6.43%, #AD4AFF 38.58%, #8A31FF 92.03%, #802AFF 106.09%)',
	'linear-gradient(108.52deg, #39632F -34.56%, #47A737 -6.43%, #33D130 38.58%, #34E025 92.03%, #16D23F 106.09%)'
];
export function Pyramid({ items }) {
	return (
		<div className="wrapper-pyramid" style={{ minWidth: `${items.length}*3em` }}>
			{items.map(({ name, total_manager_active, number_managers, order, recruiters }, key) => {
				let porcentaje = '';

				if (order === 0) {
					porcentaje =
						number_managers === 0 ? '0%' : `${Math.ceil((recruiters.length / number_managers) * 100)}%`;
				} else {
					porcentaje =
						number_managers === 0 ? '0%' : `${Math.ceil((total_manager_active / number_managers) * 100)}%`;
				}

				return (
					<div className="layer-item" key={key}>
						<div className="layer-info">
							<div className="mr-2 layer-icon">
								<div
									style={{
										width: '48px',
										height: '48px',
										background: backgrounds[key],
										borderRadius: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{key === 0 ? <User color="white" /> : <Users color="white" />}
								</div>
							</div>
							<div
								className="ml-2"
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center'
								}}
							>
								<div className="managers-info">
									<div>
										{order === 0 ? (
											<>
												<span>{`${recruiters.length}`}</span>
												<span style={{ color: '#777' }}>{` de ${number_managers}`}</span>
											</>
										) : (
											<>
												<span>{`${total_manager_active}`}</span>
												<span style={{ color: '#777' }}>{` de ${number_managers}`}</span>
											</>
										)}
									</div>
									<span className="percentage-badge">{porcentaje}</span>
								</div>
								<span className="level-name">{name}</span>
							</div>
						</div>
						<div
							key={key}
							className={`layer ${layersPyramid[key]}`}
							style={{ width: `${70 + key * 65}px`, background: backgrounds[key] }}
						></div>
					</div>
				);
			})}
		</div>
	);
}

export function Pyramid2() {
	return (
		<div className="d-flex flex-column align-items-center pyramid_wrap">
			<div className="category_one pyramidLayer">
				<h6 className="value">2</h6>
			</div>
			<div className="category_two pyramidLayer">
				<h6 className="value">8</h6>
			</div>
			<div className="category_three pyramidLayer">
				<h6 className="value">11</h6>
			</div>
			<div className="category_four pyramidLayer">
				<h6 className="value">16</h6>
			</div>
			<div className="category_four pyramidLayer">
				<h6 className="value">20</h6>
			</div>
		</div>
	);
}
