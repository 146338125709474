import {gql, useQuery} from "@apollo/client";
import {useSearchParams} from "react-router-dom";
import {graphQlClient} from "../../store/graphql";


const getAllSupports = gql`
query Query($page: Int, $perPage: Int, $filter: GetSupportAllFilterTCInput) {
  supportGetAll(page: $page, perPage: $perPage, filter: $filter) 
}
`;

const getAllCampaign = gql`
query Query($page: Int, $perPage: Int, $filter: GetCampaignAllFilterTCInput) {
  campaignGetAll(page: $page, perPage: $perPage, filter: $filter) 
}
`;

const getOneCampaign = gql`
query Query($id: String!) {
  campaignGetOne(_id: $id)
}
`;

const getOneSupport = gql`
query Query($id: String!) {
  supportGetOne(_id: $id)
}
`;

export function useSupportsWithPagination({perPage, page, filter = {}} = {}) {
    const [searchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page') || page || 1);
    const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

    const {loading, data, error, refetch} = useQuery(getAllSupports, {
        fetchPolicy: 'network-only',
        client: graphQlClient,
        variables: {
            perPage: currentPerPage,
            page: currentPage,
            filter
        }
    });

    return {loading, data: data?.supportGetAll || {}, error, refetch};
}

export function useCampaignWithPagination({perPage, page, filter = {}} = {}) {
    const [searchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page') || page || 1);
    const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

    const {loading, data, error, refetch} = useQuery(getAllCampaign, {
        fetchPolicy: 'network-only',
        client: graphQlClient,
        variables: {
            perPage: currentPerPage,
            page: currentPage,
            filter
        }
    });

    return {loading, data: data?.campaignGetAll || {}, error, refetch};
}

export function useGetOneCampaign({id} = {}) {

    const {loading, data, error, refetch} = useQuery(getOneCampaign, {
        fetchPolicy: 'network-only',
        client: graphQlClient,
        variables: {
            id
        }
    });

    return {loading, data: data?.campaignGetOne || {}, error, refetch};
}

export function useGetOneSupport({id} = {}) {

    const {loading, data, error, refetch} = useQuery(getOneSupport, {
        fetchPolicy: 'network-only',
        client: graphQlClient,
        variables: {
            id
        }
    });

    return {loading, data: data?.supportGetOne || {}, error, refetch};
}
