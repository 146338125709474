import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import './modal.scss';

export const ModalComponent = ({ children, open, handleClose, title }) => {
	return (
		<Modal
			isOpen={open}
			toggle={handleClose}
			style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '60vw' }}
			centered
		>
			{title && (
				<ModalHeader toggle={handleClose}>
					<div className="f-16">{title}</div>
				</ModalHeader>
			)}
			<div>{children}</div>
		</Modal>
	);
};
