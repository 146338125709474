import { SubirCredencial } from 'components/dashboard/electores/registrar/RegistrarElectorModal';
import { X } from 'react-feather';
import { ToastContainer } from 'react-toastify';
import { Modal, ModalBody, Row } from 'reactstrap';

const UpdateVoterModal = ({ isOpen, closeModal, curp, otherData }) => {
	return (
		<>
			<Modal isOpen={isOpen} toggle={closeModal} centered size={'xl'}>
				<ModalBody style={{ width: '100%', padding: '8px 32px 32px 32px' }}>
					<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={closeModal} />
					</Row>
					<SubirCredencial handleBack={closeModal} curp={curp} otherData={otherData} />
				</ModalBody>
			</Modal>
			<ToastContainer />
		</>
	);
};

export default UpdateVoterModal;
