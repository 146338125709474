import React, { Fragment, useEffect, useState } from 'react';
import CreateSurveyHeader from './create-survey-header';
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
	UncontrolledTooltip
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { ChevronRight, Minus, Plus } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

import * as ROUTES from '../../../routes/index';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import { axiosInstance } from 'utils/axiosInstance';

const SubcategoriesRows = ({
	categorySubcatetgories,
	setCategorySubcatetgories,
	categoryId,
	setIsLoading,
	setTempSurveyCategory
}) => {
	/**
	 * Handle the value of the new subcategory
	 */
	const [newSubcategory, setNewSubcategory] = useState('');

	/**
	 * Handle the value of existing subcategory
	 */
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [modifySubcategory, setModifySubcategory] = useState('');

	const handleAddSubcategory = () => {
		if (newSubcategory) {
			setIsLoading(true);

			axiosInstance({
				url: '/polls/category/',
				method: 'POST',
				data: {
					name: newSubcategory,
					parent: categoryId
				}
			})
				.then(({ data }) => {
					setCategorySubcatetgories((prev) => [...prev, { id: data.id, name: data.name }]);
					setTempSurveyCategory((prev) => ({
						...prev,
						subcategories: [...prev.subcategories, { id: data.id, name: data.name }]
					}));
				})
				.catch((error) => console.log(error))
				.finally(() => {
					setIsLoading(false);
					setNewSubcategory('');
				});
		} else {
			toast.error('El nombre de la subcategoría no puede estar vacío', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	const handleRemoveSubcategory = (id) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${id}/`,
			method: 'DELETE'
		})
			.then((res) => {
				setCategorySubcatetgories((prev) => prev.filter(({ id: subcId }) => subcId !== id));
				setTempSurveyCategory((prev) => ({
					...prev,
					subcategories: prev.subcategories.filter((subc) => subc.id !== id)
				}));
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleModifySubcategory = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${selectedIndex}/`,
			method: 'PUT',
			data: {
				name: modifySubcategory
			}
		})
			.then(({ data }) => {
				const auxSubc = categorySubcatetgories.map((subc) => {
					if (subc.id === selectedIndex) {
						return { ...subc, name: modifySubcategory };
					}

					return subc;
				});

				setCategorySubcatetgories(auxSubc);
				setTempSurveyCategory((prev) => ({
					...prev,
					subcategories: auxSubc
				}));
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setSelectedIndex(null);
				setModifySubcategory('');
			});
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				gap: '4px'
			}}
		>
			{categorySubcatetgories.map((subc) => {
				if (selectedIndex !== subc.id) {
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								height: '32px',
								gap: '24px'
							}}
						>
							<span
								style={{
									color: '#333',
									fontSize: '14px',
									fontWeight: '400',
									lineHeight: 'normal'
								}}
								onDoubleClick={() => {
									setModifySubcategory(subc.name);
									setSelectedIndex(subc.id);
								}}
							>
								{subc.name}
							</span>
							<Minus
								size={24}
								color="#F04A5E"
								style={{ cursor: 'pointer' }}
								onClick={() => handleRemoveSubcategory(subc.id)}
							/>
						</div>
					);
				} else {
					return (
						<Input
							bsSize="sm"
							id={`modifysubcategory`}
							name={`modifySubcategory`}
							placeholder="Ingrese nombre de subcategoría"
							value={modifySubcategory}
							onChange={(e) => setModifySubcategory(e.target.value)}
							onKeyDown={({ key }) => {
								if (key === 'Enter') handleModifySubcategory();
								if (key === 'Escape') setSelectedIndex(null);
							}}
						/>
					);
				}
			})}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: '32px',
					gap: '24px'
				}}
			>
				<Input
					bsSize="sm"
					id={`new-subcategoryName`}
					name={`new-subcategoryName`}
					placeholder="Ingrese nombre de subcategoría"
					value={newSubcategory}
					onChange={(e) => setNewSubcategory(e.target.value)}
					onKeyDown={({ key }) => key === 'Enter' && handleAddSubcategory()}
				/>
				<Plus size={24} color="#F04A5E" style={{ cursor: 'pointer' }} onClick={handleAddSubcategory} />
			</div>
		</div>
	);
};

const CategoryRow = ({ setSurveyCategories, setIsLoading, setTempSurveyCategory }) => {
	const [categoryName, setCategoryName] = useState('');
	const [auxName, setAuxName] = useState('');
	const [categoryNameIsSaved, setCategoryNameIsSaved] = useState(false);
	const [categorySubcatetgories, setCategorySubcatetgories] = useState([]);

	const [categoryId, setCategoryId] = useState(null);

	const [editable, setEditable] = useState(false);

	const handleSaveCategory = () => {
		if (categoryName) {
			setIsLoading(true);

			axiosInstance({
				url: '/polls/category/',
				method: 'POST',
				data: {
					name: categoryName
				}
			})
				.then(({ data }) => {
					setCategoryName(data.name);
					setCategoryId(data.id);
					setTempSurveyCategory({ id: data.id, name: data.name, subcategories: [] });
				})
				.catch((error) => console.log(error))
				.finally(() => {
					setIsLoading(false);
					setCategoryNameIsSaved(true);
				});
		} else {
			toast.error('Debe asignar un nombre a la categoría', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	const addNewCategory = () => {
		if (categorySubcatetgories.length > 0) {
			setSurveyCategories((prev) => [
				...prev,
				{ id: categoryId, name: categoryName, subcategories: categorySubcatetgories }
			]);
			setCategoryName('');
			setCategoryNameIsSaved(false);
			setCategorySubcatetgories([]);
			setCategoryId(null);

			setTempSurveyCategory({});
		} else {
			toast.error('Debe agregar al menos una subcategoría a la categoría', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	const removeCategory = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${categoryId}/`,
			method: 'DELETE'
		})
			.then(() => {
				setCategoryName('');
				setCategoryNameIsSaved(false);
				setCategorySubcatetgories([]);
				setCategoryId(null);
				setTempSurveyCategory({});
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleUpdateCategory = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${categoryId}/`,
			method: 'PUT',
			data: {
				name: auxName
			}
		})
			.then(({ data }) => {
				setCategoryName(data.name);
				setTempSurveyCategory((prev) => ({ ...prev, name: data.name }));
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setEditable(false);
				setAuxName('');
			});
	};

	return (
		<div
			className="mb-2"
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#F8F8F8',
				padding: '8px 16px',
				gap: '48px',
				alignItems: 'start',
				height: '100%',
				width: '100%',
				borderRadius: '5px'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					gap: '48px',
					width: '100%',
					height: '100%'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						height: '32px',
						gap: '24px'
					}}
				>
					{!categoryNameIsSaved ? (
						<>
							<Input
								bsSize="sm"
								placeholder="Ingrese nombre de categoría"
								value={categoryName}
								onChange={(e) => {
									setCategoryName(e.target.value);
								}}
								onKeyDown={({ key }) => key === 'Enter' && handleSaveCategory()}
							/>
							{/* <Save
								size={24}
								color="#F04A5E"
								style={{ cursor: 'pointer' }}
								onClick={handleSaveCategory}
							/> */}
							<ChevronRight
								size={24}
								color="#F04A5E"
								style={{ cursor: 'pointer' }}
								onClick={handleSaveCategory}
							/>
						</>
					) : (
						<>
							{editable ? (
								<>
									<Input
										bsSize="sm"
										placeholder="Ingrese nombre de categoría"
										value={auxName}
										onChange={(e) => {
											setAuxName(e.target.value);
										}}
										onKeyDown={({ key }) => {
											console.log(key);
											if (key === 'Enter') handleUpdateCategory();
											if (key === 'Escape') setEditable(false);
										}}
									/>
								</>
							) : (
								<>
									<span
										style={{
											color: '#333',
											fontSize: '14px',
											fontWeight: '700',
											lineHeight: 'normal'
										}}
										onDoubleClick={() => {
											setEditable(true);
											setAuxName(categoryName);
										}}
									>
										{categoryName}
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-end',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										<Plus
											size={24}
											color="#F04A5E"
											style={{ cursor: 'pointer' }}
											id="addNewCategory"
											onClick={addNewCategory}
										/>
										<UncontrolledTooltip target="addNewCategory">
											Agregar nueva categoría
										</UncontrolledTooltip>
										<Minus
											size={24}
											color="#F04A5E"
											style={{ cursor: 'pointer' }}
											id="removeCategory"
											onClick={removeCategory}
										/>
										<UncontrolledTooltip target="removeCategory">
											Eliminar categoría
										</UncontrolledTooltip>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					gap: '48px',
					width: '100%',
					height: '100%'
				}}
			>
				{categoryId && (
					<SubcategoriesRows
						categoryId={categoryId}
						setIsLoading={setIsLoading}
						categorySubcatetgories={categorySubcatetgories}
						setCategorySubcatetgories={setCategorySubcatetgories}
						setTempSurveyCategory={setTempSurveyCategory}
					/>
				)}
			</div>
		</div>
	);
};

const CreateSurvey1 = () => {
	/**
	 * Instance of useNavigate
	 */
	const navigate = useNavigate();

	/**
	 * State for creating a new survey
	 */
	const [surveyName, setSurveyName] = useState('');
	const [surveyDescription, setSurveyDescription] = useState('');

	const [surveyCategories, setSurveyCategories] = useState([]);

	const [tempSurveyCategory, setTempSurveyCategory] = useState({});

	/**
	 * State for loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	const [stages, setStages] = useState([]);

	/**
	 * Handler to modify existing categories/subcategories
	 */
	const [selectedId, setSelectedId] = useState(null);
	const [modifyValue, setModifyValue] = useState('');

	useEffect(() => {
		axiosInstance({ method: 'GET', url: '/polls/stages/' })
			.then(({ data }) => {
				setStages(data.results.map((stage) => stage.id));
			})
			.catch((err) => console.log(err));
	}, []);

	const handleSurveyToStep2 = () => {
		const catArray =
			Object.keys(tempSurveyCategory).length > 1 ? [...surveyCategories, tempSurveyCategory] : surveyCategories;

		const emptySubcategories = catArray.some((cat) => cat.subcategories.length === 0);

		if (surveyName && surveyDescription && surveyCategories.length > 0 && !emptySubcategories) {
			setIsLoading(true);

			axiosInstance({
				method: 'POST',
				url: '/polls/poll/',
				data: {
					name: surveyName,
					description: surveyDescription,
					stages: stages,
					category: surveyCategories.map((cat) => cat.id)
				}
			})
				.then((res) => {
					navigate(ROUTES.CREAR_ENCUESTAS_2, {
						state: {
							id: res.data.id,
							name: surveyName,
							description: surveyDescription,
							categories: catArray
						}
					});
				})
				.catch((err) => console.log(err))
				.finally(() => setIsLoading(false));
		} else {
			if (!surveyName)
				toast.error('Debe asignar un nombre a la encuesta', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});

			if (!surveyDescription)
				toast.error('Debe asignar una descripción a la encuesta', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});

			if (surveyCategories.length === 0)
				toast.error('Debe asignar al menos una categoría a la encuesta', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});

			if (emptySubcategories)
				toast.error('Una de las categorías no tienes subcategorías', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
		}
	};

	const removeCategory = (catId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${catId}/`,
			method: 'DELETE'
		})
			.then((res) => {
				setSurveyCategories((prev) => prev.filter(({ id }) => catId !== id));
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const removeSubcategory = (catId, subcId) => {
		setIsLoading(true);

		const newCategories = surveyCategories.map((category) => {
			if (category.id === catId) {
				const newSubcategories = category.subcategories.filter(({ id }) => id !== subcId);

				return {
					...category,
					subcategories: newSubcategories
				};
			}

			return category;
		});

		axiosInstance({
			url: `/polls/category/${subcId}/`,
			method: 'DELETE'
		})
			.then((res) => {
				setSurveyCategories(newCategories);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleModifyCategory = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${selectedId}/`,
			method: 'PUT',
			data: {
				name: modifyValue
			}
		})
			.then(({ data }) => {
				const auxCategories = surveyCategories.map((cat) => {
					if (selectedId === cat.id) {
						return { ...cat, name: modifyValue };
					}

					return cat;
				});

				setSurveyCategories(auxCategories);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setSelectedId(null);
				setModifyValue('');
			});
	};

	const handleModifySubcategory = (catId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/${selectedId}/`,
			method: 'PUT',
			data: {
				name: modifyValue
			}
		})
			.then(({ data }) => {
				const auxCategories = surveyCategories.map((cat) => {
					if (catId === cat.id) {
						// return { ...cat, name: modifyValue };
						const auxSubc = cat.subcategories.map((subc) => {
							if (subc.id === selectedId) {
								return { ...subc, name: modifyValue };
							}

							return subc;
						});

						return { ...cat, subcategories: auxSubc };
					}

					return cat;
				});

				setSurveyCategories(auxCategories);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setSelectedId(null);
				setModifyValue('');
			});
	};

	return (
		<Fragment>
			<CreateSurveyHeader step={1} lastStep={2} />
			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Definición de Nombre y Descripción</Row>
							</CardHeader>
							<CardBody className="py-3 px-3">
								<Row className="mb-2">
									<Col lg="6" sm="12">
										<FormGroup>
											<Label>{'Nombre'}</Label>
											<Input
												type="text"
												name="surveyName"
												id="surveyName"
												placeholder="Nombre de la encuesta"
												value={surveyName}
												onChange={(e) => setSurveyName(e.target.value)}
											/>
										</FormGroup>
									</Col>
									<Col lg="6" sm="12">
										<FormGroup>
											<Label>{'Descripción'}</Label>
											<Input
												type="text"
												name="surveyDescription"
												id="surveyDescription"
												placeholder="Nombre de evento"
												value={surveyDescription}
												onChange={(e) => setSurveyDescription(e.target.value)}
											/>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Descripción de Categorías y Subcategorías</Row>
							</CardHeader>
							<CardBody className="py-3 px-3">
								<Row className="mb-2">
									<Col lg="6" sm="12">
										<div
											style={{
												backgroundColor: '#FFF1F1',
												color: '#F04A5E',
												padding: '15px 17px',
												borderRadius: '5px',
												margin: '0 4px',
												fontWeight: 500,
												fontSize: '16px',
												lineHeight: '19px'
											}}
										>
											Categorías
										</div>
									</Col>
									<Col lg="6" sm="12">
										<div
											style={{
												backgroundColor: '#FFF1F1',
												color: '#F04A5E',
												padding: '15px 17px',
												borderRadius: '5px',
												margin: '0 4px',
												fontWeight: 500,
												fontSize: '16px',
												lineHeight: '19px'
											}}
										>
											Subcategorías
										</div>
									</Col>
								</Row>
								{surveyCategories.length > 0 &&
									surveyCategories.map((cat) => {
										const { id: catId, name, subcategories } = cat;

										return (
											<div
												className="mb-2"
												style={{
													display: 'flex',
													flexDirection: 'row',
													backgroundColor: '#F8F8F8',
													padding: '8px 16px',
													gap: '48px',
													alignItems: 'start',
													height: '100%',
													width: '100%',
													borderRadius: '5px'
												}}
												key={catId}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														padding: '8px 16px',
														gap: '48px',
														width: '100%',
														height: '100%'
													}}
												>
													{selectedId === catId ? (
														<Input
															bsSize="sm"
															id={`modifyValue`}
															name={`modifyValue`}
															placeholder="Ingrese nombre de categoría"
															value={modifyValue}
															onChange={(e) => setModifyValue(e.target.value)}
															onKeyDown={({ key }) => {
																if (key === 'Enter') handleModifyCategory();
																if (key === 'Escape') setSelectedId(null);
															}}
														/>
													) : (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'space-between',
																alignItems: 'center',
																height: '32px',
																gap: '24px'
															}}
														>
															<span
																style={{
																	color: '#333',
																	fontSize: '14px',
																	fontWeight: '700',
																	lineHeight: 'normal'
																}}
																onDoubleClick={() => {
																	setModifyValue(name);
																	setSelectedId(catId);
																}}
															>
																{name}
															</span>
															<Minus
																size={24}
																color="#F04A5E"
																style={{ cursor: 'pointer' }}
																onClick={() => removeCategory(catId)}
															/>
														</div>
													)}
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														padding: '8px 16px',
														gap: '48px',
														width: '100%',
														height: '100%'
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '100%',
															height: '100%',
															gap: '4px'
														}}
													>
														{subcategories.map((subcategory) => {
															const { id: subcId, name } = subcategory;

															if (selectedId === subcId) {
																return (
																	<Input
																		bsSize="sm"
																		id={`modifyValue`}
																		name={`modifyValue`}
																		placeholder="Ingrese nombre de subcategoría"
																		value={modifyValue}
																		onChange={(e) => setModifyValue(e.target.value)}
																		onKeyDown={({ key }) => {
																			if (key === 'Enter')
																				handleModifySubcategory(catId);
																			if (key === 'Escape') setSelectedId(null);
																		}}
																	/>
																);
															} else {
																return (
																	<div
																		style={{
																			display: 'flex',
																			flexDirection: 'row',
																			justifyContent: 'space-between',
																			alignItems: 'center',
																			height: '32px',
																			gap: '24px'
																		}}
																	>
																		<span
																			style={{
																				color: '#333',
																				fontSize: '14px',
																				fontWeight: '400',
																				lineHeight: 'normal'
																			}}
																			onDoubleClick={() => {
																				setModifyValue(name);
																				setSelectedId(subcId);
																			}}
																		>
																			{name}
																		</span>
																		<Minus
																			size={24}
																			color="#F04A5E"
																			style={{ cursor: 'pointer' }}
																			onClick={() => removeSubcategory(subcId)}
																		/>
																	</div>
																);
															}
														})}
													</div>
												</div>
											</div>
										);
									})}
								<CategoryRow
									setSurveyCategories={setSurveyCategories}
									setIsLoading={setIsLoading}
									setTempSurveyCategory={setTempSurveyCategory}
								/>
							</CardBody>
							<CardFooter>
								<Row
									className="pt-2 px-3"
									style={{ display: 'flex', flex: 'row', justifyContent: 'flex-end', gap: '8px' }}
								>
									<Link to={ROUTES.ENCUESTAS}>
										<Button outline color="primary">
											Cancelar
										</Button>{' '}
									</Link>
									<Button color="primary" onClick={handleSurveyToStep2}>
										Siguiente
									</Button>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer />
			<LoadingAffiliation isOpen={isLoading} />
		</Fragment>
	);
};

export default CreateSurvey1;
