import React, { Fragment, useState } from 'react';
import { Calendar, Minus, Plus } from 'react-feather';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Row,
	TabPane
} from 'reactstrap';
import Select from 'react-select';

import InternalLoader from 'layout/internal-loader';
import {
	useEntities,
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import DatePicker from 'react-date-picker';
import CreateEventHeader from './create-event-header';
import Tab from 'components/tab/Tab.';
import { useTab } from 'components/tab/useTab';
import TabContentContainer from 'components/tab/TabContent';
import Multiselect from 'components/multiselect/Multiselect';
import { useMultiSelectState } from 'components/multiselect/useMultiSelectState';
import AppliedTerritories from 'components/appliedTerritories/appliedTerritories';

import * as ROUTES from '../../../routes';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';

const TABS = ['Sección', 'Municipio', 'D. Local', 'D. Federal'];

const CreateEvent1 = ({ eventInfo } = {}) => {
	const navigate = useNavigate();

	const [eventName, setEventName] = useState(eventInfo ? eventInfo.name : '');
	const [eventTargetPeople, setEventTargetPeople] = useState(eventInfo ? eventInfo.targetPeople : 0);
	const [selectedEntity, setSelectedEntity] = useState(eventInfo ? eventInfo.entity : null);
	const [eventPlace, setEventPlace] = useState(eventInfo ? eventInfo.place : '');
	const [eventDate, setEventDate] = useState(eventInfo ? eventInfo.date : new Date());

	const { loading: loadingEntities, data: entities } = useEntities();

	const { activeTab, handleChangeTab } = useTab(TABS[0], { replaceUrl: false });

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	const { selectedOptions: selectedSections, handleChange: setSelectedSections } = useMultiSelectState();
	const { selectedOptions: selectedFDistrics, handleChange: setSelectedFDistrics } = useMultiSelectState();
	const { selectedOptions: selectedLDistrics, handleChange: setSelectedLDistrics } = useMultiSelectState();
	const { selectedOptions: selectedMunicipalities, handleChange: setSelectedMunicipalities } = useMultiSelectState();

	if (loadingEntities) {
		return <InternalLoader />;
	}

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const handleNextStep = () => {
		if (
			eventName &&
			eventTargetPeople > 0 &&
			selectedEntity &&
			eventPlace &&
			eventDate &&
			(selectedLDistrics.length > 0 ||
				selectedFDistrics.length > 0 ||
				selectedMunicipalities.length > 0 ||
				selectedSections.length > 0)
		) {
			navigate(ROUTES.CREATE_EVENT_2, {
				state: {
					eventsInfo: {
						name: eventName,
						targetPeople: eventTargetPeople,
						entity: selectedEntity?.value,
						place: eventPlace,
						date: eventDate,
						sections: selectedSections.map((section) => section.id),
						municipalities: selectedMunicipalities.map((municipality) => municipality.id),
						lDistricts: selectedLDistrics.map((lDistric) => lDistric.id),
						fDistricts: selectedFDistrics.map((fDistrics) => fDistrics.id)
					}
				}
			});
		} else {
			toast.error('Faltan datos en el formulario', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	return (
		<Fragment>
			<CreateEventHeader step={1} lastStep={2} />
			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Datos del evento</Row>
							</CardHeader>
							<CardBody className="py-3 px-3">
								<Row className="mb-2">
									<Col lg="10" sm="12">
										<FormGroup>
											<Label>{'Nombre'}</Label>
											<Input
												type="text"
												name="eventName"
												id="eventName"
												placeholder="Nombre de evento"
												value={eventName}
												onChange={(e) => setEventName(e.target.value)}
											/>
										</FormGroup>
									</Col>
									<Col lg="2" sm="12">
										<FormGroup>
											<Label>{'Número objetivo de personas'}</Label>
											<InputGroup>
												<button
													className="increment-decrement-voters decrement"
													onClick={() => {
														if (eventTargetPeople === 0) {
															setEventTargetPeople(0);
															return;
														}
														setEventTargetPeople((prevState) => prevState - 1);
													}}
												>
													<Minus size={16} />
												</button>
												<Input
													style={{ borderLeft: '0', borderRight: '0', textAlign: 'center' }}
													placeholder="Ingresar número"
													type="number"
													min={0}
													value={eventTargetPeople}
													pattern="[0-9]*"
													onChange={(e) =>
														setEventTargetPeople((v) =>
															e.target.validity.valid ? e.target.value : v
														)
													}
												/>
												<button
													className="increment-decrement-voters increment"
													onClick={() => setEventTargetPeople((prevState) => prevState + 1)}
												>
													<Plus size={16} />
												</button>
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col lg="5" sm="12">
										<FormGroup>
											<Label>{'Ubicación'}</Label>
											<Select
												isClearable
												name="selectedPlace"
												options={entitiesOptions}
												id="selectedPlace"
												onChange={(option) => {
													setSelectedEntity(option);
												}}
												placeholder="Seleccionar ubicación"
												value={selectedEntity}
											></Select>
										</FormGroup>
									</Col>
									<Col lg="5" sm="12">
										<FormGroup>
											<Label>{'Lugar de Realización'}</Label>
											<Input
												type="text"
												name="eventPlaceDetail"
												id="eventPlaceDetail"
												placeholder="Ejemplo de lugar: Mercado de San Jacinto"
												value={eventPlace}
												onChange={(e) => setEventPlace(e.target.value)}
											/>
										</FormGroup>
									</Col>
									<Col lg="2" sm="12">
										<FormGroup>
											<Label>{'Fecha de Realización'}</Label>
											<DatePicker
												value={eventDate}
												onChange={(value) => {
													//if (value.toLocaleDateString() < new Date().toLocaleDateString()) {
													if (moment(value).isBefore(moment(new Date()))) {
														setEventDate(new Date());
													} else {
														setEventDate(value);
													}
												}}
												clearIcon={false}
												format="dd/MM/yyyy"
												calendarIcon={<Calendar size={16} stroke="#F04A5E" />}
											/>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">{`Demarcaciones objectivo${
									selectedEntity ? `: ${selectedEntity.label}` : ''
								}`}</Row>
							</CardHeader>
							<CardBody className="py-3 px-3">
								<Row>
									<Col lg="6" sm="12" className="pr-4">
										<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} small />
										<TabContentContainer activeTab={activeTab} noPadding>
											<TabPane tabId={TABS[0]}>
												<Multiselect
													options={!selectedEntity ? [] : sections}
													onChange={setSelectedSections}
													loading={loadingSections}
													disabled={
														selectedMunicipalities.length > 0 ||
														selectedLDistrics.length > 0 ||
														selectedFDistrics.length > 0
													}
												/>
											</TabPane>
											<TabPane tabId={TABS[1]}>
												<Multiselect
													options={!selectedEntity ? [] : municipalities}
													onChange={setSelectedMunicipalities}
													loading={loadingMunicipalities}
													disabled={
														selectedSections.length > 0 ||
														selectedLDistrics.length > 0 ||
														selectedFDistrics.length > 0
													}
												/>
											</TabPane>
											<TabPane tabId={TABS[2]}>
												<Multiselect
													options={!selectedEntity ? [] : lDistrics}
													onChange={setSelectedLDistrics}
													loading={loadingLDistrics}
													disabled={
														selectedSections.length > 0 ||
														selectedMunicipalities.length > 0 ||
														selectedFDistrics.length > 0
													}
												/>
											</TabPane>
											<TabPane tabId={TABS[3]}>
												<Multiselect
													options={!selectedEntity ? [] : fDistrics}
													onChange={setSelectedFDistrics}
													loading={loadingFDistrics}
													disabled={
														selectedSections.length > 0 ||
														selectedMunicipalities.length > 0 ||
														selectedLDistrics.length > 0
													}
												/>
											</TabPane>
										</TabContentContainer>
									</Col>
									<Col lg="6" sm="12" className="pl-4">
										<AppliedTerritories
											sections={selectedSections}
											municipalities={selectedMunicipalities}
											lDistricts={selectedLDistrics}
											fDistrics={selectedFDistrics}
										/>
									</Col>
								</Row>
								<Row
									className="pt-2 px-3"
									style={{ display: 'flex', flex: 'row', justifyContent: 'flex-end' }}
								>
									<Link to={ROUTES.EVENTOS}>
										<Button outline color="primary" style={{ marginRight: '4px' }}>
											Cancelar
										</Button>{' '}
									</Link>
									<Button color="primary" style={{ marginLeft: '4px' }} onClick={handleNextStep}>
										Siguiente
									</Button>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer />
		</Fragment>
	);
};

export default CreateEvent1;
