import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getNeeds } from '../../../redux/actions';
import UploadIcon from '../../../assets/images/avatars/upload.svg';
import { ProgressBar } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstanceFiles } from 'utils/axiosInstance';
import { ModalComponent } from 'components/dashboard/modal';

const Needs = () => {
	const dispatch = useDispatch();
	const metrics = useSelector((state) => state.Metrics);
	const [dragActive, setDragActive] = useState(false);
	const [uploadingPercentage, setUploadingPercentage] = useState(0);
	const [uploadingPercentagePyra, setUploadingPercentagePyra] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [importError, setImportError] = useState([]);
	const [importData, setImportData] = useState('');
	const successToast = () => {
		toast.success('¡Archivo cargado con éxito!', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const errorToast = (message = '') => {
		toast.error(`¡Error al subir el archivo!, ${message}`, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const inputRef = useRef(null);

	const inputRefPyra = useRef(null);

	useEffect(() => {
		dispatch(getNeeds());
	}, [dispatch]);

	useEffect(() => {
		if (metrics.createNeedSuccess) {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		}
	}, [metrics.createNeedSuccess]);

	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleDragPyra = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleFile = function (file) {
		uploadFile(file[0]);
	};

	const handleFilePyra = function (file) {
		uploadFilePyra(file[0]);
	};

	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			handleFile(e.dataTransfer.files);
		}
	};

	const handleDropPyra = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			handleFilePyra(e.dataTransfer.files);
		}
	};

	// triggers when file is selected with click
	const handleChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			// at least one file has been selected so do something
			handleFile(e.target.files);
		}
	};

	const handleChangePyra = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			// at least one file has been selected so do something
			handleFilePyra(e.target.files);
		}
	};

	const onButtonClick = () => {
		inputRef.current.click();
	};

	const onButtonClickPyra = () => {
		inputRefPyra.current.click();
	};

	const uploadFile = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		await axiosInstanceFiles({
			url: '/masive-migrate-data/',
			method: 'post',
			data: formData,
			onUploadProgress: (data) => {
				setUploadingPercentage(Math.round((98 * data.loaded) / data.total));
			}
		})
			.then(({ data }) => {
				successToast();
				setUploadingPercentage(100);
				const resp = data.response.data.success;

				if (resp) {
					setImportData(`Se importo correctamente el archivo con ${resp.length ?? 10} registros`);
				}
			})
			.catch((err) => {
				console.log(err.response);
				errorToast();
				setUploadingPercentage(0);

				let errors = [];

				if (err.response.data.detail) {
					errors = err.response.data.detail;
					setImportError([[...errors.map((error) => Object.values(error))]]);
				}

				if (err.response.data.errors) {
					errors = err.response.data.errors;
					setImportError([[...errors.map((error) => Object.values(error))]]);
				}

				if (err.response.data.success) {
					setImportData(
						`Se importo correctamente el archivo con ${err.response.data.success.length ?? 10} registros`
					);
				}
			})
			.finally(() => {
				setUploadingPercentage(0);
				setOpenModal(true);
			});
	};

	const uploadFilePyra = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		await axiosInstanceFiles({
			url: '/activism/imports/',
			method: 'post',
			data: formData,
			onUploadProgress: (data) => {
				setUploadingPercentagePyra(Math.round((98 * data.loaded) / data.total));
			}
		})
			.then(({ data }) => {
				setUploadingPercentagePyra(100);
				successToast();
			})
			.catch((err) => {
				console.log({ err });
				errorToast(err.response.data.detail);
				setUploadingPercentagePyra(0);
			})
			.finally(() => {
				setUploadingPercentagePyra(0);
			});
	};

	return (
		<Fragment>
			<Breadcrumb parent="Configuración" title="CSV Importer" isAccount={true} />
			<ModalComponent
				title="Resultados de la importación"
				open={openModal}
				handleClose={() => {
					setOpenModal(false);
				}}
			>
				{importError.length > 0 ? (
					<>
						<Card>
							<CardHeader>
								<h5>Errores encontrados</h5>
							</CardHeader>
							<CardBody>
								<h6 style={{ color: 'red' }}>
									{importError.map((error, index) => (
										<>
											<span key={index}>{error}</span>
											<br></br>
										</>
									))}
								</h6>
							</CardBody>
						</Card>
						{importData && (
							<Card>
								<CardHeader>
									<h5>Importados:</h5>
								</CardHeader>
								<CardBody>
									<h6>{importData}</h6>
								</CardBody>
							</Card>
						)}
					</>
				) : (
					<Card>
						<CardHeader>
							<h5>Importación exitosa</h5>
						</CardHeader>
						<CardBody>
							<h6>{importData}</h6>
						</CardBody>
					</Card>
				)}
			</ModalComponent>
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h4>Importador Masivo</h4>
								<span>Selecciona el archivo a importar</span>
							</CardHeader>
							<CardBody>
								<form onDragEnter={handleDragPyra} onSubmit={(e) => e.preventDefault()}>
									{uploadingPercentagePyra === 0 && (
										<div
											className="importer"
											style={{ cursor: 'pointer' }}
											onClick={onButtonClickPyra}
										>
											<img src={UploadIcon} alt="upload icon" />
											<p>
												<b style={{ color: '#F04A5E' }}>Carga masiva de Pirámide</b>
											</p>
											<span>Selecciona el .csv a importar</span>
										</div>
									)}
									{uploadingPercentagePyra > 0 && (
										<div className="importer">
											<b>{uploadingPercentagePyra.toFixed(0)}%</b>
											<p>
												<span>Archivo subido y cargado</span>
											</p>
											<ProgressBar
												now={uploadingPercentagePyra}
												variant="success"
												style={{ width: '30%', height: '10px' }}
											/>
										</div>
									)}
									<input
										ref={inputRefPyra}
										type="file"
										id="input-file-upload"
										accept=".xlsx, .xls, .csv"
										multiple={false}
										onChange={handleChangePyra}
									/>
									{dragActive && (
										<div
											id="drag-file-element-pyra"
											onDragEnter={handleDragPyra}
											onDragLeave={handleDragPyra}
											onDragOver={handleDragPyra}
											onDrop={handleDropPyra}
										></div>
									)}
								</form>
								<form
									onDragEnter={handleDrag}
									onSubmit={(e) => e.preventDefault()}
									style={{ paddingTop: '40px' }}
								>
									{uploadingPercentage === 0 && (
										<div className="importer" style={{ cursor: 'pointer' }} onClick={onButtonClick}>
											<img src={UploadIcon} alt="upload icon" />
											<p>
												<b style={{ color: '#F04A5E' }}>Carga masiva de Electores</b>
											</p>
											<span>Selecciona el .csv a importar</span>
										</div>
									)}
									{uploadingPercentage > 0 && (
										<div className="importer">
											<b>{uploadingPercentage.toFixed(0)}%</b>
											<p>
												<span>Archivo subido y cargado</span>
											</p>
											<ProgressBar
												now={uploadingPercentage}
												variant="success"
												style={{ width: '30%', height: '10px' }}
											/>
										</div>
									)}
									<input
										ref={inputRef}
										type="file"
										id="input-file-upload"
										accept=".xlsx, .xls, .csv"
										multiple={false}
										onChange={handleChange}
									/>
									{dragActive && (
										<div
											id="drag-file-element"
											onDragEnter={handleDrag}
											onDragLeave={handleDrag}
											onDragOver={handleDrag}
											onDrop={handleDrop}
										></div>
									)}
								</form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer />
		</Fragment>
	);
};

export default Needs;
