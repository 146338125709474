import { useSearchParams } from 'react-router-dom';

export function usePagination() {
	// const [perPage, setPerPage] = useState(10);
	// const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const pageFromUrl = Number(searchParams.get('page') || 1);
	const perPageFromUrl = Number(searchParams.get('perPage') || 10);

	const handlePageChange = (page) => {
		// setPage(page);
		setSearchParams(`page=${page}${perPageFromUrl ? `&perPage=${perPageFromUrl}` : ''}`);
	};

	const handlePerRowsChange = (newPerPage, page) => {
		// setPage(page);
		// setPerPage(newPerPage);
		setSearchParams(`page=${page}${newPerPage ? `&perPage=${newPerPage}` : ''}`);
	};

	return { handlePageChange, handlePerRowsChange, page: pageFromUrl, perPage: perPageFromUrl };
}
