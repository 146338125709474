import { put, takeLatest } from 'redux-saga/effects';
import { CREATE_GOAL, GET_WEEKLY_REPORT, GET_GOALS, GET_CURRENT_GOAL } from '../actionTypes';
import {
	fetchWeeklyReportSuccess,
	fetchWeeklyReportError,
	isLoading,
	loadingCreateGoal,
	fetchCreateGoalSuccess,
	fetchCreateGoalError,
	fetchGetGoalsError,
	fetchGetGoalsSuccess,
	fetchGetCurrentGoalSuccess,
	fetchGetCurrentGoalError,
	loadingCreateNeed,
	fetchCreateNeedError,
	fetchCreateNeedSuccess,
	fetchGetNeedsSuccess,
	fetchGetNeedsError
} from '../actions';
import { gql } from '@apollo/client';
import { graphQlClient } from '../../store/graphql';
import { ErrorHandler } from '../errorHandler';
import { CREATE_NEED } from 'redux/actionTypes';
import { GET_NEEDS } from 'redux/actionTypes';
import { EDIT_NEED } from 'redux/actionTypes';

function* fetchWeeklyReport() {
	try {
		yield put(isLoading(true));
		yield put(fetchWeeklyReportError(''));
		const res = yield graphQlClient.query({
			query: gql`
				query weeklyReport {
					weeklyReport
				}
			`
		});

		yield put(isLoading(false));
		yield put(fetchWeeklyReportSuccess(res.data.weeklyReport));
	} catch (error) {
		ErrorHandler(error);
		yield put(isLoading(false));
		yield put(fetchWeeklyReportError(error.message));
	}
}

function* createGoal(goalData) {
	try {
		yield put(loadingCreateGoal(true));
		yield put(fetchCreateGoalError(''));
		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation kpiCreateOne($record: CreateOneKPISInput!) {
					kpiCreateOne(record: $record) {
						recordId
						record {
							descripcion
							dateStart
							dateFinish
							meta
							active
							_id
							updatedAt
							createdAt
						}
					}
				}
			`,
			variables: {
				...goalData.payload
			}
		});

		yield put(loadingCreateGoal(false));
		yield put(fetchCreateGoalSuccess(res.data.kpiCreateOne));
	} catch (error) {
		ErrorHandler(error);
		yield put(loadingCreateGoal(false));
		yield put(fetchCreateGoalError(error.message));
	}
}

function* fetchGoalList(pageData) {
	let variables = {};

	if (pageData.payload) {
		variables = {
			perPage: pageData.payload.perPage,
			page: pageData.payload.page
		};
	}

	try {
		yield put(isLoading(true));
		const res = yield graphQlClient.query({
			query: gql`
				query kpiPagination($page: Int!) {
					kpiPagination(page: $page) {
						count
						items {
							descripcion
							dateStart
							dateFinish
							meta
							active
							_id
							updatedAt
							createdAt
						}
						pageInfo {
							currentPage
							perPage
							pageCount
							itemCount
							hasNextPage
							hasPreviousPage
						}
					}
				}
			`,
			variables
		});

		const goalsList = {
			items: pageData.payload
				? pageData.payload.lastItems
					? pageData.payload.lastItems.concat(res.data.kpiPagination.items)
					: res.data.kpiPagination.items
				: [].concat(res.data.kpiPagination.items),
			pageInfo: res.data.kpiPagination.pageInfo
		};

		yield put(isLoading(false));
		yield put(fetchGetGoalsSuccess(goalsList));
	} catch (error) {
		yield put(isLoading(false));
		yield put(fetchGetGoalsError(error.message));
	}
}

function* fetchGetCurrentGoal() {
	try {
		yield put(isLoading(true));
		yield put(fetchGetCurrentGoalError(''));
		const res = yield graphQlClient.query({
			query: gql`
				query kpiCount {
					kpiCount
				}
			`
		});

		yield put(isLoading(false));
		yield put(fetchGetCurrentGoalSuccess(res.data.kpiCount));
	} catch (error) {
		ErrorHandler(error);
		yield put(isLoading(false));
		yield put(fetchGetCurrentGoalError(error.message));
	}
}

function* createNeed(needData) {

	try {
		yield put(loadingCreateNeed(true));
		yield put(fetchCreateNeedError(''));
		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation needCreateOne($record: CreateOneNeeds_ListInput!) {
					needCreateOne(record: $record) {
						record {
							active
							name
							label
							color
							icon
						}
					}
				}
			`,
			variables: {
				...needData.payload
			}
		});

		yield put(loadingCreateNeed(false));
		yield put(fetchCreateNeedSuccess(res.data.needCreateOne));
	} catch (error) {
		ErrorHandler(error);
		yield put(loadingCreateNeed(false));
		yield put(fetchCreateNeedError(error.message));
	}
}

function* editNeed(needData) {
	try {
		yield put(loadingCreateNeed(true));
		yield put(fetchCreateNeedError(''));
		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation needUpdateByIdmutation($_id: MongoID!, $record: UpdateByIdNeeds_ListInput!) {
					needUpdateById(_id: $_id, record: $record) {
						record {
							_id
							active
							label
							name
							color
							icon
						}
					}
				}
			`,
			variables: {
				...needData.payload
			}
		});

		yield put(loadingCreateNeed(false));
		yield put(fetchCreateNeedSuccess(res.data.needCreateOne));
	} catch (error) {
		ErrorHandler(error);
		yield put(loadingCreateNeed(false));
		yield put(fetchCreateNeedError(error.message));
	}
}

function* fetchNeedsList(pageData) {
	let variables = {};

	try {
		yield put(isLoading(true));
		const res = yield graphQlClient.query({
			query: gql`
				query needMany {
					needMany {
						_id
						active
						name
						label
						icon
						color
						createdAt
					}
				}
			`,
			variables
		});

		yield put(isLoading(false));
		yield put(fetchGetNeedsSuccess(res.data.needMany));
	} catch (error) {
		yield put(isLoading(false));
		yield put(fetchGetNeedsError(error.message));
	}
}

export function* Metrics() {
	yield takeLatest(GET_WEEKLY_REPORT, fetchWeeklyReport);
	yield takeLatest(CREATE_GOAL, createGoal);
	yield takeLatest(GET_GOALS, fetchGoalList);
	yield takeLatest(GET_CURRENT_GOAL, fetchGetCurrentGoal);
	yield takeLatest(CREATE_NEED, createNeed);
	yield takeLatest(GET_NEEDS, fetchNeedsList);
	yield takeLatest(EDIT_NEED, editNeed);
}
