import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Container, TabPane} from 'reactstrap';
import {useTab} from 'components/tab/useTab';
import TabContentContainer from 'components/tab/TabContent';
import Tab from 'components/tab/Tab.';
import Breadcrumb from "../../../../layout/breadcrumb";
import InformationTab from "./InformationTab";
import {useGetOneSupport} from "../../../../utils/fetch/supports";
import InstallTab from "./InstallTab";
import UninstallTab from "./UninstallTab";

const TABS = ['Información', 'Instalación', 'Desinstalación'];

function SupportsDetails() {
    const {activeTab, handleChangeTab} = useTab(TABS[0]);
    const {id} = useParams();

    const {
        data: support
    } = useGetOneSupport({id});

    const [selectedSupport, setSelectedSupport] = useState({});

    useEffect(() => {
        if (support && support !== {}) {
            setSelectedSupport(support._doc)
        }
    }, [support]);

    console.log(selectedSupport)

    return (
        <Container className="page-voter-details" fluid>
            <Breadcrumb parent="Apoyos" title={selectedSupport?.type}/>

            <Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS}/>

            <TabContentContainer activeTab={activeTab}>
                <TabPane tabId={TABS[0]}>
                    <InformationTab selectedSupport={selectedSupport}/>
                </TabPane>
                <TabPane tabId={TABS[1]}>
                    <InstallTab selectedSupport={selectedSupport}/>
                </TabPane>
                <TabPane tabId={TABS[2]}>
                    <UninstallTab selectedSupport={selectedSupport}/>
                </TabPane>
            </TabContentContainer>
        </Container>
    );
}

export default SupportsDetails;
