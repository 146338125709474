import React, {Fragment, useEffect, useState} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, Row} from 'reactstrap';
import Breadcrumb from "../../../../layout/breadcrumb";
import {Filter, Search} from "react-feather";
import DataTable from "react-data-table-component";
import {customStyles} from "../../electores/tableStyles";
import {useGetOneCampaign} from "../../../../utils/fetch/supports";
import {useParams} from "react-router-dom";

export function CellStatus({status}) {
    let colorState = 'warning';
    let labelState = 'create';

    if (status === 'install') {
        colorState = 'success';
        labelState = 'Instalado';
    } else if (status === 'remove') {
        colorState = 'danger';
        labelState = 'Removido';
    }
    return (
        <Badge color={colorState} pill>
            {labelState}
        </Badge>
    );
}

const data = [
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'create'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'install'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'create'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'remove'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'create'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'install'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'create'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'remove'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'create'},
    {name: '{nombre_tipo}', subType: '{nombre_subtipo}', lastUpdate: '28/4/2002', status: 'remove'},
];

const columns = [
    {
        name: 'Tipo',
        selector: (row) => row.name,
    },
    {
        name: 'Subtipo',
        selector: (row) => row.subType,
        center: true,
    },
    {
        name: 'Última actualización',
        selector: (row) => row.lastUpdate
    },
    {
        name: 'Estado',
        selector: (row) => row.status,
        center: true,
        cell: (row) => <CellStatus status={row.status}/>
    },

]

function CampaignDetails() {

    const {id} = useParams();
    const {
        data: campaign
    } = useGetOneCampaign({id});
    const [selectedCampaign, setSelectedCampaign] = useState({});

    useEffect(() => {
        if (campaign) {
            setSelectedCampaign(campaign._doc)
        }
    }, [campaign]);

    return (
        <Fragment>
            <Breadcrumb parent="Apoyos" title="Detalle de campaña"/>
            <Container className="page-voter-details" fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{padding: '32px 40px'}}>
                                <div className="main-data-container">
                                    <div className="avatar-name">
                                        <h2>{selectedCampaign?.name}</h2>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className="p-0">

                                <Row>
                                    <Col sm="12" md="6">
                                        <div style={{margin: '10px'}}>
                                            <div className="custom-detail-card">
                                                <div className="custom-title">
                                                    <h3 className="m-0">Datos generales</h3>
                                                </div>
                                                <div>
                                                    <p className="item-title">Líder de campaña</p>
                                                    <div className="item-description">Maria F. Rodriguez</div>
                                                </div>
                                                <div>
                                                    <p className="item-title">Apoyos asigandos</p>
                                                    <div className="item-description">5</div>
                                                </div>
                                                <div>
                                                    <p className="item-title">Apoyos instalados</p>
                                                    <div className="item-description">2</div>
                                                </div>
                                                <div>
                                                    <p className="item-title">Apoyos removidos</p>
                                                    <div className="item-description">3</div>
                                                </div>
                                                <div>
                                                    <p className="item-title">Apoyos en total</p>
                                                    <div className="item-description">10</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <Row style={{margin: "10px"}}>
                                            <h5>Lista de apoyos </h5>
                                            <Col
                                                lg="3"
                                                sm="6"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    height: '100%',
                                                    padding: '0px 4px'
                                                }}
                                            >
                                                <div>
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            lineHeight: '14px',
                                                            padding: '8px 16px'
                                                        }}
                                                    >
                                                        <Filter size={16}/>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col lg="5" sm="6" style={{height: '100%', padding: '0px 4px'}}>
                                                <InputGroup className="search-bar">
                                                    <Input
                                                        placeholder="Buscar por..."
                                                        className="pl-4 search-input"
                                                    />
                                                    <Search
                                                        style={{
                                                            position: 'absolute',
                                                            left: '6px',
                                                            bottom: '10px',
                                                            color: 'gray',
                                                            zIndex: 10
                                                        }}
                                                        size={16}
                                                    />
                                                    <Button
                                                        className="search-elector-button"
                                                        color="primary"
                                                    >
                                                        Buscar
                                                    </Button>
                                                </InputGroup>
                                            </Col>

                                        </Row>
                                        <div className="table-responsive product-table">
                                            <DataTable customStyles={customStyles} columns={columns}
                                                       data={data}
                                                       paginationComponentOptions={{
                                                           rowsPerPageText: 'Mensajes por página:',
                                                           rangeSeparatorText: 'de'
                                                       }}
                                                       pagination
                                                       paginationServer
                                                       highlightOnHover={true}/>
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default CampaignDetails;
