import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../../../../layout/breadcrumb';
import { Filter, XCircle } from 'react-feather';
import { useMap } from '../../../../utils/useMap';
import InternalLoader from 'layout/internal-loader';
import { mapSetup } from 'data/map-setup';
import bbox from '@turf/bbox';
import FilterCartographysModal from './FilterCartographyModal';
import {
	useEntities,
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { getDemarcationType } from 'utils/getDemarcationType';

const Cartography = () => {
	const mapContainer = useRef();

	const { map } = useMap(mapContainer);
	const [modal, setModal] = useState(false);

	const { loading: loadingStates, data: states } = useEntities();

	const [selectedState, setSelectedState] = useState(null);
	const [selectedLocaleDistrict, setSelectedLocaleDistrict] = useState([]);
	const [selectedFederalDistrict, setSelectedFederalDistrict] = useState([]);
	const [selectedMunicipality, setSelectedMunicipality] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedState);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedState);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedState);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedState);

	const [stateOptions, setStateOptions] = useState([]);

	const filterStates = {
		selectedState,
		selectedFederalDistrict,
		selectedLocaleDistrict,
		selectedMunicipality,
		selectedSection,
		setSelectedState,
		setSelectedLocaleDistrict,
		setSelectedFederalDistrict,
		setSelectedMunicipality,
		setSelectedSection
	};

	const filterOptions = {
		stateOptions,
		fDistrics,
		loadingFDistrics,
		lDistrics,
		loadingLDistrics,
		municipalities,
		loadingMunicipalities,
		sections,
		loadingSections
	};

	const toggle = () => setModal(!modal);

	const handleClearFilter = () => {
		setSelectedState(null);
		setSelectedLocaleDistrict([]);
		setSelectedFederalDistrict([]);
		setSelectedMunicipality([]);
		setSelectedSection([]);
	};

	const handleApplyFilter = () => {
		setModal(false);

		if (selectedState) {
			drawOnMap();
		}
	};

	const filterHandlers = {
		handleApplyFilter,
		handleClearFilter
	};

	const drawOnMap = () => {
		const stateCode = selectedState.value.toString();
		const stateData = mapSetup.find((x) => x.id === stateCode);

		let demarcationsArr = [];
		let demarcationType = '';

		if (
			selectedState &&
			selectedLocaleDistrict.length === 0 &&
			selectedFederalDistrict.length === 0 &&
			selectedSection.length === 0 &&
			selectedMunicipality.length === 0
		) {
			demarcationsArr = [selectedState.value];
			demarcationType = 'entidad';
		} else {
			if (selectedLocaleDistrict.length > 0) {
				demarcationsArr = selectedLocaleDistrict.map((demarcation) => demarcation.value);
				demarcationType = 'distrito_local';
			}

			if (selectedFederalDistrict.length > 0) {
				demarcationsArr = selectedFederalDistrict.map((demarcation) => demarcation.value);
				demarcationType = 'distrito';
			}

			if (selectedMunicipality.length > 0) {
				demarcationsArr = selectedMunicipality.map((demarcation) => demarcation.value);
				demarcationType = 'municipio';
			}

			if (selectedSection.length > 0) {
				demarcationsArr = selectedSection.map((demarcation) => demarcation.value);
				demarcationType = 'seccion';
			}
		}

		if (stateCode && demarcationsArr.length > 0) {
			drawDemarcationOnMap({ demarcations: demarcationsArr, demarcationType: demarcationType }, stateData);
		}
	};

	const PopupMap = ({ demarcationType, territory, close }) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
				<div>
					<Button close onClick={close}>
						<XCircle fill="red" size={16} color="white" className="p-0" />
					</Button>
				</div>
				<Card className="mb-0" style={{ width: '150px' }}>
					<CardTitle
						className="text-center mb-0 py-2"
						style={{
							opacity: 0.7,
							color: 'black',
							fontWeight: 'bold'
						}}
					>
						{`${demarcationType}: ${territory}`}
					</CardTitle>
				</Card>
			</div>
		);
	};

	const popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		anchor: 'bottom'
	});

	let root;

	const drawDemarcationOnMap = (demarcationObj, stateData) => {
		const demarcation =
			demarcationObj.demarcationType === 'distrito_local' ? 'DISTRITO_L' : demarcationObj.demarcationType;
		const uniqueDemarcationsArr = [...new Set(demarcationObj.demarcations)];
		const modelFilter = ['all', ['match', ['get', demarcation], uniqueDemarcationsArr, true, false]];
		const sourceObj = stateData?.sources?.find((x) => x.type === demarcationObj.demarcationType);

		map.setStyle(stateData.style)
			.once('styledata', () => {
				map.setLayoutProperty(sourceObj.source, 'visibility', 'visible')
					.setFilter(sourceObj.source, modelFilter)
					.easeTo({
						zoom: 7,
						center: [stateData.location?.lng, stateData.location?.lat],
						curve: 1.5,
						speed: 0.5
					});
			})
			.once('moveend', () => {
				let features = map.querySourceFeatures('composite', {
					filter: modelFilter,
					sourceLayer: sourceObj.source,
					validate: false
				});

				if (features.length) {
					const bboxPolygons = bbox({
						type: 'FeatureCollection',
						features: features
					});

					features.forEach((feature) => {
						map.setFeatureState({
							source: 'composite',
							sourceLayer: sourceObj.source,
							id: feature.id
						})
							.setPaintProperty(sourceObj.source, 'fill-color', 'rgba(240, 74, 94, 0.3)')
							.setPaintProperty(sourceObj.source, 'fill-outline-color', '#333');
					});

					const ref = React.createRef();
					ref.current = document.createElement('div');

					map.on('mousemove', sourceObj.source, (e) => {
						const coordinates = e.lngLat;

						let territory;

						if (demarcation === 'entidad' || demarcation === 'municipio') {
							territory = e.features[0].properties.nombre;
						} else {
							territory = e.features[0].properties[demarcation];
						}

						if (!root) root = createRoot(ref.current);

						const closePopup = () => {
							popup.remove();
						};

						root.render(
							<PopupMap
								territory={territory}
								demarcationType={getDemarcationType(demarcationObj.demarcationType)}
								close={closePopup}
							/>
						);

						popup.setLngLat(coordinates).setDOMContent(ref.current).addTo(map);
					});

					map.on('mouseenter', sourceObj.source, () => {
						map.getCanvas().style.cursor = 'pointer';
					});

					map.on('mouseleave', sourceObj.source, () => {
						popup.remove();
						map.getCanvas().style.cursor = '';
					});

					map.fitBounds(bboxPolygons, {
						padding: { top: 10, bottom: 25, left: 15, right: 5 },
						maxZoom: uniqueDemarcationsArr.length > 1 ? 10.7 : 12
					});
				}
			});
	};

	useEffect(() => {
		if (states) {
			const mappedStates = Object.values(states || [])?.map((entity) => {
				return {
					label: entity.name,
					value: entity.code
				};
			});
			setStateOptions(mappedStates);
		}
	}, [states]);

	return (
		<Fragment>
			<Breadcrumb parent="Mapas" title="Cartografía" />
			<Container fluid={true}>
				<Row>
					{loadingStates ? (
						<InternalLoader />
					) : (
						<Col sm="12">
							<Card>
								<CardHeader style={{ padding: '32px 40px' }}>
									<Row className="electores-header">
										<Col>
											<h5>Seleccione filtros para buscar</h5>
										</Col>
										<Col className="filter-elector-headers">
											<Col
												lg="12"
												sm="12"
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													height: '100%',
													padding: '0px 4px'
												}}
											>
												<div>
													<button
														className="btn btn-primary"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															fontWeight: '400',
															fontSize: '12px',
															lineHeight: '14px',
															padding: '8px 16px'
														}}
														onClick={toggle}
													>
														<span>Filtrar</span>
														<Filter size={16} />
													</button>
												</div>
											</Col>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					)}
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="mapContainer" ref={mapContainer} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<FilterCartographysModal
				isOpen={modal}
				toggle={toggle}
				filterStates={filterStates}
				filterOptions={filterOptions}
				filterHandlers={filterHandlers}
			/>
		</Fragment>
	);
};

export default Cartography;
