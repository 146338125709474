import { gql, useQuery } from '@apollo/client';
import InternalLoader from 'layout/internal-loader';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, TabPane } from 'reactstrap';
import { graphQlClient } from 'store/graphql';
import GeneralTab from './general-tab/GeneralTab';
import SmsTab from './sms-tab/SmsTab';
import { useTab } from 'components/tab/useTab';
import TabContentContainer from 'components/tab/TabContent';
import Tab from 'components/tab/Tab.';
import { CellGender } from '../electores/Electores';
import PyramidTab from './pyramid-tab/PyramidTab';
import SocialMediaTab from './socialMediaTab/SocialMediaTab';
import MilitanciaTab from './militanciaTab/MilitanciaTab';
import EventosTab from './eventosTab/EventosTab';
import manAvatar from '../../../assets/images/avatars/man-avatar.svg';
import womanAvatar from '../../../assets/images/avatars/woman-avatar.svg';
import MeetingsTab from './meetings-tab/MeetingsTab';
import { axiosInstanceV1 } from 'utils/axiosInstance';
import { getAgeWithMoment } from 'utils/getAgeFromDate';
import moment from 'moment';
import DetailNotFound from 'pages/detailNotFound';

const getElector = gql`
	query IneOne($filter: FilterFindOneMexican_VoterInput) {
		ineOne(filter: $filter) {
			ocr
			userId
			status
			phoneNumber
			scanLocation {
				lat
				lng
			}
			socialMedia
			updatedAt
			createdAt
			user
			need
			fields
			specialGroups
			typeAffiliate
			nameSpecialGroups
			verified
			isManual
		}
	}
`;

const TABS = ['General', 'SMS', 'Eventos', 'Pirámide', 'Militancia', 'Redes S.', 'Reuniones'];

function VoterDetails() {
	const { activeTab, handleChangeTab } = useTab(TABS[0]);
	const { id: curp } = useParams();
	const { loading, data: voterDetail } = useQuery(getElector, {
		client: graphQlClient,
		variables: {
			filter: {
				ocr: {
					curp: curp
				}
			}
		}
	});

	const { ocr: personData, scanLocation, ...otherData } = voterDetail?.ineOne || {};

	const [loadingCredentials, setLoadingCredentials] = useState(false);
	const [dataCredentials, setDataCredentials] = useState({});

	// const { loading: loadingCredentials, data: dataCredentials } = useCredentials(curp);

	const fetchDataCredentials = useCallback(() => {
		setLoadingCredentials(true);

		axiosInstanceV1({
			method: 'GET',
			url: `/credentials/${curp}`
		})
			.then((res) => {
				setDataCredentials(res.data);
			})
			.catch((err) => console.log(err))
			.finally(() => setLoadingCredentials(false));
	}, [curp]);

	useEffect(() => {
		fetchDataCredentials();
	}, [fetchDataCredentials]);

	if (loading || loadingCredentials || !voterDetail) {
		return <InternalLoader />;
	}

	if (!personData) {
		return <DetailNotFound moduleTitle={'Elector'} />;
	}

	return (
		<Container className="page-voter-details" fluid>
			<div className="main-data-container">
				<div className="avatar-name">
					{dataCredentials?.text_detect?.fields?.passport_photo ? (
						<img src={dataCredentials?.text_detect?.fields?.passport_photo} alt="user avatar" />
					) : personData?.gender.toLocaleLowerCase() === 'hombre' ? (
						<img src={manAvatar} alt="user avatar" />
					) : (
						<img src={womanAvatar} alt="user avatar" />
					)}
					<h2>
						{dataCredentials?.text_detect?.fields?.first_name}{' '}
						{dataCredentials?.text_detect?.fields?.last_name}
					</h2>
				</div>
				{personData && (
					<div className="main-data-list">
						<ul className="list-horizontal">
							<li key={0}>
								<CellGender gender={personData.gender} />
							</li>
							<li key={1}>{getAgeWithMoment(personData.date_birth)} años</li>
							<li key={2}>{personData.section}</li>
							<li key={3}>{personData.state}</li>
							<li key={4}>{moment(personData.date_birth).format('DD/MM/YYYY')}</li>
						</ul>
					</div>
				)}
			</div>

			<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} />

			<TabContentContainer activeTab={activeTab}>
				<TabPane tabId={TABS[0]}>
					<GeneralTab
						scanLocation={scanLocation}
						dataCredentials={dataCredentials || {}}
						otherDataIne={otherData || {}}
						personData={personData}
						refetch={fetchDataCredentials}
					/>
				</TabPane>
				<TabPane tabId={TABS[1]}>
					<SmsTab personData={personData} otherData={otherData} />
				</TabPane>
				<TabPane tabId={TABS[2]}>
					<EventosTab voterId={dataCredentials?.id || null} />
				</TabPane>
				<TabPane tabId={TABS[3]}>
					<PyramidTab
						dataCredentials={dataCredentials || {}}
						isRecruiter={dataCredentials?.is_recruiter || []}
					/>
				</TabPane>
				<TabPane tabId={TABS[4]}>
					<MilitanciaTab dataCredentials={dataCredentials || {}} fullName={personData?.full_name || ''} />
				</TabPane>
				<TabPane tabId={TABS[5]}>
					<SocialMediaTab socialMedia={otherData.socialMedia || {}} />
				</TabPane>
				<TabPane tabId={TABS[6]}>
					<MeetingsTab curp={curp} roles={dataCredentials?.user_roles} />
				</TabPane>
			</TabContentContainer>
		</Container>
	);
}

export default VoterDetails;
