import React from 'react';
import { XCircle } from 'react-feather';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';

const PopupMap = ({ demarcationType, territory, voters, target, percentage, closePopup, color }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
			<div>
				<Button close onClick={closePopup}>
					<XCircle fill="red" size={16} color="white" className="p-0" />
				</Button>
			</div>
			<Card className="mb-0" style={{ width: 'auto' }}>
				<CardTitle
					className="text-center mb-0 py-2"
					style={{
						backgroundColor: `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, ${color.a})`,
						opacity: 0.7,
						color: 'black',
						fontWeight: 'bold'
					}}
				>
					{`${demarcationType}: ${territory}`}
				</CardTitle>
				<CardBody className="py-2 px-2">
					<ul>
						<li>{`Votantes: ${voters ? voters : 0}`}</li>
						<li>{`Objetivo: ${target ? target : 'Sin meta'}`}</li>
						<li>{`Porcentaje: ${percentage.toFixed(2)}%`}</li>
					</ul>
				</CardBody>
			</Card>
		</div>
	);
};

export default PopupMap;
