export const toSentenceCase = (str) => {
    if (str) {
        const stringArr = str.toLowerCase().split(" ")

        for (let i = 0; i < stringArr.length; i++) {
            stringArr[i] = stringArr[i].charAt(0).toUpperCase() + stringArr[i].slice(1)
        }
        return stringArr.join(" ")
    } else {
        return ""
    }
}

    export const transformDemarcationType = (str) => {
        // eslint-disable-next-line default-case
        switch (str) {
            case 'section':
                return 'Sección';
            case 'local_district':
                return 'Distrito local';
            case 'municipality':
                return 'Municipio';
            case 'federal_district':
                return 'Distrito Federal';
        }
    }
