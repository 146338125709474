import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from 'layout/breadcrumb';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Input, InputGroup, Col, Card, CardHeader, CardBody, InputGroupText } from 'reactstrap';

import DataTable from 'react-data-table-component';
import InternalLoader from 'layout/internal-loader';

import { customStyles } from 'components/dashboard/electores/tableStyles';
import { Filter, MapPin, PlusCircle, Search } from 'react-feather';
import { usePagination } from 'utils/fetch/pagination';
import { usePyramids } from 'utils/fetch/pyramids';
import moment from 'moment';
import './pyramidList.scss';
import DemarcacionModal from './DemarcacionModal';
import { axiosInstance } from 'utils/axiosInstance';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';
import FilterPyramidModal from './FilterPyramidsModal';
import { useEntities } from 'utils/fetch/entities';

export default function Piramides(props) {
	/* Datatable states */
	const [piramides, setPiramides] = useState([]);
	const [count, setCount] = useState(0);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [demarcacionModal, setDemarcacionModal] = useState(false);
	const demarcacionToggle = () => setDemarcacionModal(!demarcacionModal);

	const [demarcacionData, setDemarcacionData] = useState({});

	/* States for the table pagination */
	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	/* Filter states */
	const [loadingFilter, setLoadingFilter] = useState(false);

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedLevels, setSelectedLevels] = useState(null);
	const [selectedEntity, setSelectedEntity] = useState(null);
	const [selectedLDistricts, setSelectedLDistricts] = useState([]);
	const [selectedFDistricts, setSelectedFDistricts] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);
	const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);

	const { loading: loadingEntities, data: entities } = useEntities();
	const [loadingSections, setLoadingSections] = useState(false);

	/**
	 * Manage the options of multiselects
	 */
	const [localeDistrictsOptions, setLocaleDistrictsOptions] = useState([]);
	const [federalDistrictsOptions, setFederalDistrictsOptions] = useState([]);
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [sectionsOptions, setSectionsOptions] = useState([]);

	const [search, setSearch] = useState('');

	/**
	 * Fetch pyramids data
	 */
	const { loading, data } = usePyramids({ page: page, perPage: perPage });

	useEffect(() => {
		if (data) {
			setCount(data.count);
			setPiramides(data.results);
		}
	}, [data]);

	const handleSelectedEntityChange = async (entity) => {
		setSectionsOptions([]);
		setMunicipalityOptions([]);
		setLocaleDistrictsOptions([]);
		setFederalDistrictsOptions([]);
		setSelectedLDistricts([]);
		setSelectedFDistricts([]);
		setSelectedSection([]);
		setSelectedMunicipalities([]);

		if (!entity) {
			setSelectedEntity();
			return;
		}

		setSelectedEntity(entity);

		setLoadingSections(true);
		const mappedSections = await axiosInstance({
			method: 'GET',
			url: `/sections/?no_paginate=true&fk_municipality__fk_local_district__fk_district__fk_entity__id=${entity.value}`
		})
			.then((res) =>
				res.data.map((sec) => {
					return {
						label: sec.code,
						value: sec.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedMunicipalities = await axiosInstance({
			method: 'GET',
			url: `/municipality/?no_paginate=true&fk_local_district__fk_district__fk_entity_id=${entity.value}`
		})
			.then((res) =>
				res.data.map((mun) => {
					return {
						label: mun.nombre,
						value: mun.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedLDistricts = await axiosInstance({
			method: 'GET',
			url: `/local-distric/?no_paginate=true&fk_district__fk_entity_id=${entity.value}`
		})
			.then((res) =>
				res.data.map((lD) => {
					return {
						label: lD.code,
						value: lD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedFDistricts = await axiosInstance({
			method: 'GET',
			url: `/districts/?no_paginate=true&fk_entity_id=${entity.value}`
		})
			.then((res) =>
				res.data.map((fD) => {
					return {
						label: fD.code,
						value: fD.code
					};
				})
			)
			.catch((err) => console.log(err));

		setSectionsOptions(mappedSections);
		setMunicipalityOptions(mappedMunicipalities);
		setLocaleDistrictsOptions(mappedLDistricts);
		setFederalDistrictsOptions(mappedFDistricts);

		setLoadingSections(false);
	};

	if (loading || loadingEntities) return <InternalLoader />;

	const handleFilterPyramid = async () => {
		setLoadingFilter(true);
		let params = '';

		if (search) {
			const finalQuery = search.replace(/\s/, '%20');
			params += `&name__icontains=${finalQuery}`;
		}

		if (selectedLevels) {
			params += `&levels_number=${selectedLevels.value}`;
		}

		if (selectedEntity) {
			params += `&entities=${selectedEntity.value}`;
		}

		if (selectedFDistricts.length > 0) {
			selectedFDistricts.forEach(({ value }) => {
				params += `&districts=${value}`;
			});
		}

		if (selectedLDistricts.length > 0) {
			selectedLDistricts.forEach(({ value }) => {
				params += `&districts_local=${value}`;
			});
		}

		if (selectedMunicipalities.length > 0) {
			selectedMunicipalities.forEach(({ value }) => {
				params += `&municipalities=${value}`;
			});
		}

		if (selectedSection.length > 0) {
			selectedSection.forEach(({ value }) => {
				params += `&sections=${value}`;
			});
		}

		if (selectedOrder) {
			switch (selectedOrder.value) {
				case 'aToZ':
					params += '&ordering=name';
					break;
				case 'zToA':
					params += '&ordering=-name';
					break;
				case 'newer':
					params += '&ordering=-created_at';
					break;
				default:
					params += '&ordering=created_at';
					break;
			}
		}

		const url = `/activism/estructure/?page=${page}&size=${perPage}${params}`;

		await axiosInstance({ method: 'GET', url: url })
			.then(({ data }) => {
				setPiramides(data.results);
				setCount(data.count);
			})
			.catch((err) => console.log({ err }))
			.then(() => setLoadingFilter(false));

		setModal(false);
	};

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const filterStates = {
		selectedOrder,
		setSelectedOrder,
		selectedLevels,
		setSelectedLevels,
		selectedEntity,
		setSelectedEntity,
		selectedLDistricts,
		setSelectedLDistricts,
		selectedFDistricts,
		setSelectedFDistricts,
		selectedSection,
		setSelectedSection,
		selectedMunicipalities,
		setSelectedMunicipalities
	};

	const filterOptions = {
		loadingSections,
		entitiesOptions,
		localeDistrictsOptions,
		federalDistrictsOptions,
		municipalityOptions,
		sectionsOptions
	};

	const filterHandlers = {
		handleFilterPyramid,
		handleSelectedEntityChange
	};

	const columns = [
		{
			name: 'Piramide',
			selector: (row) => row.name,
			center: true
		},
		{
			name: 'Descripción',
			center: true,
			selector: (row) => row.description
		},
		{
			name: 'Niveles',
			center: true,
			selector: (row) => row.levels_number
		},
		{
			name: 'Demarcación',
			center: true,
			selector: (row) => row.entity,
			cell: (row, id) => (
				<div className="cell-gender p-1">
					<MapPin
						size="16"
						id={`demarcacion-pin-${id}`}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setDemarcacionData({
								entities: row.related_entities,
								municipalities: row.related_municipality,
								fDistricts: row.related_districts,
								lDistricts: row.related_districts_local,
								sections: row.related_sections
							});

							demarcacionToggle();
						}}
					/>
				</div>
			)
		},
		{
			name: 'Fecha de registro',
			center: true,
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY')
		},
		{
			cell: (row) => (
				<Link className="actionButton" to={row.id ? `/piramides/${row.id}` : '#'}>
					<i className="fa fa-angle-right"></i>
				</Link>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	return (
		<Fragment>
			<Breadcrumb title="Pirámide" />
			<Container fluid>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: '32px 40px' }}>
								<Row className="events-header">
									<Col lg={4}>
										<h5 style={{ order: '0' }}>Lista de pirámides</h5>
										<span
											style={{
												color: '#AFAFAF',
												fontWeight: '400',
												fontSize: '14px',
												lineHeight: '17px'
											}}
										>{`${count} eventos`}</span>
									</Col>
									<Col className="filter-events-header">
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<Button
												className="button-content headers-button"
												style={{ width: '161px' }}
												color="primary"
												onClick={toggle}
											>
												<span>Filtrar y Ordenar</span>
												<Filter size={16} />
											</Button>
										</div>
										<div
											style={{
												display: 'flex',
												height: '33PX',
												width: '320px'
											}}
										>
											<InputGroup>
												<InputGroupText
													style={{
														height: '33px',
														borderRadius: '6px 0px 0px 6px',
														backgroundColor: 'transparent',
														borderRight: '0px',
														padding: '2px 6px'
													}}
												>
													<Search
														style={{
															color: 'gray'
														}}
														size={16}
													/>
												</InputGroupText>
												<Input
													style={{
														height: '33px',
														borderRadius: '0px',
														backgroundColor: 'transparent',
														borderRight: '0px',
														borderLeft: '0px'
													}}
													placeholder="Buscar por..."
													className="pl-0"
													onChange={(e) => setSearch(e.target.value)}
												/>
												<Button
													className="button-content"
													style={{
														height: '33px',
														width: '78px',
														borderRadius: '0px 6px 6px 0px',
														borderLeft: '0px',
														padding: '2px 6px'
													}}
													color="primary"
													onClick={handleFilterPyramid}
												>
													Buscar
												</Button>
											</InputGroup>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<Link to={'/piramides/create'}>
												<Button
													className="button-content headers-button"
													style={{ width: '131px' }}
													color="primary"
												>
													<PlusCircle size={16} />
													<span>Crear nuevo</span>
												</Button>
											</Link>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody className="p-0">
								<div className="table-responsive product-table">
									<DataTable
										noHeader
										customStyles={customStyles}
										columns={columns}
										data={piramides}
										progressComponent={<InternalLoader />}
										progressPending={loading}
										highlightOnHover={true}
										paginationComponentOptions={{
											rowsPerPageText: 'Pirámides por página:',
											rangeSeparatorText: 'de'
										}}
										paginationRowsPerPageOptions={[2, 3, 10, 15, 20, 25, 30, 50]}
										pagination
										paginationServer
										paginationTotalRows={count}
										onChangeRowsPerPage={handlePerRowsChange}
										onChangePage={handlePageChange}
										paginationDefaultPage={page}
										paginationPerPage={perPage}
										noDataComponent={'No se han encontrado pirámides'}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<FilterPyramidModal
				isOpen={modal}
				toggle={toggle}
				filterHandlers={filterHandlers}
				filterOptions={filterOptions}
				filterStates={filterStates}
			/>
			<DemarcacionModal isOpen={demarcacionModal} toggle={demarcacionToggle} data={demarcacionData} />
			<LoadingAffiliation isOpen={loadingFilter} />
		</Fragment>
	);
}
