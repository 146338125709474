export const getDemarcationType = (demarcationType) => {
	switch (demarcationType) {
		case 'distrito_local':
			return 'D. Local';
		case 'distrito':
			return 'D. Federal';
		case 'municipio':
			return 'Municipio';
		case 'seccion':
			return 'Sección';
		case 'entidad':
			return 'Entidad';
		default:
			return 'Territorio';
	}
};
