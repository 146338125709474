import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useTab(initialSelectedTab, { replaceUrl = true } = {}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const initialValue = replaceUrl ? searchParams.get('tab') || initialSelectedTab : initialSelectedTab;
	const [activeTab, setActiveTab] = useState(initialValue);

	useEffect(() => {
		setActiveTab((prevState) => (!prevState ? initialSelectedTab : prevState));
	}, [initialSelectedTab, setActiveTab]);

	const handleChangeTab = (tabId) => {
		setActiveTab(tabId);
		replaceUrl && setSearchParams(`tab=${tabId}`);
	};

	return { activeTab, handleChangeTab };
}
