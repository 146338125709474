import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Input, InputGroup, Row } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import { graphQlClient } from 'store/graphql';

import SendMessageForm from 'components/sms/SendMessageForm';
import PreviewMessage from 'components/sms/PreviewMessage';

import './smstab.scss';
import InternalLoader from 'layout/internal-loader';
import SmsTable from './SmsTable';
import FilterSmsModal from 'components/dashboard/sms/send-tab/FilterSmsModal';
import { Filter, Search } from 'react-feather';
import { usePagination } from '../../../../utils/fetch/pagination';
import { useSmsWithPagination } from '../../../../utils/fetch/sms';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { useGetSmsAvailable } from '../../../../utils/fetch/electores';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { axiosInstanceV1 } from '../../../../utils/axiosInstance';
import filterDemarcations from '../../../../utils/filterDemarcations';
import { useGetStatesByCredentials } from '../../../../utils/fetch/useGetStatesByCredentials';
import { getAgeFromDate } from 'utils/getAgeFromDate';

const CREATE_SMS = gql`
	mutation Insert_sms($record: CreateOnesmsInput!) {
		insert_sms(record: $record) {
			record {
				status
				_id
				message
			}
		}
	}
`;

export function FilterComponent({ onShowModal, title, withoutBackground, search, setSearch, handleApplyFilter }) {
	return (
		<Container fluid className={`${withoutBackground ? '' : 'bg-light-primary'} py-2 px-4 filterSmsContainer`}>
			<Row className="align-items-center">
				<Col sm="auto" className="mr-auto">
					<h3 className={`${withoutBackground ? '' : 'font-primary'} f-16  m-0`}>
						{title || 'Lista de mensajes'}
					</h3>
				</Col>
				<Col className="header-options" sm="auto">
					<Button
						size="md"
						className="filterButton ml-2 px-2 d-flex align-items-center"
						color="primary"
						onClick={onShowModal}
					>
						Filtrar y ordenar
						<Filter className="ml-2" size={16} />
					</Button>

					<InputGroup className="search-bar">
						<Search size={16} />
						<Input
							placeholder="Buscar por ID"
							className="pl-4 search-input"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<Button className="" color="primary" onClick={handleApplyFilter}>
							Buscar
						</Button>
					</InputGroup>
				</Col>
			</Row>
		</Container>
	);
}

export default function SmsTab({ personData, otherData }) {
	const [modal, setModal] = useState(false);
	const [filterParams, setFilterParams] = useState({});
	const [isScheduledSms, setIsScheduledSms] = useState(false);

	const [stateOptions, setStateOptions] = useState([]);
	const [localeDistrictOptions, setLocaleDistrictOptions] = useState([]);
	const [federalDistrictOptions, setFederalDistrictOptions] = useState([]);
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [sectionOptions, setSectionOptions] = useState([]);

	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	const [selectedLocaleDistrict, setSelectedLocaleDistrict] = useState([]);
	const [selectedFederalDistrict, setSelectedFederalDistrict] = useState([]);
	const [selectedMunicipality, setSelectedMunicipality] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);

	const [search, setSearch] = useState('');

	const [loadingDemarcations, setLoadingDemarcations] = useState(false);
	const [sortObj, setSortObj] = useState('CREATED_AT_DESC');

	const [message, setMessage] = useState('');
	const [dateToSend, setDateToSend] = useState('');
	const [timeToSend, setTimeToSend] = useState('');

	const [clientId, setClientId] = useState('demo');

	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmModalMsg, setConfirmModalMsg] = useState('');
	const [record, setRecord] = useState({});

	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();
	const {
		loading,
		data: { items, count }
	} = useSmsWithPagination({
		perPage: perPage,
		page: page,
		filter: filterParams,
		sort: sortObj
	});

	const { data: states } = useGetStatesByCredentials();

	const [createSms, { loading: loadingCreate }] = useMutation(CREATE_SMS, {
		errorPolicy: 'all',
		client: graphQlClient,
		onCompleted: () => {
			toast.success('SMS enviado con exito!', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		},
		onError: () => {
			toast.error('Error al enviar SMS masivo', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	});

	const [getSmsAvailable, { loading: loadingSmsAvailable }] = useGetSmsAvailable({ clientId });

	const handleSelectedStateChange = async (state) => {
		setMunicipalityOptions([]);
		setLocaleDistrictOptions([]);
		setFederalDistrictOptions([]);
		setSectionOptions([]);

		if (!state) {
			setSelectedState(null);
			return;
		}

		setSelectedState(state);

		setLoadingDemarcations(true);

		const mappedLDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-local-district-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((lD) => {
					return {
						label: lD.code,
						value: lD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedFDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-districts-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((fD) => {
					return {
						label: fD.code,
						value: fD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedSections = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-sections-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((sec) => {
					return {
						label: sec.code,
						value: sec.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedMunicipalities = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-municipalities-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((mun) => {
					return {
						label: mun.nombre,
						value: mun.code
					};
				})
			)
			.catch((err) => console.log(err));

		setSectionOptions(filterDemarcations(mappedSections));
		setMunicipalityOptions(filterDemarcations(mappedMunicipalities));
		setLocaleDistrictOptions(filterDemarcations(mappedLDistricts));
		setFederalDistrictOptions(filterDemarcations(mappedFDistricts));

		setLoadingDemarcations(false);
	};

	const toggle = (state) => {
		setConfirmModal(!confirmModal);

		if (state) {
			createSms({
				variables: {
					record
				}
			});
		}
	};

	const toggleModal = () => setModal(!modal);

	const [filterText, setFilterText] = useState('');

	useEffect(() => {
		if (personData) {
			setFilterParams({
				destinataries: [
					{
						firstname: personData?.first_name,
						lastname: personData?.last_name,
						phoneNumber: otherData?.phoneNumber
					}
				]
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personData]);

	useEffect(() => {
		const baseUrl = window.location.host;
		console.log('Base URL:', baseUrl);
		if (window.location) {
			if (baseUrl.indexOf('localhost') !== -1) {
				setClientId('demo');
			} else {
				console.log(baseUrl.split('.'));
				console.log(baseUrl.split('.')[0]);
				let clientIdFound = baseUrl.split('.')[0];

				setClientId(clientIdFound);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location]);

	const filterSms = {
		selectedOrder,
		setSelectedOrder,
		selectedDate,
		setSelectedDate,
		selectedStatus,
		setSelectedStatus,
		selectedGender,
		setSelectedGender,
		selectedState,
		setSelectedState,
		selectedLocaleDistrict,
		setSelectedLocaleDistrict,
		selectedFederalDistrict,
		setSelectedFederalDistrict,
		selectedMunicipality,
		setSelectedMunicipality,
		selectedSection,
		setSelectedSection,
		setLocaleDistrictOptions,
		setFederalDistrictOptions,
		setMunicipalityOptions,
		setSectionOptions
	};

	const filterOptions = {
		loadingDemarcations,
		stateOptions,
		localeDistrictOptions,
		federalDistrictOptions,
		municipalityOptions,
		sectionOptions
	};

	const handleApplyFilter = () => {
		let filterObj = {};
		let filter = {};
		let order = {};

		if (selectedOrder) {
			let orderValue = selectedOrder.value;

			switch (orderValue) {
				case 'newer':
					order = 'CREATED_AT_DESC';
					break;

				case 'older':
					order = 'CREATED_AT_ASC';
					break;

				default:
					order = 'CREATED_AT_DESC';
					break;
			}

			setSortObj(order);
		} else {
			setSortObj('CREATED_AT_DESC');
		}

		filterObj.destinataries = [
			{
				firstname: personData?.first_name,
				lastname: personData?.last_name,
				phoneNumber: otherData?.phoneNumber
			}
		];

		if (search) filterObj._id = search;

		if (selectedGender && selectedGender.length > 0)
			filter.gender = selectedGender.map((gender) => gender.value.toString());

		if (selectedState) filter.state = selectedState.label;

		if (selectedStatus) filterObj.status = selectedStatus.value;

		if (selectedLocaleDistrict.length > 0)
			filter.localDistrict = selectedLocaleDistrict.map((lDistrict) => lDistrict.value.toString());

		if (selectedFederalDistrict.length > 0)
			filter.federalDistrict = selectedFederalDistrict.map((fDistrict) => fDistrict.value.toString());

		if (selectedSection.length > 0) filter.sections = selectedSection.map((section) => section.value.toString());

		if (selectedDate) filter.createdAt = new Date(selectedDate).toISOString().split('T')[0];

		if (Object.keys(filter).length > 0) filterObj.filter = filter;

		setFilterParams(filterObj);

		setModal(false);
	};

	const filterHandlers = {
		handleApplyFilter,
		handleSelectedStateChange
	};

	useEffect(() => {
		if (states) {
			const mappedStates = Object.values(states || [])?.map((entity) => {
				return {
					label: entity.name,
					value: entity.code
				};
			});
			setStateOptions(mappedStates);
		}
	}, [states]);

	if (loading) {
		return <InternalLoader />;
	}

	const onSubmitMessage = () => {
		if (message === null || message === '') {
			return toast.error('El mensaje a anviar es requerido', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}

		if (!otherData?.phoneNumber || otherData?.phoneNumber === '') {
			return toast.error('El usuario no tiene un número de telefono asociado', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}

		if (isScheduledSms) {
			if (!dateToSend || !timeToSend || dateToSend === '' || timeToSend === '') {
				return toast.error('Debe incluir una fecha y hora', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}

		const [dob, mob, yob] = personData.date_birth.split('/');

		const filter = {
			city: personData?.municipality || '', //Label del municipio o vacio
			federalDistrict: [personData?.district.toString()], //Array de valores de distrito federal convertidos a string
			gender: personData?.gender === 'Male' ? 'Hombre' : 'Mujer', //Gender del elector
			localDistrict: [personData?.local_district.toString()], //Array de valores de distrito local convertidos a string
			necessity: otherData?.need, //null
			sections: [personData?.section.toString()], //Array de valores de secciones convertidos a string
			state: personData?.state //Label del estado del elector
		};

		const destinataries = [
			{
				firstname: personData?.first_name || '-',
				lastname: personData?.last_name || '-',
				phoneNumber: otherData?.phoneNumber || '-',
				gender: personData?.gender,
				state: personData?.state || '-',
				section: personData?.section || '-',
				age: getAgeFromDate(dob, mob, yob),
				curp: personData?.curp || '-',
				photo: personData?.passport_photo || '-'
			}
		];

		const messageRecord = {
			clientId,
			dateTimeToSend: isScheduledSms ? moment(dateToSend + ' ' + timeToSend).format() : moment().format(),
			destinataries,
			filter,
			message,
			status: isScheduledSms ? 'SCHEDULED' : 'SEND'
		};

		setRecord(messageRecord);

		getSmsAvailable().then((response) => {
			setConfirmModalMsg(
				`Tienes ${response.data?.getSMSAvailable} mensajes disponibles, ¿Estás seguro de enviar el mensaje?`
			);
			setConfirmModal(true);
		});
	};

	return (
		<Container fluid className="bg-white">
			<FilterSmsModal
				isOpen={modal}
				toggle={toggleModal}
				filterSms={filterSms}
				filterOptions={filterOptions}
				filterHandlers={filterHandlers}
			/>
			<Row className="">
				<Col md="8" xs="12" className="pl-0">
					<SendMessageForm
						firstName={personData.first_name}
						lastName={personData.last_name}
						text={message}
						setText={setMessage}
						setDateToSend={setDateToSend}
						dateToSend={dateToSend}
						isScheduledSms={isScheduledSms}
						setIsScheduledSms={setIsScheduledSms}
						timeToSend={timeToSend}
						setTimeToSend={setTimeToSend}
						onSubmitMessage={onSubmitMessage}
						disabledText={loadingCreate || loadingSmsAvailable}
						oneVoterMessage={true}
					/>
				</Col>
				<Col md="4" xs="12">
					<PreviewMessage message={message} />
				</Col>
			</Row>
			<Row className="mt-2">
				<Col xs="12" className="p-0">
					<FilterComponent
						onShowModal={toggleModal}
						onFilter={(e) => setFilterText(e.target.value)}
						filterText={filterText}
						search={search}
						setSearch={setSearch}
						handleApplyFilter={handleApplyFilter}
					/>
				</Col>
				<Col className="p-0">
					<SmsTable
						data={items}
						personData={personData}
						historyTable={false}
						handlePerRowsChange={handlePerRowsChange}
						handlePageChange={handlePageChange}
						count={count}
						page={page}
						perPage={perPage}
					/>
				</Col>
			</Row>
			<ConfirmModal isOpen={confirmModal} msg={confirmModalMsg} toggle={toggle} />
		</Container>
	);
}
