import { put, takeLatest, select } from 'redux-saga/effects';
import {
	CREATE_USER,
	LOGIN,
	LOGOUT,
	GET_ME,
	GET_USER_LIST,
	EDIT_USER,
	REMOVE_2FA_TOKEN,
	GENERATE_QR_CODE,
	VERIFY_2FA_CODE
} from '../actionTypes';
import {
	fetchLoginSuccess,
	fetchLoginError,
	isLoading,
	fetchLogoutSuccess,
	fetchCreateUserError,
	fetchCreateUserSuccess,
	fetchGetMeSuccess,
	fetchGetMeError,
	fetchGetUsersListSuccess,
	fetchUsersListError,
	fetchEditUserSuccess,
	fetchEditUserError,
	remove2FATokenError,
	remove2FATokenSuccess,
	generateQRCodeError,
	generateQRCodeSuccess,
	verify2FACodeError,
	verify2FACodeSuccess,
	move2FA
} from '../actions';
import { gql } from '@apollo/client';
import { graphQlClient } from '../../store/graphql';
import { setLocalStorageItem, removeLocalStorageItem } from '../../services/localStorageService';
import { createUserErrorHandler } from '../../services/errorHandlerSevice';
import { HAS_COMPLETED_2FA, TOKEN_DATA, USER_DATA, USES_2FA } from '../../constant/localStorage';
import { ErrorHandler } from 'redux/errorHandler';

function* login({ payload: { username, password } }) {
	try {
		yield put(isLoading(true));
		const res = yield graphQlClient.query({
			query: gql`
				query getAuthTokens($username: String!, $password: String!) {
					getAuthTokens(username: $username, password: $password) {
						access_token
						refresh_token
						uses_2FA
					}
				}
			`,
			variables: {
				username,
				password
			}
		});

		yield put(fetchLoginError(''));
		const tokens = res.data?.getAuthTokens;
		const uses2FA = res.data?.getAuthTokens?.uses_2FA;
		if (!uses2FA) {
			setLocalStorageItem(TOKEN_DATA, tokens);
			yield put(fetchLoginSuccess(tokens));
			setTimeout(() => {
				window.location.reload();
			}, 300);
		} else {
			setLocalStorageItem(TOKEN_DATA, tokens);
			yield put(move2FA({ tokens }));
		}

		yield put(isLoading(false));
	} catch (error) {
		yield put(isLoading(false));
		yield put(fetchLoginError(error.message));
		ErrorHandler(error);
	}
}

export function* logOut() {
	try {
		yield put(fetchLogoutSuccess());
		removeLocalStorageItem(TOKEN_DATA);
		removeLocalStorageItem(USER_DATA);
		removeLocalStorageItem(HAS_COMPLETED_2FA);
		setTimeout(() => {
			window.location.reload();
		}, 100);
	} catch (error) {}
}

function* createUer(userData) {
	try {
		yield put(isLoading(true));
		yield put(fetchCreateUserError(''));

		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation CreateProfile($input: UserProfileInput) {
					userCreateProfile(input: $input) {
						record {
							email
							name
							user_id
							app_metadata {
								roles
							}
						}
					}
				}
			`,
			variables: {
				...userData.payload
			}
		});

		yield put(isLoading(false));
		yield put(fetchCreateUserError(''));
		yield put(fetchCreateUserSuccess(res));
		setTimeout(() => {
			window.location.reload();
		}, 100);
	} catch (error) {
		yield put(isLoading(false));
		const handledError = createUserErrorHandler(error.message);

		yield put(fetchCreateUserError(handledError));
		ErrorHandler(error);
	}
}

function* getMe() {
	try {
		yield put(isLoading(true));
		yield put(fetchCreateUserError(''));

		const user = yield select((state) => state.User?.user);

		if (user) {
			return;
		}

		const res = yield graphQlClient.query({
			query: gql`
				query GetMe {
					getMe {
						blocked
						created_at
						email
						email_verified
						name
						nickname
						picture
						updated_at
						user_id
						app_metadata {
							roles
						}
						two_factor_enabled
					}
				}
			`,
			variables: {}
		});

		yield put(isLoading(false));
		yield put(fetchGetMeError(''));
		yield put(fetchGetMeSuccess(res.data.getMe));
		setLocalStorageItem(USER_DATA, res.data.getMe);
	} catch (error) {
		yield put(isLoading(false));
		yield put(fetchGetMeError(error.message));
		yield put(fetchLoginError(error.message));
		ErrorHandler(error);
	}
}

function* fetchUsersList(pageData) {
	let variables = {};

	if (pageData.payload) {
		variables = {
			perPage: pageData.payload.perPage,
			page: pageData.payload.page
		};
	}

	try {
		yield put(isLoading(true));
		const res = yield graphQlClient.query({
			query: gql`
				query userPagination($page: Int, $perPage: Int) {
					userPagination(page: $page, perPage: $perPage) {
						count
						items {
							blocked
							created_at
							email
							email_verified
							name
							nickname
							picture
							password
							updated_at
							user_id
							app_metadata {
								roles
							}
						}
						pageInfo {
							currentPage
							perPage
							pageCount
							itemCount
							hasNextPage
							hasPreviousPage
						}
					}
				}
			`,
			variables: variables
		});

		const usersList = {
			items: pageData.payload
				? pageData.payload?.lastItems?.concat(res.data.userPagination.items)
				: [].concat(res.data?.userPagination?.items),
			pageInfo: res.data?.userPagination?.pageInfo
		};

		yield put(isLoading(false));

		yield put(fetchGetUsersListSuccess(usersList));
	} catch (error) {
		yield put(isLoading(false));
		yield put(fetchUsersListError(error.message));
		ErrorHandler(error);
	}
}

function* verify2FAToken(action) {
	try {
		yield put(isLoading(true));
		yield put(verify2FACodeSuccess(false));
		yield put(verify2FACodeError(false));
		action.payload.token && setLocalStorageItem(TOKEN_DATA, action.payload.token);
		const res = yield graphQlClient.query({
			query: gql`
				query validate2FA($code: String!) {
					validate2FA(code: $code) {
						valid_code
					}
				}
			`,
			variables: {
				code: action.payload.code
			}
		});
		const validCode = res.data?.validate2FA?.valid_code;
		if (!validCode) {
			yield put(verify2FACodeError(true));
		} else {
			yield put(verify2FACodeSuccess(validCode));
			setTimeout(() => {
				window.location.reload();
			}, 300);
			removeLocalStorageItem(USES_2FA);
		}
		yield put(isLoading(false));
	} catch (e) {
		yield put(isLoading(false));
		yield put(verify2FACodeError(true));
		ErrorHandler(e);
	}
}

function* remove2FAToken() {
	try {
		yield put(isLoading(true));
		yield put(remove2FATokenError(false));
		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation remove2FA {
					removeTwoFactorAuthToken
				}
			`
		});
		yield put(isLoading(false));
		yield put(remove2FATokenSuccess(res));
	} catch (e) {
		yield put(isLoading(false));
		yield put(remove2FATokenError(true));
		ErrorHandler(e);
	}
}

function* generateQRCode() {
	try {
		yield put(isLoading(true));
		yield put(generateQRCodeError(false));
		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation get2f {
					generateTwoFactorAuthToken {
						otpauthUrl
						base32
					}
				}
			`
		});
		yield put(
			generateQRCodeSuccess({
				otpauthUrl: res.data?.generateTwoFactorAuthToken?.otpauthUrl,
				base32: res.data?.generateTwoFactorAuthToken?.base32
			})
		);
		yield put(isLoading(false));
		yield put(generateQRCodeError(false));
	} catch (e) {
		yield put(isLoading(false));
		yield put(generateQRCodeError(true));
		ErrorHandler(e);
	}
}

function* editUser(userData) {
	try {
		yield put(isLoading(true));
		yield put(fetchEditUserError(''));

		const res = yield graphQlClient.mutate({
			mutation: gql`
				mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUser_ProfileInput!) {
					userUpdateById(_id: $_id, record: $record) {
						record {
							email
							name
							nickname
							blocked
							picture
							app_metadata {
								roles
							}
						}
					}
				}
			`,
			variables: {
				...userData.payload
			}
		});
		yield put(isLoading(false));
		yield put(fetchEditUserError(''));
		yield put(fetchEditUserSuccess(res));
		// Deuda tecnica: por alguna razon luego de
		// Editar un usuario el servicio no actualiza el cambio,
		// La unica manera es reiniciando
		setTimeout(() => {
			window.location.reload();
		}, 100);
	} catch (error) {
		yield put(isLoading(false));
		yield put(fetchEditUserError(error.message));
		ErrorHandler(error);
	}
}

export function* User() {
	yield takeLatest(LOGIN, login);
	yield takeLatest(LOGOUT, logOut);
	yield takeLatest(CREATE_USER, createUer);
	yield takeLatest(GET_ME, getMe);
	yield takeLatest(GET_USER_LIST, fetchUsersList);
	yield takeLatest(EDIT_USER, editUser);
	yield takeLatest(REMOVE_2FA_TOKEN, remove2FAToken);
	yield takeLatest(GENERATE_QR_CODE, generateQRCode);
	yield takeLatest(VERIFY_2FA_CODE, verify2FAToken);
}
