import React, { useState, useEffect } from 'react';
import './customRangePickerStyle.scss';
import { v4 } from 'uuid';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { addMonths, endOfWeek, startOfWeek, subMonths } from 'date-fns';
// import { getDaysInMonth } from "date-fns/esm";
import { getDaysInMonth } from 'date-fns';
import moment from 'moment';

export const CustomRangePicker = ({ onChange, handleFilter }) => {
	const [open, setOpen] = useState(false);
	const [date, setDate] = useState(new Date());
	const [week, setWeek] = useState({
		firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
		lastDay: endOfWeek(new Date(), { weekStartsOn: 1 })
	});

	useEffect(() => {
		if (onChange) {
			onChange(week);
			setOpen(false);
		}
	}, [week, onChange]);

	const isLeapYear = () => {
		let leapYear = new Date(new Date().getFullYear(), 1, 29);
		return leapYear.getDate() === 29;
	};

	const convertDate = (date) => {
		let dt = new Date(date);

		return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
	};

	const handleClick = (e) => {
		let localDate;
		if (e.target.id.includes('prev')) {
			localDate = new Date(date.setDate(1));
			setDate(new Date(date.setDate(1)));
		} else if (e.target.id.includes('next')) {
			localDate = new Date(date.setDate(getDaysInMonth(date)));
			setDate(new Date(date.setDate(getDaysInMonth(date))));
		} else {
			localDate = new Date(date.setDate(e.target.id));
			setDate(new Date(date.setDate(e.target.id)));
		}
		const firstDay = startOfWeek(localDate, { weekStartsOn: 1 });
		const lastDay = endOfWeek(localDate, { weekStartsOn: 1 });
		setWeek({ firstDay, lastDay });
		handleFilter(firstDay, lastDay);
	};

	const months = ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May', 'Jun', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'];

	const days = {
		1: 31,
		2: isLeapYear() ? 29 : 28,
		3: 31,
		4: 30,
		5: 31,
		6: 30,
		7: 31,
		8: 31,
		9: 30,
		10: 31,
		11: 30,
		12: 31
	};

	const renderDays = () => {
		let month = moment(date).month() + 1;
		let ar = [];
		for (let i = 1; i <= days[month]; i++) {
			let currentDate = moment(date).set('date', i).toDate();

			let cName = 'single-number ';
			if (
				moment(week.firstDay).isSameOrBefore(moment(currentDate)) &&
				moment(currentDate).isSameOrBefore(moment(week.lastDay))
			) {
				cName = cName + 'selected-week';
			}

			ar.push(
				<div key={v4()} id={i} className={cName} onClick={handleClick}>
					{i}
				</div>
			);
		}

		const displayDate = moment(date).set('date', 1).toDate();
		let dayInTheWeek = moment(displayDate).day();
		if (dayInTheWeek < 1) {
			dayInTheWeek = 7;
		}
		let prevMonth = [];
		let prevMonthDays = moment(date).month();
		if (prevMonthDays === 0) {
			prevMonthDays = 12;
		}
		for (let i = dayInTheWeek; i > 1; i--) {
			let previousMonth = moment(date).subtract(1, 'month').toDate();
			let currentDate = moment(previousMonth)
				.set('date', days[prevMonthDays] - i + 2)
				.toDate();
			let cName = 'single-number other-month';
			if (
				moment(currentDate).isSameOrAfter(moment(week.firstDay)) &&
				moment(currentDate).isSameOrBefore(moment(week.lastDay))
			) {
				cName = 'single-number selected-week';
			}

			prevMonth.push(
				<div onClick={handleClick} key={v4()} id={'prev-' + i} className={cName}>
					{days[prevMonthDays] - i + 2}
				</div>
			);
		}

		let nextMonth = [];
		let fullDays = 35;
		if ([...prevMonth, ...ar].length > 35) {
			fullDays = 42;
		}

		for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
			let cName = 'single-number other-month';
			const lastDayOfMonth = moment(date).endOf('month').toDate();

			if (
				moment(lastDayOfMonth).isSameOrBefore(moment(week.lastDay)) &&
				moment(week.firstDay).month() === moment(lastDayOfMonth).month()
			) {
				cName = 'single-number selected-week';
			}

			nextMonth.push(
				<div onClick={handleClick} key={v4()} id={'next-' + i} className={cName}>
					{i}
				</div>
			);
		}

		const allDays = [...prevMonth, ...ar, ...nextMonth];

		const chunk = (arr, size) => {
			const chunkedArr = [];
			for (let i = 0; i < arr.length; i += size) {
				chunkedArr.push(arr.slice(i, i + size));
			}
			return chunkedArr;
		};

		const chunkedDays = chunk(allDays, 7);

		return chunkedDays.map((week, index) => (
			<div key={index} className="numbers-container">
				{week}
			</div>
		));
	};

	const handleDate = (next) => {
		let localDate = new Date(date);
		if (next) {
			localDate = addMonths(localDate, 1);
		} else {
			localDate = subMonths(localDate, 1);
		}
		setDate(new Date(localDate));
	};

	return (
		<div className="week-picker-display" onBlur={() => setOpen(false)} onClick={() => setOpen(true)} tabIndex={0}>
			<p>
				{convertDate(week.firstDay)} - {convertDate(week.lastDay)}
			</p>
			{open && (
				<div className="week-picker-options">
					<div className="title-week">
						<div onClick={() => handleDate()} className="arrow-container">
							{ArrowLeft}
						</div>
						{`${months[date.getMonth()]} ${date.getFullYear()}.`}
						<div onClick={() => handleDate(true)} className="arrow-container">
							{ArrowRight}
						</div>
					</div>
					<div className="numbers-container">
						<div className="single-number day">Lun</div>
						<div className="single-number day">Mar</div>
						<div className="single-number day">Mie</div>
						<div className="single-number day">Jue</div>
						<div className="single-number day">Vie</div>
						<div className="single-number day">Sab</div>
						<div className="single-number day">Dom</div>
					</div>
					{renderDays()}
				</div>
			)}
		</div>
	);
};
