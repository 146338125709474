import React, { useEffect, useState } from 'react';
import App from '../components/dashboard/app';
import Account from '../components/account/account';
import { Routes as Switch, Route, useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../routes';
import { useSelector } from 'react-redux';

import Initial from '../pages/initial';
import Login from '../pages/login';

import { getLocalStorageItem } from '../services/localStorageService';
import { HAS_COMPLETED_2FA, TOKEN_DATA, USES_2FA } from '../constant/localStorage';
import { useSubscription, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';

import * as actions from '../redux/actions';
import Login2FA from '../pages/login2fa';
import { ROLE_OPTIONS } from '../constant/variables';
import PageNotFound from 'pages/pageNotFound';

const usersSubscription = gql`
	subscription weeklyReport {
		weeklyReport
	}
`;

function NoMatch() {
	return <PageNotFound />;
}

const Navigator = () => {
	const { data } = useSubscription(usersSubscription, {});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tokenData = getLocalStorageItem(TOKEN_DATA);
	const userData = getLocalStorageItem('userData');
	const hasCompleted2fa = getLocalStorageItem(HAS_COMPLETED_2FA);
	const uses2FA = getLocalStorageItem(USES_2FA);

	const location = useLocation();

	const loginSucceded = useSelector((state) => state.User.loginSucceded);

	const [isAdmin, setIsAdmin] = useState(false);
	const [voterTab, setVoterTab] = useState(false);
	const [eventsTab, setEventsTab] = useState(false);
	const [pyramidTab, setPyramidTab] = useState(false);
	const [smsTab, setSmsTab] = useState(false);
	const [needTab, setNeedTab] = useState(false);
	const [mapsTab, setMapsTab] = useState(false);
	const [supportsTab, setSupportsTab] = useState(false);

	useEffect(() => {
		if (data) {
			const message = 'Received notification through GraphQL subscription.';
			console.info(message, data);
			dispatch(actions.fetchWeeklyReportSuccess(data.weeklyReport));
		}
	}, [data, dispatch]);

	useEffect(() => {
		if (!tokenData && !loginSucceded) {
			navigate(ROUTES.LOGIN);
		} else if (uses2FA && JSON.parse(uses2FA) && !hasCompleted2fa && location.pathname !== ROUTES.LOGIN_2FA) {
			navigate(ROUTES.LOGIN_2FA);
		}
	}, [location.pathname, hasCompleted2fa, loginSucceded, tokenData, uses2FA, navigate]);

	useEffect(() => {
		if (userData) {
			const userIsAdmin = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[2]);
			const userHasVoterTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[3]);
			const userHasEventsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[4]);
			const userHasPyramidTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[5]);
			const userHasSmsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[6]);
			const userHasNeedTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[7]);
			const userHasMapsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[8]);
			const userHasASupportsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[9]);

			setIsAdmin(userIsAdmin);
			setVoterTab(userHasVoterTab);
			setEventsTab(userHasEventsTab);
			setPyramidTab(userHasPyramidTab);
			setSmsTab(userHasSmsTab);
			setNeedTab(userHasNeedTab);
			setMapsTab(userHasMapsTab);
			setSupportsTab(userHasASupportsTab);
		}

		// eslint-disable-next-line
	}, [userData]);

	return (
		<Switch>
			<Route path={ROUTES.INITIAL} element={<Initial />} />
			<Route path={ROUTES.LOGIN} element={<Login />} />
			<Route path={ROUTES.LOGIN_2FA} element={<Login2FA></Login2FA>} />

			{ROUTES.CREATE_UPDATE_VOTER_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							tokenData || loginSucceded ? (
								<App>
									<route.component mode={route.mode} />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.DASHBOARD_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							tokenData || loginSucceded ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{isAdmin &&
				ROUTES.ADMIN_DASHBOARD_ROUTES.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							element={
								(tokenData || loginSucceded) && isAdmin ? (
									<App>
										<route.component />
									</App>
								) : (
									<NoMatch />
								)
							}
						></Route>
					);
				})}

			{ROUTES.VOTER_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && voterTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.EVENTS_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && eventsTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.PYRAMIDS_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && pyramidTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.SMS_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && smsTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.NEED_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && needTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.MAPS_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && mapsTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.SUPPORTS_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && !isAdmin && supportsTab ? (
								<App>
									<route.component />
								</App>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.ACCOUNT_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							tokenData || loginSucceded ? (
								<Account>
									<route.component />
								</Account>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}

			{ROUTES.RECRUITED_LOGS_ROUTES.map((route, index) => (
				<Route
					key={index}
					path={route.path}
					element={
						tokenData || loginSucceded ? (
							<Account>
								<route.component />
							</Account>
						) : (
							<NoMatch />
						)
					}
				></Route>
			))}

			{ROUTES.ADMIN_ACCOUNT_ROUTES.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							(tokenData || loginSucceded) && isAdmin ? (
								<Account>
									<route.component />
								</Account>
							) : (
								<NoMatch />
							)
						}
					></Route>
				);
			})}
			<Route path="*" element={<NoMatch />} />
		</Switch>
	);
};

export default Navigator;
