import React, { Fragment, useEffect, useState } from 'react';
import CreateSurveyHeader from './create-survey-header';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, CustomInput, Row } from 'reactstrap';
import { ToastContainer } from 'react-toastify';

import * as ROUTES from '../../../routes/index';
import { Link, useLocation } from 'react-router-dom';

import Select from 'react-select';
import PreguntasSimples from './preguntas-simples';
import PreguntasOptionMultiple from './preguntas-opcion-multiple';
import PreguntasAbiertas from './preguntas-abiertas';
import PreguntasRelacionables from './preguntas-relacionables';
import OrdenValoracion from './orden-valoracion';
import { axiosInstance } from 'utils/axiosInstance';
import InternalLoader from 'layout/internal-loader';

const Stage = ({ stage, questionTypes, poll, categories, subcategories, allQuestions, setAllQuestions }) => {
	const [selectedType, setSelectedType] = useState(null);

	const { active, id, name } = stage;

	const [questions, setQuestions] = useState([]);

	return (
		<Row style={{ padding: '8px 0px' }}>
			<Col>
				<Row>
					<Col lg={6} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: 'full'
							}}
						>
							<span style={{ fontSize: '16px' }}>{name}</span>
							<CustomInput type="switch" id={`switch-${id}`} checked={active} className="custom-switch" />
						</div>
						<Select
							name="selectedSurveyOption"
							options={
								id === 4
									? questionTypes.filter((q) => q.value === 6)
									: questionTypes.filter((q) => q.value !== 1 && q.value !== 6)
							}
							id="selectedSurveOption"
							placeholder="Seleccione un tipo de pregunta"
							value={selectedType}
							onChange={(option) => setSelectedType(option)}
						/>
					</Col>
					<Col lg={6}>
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'end',
								alignItems: 'center'
							}}
						></div>
					</Col>
				</Row>
				<Row>
					<>
						{selectedType && selectedType.value === 2 && (
							<PreguntasOptionMultiple
								questions={questions}
								setQuestions={setQuestions}
								categories={categories}
								subcategories={subcategories}
								stageId={id}
								poll={poll}
								setAllQuestions={setAllQuestions}
							/>
						)}
						{selectedType && selectedType.value === 3 && (
							<PreguntasSimples
								questions={questions}
								setQuestions={setQuestions}
								categories={categories}
								subcategories={subcategories}
								stageId={id}
								poll={poll}
								setAllQuestions={setAllQuestions}
							/>
						)}
						{selectedType && selectedType.value === 4 && (
							<OrdenValoracion
								questions={questions}
								setQuestions={setQuestions}
								categories={categories}
								stageId={id}
								poll={poll}
							/>
						)}
						{selectedType && selectedType.value === 5 && (
							<PreguntasAbiertas
								questions={questions}
								setQuestions={setQuestions}
								categories={categories}
								stageId={id}
								poll={poll}
							/>
						)}
						{selectedType && selectedType.value === 6 && (
							<PreguntasRelacionables
								questions={questions}
								setQuestions={setQuestions}
								categories={categories}
								subcategories={subcategories}
								stageId={id}
								poll={poll}
								questionTypes={questionTypes}
								allQuestions={allQuestions}
							/>
						)}
					</>
				</Row>
			</Col>
		</Row>
	);
};

const CreateSurvey2 = () => {
	const location = useLocation();
	const { state } = location;

	const [stages, setStages] = useState([]);

	const [allQuestions, setAllQuestions] = useState([]);

	const categoriesOptions = state?.categories.map(({ name, id }) => ({ value: id, label: name })) || [];
	const subcategoriesOptions =
		state?.categories.flatMap(({ subcategories }) => {
			return subcategories.map(({ id, name }) => ({ value: id, label: name }));
		}) || [];

	const [questionTypes, setQuestionTypes] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		axiosInstance({ method: 'GET', url: '/polls/question-type' })
			.then(async ({ data }) => {
				await axiosInstance({ method: 'GET', url: '/polls/stages/' })
					.then(({ data }) => {
						setStages(data.results);
					})
					.catch((err) => console.log(err));

				setQuestionTypes(
					data.results.map(({ id, type_question }) => ({
						value: id,
						label: type_question,
						isDisabled: id === 1
					}))
				);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [setQuestionTypes]);

	return (
		<Fragment>
			<CreateSurveyHeader step={2} lastStep={2} />
			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Definición de Estapas</Row>
							</CardHeader>
							<CardBody>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '16px',
										alignContent: 'center'
									}}
								>
									{isLoading ? (
										<InternalLoader />
									) : (
										<>
											{stages.map((stage) => (
												<Stage
													stage={stage}
													questionTypes={questionTypes}
													poll={state?.id || 0}
													categories={categoriesOptions}
													subcategories={subcategoriesOptions}
													allQuestions={allQuestions}
													setAllQuestions={setAllQuestions}
												/>
											))}
										</>
									)}
								</div>
							</CardBody>
							<CardFooter>
								<Row
									className="pt-2 px-3"
									style={{ display: 'flex', flex: 'row', justifyContent: 'flex-end', gap: '8px' }}
								>
									<Link to={ROUTES.CREAR_ENCUESTAS_1}>
										<Button outline color="primary">
											Cancelar
										</Button>{' '}
									</Link>
									<Link to={ROUTES.ENCUESTAS}>
										<Button color="primary">Crear ahora</Button>
									</Link>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer />
		</Fragment>
	);
};

export default CreateSurvey2;
