import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Card, CardBody, CardHeader, Col, Input, InputGroup, Row } from 'reactstrap';

import { Check, Edit, Minus, Plus, Save, X } from 'react-feather';

import InternalLoader from 'layout/internal-loader';
import { Link } from 'react-router-dom';
import { useAffiliatesByLevel } from 'utils/fetch/pyramids';
import { updateLevel } from 'utils/fetch/pyramidLevels';
import { toast } from 'react-toastify';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import { usePagination } from 'utils/fetch/pagination';

const badgeStyle = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '2px 6px',
	marginLeft: '4px',
	fontWeight: '500',
	fontSize: '12px',
	lineHeight: '14px',

	width: '37px',
	height: '16px',

	background: '#fff1f1',

	border: '1px solid #f04a5e',
	borderRadius: '24px',
	color: '#f04a5e'
};

const datatableCustomStyle = {
	cells: {
		style: {
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '0'
		}
	},
	rows: {
		style: {
			'&:hover': {
				backgroundColor: '#FFF1F1 !important',
				'.actionButton': {
					color: '#F04A5E !important'
				}
			}
		}
	}
};

function CellRecruiter({ recruiter }) {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '24px',
				height: '24px',
				borderRadius: '50%',
				backgroundColor: recruiter ? '#C5F7DD' : '#FFEFEF'
			}}
		>
			{recruiter ? <Check color="#3BC279" size={16} /> : <X color="#DF1212" size={16} />}
		</div>
	);
}

const votersHeader = [
	{
		name: 'Nombre y apellido',
		selector: (row) => row?.full_name
	},
	{
		name: 'Clave de elector',
		selector: (row) => row?.electoral_key || '-',
		width: '15%'
	},
	{
		name: 'Reclutado por',
		selector: (row) => row.recruitedBy,
		width: '25%'
	},
	{
		name: 'Reclutados',
		center: true,
		cell: (row) => row.total_voters_recruited,
		width: '15%'
	},
	{
		name: 'Reclutador',
		selector: (row) => row.recruiter,
		center: true,
		width: '120px',
		cell: (row) => <CellRecruiter recruiter={row.recruiter} />
	},
	{
		cell: (row) => (
			<Link className="actionButton" to={row.curp ? `/electores/${row.curp}` : '#'} target="_blank">
				<i className="fa fa-angle-right"></i>
			</Link>
		),
		width: '40px',
		ignoreRowClick: true,
		allowOverflow: true,
		button: true
	}
];

const Levels = ({ level, fetchPyramidDetails }) => {
	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	const { data, loading } = useAffiliatesByLevel({ levelId: level.id, page: page, perPage: perPage });

	// const [modal, setModal] = useState(false);
	// const [modalRecruiters, setModalRecruiters] = useState(false);

	const [votersData, setVotersData] = useState([]);

	const [levelName, setLevelName] = useState(level?.name);
	const [levelNumberManagers, setLevelNumberManagers] = useState(level?.number_managers);

	const [loadingUpdateLevel, setLoadingUpdateLevel] = useState(false);

	// const toggle = () => setModal(!modal);

	const [editLevel, setEditLevel] = useState(false);

	const calculatePercentage = (item) => {
		if (item.order === 0) {
			return item.number_managers === 0
				? '0%'
				: `${Math.ceil((item.recruiters.length / item.number_managers) * 100)}%`;
		} else {
			return item.number_managers === 0
				? '0%'
				: `${Math.ceil((level.total_manager_active / level.number_managers) * 100)}%`;
		}
	};

	useEffect(() => {
		if (data.results) {
			setVotersData(
				data.results.map((voter) => ({
					full_name: `${voter?.data_affiliate?.first_name} ${voter?.data_affiliate?.last_name}` || '-',
					electoral_key: voter?.data_affiliate?.electoral_key || '-',
					recruitedBy: voter?.recruiter_info?.full_name || 'SISTEMA',
					total_voters_recruited: voter?.total_voters_recruited || 0,
					recruiter: true,
					curp: voter?.data_affiliate?.curp || '#'
				}))
			);
		}
	}, [data]);

	return (
		<>
			<Row noGutters style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Col sm="auto" style={{ width: '100%' }}>
					<Card style={{ width: '100%' }}>
						<CardHeader className="p-3">
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<p
									style={{
										fontWeight: '500',
										fontSize: '16px',
										lineHeight: '19px',
										color: '#F04A5E',
										margin: 0
									}}
								>
									Detalle del nivel
								</p>
								{!editLevel ? (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											flexDirection: 'row',
											gap: '12px'
										}}
									>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											color="primary"
											onClick={() => setEditLevel(true)}
										>
											Editar
											<Edit size={16} />
										</Button>
									</div>
								) : (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											flexDirection: 'row',
											gap: '12px'
										}}
									>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											outline
											color="primary"
											onClick={() => {
												setLevelName(level?.name);
												setLevelNumberManagers(level?.number_managers);

												setEditLevel(false);
											}}
										>
											Cancelar
											<X size={16} />
										</Button>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											color="primary"
											onClick={() => {
												setLoadingUpdateLevel(true);

												updateLevel({
													...level,
													name: levelName,
													number_managers: levelNumberManagers
												})
													.then(() => {
														toast.success('Se ha modificado el nivel con éxito', {
															position: 'top-right',
															autoClose: 3000,
															hideProgressBar: false,
															closeOnClick: true,
															pauseOnHover: true,
															draggable: true,
															progress: undefined
														});
													})
													.catch(() => {
														toast.error('Error al modificar la pirámide', {
															position: 'top-right',
															autoClose: 3000,
															hideProgressBar: false,
															closeOnClick: true,
															pauseOnHover: true,
															draggable: true,
															progress: undefined
														});
													})
													.finally(() => {
														setLoadingUpdateLevel(false);
														fetchPyramidDetails();
													});
											}}
										>
											Guardar
											<Save size={16} />
										</Button>
									</div>
								)}
							</div>
						</CardHeader>
						<CardBody className="p-3">
							<Row
								className="py-2 event-info-line"
								style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
							>
								<Col>
									<Row className="event-info-titles">
										<span>Nombre</span>
									</Row>
									<Row>
										{editLevel ? (
											<Input
												type="text"
												name="levelName"
												id="levelName"
												placeholder="Nombre del nivel"
												value={levelName}
												onChange={(e) => setLevelName(e.target.value)}
												className="edit-event-info"
											/>
										) : (
											<span>{levelName}</span>
										)}
									</Row>
								</Col>
							</Row>
							<Row
								className="py-2 event-info-line"
								style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
							>
								<Col>
									<Row className="event-info-titles">
										<span>Objetivo de participantes</span>
									</Row>
									<Row>
										{editLevel ? (
											<InputGroup>
												<button
													className="increment-decrement-voters decrement"
													onClick={() => {
														if (levelNumberManagers === 0) {
															setLevelNumberManagers(0);
															return;
														}
														setLevelNumberManagers((count) => count - 1);
													}}
												>
													<Minus size={12} />
												</button>
												<Input
													style={{
														borderLeft: '0',
														borderRight: '0',
														textAlign: 'center'
													}}
													placeholder="Ingresar número"
													type="number"
													min={0}
													value={levelNumberManagers}
													pattern="[0-9]*"
													onChange={(e) =>
														setLevelNumberManagers((v) =>
															Number(e.target.validity.valid ? e.target.value : v)
														)
													}
													className="edit-event-info"
												/>
												<button
													className="increment-decrement-voters increment"
													onClick={() => {
														if (levelNumberManagers === 10) {
															setLevelNumberManagers(10);
															return;
														}
														setLevelNumberManagers((count) => count + 1);
													}}
												>
													<Plus size={12} />
												</button>
											</InputGroup>
										) : (
											<span>{levelNumberManagers}</span>
										)}
									</Row>
								</Col>
								<Col>
									<Row className="event-info-titles">
										<span>Participantes activos</span>
									</Row>
									<Row>
										{editLevel ? (
											<Input
												type="text"
												name="levelTotalManagers"
												id="levelTotalManagers"
												placeholder="Nombre del nivel"
												disabled
												value={
													level.order === 0
														? level?.recruiters?.length
														: level?.total_manager_active
												}
												className="edit-event-info"
											/>
										) : (
											<span>
												{level.order === 0
													? level?.recruiters?.length
													: level?.total_manager_active}
											</span>
										)}
									</Row>
								</Col>
								<Col>
									<Row className="event-info-titles">
										<span>Porcentaje cumplido</span>
									</Row>
									<Row>
										<span style={badgeStyle}>{calculatePercentage(level)}</span>
									</Row>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row
				className="create-event-title"
				style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
			>
				<span>Lista de electores</span>
				<div style={{ display: 'flex', alignItems: 'center', gap: '4' }}>
					{/* <div>
						<Button className="button-content" color="primary" onClick={() => setModalRecruiters(true)}>
							Filtrar y Ordenar &nbsp;
							<Filter size={12} />
						</Button>
					</div>
					<div style={{ height: '100%', padding: '0px 4px' }}>
						<InputGroup className="search-bar">
							<Input placeholder="Buscar por..." className="pl-4 search-input" />
							<Search
								style={{
									position: 'absolute',
									left: '6px',
									bottom: '10px',
									color: 'gray',
									zIndex: 10
								}}
								size={16}
							/>
							<Button
								className="search-elector-button"
								color="primary"
								style={{ borderRadius: '0 4px 4px 0' }}
							>
								Buscar
							</Button>
						</InputGroup>
					</div> */}
				</div>
			</Row>
			<Row>
				<div className="table-responsive product-table">
					<DataTable
						columns={votersHeader}
						customStyles={datatableCustomStyle}
						data={votersData}
						noDataComponent="No hay electores para este nivel"
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						pagination
						paginationDefaultPage={page}
						paginationPerPage={perPage}
						paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
						paginationServer
						paginationTotalRows={data.count}
						progressComponent={<InternalLoader />}
						progressPending={loading}
						selectableRows={false}
						paginationComponentOptions={{
							rowsPerPageText: 'Electores por página:',
							rangeSeparatorText: 'de'
						}}
					/>
				</div>
			</Row>
			{/* <FilterUsersLevelsModal isOpen={modalRecruiters} toggle={toggleRecruiters} /> */}
			<LoadingAffiliation isOpen={loadingUpdateLevel} />
		</>
	);
};

export default Levels;
