import React from 'react';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import InputPlusMinus from 'components/inputPlusMinus/InputPlusMinus';

const ageOptions = [
	{
		value: 'gt',
		label: 'Mayor que'
	},
	{
		value: 'lt',
		label: 'Menor que'
	},
	{
		value: 'eq',
		label: 'Igual a'
	}
];

const genders = [
	{ label: 'Masculino', value: 'Male', recordFilterValue: 'Hombre' },
	{ label: 'Femenino', value: 'Female', recordFilterValue: 'Mujer' }
];

function FilterSMSTarget({
	states,
	selectedState,
	setSelectedState,
	selectedGender,
	setSelectedGender,
	setSelectedAge,
	selectedAge,
	selectedRangeAge,
	setSelectedRangeAge
}) {
	const handleCheckChange = (event) => {
		if (event.target.checked) {
			setSelectedGender([...selectedGender, event.target.value]);
		} else {
			setSelectedGender(selectedGender.filter((gender) => gender !== event.target.value));
		}
	};

	return (
		<div>
			<CardBody className="py-3 px-4">
				<Form className="form theme-form">
					<Row form>
						<Col md={12}>
							<div className="mb-3 f-16 font-primary">Filtrar Por</div>
							<Row form>
								<Col lg={4} md={6}>
									<FormGroup>
										<Label>Estado</Label>
										<Select
											name="selectedState"
											options={states}
											onChange={(option) => {
												setSelectedState(option);
											}}
											placeholder="Seleccione"
											value={selectedState}
										></Select>
									</FormGroup>
								</Col>
								{/*<Col lg={4} md={6}>*/}
								{/*    <FormGroup>*/}
								{/*        <Label>Necesidad</Label>*/}
								{/*        <Select*/}
								{/*            isClearable*/}
								{/*            name="selectedNecessity"*/}
								{/*            options={needs}*/}
								{/*            onChange={(option) => {*/}
								{/*                setSelectedNeed(option);*/}
								{/*            }}*/}
								{/*            placeholder="Seleccione"*/}
								{/*            value={selectedNeed}*/}
								{/*            isDisabled={!(needs?.length > 0)}*/}
								{/*        ></Select>*/}
								{/*    </FormGroup>*/}
								{/*</Col>*/}

								<Col lg="4" md={6} sm="12">
									<FormGroup
										check
										style={{
											display: 'flex',
											flexDirection: 'column',
											paddingBottom: '10px'
										}}
									>
										<Label>{'Sexo'}</Label>
										{genders.map((gender, index) => {
											return (
												<li key={index}>
													<Input
														type="checkbox"
														onChange={handleCheckChange}
														name={gender.value}
														value={gender.value}
														style={{ accentColor: 'rgb(236, 22, 48)' }}
														checked={selectedGender.includes(gender.value)}
													/>
													<Label check>{gender.label}</Label>
												</li>
											);
										})}
									</FormGroup>
								</Col>
								<Col md={12}>
									<div className="age">
										<label>Según su edad:</label>
										<div className="filter-container">
											<div className="select-content-age">
												<Select
													isClearable
													placeholder="Indique el valor"
													value={selectedRangeAge}
													onChange={(option) => {
														setSelectedRangeAge(option);
													}}
													options={ageOptions}
												/>
											</div>
											<div className="input-content-age">
												<InputPlusMinus
													onChange={(e) => setSelectedAge(e)}
													placeholder="Edad"
													value={selectedAge || ''}
												/>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</CardBody>
		</div>
	);
}

export default FilterSMSTarget;
