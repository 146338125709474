import React from 'react';
import { getAgeFromDate } from './getAgeFromDate';

export default function getVotersData(voters) {
	return voters.map((voter) => {
		// Calculo de Edad
		let voterDateParts = voter.ocr?.date_birth.split('/');

		let voterAge;

		let date = new Date();
		let birthDate = new Date(+voterDateParts[2], voterDateParts[1] - 1, +voterDateParts[0]);

		voterAge = date.getFullYear() - birthDate.getFullYear();

		let m = date.getMonth() - birthDate.getMonth();

		if (m < 0 || (m === 0 && date.getDate() < birthDate.getDate())) voterAge--;

		return {
			image: <img src={voter.ocr?.passport_photo} style={{ width: 50, height: 50 }} alt="" />,
			claveDeElector: voter.ocr?.electoral_key,
			curp: voter.ocr?.curp,
			anoDeRegistro: voter.ocr?.year_registration.split(' ')[0],
			domicilio: voter.ocr?.address,
			estadoCode: voter.ocr?.id_state,
			localidadCode: voter.ocr?.location,
			emision: voter.ocr?.validity.split('-')[0].trim(),
			firstName: voter.ocr?.first_name,
			lastName: voter.ocr?.last_name,
			dateOfBirth: voter.ocr?.date_birth,
			expirationDate: voter.ocr?.validity.split('-')[1].trim(),
			seccion: voter.ocr?.section,
			estado: voter.ocr?.state,
			age: voterAge
		};
	});
}

export function mapVotersData(voter) {
	const firstName = voter.ocr.first_name;
	const lastName = voter.ocr.last_name;
	const gender = voter.ocr.gender;
	const [dob, mob, yob] = voter.ocr.date_birth.split('/');
	const age = getAgeFromDate(dob, mob, yob);
	const state = voter.ocr.state;
	const electoralSection = voter.ocr.section;
	const createdAt = new Date(voter.createdAt).toISOString().split('T')[0];
	const [yyyy, mm, dd] = createdAt.split('-');
	const formattedCreatedAt = dd + '/' + mm + '/' + yyyy;

	return {
		userId: voter._id,
		image: voter.ocr.passport_photo,
		firstName,
		lastName,
		sexo: gender,
		age,
		estado: state,
		seccion: electoralSection,
		militancia: 'no set',
		createdAt: formattedCreatedAt
	};
}
