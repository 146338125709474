import {Button, Col, Row, Spinner} from 'reactstrap';
import './filterFormSummaryContainer.scss';

export function FilterFormSummary({results, onSubmitFilter, isLoading}) {
    return (
        <Row className="filterFormSummaryContainer mt-3 mx-0 align-items-center">
            <Col xs="6">
                <div className="">
                    <h5 className="f-18  mb-0 font-primary">Resultados:</h5>
                    {
                        isLoading ?
                            <Spinner
                                style={{width: '0.7rem', height: '0.7rem'}}
                                type="grow"
                                color="red"
                            />
                            :
                            <p className="m-0">Se han encontrado {results || 0} personas</p>

                    }
                </div>
            </Col>

            <Col xs="6 text-right">
                <Button color="primary" onClick={onSubmitFilter} disabled={isLoading}>
                    Filtrar y encontrar
                </Button>
            </Col>
        </Row>
    );
}
