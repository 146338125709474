import phoneImage from './bg.png';
import './previewMessage.scss';
import {useState} from "react";
import {getParseDateAndHours} from "../../utils/dates";

export default function PreviewMessage({message}) {
    const [todayDate] = useState(new Date());
    return (
        <div>
            <h3 className="m-0 f-16 font-primary">Vista previa del mensaje</h3>
            <div className="phone-preview">
                <img src={phoneImage} alt="phone"/>
                <div className="message-template-preview">
                    <p className="message-date">{getParseDateAndHours(todayDate)}</p>
                    <p className="message-text">
                        {message && message !== '' ? message : "Insertar mensaje..."}
                    </p>
                    <p className="message-status">Entregado</p>
                </div>
            </div>
        </div>
    );
}
