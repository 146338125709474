import { TabContent } from 'reactstrap';
import './tab.scss';

export default function TabContentContainer({ activeTab, children, noPadding, fullHeight = false }) {
	return (
		<TabContent
			className={`tabContentContainer ${noPadding ? 'noPadding' : ''}`}
			style={{ height: fullHeight ? '100%' : '' }}
			activeTab={activeTab}
		>
			{children}
		</TabContent>
	);
}
