import { GET_VOTER_LIST, GET_VOTER_LIST_ERROR, GET_VOTER_LIST_SUCCESS } from '../actionTypes';

const initial_state = {
	voters: [],
	fetchingVoters: false,
	fetchVotersError: false
};

const votersReducer = (state = initial_state, action) => {
	switch (action.type) {
		case GET_VOTER_LIST:
			return { ...state, fetchingVoters: true, fetchVotersError: false };

		case GET_VOTER_LIST_ERROR:
			return { ...state, fetchVotersError: true, fetchingVoters: false };

		case GET_VOTER_LIST_SUCCESS:
			return { ...state, fetchVotersError: false, fetchingVoters: false, voters: action.payload };

		default:
			return { ...state };
	}
};

export default votersReducer;
