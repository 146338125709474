import React, { Fragment } from 'react';
import {Row} from 'reactstrap';
// import RightHeader from './rightbar';

const Header = () => {
  return (
      <Fragment>
      <div className="page-header">
      <Row className="header-wrapper m-0">
      {/* <RightHeader/> */}
      </Row>
    </div>
    </Fragment>
  );
}

export default Header;