import { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = 'pk.eyJ1IjoibnVuZXphbGFuIiwiYSI6ImNra2Jva2U0MDE2dXEybm5zZGVmaXZqejIifQ.L1iUnCoMlDh6p2HaJHwnWA';

const lngDefault = -99.442201;
const latDefault = 23.496807;
const zoomDefault = 5.09;

export function useMap(ref, { location, zoom, addMarker = false } = {}) {
	const [map, setMap] = useState();

	function resetInitialMapSetup() {
		return map.easeTo({
			zoom: zoom || zoomDefault,
			center: location || [lngDefault, latDefault]
		});
	}

	useEffect(() => {
		if (ref && ref.current) {
			const container = ref.current;

			const initializeMap = ({ setMap }) => {
				const map = new mapboxgl.Map({
					container,
					style: 'mapbox://styles/nunezalan/ckksedf820ys117mp609fr0nq',
					center: location || [lngDefault, latDefault],
					zoom: zoom || zoomDefault
				});

				map.on('load', () => {
					addMarker && new mapboxgl.Marker().setLngLat(location).addTo(map);
					setMap(map);
					map.resize();
				});
			};

			if (!map) {
				initializeMap({ setMap });
			}
		}
	}, [map, addMarker, location, zoom, ref]);

	return {
		map,
		resetInitialMapSetup
	};
}
