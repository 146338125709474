import React, { useEffect, useRef } from 'react';
import { useMap } from 'utils/useMap';
import mapboxgl from 'mapbox-gl';
import bbox from '@turf/bbox';

const MapContainerWrapper = ({ location: latLngData, asDemarcation, stateData = {}, demarcationObj = {} }) => {
	const MapContainer = () => {
		const mapContainer = useRef();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const location = [
			Number(asDemarcation ? stateData.location?.lng : latLngData.lng),
			Number(asDemarcation ? stateData.location?.lat : latLngData.lat)
		];

		const { map } = useMap(mapContainer, { location, zoom: 12, addMarker: !asDemarcation });

		useEffect(() => {
			if (map) {
				if (asDemarcation) {
					const modelFilter = [
						'all',
						[
							'match',
							['get', demarcationObj.demarcationType],
							[demarcationObj.demarcationsArr],
							true,
							false
						]
					];

					const sourceObj = stateData?.sources?.find((x) => x.type === demarcationObj.demarcationType);

					map.setStyle(stateData.style)
						.once('styledata', () => {
							map.setLayoutProperty(sourceObj.source, 'visibility', 'visible')
								.setFilter(sourceObj.source, modelFilter)
								.easeTo({
									zoom: 8,
									center: location,
									curve: 1.5,
									speed: 1
								});

							map.setPaintProperty(sourceObj.source, 'fill-color', '#DA3B3A');
						})
						.once('moveend', () => {
							const features = map.querySourceFeatures('composite', {
								filter: modelFilter,
								sourceLayer: sourceObj.id,
								validate: false
							});

							if (features.length) {
								const bboxPolygons = bbox({
									type: 'FeatureCollection',
									features: features
								});

								map.fitBounds(bboxPolygons, {
									padding: { top: 10, bottom: 10, left: 15, right: 15 },
									maxZoom: 12
								});
							}
						});
				} else {
					map.once('load', function () {
						new mapboxgl.Marker().setLngLat(location).addTo(map);
						map.easeTo({
							zoom: 12,
							center: location
						});
					});
				}
			}
		}, [location, map]);

		return <div className="mapContainer" style={{ width: '100%', height: 500 }} ref={mapContainer} />;
	};

	return (
		<div>
			<MapContainer />
		</div>
	);
};

export default MapContainerWrapper;
