export const ErrorHandler = (error) => {
	if (error?.message === 'ACCESSO NO AUTORIZADO') {
		localStorage.removeItem('tokenData');
		localStorage.removeItem('userData');
		window.location.reload();
	}

	if (error.extensions.message === 'SESSION_EXPIRED_DEVICE') {
		window.location.href = '/login';
	}
};
