import React from 'react';
import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import {useTab} from 'components/tab/useTab';
import {Container, TabPane} from 'reactstrap';

import SendTab from './send-tab/sendTab';
import HistoryTab from './history-tab/HistoryTab';
import Breadcrumbs from 'layout/breadcrumb';

const TABS = ['Enviar', 'Historial'];

function VoterDetails() {
    const {activeTab, handleChangeTab} = useTab(TABS[0]);

    return (
        <>
            <Breadcrumbs parent="Tablas" title="SMS" isAccount={true}/>
            <Container className="" fluid={true}>
                <Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS}/>

                <TabContentContainer activeTab={activeTab}>
                    <TabPane tabId={TABS[0]}>
                        <SendTab/>
                    </TabPane>
                    <TabPane tabId={TABS[1]}>
                        <HistoryTab/>
                    </TabPane>
                </TabContentContainer>
            </Container>
        </>
    );
}

export default VoterDetails;
