import React, {Fragment, useState} from 'react';
import {Badge, Button, Card, CardHeader, Col, Container, Input, InputGroup, InputGroupText, Row} from 'reactstrap';
import Breadcrumb from '../../../../layout/breadcrumb';
import {Filter, PlusCircle, Search} from "react-feather";
import DataTable from "react-data-table-component";
import {customStyles} from "../../electores/tableStyles";
import {Link} from "react-router-dom";
import {usePagination} from "../../../../utils/fetch/pagination";
import {useSupportsWithPagination} from "../../../../utils/fetch/supports";

export function CellStatus({status}) {
    let colorState = 'warning';
    let labelState = 'creado';

    if (status === 'instalado') {
        colorState = 'success';
        labelState = 'Instalado';
    } else if (status === 'removido') {
        colorState = 'danger';
        labelState = 'Removido';
    }
    return (
        <Badge color={colorState} pill>
            {labelState}
        </Badge>
    );
}

const columns = [
    {
        name: 'Tipo',
        selector: (row) => row.type,
    },
    {
        name: 'Subtipo',
        selector: (row) => row.subType,
        center: true,
    },
    {
        name: 'Campaña',
        selector: (row) => row.campaignId
    },
    {
        name: 'Lider de Apoyo',
        selector: (row) => row?.leaderId,
        center: true
    },
    {
        name: 'Estado',
        selector: (row) => row.status,
        center: true,
        cell: (row) => <CellStatus status={row.status}/>
    },
    {
        cell: (row) => (
            <Link className="actionButton" to={`/apoyos/apoyos-details/${row._id}`}>
                <i className="fa fa-angle-right"></i>
            </Link>
        ),
        width: '40px',
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
    }
]
const FilterSupports = () => {

    const {page, perPage} = usePagination();
    const [filterParamsSupports] = useState({});

    const {
        data: {items}
    } = useSupportsWithPagination({perPage: perPage, page: page, filter: filterParamsSupports});

    return (
        <Fragment>
            <Breadcrumb parent="Apoyos" title="Apoyos"/>
            <Container fluid={true}>
                <Col sm="12">
                    <Card>
                        <CardHeader style={{padding: '32px 40px'}}>
                            <Row className="events-header">
                                <Col lg={4}>
                                    <h5 style={{order: '0'}}>Lista de apoyos</h5>
                                </Col>
                                <Col className="filter-events-header">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            height: '33px',
                                            padding: '0px 4px'
                                        }}
                                    >
                                        <Button
                                            className="button-content headers-button"
                                            style={{width: '161px'}}
                                            color="primary"
                                        >
                                            <span>Filtrar y Ordenar</span>
                                            <Filter size={16}/>
                                        </Button>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: '33PX',
                                            width: '320px'
                                        }}
                                    >
                                        <InputGroup>
                                            <InputGroupText
                                                style={{
                                                    height: '33px',
                                                    borderRadius: '6px 0px 0px 6px',
                                                    backgroundColor: 'transparent',
                                                    borderRight: '0px',
                                                    padding: '2px 6px'
                                                }}
                                            >
                                                <Search
                                                    style={{
                                                        color: 'gray'
                                                    }}
                                                    size={16}
                                                />
                                            </InputGroupText>
                                            <Input
                                                style={{
                                                    height: '33px',
                                                    borderRadius: '0px',
                                                    backgroundColor: 'transparent',
                                                    borderRight: '0px',
                                                    borderLeft: '0px'
                                                }}
                                                placeholder="Buscar por..."
                                                className="pl-0"
                                            />
                                            <Button
                                                className="button-content"
                                                style={{
                                                    height: '33px',
                                                    width: '78px',
                                                    borderRadius: '0px 6px 6px 0px',
                                                    borderLeft: '0px',
                                                    padding: '2px 6px'
                                                }}
                                                color="primary"
                                            >
                                                Buscar
                                            </Button>
                                        </InputGroup>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            height: '33px',
                                            padding: '0px 4px'
                                        }}
                                    >
                                        <Link to={'/apoyos/apoyos-create'}>
                                            <Button
                                                className="button-content headers-button"
                                                style={{width: '131px'}}
                                                color="primary"
                                            >
                                                <PlusCircle size={16}/>
                                                <span>Crear nuevo</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
                <Col>
                    <div className="table-responsive product-table">
                        <DataTable customStyles={customStyles} columns={columns}
                                   data={items}
                                   paginationComponentOptions={{
                                       rowsPerPageText: 'Mensajes por página:',
                                       rangeSeparatorText: 'de'
                                   }}
                                   pagination
                                   paginationServer
                                   highlightOnHover={true}/>
                    </div>
                </Col>
            </Container>

        </Fragment>

    );
};

export default FilterSupports;
