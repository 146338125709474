import InternalLoader from 'layout/internal-loader';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CheckCircle, ChevronRight, Filter, Info, Search, User, UserCheck } from 'react-feather';
import { Link } from 'react-router-dom';
import { Col, Container, Input, InputGroup, Row, Tooltip } from 'reactstrap';
import { useEventosElector } from 'utils/fetch/electores';
import EventosTabFiltro from './EventosTabFiltro';
import { useEntities } from 'utils/fetch/entities';
import { datatableCustomStyle } from 'utils/datatableCustomStyle';
import { axiosInstance } from 'utils/axiosInstance';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';

/**
 * Parameters to use in the data table
 */
const CustomEventInfoButton = ({ id }) => {
	return (
		<Link to={`${process.env.PUBLIC_URL}/tablas/eventos/${id}`}>
			<ChevronRight />
		</Link>
	);
};

const tableColumns = [
	{
		name: 'Nombre',
		selector: (row) => row.name,
		sortable: true,
		width: '30%'
	},
	{
		name: 'Fecha de realización',
		width: '20%',
		selector: (row) => row.date,
		sortable: true,
		center: true
	},
	{
		name: 'Entidad',
		selector: (row) => row.entity,
		sortable: true,
		width: '10%'
	},
	{
		name: 'Status',
		selector: (row) => row.status,
		width: '15%',
		center: true
	},
	{
		name: 'Responsable',
		selector: (row) => row.responsible,
		width: '15%',
		center: true
	},
	{
		cell: (row) => <CustomEventInfoButton id={row.id} />,
		allowOverflow: true,
		button: true,
		width: '10%',
		center: true
	}
];

const StatusBadge = (status) => {
	switch (status) {
		case 'Programado':
			return <div className="event-status not-started">{status}</div>;
		case 'En progreso':
			return <div className="event-status in-progress">{status}</div>;
		default:
			return <div className="event-status finished">{status}</div>;
	}
};

const EventosTabHeader = ({ userEventsData }) => {
	const [openAssigned, setOpenAssigned] = useState(false);
	const [openAssisted, setOpenAssisted] = useState(false);
	const [openNotAssisted, setOpenNotAssisted] = useState(false);

	const toggleOpenAssigned = () => setOpenAssigned(!openAssigned);
	const toggleOpenAssisted = () => setOpenAssisted(!openAssisted);
	const toggleOpenNotAssisted = () => setOpenNotAssisted(!openNotAssisted);

	return (
		<Row className="mb-2">
			<Col>
				<div
					style={{
						minHeight: '135px',
						padding: '24px',
						backgroundColor: '#FFF1F1',
						borderRadius: '8px'
					}}
				>
					<Col>
						<Row style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
							<div
								style={{
									width: '32px',
									height: '32px',
									backgroundColor: '#F04A5E',
									borderRadius: '6px',
									padding: '7px'
								}}
							>
								<User size={18} stroke="white" />
							</div>
							<Info size={16} stroke="#F04A5E" id={'tooltip-assistance'} />
							<Tooltip
								placement="bottom"
								isOpen={openAssisted}
								target={'tooltip-assistance'}
								toggle={toggleOpenAssisted}
							>
								Total eventos asignados al elector a los cuales asistió
							</Tooltip>
						</Row>
						<Row className="my-1">
							<span
								style={{
									fontWeight: '400',
									fontSize: '12px',
									lineHeight: '14px',
									color: '#AFAFAF'
								}}
							>
								Total eventos asignados al elector a los cuales asistió
							</span>
						</Row>
						<Row>
							<span
								style={{
									fontWeight: '500',
									fontSize: '28px',
									lineHeight: '33px',
									color: '#F04A5E'
								}}
							>
								{userEventsData.assistedEvents}
							</span>
						</Row>
					</Col>
				</div>
			</Col>
			<Col>
				<div
					style={{
						minHeight: '135px',
						padding: '24px',
						backgroundColor: '#FFFCE6',
						borderRadius: '8px'
					}}
				>
					<Col>
						<Row style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
							<div
								style={{
									width: '32px',
									height: '32px',
									backgroundColor: '#C8A800',
									borderRadius: '6px',
									padding: '7px'
								}}
							>
								<UserCheck size={18} stroke="white" />
							</div>
							<Info size={16} stroke="#C8A800" id="tooltip-assigned" />
							<Tooltip
								placement="bottom"
								isOpen={openAssigned}
								target={'tooltip-assigned'}
								toggle={toggleOpenAssigned}
							>
								Total eventos asignados
							</Tooltip>
						</Row>
						<Row className="my-1">
							<span
								style={{
									fontWeight: '400',
									fontSize: '12px',
									lineHeight: '14px',
									color: '#AFAFAF'
								}}
							>
								Total eventos asignados
							</span>
						</Row>
						<Row>
							<span
								style={{
									fontWeight: '500',
									fontSize: '28px',
									lineHeight: '33px',
									color: '#C8A800'
								}}
							>
								{userEventsData.assignedEvents}
							</span>
						</Row>
					</Col>
				</div>
			</Col>
			<Col>
				<div
					style={{
						minHeight: '135px',
						padding: '24px',
						backgroundColor: '#FFF1F1',
						borderRadius: '8px'
					}}
				>
					<Col>
						<Row style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
							<div
								style={{
									width: '32px',
									height: '32px',
									backgroundColor: '#F04A5E',
									borderRadius: '6px',
									padding: '7px'
								}}
							>
								<CheckCircle size={16} stroke="white" />
							</div>
							<Info size={16} stroke="#F04A5E" id="tooltip-not-assisted" />
							<Tooltip
								placement="bottom"
								isOpen={openNotAssisted}
								target={'tooltip-not-assisted'}
								toggle={toggleOpenNotAssisted}
							>
								Total eventos con inasistencia
							</Tooltip>
						</Row>
						<Row className="my-1">
							<span
								style={{
									fontWeight: '400',
									fontSize: '12px',
									lineHeight: '14px',
									color: '#AFAFAF'
								}}
							>
								Total eventos con inasistencia
							</span>
						</Row>
						<Row>
							<span
								style={{
									fontWeight: '500',
									fontSize: '28px',
									lineHeight: '33px',
									color: '#F04A5E'
								}}
							>
								{userEventsData.notAssistedEvents}
							</span>
						</Row>
					</Col>
				</div>
			</Col>
		</Row>
	);
};

const SearchBar = ({ search, setSearch, openFilter, handleFilter }) => {
	return (
		<Row className="mt-2">
			<Col>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '0 24px',
						height: '51px',
						background: '#FFF1F1',
						borderRadius: '4px'
					}}
				>
					<span
						style={{
							fontWeight: '500',
							fontSize: '16px',
							lineHeight: '19px',
							color: '#F04A5E'
						}}
					>
						Lista de eventos
					</span>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
						<div>
							<button
								className="btn btn-primary"
								onClick={openFilter}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									fontWeight: '400',
									fontSize: '12px',
									lineHeight: '14px',
									padding: '8px 16px'
								}}
							>
								<span>Filtrar y Ordenar</span>
								<Filter size={16} />
							</button>
						</div>
						<div>
							<InputGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Input
									placeholder="Buscar por..."
									className="pl-4 search-input"
									style={{
										fontWeight: '400',
										fontSize: '12px',
										lineHeight: '14px',
										color: '#AFAFAF'
									}}
									onChange={(e) => setSearch(e.target.value)}
									value={search}
								/>
								<Search
									style={{
										position: 'absolute',
										left: '6px',
										bottom: '10px',
										color: 'gray',
										zIndex: 10
									}}
									size={16}
								/>
								<button
									className="btn btn-primary"
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										fontWeight: '400',
										fontSize: '12px',
										lineHeight: '14px',
										padding: '8px 16px',
										borderRadius: '0 6px 6px 0'
									}}
									onClick={handleFilter}
								>
									Buscar
								</button>
							</InputGroup>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
};

const EventosTab = ({ voterId }) => {
	const [filterIsOpen, setFilterIsOpen] = useState(false);

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedDate, setSelectedDate] = useState();
	const [selectedEntity, setSelectedEntity] = useState();
	const [selectedStatus, setSelectedStatus] = useState([]);

	const { loading: loadingEntities, data: entities } = useEntities();

	const [eventsData, setEventsData] = useState([]);

	const [loadingFilter, setLoadingFilter] = useState(false);

	/**
	 * Manage the string for search from input
	 */
	const [search, setSearch] = useState('');

	const { data, loading, count } = useEventosElector(voterId);

	const toggleFilter = () => setFilterIsOpen((prev) => !prev);

	useEffect(() => {
		if (data) {
			const mapEventsData = () => {
				return data.map(({ id, name, start_time, related_entities, status, responsable }) => {
					return {
						id: id,
						name: name,
						date: new Date(start_time).toLocaleDateString('es-ES', {
							month: '2-digit',
							day: '2-digit',
							year: 'numeric'
						}),
						entity: related_entities[0].entity_name,
						status: StatusBadge(status),
						responsible: responsable[0]?.full_name ?? ''
					};
				});
			};

			setEventsData(mapEventsData);
		}
	}, [data]);

	if (loading || loadingEntities) return <InternalLoader />;

	const handleFilterSearch = async () => {
		setLoadingFilter(true);

		let params = '';

		if (search) {
			const finalQuery = search.replace(/\s/, '%20');
			params += `&name__icontains=${finalQuery}`;
		}

		if (selectedEntity) {
			params += `&entities=${selectedEntity.value}`;
		}

		if (selectedDate) {
			const finalDate = new Date(selectedDate).toISOString().replace(/:/g, '%3A');
			params += `&start_time__icontains=${finalDate}`;
		}

		if (selectedStatus.length > 0) {
			selectedStatus.forEach((sta) => {
				const finalStatus = sta.value.replace(/\s/, '%20');
				params += `&status__icontains=${finalStatus}`;
				return;
			});
		}

		if (selectedOrder) {
			switch (selectedOrder.value) {
				case 'aToZ':
					params += '&ordering=name';
					break;
				case 'zToA':
					params += '&ordering=-name';
					break;
				case 'older':
					params += '&ordering=start_time';
					break;
				default:
					params += '&ordering=-start_time';
					break;
			}
		}

		const url = `/events/?page=1&size=10&participant_id=${voterId}${params}`;

		const { results } = await axiosInstance({ method: 'GET', url: url })
			.then((response) => response?.data)
			.catch((err) => console.log({ err }))
			.finally(() => setLoadingFilter(false));

		setEventsData(
			results.map(({ id, name, start_time, related_entities, status, responsable }) => {
				return {
					id: id,
					name: name,
					date: new Date(start_time).toLocaleDateString('es-ES', {
						month: '2-digit',
						day: '2-digit',
						year: 'numeric'
					}),
					entity: related_entities[0].entity_name,
					status: StatusBadge(status),
					responsible: responsable[0]?.full_name ?? ''
				};
			})
		);
	};

	const getUserEventsData = () => {
		return {
			assistedEvents: 0,
			assignedEvents: 0,
			notAssistedEvents: 0
		};
	};

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const filterStates = {
		selectedOrder,
		setSelectedOrder,
		selectedDate,
		setSelectedDate,
		selectedEntity,
		setSelectedEntity,
		selectedStatus,
		setSelectedStatus
	};

	const filterOptions = {
		entitiesOptions
	};

	return (
		<>
			<Container fluid className="bg-white">
				<EventosTabHeader userEventsData={getUserEventsData()} />
				<SearchBar
					search={search}
					setSearch={setSearch}
					openFilter={toggleFilter}
					handleFilter={handleFilterSearch}
				/>
				<DataTable
					noHeader
					columns={tableColumns}
					data={eventsData}
					progressComponent={<InternalLoader />}
					progressPending={loading}
					highlightOnHover={true}
					pointerOnHover={true}
					selectableRows={false}
					pagination={true}
					paginationTotalRows={count}
					paginationComponentOptions={{
						rowsPerPageText: 'Eventos por página:',
						rangeSeparatorText: 'de'
					}}
					customStyles={datatableCustomStyle}
					noDataComponent={'No se han encontrado eventos'}
				/>
			</Container>
			<EventosTabFiltro
				isOpen={filterIsOpen}
				toggle={toggleFilter}
				states={filterStates}
				filterOptions={filterOptions}
				handleFilter={handleFilterSearch}
			/>
			<LoadingAffiliation isOpen={loadingFilter} />
		</>
	);
};

export default EventosTab;
