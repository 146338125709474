import moment from 'moment';

export const getAgeFromDate = (dob, mob, yob) => {
	const today = new Date();
	let age = today.getFullYear() - yob;

	if (today.getMonth() < mob || (today.getMonth() === mob && today.getDate() < dob)) age--;

	return age;
};

export const getAgeWithMoment = (date_birth) => {
	let age = '-';

	if (date_birth) {
		const dateString = moment(date_birth, 'DD/MM/YYYY').isValid()
			? moment(date_birth, 'DD/MM/YYYY').toDate()
			: moment(date_birth, 'YYYY/MM/DD').isValid()
			? moment(date_birth, 'YYYY/MM/DD').toDate()
			: moment(date_birth, 'YYYY-MM-DD').isValid()
			? moment(date_birth, 'YYYY-MM-DD').toDate()
			: moment().subtract(15, 'years').toDate();
		const dateOfBirth = moment(dateString);

		if (dateOfBirth.isValid()) {
			age = moment().diff(dateOfBirth, 'years');
		}
	}

	return age;
};
