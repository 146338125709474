import React, { useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	FormGroup,
	Label,
	Button
} from 'reactstrap';
import { generateQRCode, remove2FAToken, verify2FACode } from '../../../redux/actions';
import { ModalComponent } from '../../dashboard/modal';

import * as QRCodeReact from 'qrcode.react';

export const TwoFactorConfig = () => {
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState(false);
	const [showQR, setShowQR] = useState(false);
	const [codeConfirmation, setCodeConfirmation] = useState('');

	const user = useSelector(state => state.User?.user);
	const remove2FAError = useSelector(state => state.User?.remove2FATokenError);
	const remove2FATokenSuccess = useSelector(state => state.User?.remove2FATokenSuccess);
	const isLoading = useSelector(state => state.UI?.isLoading);
	const QRError = useSelector(state => state.User?.QRError);
	const QRCode = useSelector(state => state.User?.QRCode);
	const base32 = useSelector(state => state.User?.base32);
	const verify2FACodeSuccesfull = useSelector(state => state.User?.verify2FACodeSuccesfull);
	const verify2FACodeError = useSelector(state => state.User?.verify2FACodeError);	

	
	const toogleRemoveModal = () => {
		setOpenModal(!openModal);
	}

	const toogleShowQR = () => {
		if (!showQR) {
			dispatch(generateQRCode());
		}
		setShowQR(!showQR);
	};

	const onChangeInputConfirmation = (e) => {
		setCodeConfirmation(e.target.value);
	};

	const onConfirm2FACode = () => {
		dispatch(verify2FACode({code: codeConfirmation}));
	};
	
	const onRemoveToken = () => {
		dispatch(remove2FAToken());
	};

	const tfaEnabled = user?.two_factor_enabled;
	const status = tfaEnabled ? 'Activado' : 'Desactivado';
    return <>
		<Container fluid={true}>
			<Breadcrumb parent="Configuración" title="Configurar segundo factor" isAccount={true} />
			<Row>
				<Col sm={{ size: 8, offset: 2 }}>
					<Card>
						<CardBody>
							<div
								style={{
									backgroundColor: '#FFF',
									padding: 20,
									marginTop: 5,
									marginBottom: 5
								}}
							>
								<Row className="theme-form">
									<FormGroup className="col-12">
										<Row>
											<Label className="col-form-label">Estado: {status}</Label>
										</Row>
										<Row>
											{tfaEnabled && <Button onClick={toogleRemoveModal} color="danger">Desactivar segundo factor</Button>}
											{!tfaEnabled && <Button onClick={toogleShowQR} color="success">Generar código de segundo Factor.</Button>}
										</Row>
										<Row>
											{
												isLoading && <div className="spinner-border mt-2" role="status">
													<span className="sr-only">Loading...</span>
											  	</div>
											}
											{
												remove2FAError && <div className="alert alert-danger mt-2" role="alert">
												  Ha ocurrido un error, intente más tarde.
											    </div>
											}
										</Row>
										{showQR && !isLoading && !verify2FACodeSuccesfull && <Row className="mt-2">
											<Col>
												<Row>
													<p>1. Descarga la aplicacion de Google Authenticator para tu dispositivo móvil</p>
												</Row>
												<Row>
													<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Para Android</a>
												</Row>
												<Row>
													<a href="https://apps.apple.com/es/app/google-authenticator/id388497605">Para iOs</a>
												</Row>
												<Row>
													<p>2. Escanea el codigo QR</p>
												</Row>
												<Row>
													{/* Muestra el codigo QR aca */}
													{QRError && <div className="alert alert-danger mt-2 mb-2" role="alert">Ha ocurrido un error generando el codigo QR, intente más tarde.</div>}
													{QRCode && <Col>
														<Row className="mt-3 mb-3">
														  <QRCodeReact value={QRCode}></QRCodeReact>
														</Row>
														<Row>
															<div className="alert alert-light">
																{base32}
															</div>
														</Row>
													</Col>}
												</Row>
												<Row className="mt-2">
													3. Confirma la activación con el código generado por Google Authenticator.
												</Row>
												<Row>
													<input type="number" onChange={onChangeInputConfirmation} className="form-control" value={codeConfirmation}/>
												</Row>
												<Row>
													<Button disabled={!codeConfirmation.length} onClick={onConfirm2FACode} className="mt-2" color="success">Confirmar</Button>
												</Row>
												<Row>
													{verify2FACodeError && <div className="alert alert-danger mt-2 mb-2" role="alert">
														Ha ocurrido un error, intente más tarde.
													</div>}
													{verify2FACodeSuccesfull && <div className="alert alert-success mt-2 mb-2" role="alert">
														Código de segundo factor configurado correctamente.
													</div>}
													{(verify2FACodeSuccesfull === false) && <div className="alert alert-danger mt-2 mb-2" role="alert">
														Código incorrecto.
													</div>}
												</Row>
											</Col>
										</Row>}
									</FormGroup>
								</Row>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
		<ModalComponent open={openModal && !remove2FATokenSuccess}>
			<Card>
				<CardBody>
					<Row className="mb-3">
						¿Estás seguro de eliminar la autenticación de segundo factor?
					</Row>
					<Row>
						<Col>
							<Button className="form-control" onClick={toogleRemoveModal} color="dark">Cancelar</Button>
						</Col>
						<Col>
							<Button className="form-control" onClick={onRemoveToken} color="danger">Eliminar</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</ModalComponent>
	</>
}