import React, {Fragment} from 'react';
import {
    Col,
    Container,
    Row,
} from 'reactstrap';
import moment from "moment";

export default function InformationTab({selectedSupport}) {

    return (
        <Fragment>
            <Container className="page-voter-details" fluid>
                <Row>
                    <Col sm="12">

                        <Row>
                            <Col sm="12" md="6">
                                <div style={{margin: '10px'}}>
                                    <div className="custom-detail-card">
                                        <div className="custom-title">
                                            <h3 className="m-0">Datos generales</h3>
                                        </div>
                                        <div>
                                            <p className="item-title">Líder de apoyo</p>
                                            <div className="item-description">{selectedSupport?.leaderId}</div>
                                        </div>
                                        <div>
                                            <p className="item-title">Campaña perteneciente</p>
                                            <div className="item-description">{selectedSupport?.campaingId}</div>
                                        </div>
                                        <div>
                                            <p className="item-title">Úlima actualización</p>
                                            <div className="item-description">{moment(selectedSupport?.updatedAt).format('LL')}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="6">
                                <div style={{margin: '10px'}}>
                                    <div className="custom-detail-card">
                                        <div className="custom-title">
                                            <h3 className="m-0">Sub tipo</h3>
                                        </div>
                                        <div>
                                            <div className="item-description">{selectedSupport?.subType}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
