import { useEffect, useState } from 'react';
import { axiosInstance } from 'utils/axiosInstance';

export const searchAffiliates = (searchTerm) => {
	if (!searchTerm) {
		return [];
	}

	return axiosInstance(`/get-affiliates/`, {
		//?page=${currentPage}&size=${currentPerPage}
		params: { size: 100, no_paginate: true /*  search: searchTerm */ }
	})
		.then((response) => {
			const data = response?.data?.results || [];
			return data.map((affiliate) => ({ value: affiliate.id, label: affiliate?.text_detect?.fields?.full_name }));
		})
		.catch((error) => {
			console.error(error);
			return [];
		});
};

export function useAffiliates(search) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		setLoading(true);
		if (search) {
			axiosInstance(`/get-affiliates/`, {
				//?page=${currentPage}&size=${currentPerPage}
				params: { size: 100, no_paginate: true }
			})
				.then((response) => {
					const data = response?.data;
					setData(data);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}, [search]);

	return { loading, data };
}

export function useRecruiters() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		setLoading(true);
		axiosInstance(`users/profiles/?user__groups__name=Reclutadores`)
			.then((response) => {
				const data = response?.data?.results || [];
				setData(data);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => setLoading(false));
	}, []);

	return { loading, data };
}

export function useLevels(structureId, { toObject = false } = {}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		if (structureId) {
			setLoading(true);

			let levels = null;

			axiosInstance(`/activism/level/?no_paginate=true&estructure=${structureId}&size=20`)
				.then((response) => {
					levels = response?.data?.results?.sort((a, b) => a.id - b.id);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(async () => {
					const finalData = await Promise.all(
						levels.map(async (level) => {
							let affiliates = [];

							await axiosInstance(`/activism/associate-affiliates/?fk_level=${level.id}`)
								.then(({ data }) => {
									affiliates = data.results;
								})
								.catch((error) => console.log(error));

							return { ...level, affiliates };
						})
					);

					const res = toObject
						? finalData.reduce(
								(obj, item) => ({
									...obj,
									[item.id]: item
								}),
								{}
						  )
						: finalData;

					setData(res);
					setLoading(false);
				});
		}
	}, [structureId, toObject]);

	return { loading, data };
}

export function useLevel(levelId = {}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		if (levelId) {
			setLoading(true);
			axiosInstance(`/activism/level/${levelId}`)
				.then((response) => setData(response.data))
				.catch((error) => {
					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}, [levelId]);

	return { loading, data };
}

export const createLevel = ({
	name,
	number_managers,
	recruiter,
	number_people_recruit,
	estructure,
	parent_lvl,
	recruiters
}) =>
	axiosInstance({
		method: 'post',
		url: `/activism/level/`,
		data: {
			name,
			number_managers,
			recruiter,
			number_people_recruit,
			estructure,
			parent_lvl,
			recruiters
		}
	});

export const updateLevel = ({
	id: levelId,
	name,
	number_managers,
	recruiter,
	number_people_recruit,
	estructure,
	parent_lvl,
	recruiters
}) =>
	axiosInstance({
		method: 'put',
		url: `/activism/level/${levelId}/`,
		data: {
			name,
			number_managers,
			recruiter,
			number_people_recruit,
			estructure,
			parent_lvl,
			recruiters
		}
	});
