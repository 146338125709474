import React, { useState } from 'react';

import { Button } from 'reactstrap';
import Select from 'react-select';
import { mapSetup } from '../../../data/map-setup';

const ageOptions = [
	{
		value: "greater-than",
		label: "Mayor que"
	},
	{
		value: "less-than",
		label: "Menor que"
	},
	{
		value: "equals-to",
		label: "Igual a"
	}
]

const genderOptions = [
	{
		value: 'hombre',
		label: 'Hombre'
	},
	{
		value: 'mujer',
		label: 'Mujer'
	}
];

const sectionVoters = (voters, selectedCity) => {
	if (!voters || !selectedCity) {
		return [];
	}
	
	return voters.reduce((acc, voter) => {
		const currentsection = voter.ocr.section;
		const currentCity = voter.ocr?.state;

		if (
			currentsection &&
			currentCity &&
			selectedCity === currentCity &&
			!acc.find((filteredValues) => filteredValues.value === currentsection)
		) {
			const item = { label: currentsection, value: currentsection };
			acc.push(item);
		}

		return acc;
	}, []);
};

const stateVoters = (voters) => {
	if (!voters) {
		return;
	}

	return voters.reduce((acc, voter) => {
		const currentLocation = voter.ocr?.state;

		if (currentLocation && !acc.find((filteredValues) => filteredValues.value === currentLocation)) {
			const item = { label: currentLocation, value: currentLocation };
			acc.push(item);
		}

		return acc;
	}, []);
};

const getSelectedSectionsValues = (selectedSections) => {
	return selectedSections.map((section) => section.value);
};

const getUserValues = (selectedUser) => {
	return selectedUser.map((user) => user.value.toLowerCase());
}

const getSelectedGenderValues = (selectedGender) => {
	return selectedGender.map((gender) => gender.value.toLowerCase());
}

export default function CustomFilter({
	voters,
	resetInitialMapSetup,
	setTableResults,
	drawSectionOnMap
}) {
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedSections, setSelectedSections] = useState([]);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('')
	const [electoralKey, setElectoralKey] = useState('');
	const [user, setUser] = useState([]);
	const [ageFilter, setAgeFilter] = useState();
	const [age, setAge] = useState('');
	const [selectedGender, setSelectedGender] = useState([]);
	const [filterResult, setFilterResult] = useState([]);

	const sectionOptions = sectionVoters(voters, selectedLocation);
	const cityOptions = stateVoters(voters);

	const resetCustomFilter = () => {
		setSelectedLocation('');
		setSelectedSections([]);
		setFirstName('');
		setLastName('');
		setElectoralKey('');
		setUser([]);
		setAgeFilter('');
		setAge('');
		setSelectedGender([]);
		resetInitialMapSetup();
		setFilterResult([]);
		setTableResults && setTableResults([]);
	};

	const handleResultChange = (newValue) => {
		setFilterResult(newValue);

		if (setTableResults) {
			const sectionsToIterate = selectedSections.length > 0 ? selectedSections : sectionOptions;
			const filteredDatabySection =
				sectionsToIterate.length > 0
					? getSelectedSectionsValues(sectionsToIterate).map((section) => {
							const sectionData = { section };
							sectionData.voters = newValue.filter(
								(elector) => {
									return parseInt(elector.ocr?.section) === parseInt(section, 10);
								}
							);
							return sectionData;
					  })
					: [];

			setTableResults(filteredDatabySection);
		}
	};

	const userMapSelector = Array.from(new Set(voters.map((a) => a.user))).map((user) => {
		if (!user) {
			return {};
		}
		return { value: user, label: user };
	});

	const getFilteredVoters = () => {
		const filteredVoters = voters.filter((voter) => {
			if (selectedLocation && voter.ocr?.state !== selectedLocation) {
				return false;
			}

			if (
				selectedSections.length > 0 &&
				!getSelectedSectionsValues(selectedSections).includes(voter.ocr?.section)
			) {
				return false;
			}

			if(firstName && !voter.ocr?.first_name.toLowerCase().includes(firstName.toLowerCase())) {
				return false;
			}

			if(lastName && !voter.ocr?.last_name.toLowerCase().includes(lastName.toLowerCase())) {
				return false;
			}

			if(electoralKey && !voter.ocr?.electoral_key.toLowerCase().includes(electoralKey.toLowerCase())) {
				return false;
			}

			if(user.length > 0 && !getUserValues(user).includes(voter.user?.toLowerCase())) {
				return false;
			}

			if(ageFilter && age) {
				const parseAge = parseInt(age, 10);

				let voterDateParts = voter.ocr?.date_birth.split("/");

				let voterAge;

				let date = new Date();
				let birthDate = new Date(+voterDateParts[2], voterDateParts[1] - 1, +voterDateParts[0]);

				voterAge = date.getFullYear() - birthDate.getFullYear();

				let m = date.getMonth() - birthDate.getMonth();

				if (m < 0 || (m === 0 && date.getDate() < birthDate.getDate())) voterAge--;

				if (ageFilter.value === 'greater-than' && voterAge <= parseAge) {
					return false;
				} else if (ageFilter.value === 'less-than' && voterAge >= parseAge) {
					return false;
				} else if (ageFilter.value === 'equals-to' && voterAge !== parseAge) {
					return false;
				}
			}

			if(selectedGender.length > 0 && !getSelectedGenderValues(selectedGender).includes(voter.ocr?.gender.toLowerCase())) {
				return false;
			}

			return true;
		});

		if (filteredVoters.length > 0) {
			const sections = filteredVoters.map((elector) => {
				return parseInt(elector.ocr.section);
			}).filter((seccion) => {
				return !isNaN(seccion);
			});
			const estadoCode = filteredVoters[0].ocr?.id_state;
			const estadoData = mapSetup.find((x) => x.id === estadoCode);

			const votersBySection = sections.map((section) => {
				return {section: section, voters: voters.filter((voter) => (section === parseInt(voter.ocr.section))).length}
			})

			drawSectionOnMap(sections, estadoData, votersBySection);
		} else {
			resetInitialMapSetup();
		}

		handleResultChange(filteredVoters);
	};

	return (
		<div className="voters-filters-container">
			<div>
				<div className="selectors">
					<div>
						<label>Según su estado:</label>
						<Select
							options={cityOptions}
							onChange={(selectedItem) => setSelectedLocation(selectedItem.value)}
							placeholder="Selecciona un estado"
							value={cityOptions.find((item) => item.value === selectedLocation) || ''}
						></Select>
					</div>
					<div>
						<label>Según su sección:</label>
						<Select
							isMulti
							isDisabled={!selectedLocation}
							options={sectionOptions}
							onChange={setSelectedSections}
							placeholder="Selecciona una sección"
							value={selectedSections}
						></Select>
					</div>
					<div className="input-content">
						<label>Según su nombre:</label>
						<input
							type="text"
							disabled={!selectedLocation}
							value={firstName}
							placeholder="Indique el valor a buscar"
							onChange={(e) => setFirstName(e.target.value)}
							onKeyPress={(event) => {
								if (!/[a-zA-Z]/.test(event.key)) {
									event.preventDefault();
								}
							}}
						/>
					</div>
					<div className="input-content">
						<label>Según su apellido:</label>
						<input
							type="text"
							disabled={!selectedLocation}
							value={lastName}
							placeholder="Indique el valor a buscar"
							onChange={(e) => setLastName(e.target.value)}
							onKeyPress={(event) => {
								if (!/[a-zA-Z]/.test(event.key)) {
									event.preventDefault();
								}
							}}
						/>
					</div>
				</div>
				<div className="selectors">
					<div className="input-content">
						<label>Según su clave electoral:</label>
						<input
							type="text"
							value={electoralKey}
							disabled={!selectedLocation}
							placeholder="Indique el valor a buscar"
							onChange={(e) => setElectoralKey(e.target.value)}
						/>
					</div>
					<div>
						<label>Según su capturista:</label>
						<Select
							isMulti
							isDisabled={!selectedLocation}
							placeholder="Indique el valor a buscar"
							onChange={(e) => setUser(e)}
							value={user}
							options={userMapSelector}
						/>
					</div>
					<div className="age">
						<label>Según su edad:</label>
						<div className="filter-container">
							<div className="select-content-age">
								<Select
									isDisabled={!selectedLocation}
									placeholder="Indique el valor a buscar"
									value={ageFilter}
									onChange={(e) => setAgeFilter(e)}
									options={ageOptions}
								/>
							</div>
							<div className="input-content-age">
								<input
									disabled={!selectedLocation}
									type="text"
									placeholder="Indique el valor a buscar"
									value={age}
									onChange={(e) => setAge(e.target.value)}
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
										}
									}}
								/>
							</div>
						</div>
					</div>
					<div className="gender">
						<label>Según su género:</label>
						<Select
							isMulti
							isDisabled={!selectedLocation}
							value={selectedGender}
							placeholder="Indique el valor a buscar"
							onChange={(e) => setSelectedGender(e)}
							options={genderOptions}
						/>
					</div>
				</div>
				<div className="button-container">
					<div style={{ marginRight: 'auto' }}>
						<Button
							disabled={!selectedLocation}
							onClick={getFilteredVoters}
							type="primary"
							className="mt-2"
						>
							Aplicar filtro
						</Button>
						<Button color="light" onClick={resetCustomFilter} className="mt-2 ml-2">
							Remover Filtros
						</Button>
					</div>
				</div>
			</div>
			<div className="results-text">
				<h3>Resultados:</h3>
				<p>Se han encontrado {filterResult.length} usuarios que cumplen con este requerimiento</p>
			</div>
		</div>
	);
}
