import React, {useState} from 'react';
import {Container, Row, Col, FormGroup, Label, Button} from 'reactstrap';
import {Link} from "react-router-dom";
import Select from "react-select";
import InputPlusMinus from "../../../inputPlusMinus/InputPlusMinus";
import Multiselect from "../../../multiselect/Multiselect";
import "../../../appliedTerritories/appliedTerritories.scss";
import {useCampaignWithPagination} from "../../../../utils/fetch/supports";
import {usePagination} from "../../../../utils/fetch/pagination";
import InternalLoader from "../../../../layout/internal-loader";
import moment from "moment";
import {X} from "react-feather";
import {toast} from "react-toastify";

const colourStyles = {
    option: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: 'white',
        '&:hover': {backgroundColor: '#F04A5E22'}
    }),
    control: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: '#FFF1F1'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: '#FFF1F1'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#F04A5E'
        };
    }
};

const colourOptions = [
    {value: 'tipo1', label: 'Lona', color: '#00B8D9', isDisabled: true},
    {value: '45x45', label: '45x45', color: '#0052CC'},
    {value: 'tipo2', label: 'tipo 2', color: '#5243AA', isDisabled: true},
    {value: 'subtipo', label: 'subtipo', color: '#0052CC'},

];

function CreateSupportStepOne({setStep, setDataStepOne}) {

    const {page, perPage} = usePagination();
    const [filterParamsCampaign] = useState({});

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [selectedType, setSelectedType] = useState([]);

    const {
        loading: loadingCampaigns,
        data: {items}
    } = useCampaignWithPagination({perPage: perPage, page: page, filter: filterParamsCampaign});

    const nextStep = () => {
        console.log(selectedCampaign)
        console.log(selectedNumber)
        console.log(selectedType)
        if (
            selectedCampaign &&
            selectedNumber &&
            (selectedType.length > 0)
        ) {
            setDataStepOne({
                selectedCampaign,
                selectedNumber,
                selectedType
            })
            setStep(1)
        } else {
            toast.error('Faltan datos en el formulario', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }

        // navigate(ROUTES.SUPPORTS_CREATE_TWO)
    }

    if (loadingCampaigns) {
        return <InternalLoader/>;
    }

    const campaignOptions = Object.values(items || {})?.map((campaign) => {
        return {
            label: campaign.name,
            value: campaign.name,
            demarcation: campaign.demarcation,
            startDate: campaign.startDate,
            finishDate: campaign.finishDate,
            id: campaign._id
        };
    });

    return (
        <div className="create-pyramid-page">
            <Container fluid>
                <Row className="">
                    <Col className="mr-auto">
                        <h3>Crear Apoyo</h3>
                    </Col>
                    <Col sm="auto" className="ml-auto">
                        <h3 className="font-primary">Paso 1 de 2</h3>
                    </Col>
                </Row>
                <div className="containerForm">
                    <Row>
                        <Col sm="12" xs="12">
                            <h5 className="title">Datos de apoyo</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="5" xs="12">
                            <FormGroup>
                                <Label>{'Elegir campaña'}</Label>
                                <Select
                                    isClearable
                                    name="campaign"
                                    id="selectedCampaign"
                                    options={campaignOptions}
                                    placeholder={'Campaña'}
                                    styles={colourStyles}
                                    onChange={(option) => {
                                        setSelectedCampaign(option);
                                    }}
                                    value={selectedCampaign}
                                />

                            </FormGroup>
                        </Col>
                        <Col sm="3">
                            <FormGroup>
                                <Label className="col-form-label">Cantidades de este Apoyo</Label>
                                <InputPlusMinus
                                    noNegativeNumbers
                                    placeholder="Ingresar número"
                                    onChange={(value) => setSelectedNumber(value)}
                                    value={selectedNumber}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <div className="title">
                                <h5 style={{fontSize: '16px', fontWeight: '500'}}>Datos de campaña</h5>
                                <Row>
                                    <Col sm="6" xs="12">
                                        <h5 style={{
                                            fontSize: '12px',
                                            color: 'black',
                                            fontWeight: '400'
                                        }}>Inicia: {selectedCampaign?.startDate ? moment(selectedCampaign?.startDate).format('DD-MM-YYYY - HH:mm') : ''}</h5>
                                    </Col>
                                    <Col sm="6" xs="12">
                                        <h5 style={{
                                            fontSize: '12px',
                                            color: 'black',
                                            fontWeight: '400'
                                        }}>Finaliza: {selectedCampaign?.finishDate ? moment(selectedCampaign?.finishDate).format('DD-MM-YYYY - HH:mm') : ''}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" xs="12">
                                        <h5 style={{
                                            fontSize: '12px',
                                            color: 'black',
                                            fontWeight: '400'
                                        }}>Demarcación: {selectedCampaign?.demarcation || ''}</h5>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" xs="12">
                            <h5 className="title m-t-30">Datos de apoyo</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" xs="12">
                            <Multiselect
                                noOptionMessage={'Buscar tipo'}
                                options={colourOptions}
                                onChange={setSelectedType}
                            />
                        </Col>

                        <Col sm="6">
                            <div className="selected-sections">
                                <Row className="selected-sections-header">
                                    <span>Tipos de apoyo</span>
                                    <Row className="selected-actions-body">
                                        {selectedType?.length > 0 && (
                                            <div className="selected-items">

                                                <div>
                                                    <ul className="list-horizontal">
                                                        {selectedType.map((section, index) => {
                                                            return (
                                                                <li key={`section-${section.label || section.section}`}>
                                                                    {`${section.label || section.section}`} <X
                                                                    size="14"/>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </Row>
                                </Row>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col className="mr-auto">
                            <Link to="/apoyos/filtrar-apoyos">
                                <Button size="md" className="" color="primary">
                                    Volver atras
                                </Button>
                            </Link>
                        </Col>

                        <Col sm="auto">
                            <Button size="md" className="" color="primary"
                                    onClick={() => nextStep()}>
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default CreateSupportStepOne;
