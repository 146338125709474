import { useEffect, useState } from 'react';
import { axiosInstance } from 'utils/axiosInstance';
import filterDemarcations from '../filterDemarcations';

// pending note ->All this logic needs a refactor

export function useMunicipalityByEntity(entityId, municipalityId) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		if (entityId && municipalityId) {
			setLoading(true);
			axiosInstance({
				method: 'GET',
				url: `/municipality/?code__icontains=${municipalityId}&fk_local_district__fk_district__fk_entity_id=${entityId}`
			})
				.then((response) => {
					setData(response.data);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => setLoading(false));
		}
	}, [entityId, municipalityId]);

	return { loading, data };
}

export function useMunicipalitiesWithFilters(entity) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	const entityValue = entity?.value || entity?.code;
	useEffect(() => {
		if (entity) {
			setLoading(true);
			axiosInstance({
				method: 'GET',
				//url: `/municipality/?no_paginate=true&ordering=nombre&fk_local_district__fk_district__fk_entity_id=${entityValue}`
				url: `/municipality/?no_paginate=true&fk_local_district__fk_district__fk_entity_id=${entityValue}`
			})
				.then((response) => {
					const result = response.data
						.sort((a, b) => (a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0))
						.map(({ id, nombre, code }) => ({
							id: id,
							label: `${nombre}`,
							value: code
						}));
					const municipalitiesFiltered = filterDemarcations(result);
					setData(municipalitiesFiltered);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => setLoading(false));
		} else {
			setData();
		}
	}, [entity, entityValue]);

	return { loading, data };
}

export function useLocalDistricsWithFilters(entity) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const entityValue = entity?.value || entity?.code;

	useEffect(() => {
		if (entity) {
			setLoading(true);
			axiosInstance({
				method: 'GET',
				url: `/local-distric/?no_paginate=true&ordering=code&fk_district__fk_entity_id=${entityValue}`
			})
				.then((response) => {
					const result = response.data.map(({ id, code }) => ({
						id: id,
						label: code,
						value: code
					}));

					const localDistrictsFiltered = filterDemarcations(result);
					setData(localDistrictsFiltered);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => setLoading(false));
		} else {
			setData();
		}
	}, [entity, entityValue]);

	return { loading, data };
}

export function useFDistrictsWithFilters(entity) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const entityValue = entity?.value || entity?.code;

	useEffect(() => {
		if (entity) {
			setLoading(true);
			axiosInstance({
				method: 'GET',
				url: `/districts/?no_paginate=true&ordering=code&fk_entity_id=${entityValue}`
			})
				.then((response) => {
					const result = response.data.map(({ id, name, code }) => ({
						id: id,
						label: code,
						value: code
					}));
					const districtsFiltered = filterDemarcations(result);
					setData(districtsFiltered);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => setLoading(false));
		} else {
			setData();
		}
	}, [entity, entityValue]);

	return { loading, data };
}

export function useSectionsWithFilters(entity) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const entityValue = entity?.value || entity?.code;

	useEffect(() => {
		if (entity) {
			setLoading(true);
			axiosInstance({
				method: 'GET',
				url: `/sections/?no_paginate=true&ordering=code&fk_municipality__fk_local_district__fk_district__fk_entity__id=${entityValue}`
			})
				.then((response) => {
					const result = response.data.map(({ id, code, fk_municipality }) => ({
						id: id,
						label: code,
						value: code,
						municipality: {
							label: fk_municipality.nombre,
							value: fk_municipality.code
						},
						lDistrict: {
							label: fk_municipality.fk_local_district.code,
							value: fk_municipality.fk_local_district.code
						},
						district: {
							label: fk_municipality.fk_local_district.fk_district.code,
							value: fk_municipality.fk_local_district.fk_district.code
						}
					}));
					const sectionsFiltered = filterDemarcations(result);
					setData(sectionsFiltered);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => setLoading(false));
		} else {
			setData();
		}
	}, [entity, entityValue]);

	return { loading, data };
}

export function useEntities() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		setLoading(true);
		axiosInstance({
			method: 'GET',
			url: `/entitys/?no_paginate=true`
		})
			.then((response) => {
				setData(response?.data);
			})
			.catch((error) => {
				console.error({ error });
			})
			.finally(() => setLoading(false));
	}, []);

	return { loading, data };
}

export const createPyramid = ({
	levels_number,
	name,
	description,
	entities,
	districts,
	districts_local,
	municipalities,
	sections
}) =>
	axiosInstance({
		method: 'POST',
		url: `/activism/estructure/`,
		data: { levels_number, name, description, entities, districts, districts_local, municipalities, sections }
	});
// example submit data
// {
//   "levels_number": 0,
//   "name": "string",
//   "description": "string",
//   "entities": [ 0 ],
//   "districts": [ 0 ],
//   "districts_local": [ 0 ],
//   "municipalities": [ 0 ],
//   "sections": [ 0 ]
// }
