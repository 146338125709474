// dashbaord
import Default from 'components/dashboard/default';
import Electores from 'components/dashboard/electores';
import Analisis from 'components/dashboard/analisis';
import Sms from 'components/dashboard/sms';
import SmsDestinataries from 'components/dashboard/sms/sms-destinatiries';
import VoterDetails from 'components/dashboard/voterDetails/VoterDetails';
import Eventos from '../components/dashboard/eventos';
import CreateEvent1 from 'components/dashboard/eventos/create-event-1';
import CreateEvent2 from 'components/dashboard/eventos/create-event-2';
import EventDetails from 'components/dashboard/eventDetails/index';
import CreatePyramid from 'pages/pyramid/CreatePyramid';
import PyramidLevels from 'pages/pyramid/PyramidLevels';
import PyramidAffiliates from 'pages/pyramid/PyramidAffiliates';
import PyramidList from 'pages/pyramid/PyramidList';
import PyramidDetails from 'pages/pyramid/pyramidDetails/PyramidDetails';

// account
import Account from '../components/account/user';
import CreateAccount from '../components/account/create';
import AccountsList from '../components/account/accounts-list';
import EditAccount from '../components/account/edit-account';
import Goals from '../components/account/goals';
import { TwoFactorConfig } from '../components/account/two-factor';
import Needs from '../components/account/needs';
import CSV from '../components/account/csv-importer';
import Voters from '../components/dashboard/maps/electores';
import VotersGoals from '../components/dashboard/votersGoals/VotersGoals';
import CreateVoterGoals from '../components/dashboard/votersGoals/CreateVoterGoals';

import CrearElector from '../components/dashboard/electores/registrar/CrearElector';

import Encuestas from 'components/dashboard/encuestas';
import CreateSurvey1 from 'components/dashboard/encuestas/create-survey-1';
import CreateSurvey2 from 'components/dashboard/encuestas/create-survey-2';
import FilterSupports from '../components/dashboard/supports/FilterSuppors';
import CreateSupport from '../components/dashboard/supports/CreateSupport';
import FilterCampaigns from '../components/dashboard/campaigns/FilterCampaigns';
import CreateCampaign from '../components/dashboard/campaigns/CreateCampaign';
import Reuniones from 'components/dashboard/meetings';
import CreateMeeting1 from 'components/dashboard/meetings/create-meeting-1';
import MeetingDetails from 'components/dashboard/meetings/details';
import CampaignDetails from '../components/dashboard/campaigns/CampaignDetails';
import SupportsDetails from '../components/dashboard/supports/SupportsDetails';
import Cartography from 'components/dashboard/maps/cartography/Cartography';
import Bitacora from 'components/dashboard/bitacora';

import { CreateByOCR, CreateManually, EditManually } from 'constant';

export const INITIAL = `/`;
export const LOGIN = `/login`;
export const LOGIN_2FA = `/login/2fa`;
export const DASHBOARD = `/dashboard`;
export const ELECTORES = `/electores`;
export const ELECTORES_DETAIL = `/electores/:id`;

export const PYRAMIDS = `/piramides/`;
export const PYRAMIDS_CREATE = `/piramides/create/`;
export const PYRAMIDS_LEVELS = `/piramides/:pyramidId/levels/`;
export const PYRAMIDS_AFFILIATES = `/piramides/:pyramidId/affiliates/`;
export const PYRAMID_DETAILS = `/piramides/:pyramidId/`;

export const ANALISIS = `/tablas/analisis`;
export const SMS = `/sms`;
export const SMS_DESTINATARIES = '/sms/:id/destinataries';
export const ACCOUNT = `/configuracion/mi-perfil`;
export const CREATE_ACCOUNT = `/configuracion/crear-usuario`;
export const ACCOUNTS_LIST = `/configuracion/lista-usuarios`;
export const EDIT_ACCOUNT = `/configuracion/editar-account`;
export const GOALS = `/configuracion/metas`;
export const CSV_IMPORTER = `/configuracion/importar-csv`;
export const CSV_EXPORTER = `/#`;
export const TWO_FACTOR = `/configuracion/2FA`;
export const VOTERS_GOALS = `/configuracion/voter-goals`;
export const CREATE_VOTER_GOALS = `/configuracion/voter-goals/create`;
export const EDIT_VOTER_GOALS = `/configuracion/voter-goals/edit/:voterGoalId`;
export const NEEDS = `/configuracion/necesidades`;
export const EVENTOS = `/tablas/eventos`;
export const CREATE_EVENT_1 = `/tablas/eventos/create-1`;
export const CREATE_EVENT_2 = `/tablas/eventos/create-2`;
export const EVENT_DETAILS = `/tablas/eventos/:eventId`;
export const VOTERS = `/mapas/filtar-electores`;
export const PYRAMID = `/mapas/piramide`;
export const CARTOGRAPHY = `/mapas/cartografia`;
export const SUPPORTS = `/apoyos/filtrar-apoyos`;
export const SUPPORTS_CREATE = `/apoyos/apoyos-create`;
export const SUPPORTS_DETAILS = `/apoyos/apoyos-details/:id`;
export const CAMPAIGNS = `/apoyos/campaigns`;
export const CAMPAIGNS_CREATE = `/apoyos/campaign-create`;
export const CAMPAIGNS_DETAILS = `/apoyos/campaign-details/:id`;
export const REUNIONES = `/reuniones`;
export const CREATE_MEETING_1 = `/reuniones/create-1`;
export const MEETING_DETAILS = `/reuniones/:meetingId`;

/**
 * Rutas bitacoras
 */
export const RECRUITED_LOGS = `/bitacora`;

export const ENCUESTAS = `/encuestas`;
export const CREAR_ENCUESTAS_1 = `/encuestas/crear-1`;
export const CREAR_ENCUESTAS_2 = `/encuestas/crear-2`;

export const CREAR_ELECTOR_MANUAL = '/electores/crear';
export const CREAR_ELECTOR_OCR = '/electores/crear/:curp';
export const ACTUALIZAR_ELECTOR = '/electores/actualizar/:curp';

export const DASHBOARD_ROUTES = [{ path: DASHBOARD, component: Default }];

export const VOTER_ROUTES = [
	{ path: ELECTORES, component: Electores },
	{ path: ELECTORES_DETAIL, component: VoterDetails }
];

export const EVENTS_ROUTES = [
	{ path: EVENTOS, component: Eventos },
	{ path: CREATE_EVENT_1, component: CreateEvent1 },
	{ path: CREATE_EVENT_2, component: CreateEvent2 },
	{ path: EVENT_DETAILS, component: EventDetails }
];

export const MEETINGS_ROUTES = [
	{ path: REUNIONES, component: Reuniones },
	{ path: CREATE_MEETING_1, component: CreateMeeting1 },
	{ path: MEETING_DETAILS, component: MeetingDetails }
];

export const PYRAMIDS_ROUTES = [
	{ path: PYRAMIDS, component: PyramidList },
	{ path: PYRAMIDS_CREATE, component: CreatePyramid },
	{ path: PYRAMIDS_LEVELS, component: PyramidLevels },
	{ path: PYRAMIDS_AFFILIATES, component: PyramidAffiliates },
	{ path: PYRAMID_DETAILS, component: PyramidDetails }
];

export const SURVEY_ROUTES = [
	{ path: ENCUESTAS, component: Encuestas },
	{ path: CREAR_ENCUESTAS_1, component: CreateSurvey1 },
	{ path: CREAR_ENCUESTAS_2, component: CreateSurvey2 }
];

export const SMS_ROUTES = [
	{ path: SMS, component: Sms },
	{ path: SMS_DESTINATARIES, component: SmsDestinataries }
];

export const NEED_ROUTES = [{ path: NEEDS, component: Needs }];

export const MAPS_ROUTES = [
	{ path: VOTERS, component: Voters },
	{ path: CARTOGRAPHY, component: Cartography }
];

export const SUPPORTS_ROUTES = [
	{ path: SUPPORTS, component: FilterSupports },
	{ path: SUPPORTS_CREATE, component: CreateSupport },
	{ path: CAMPAIGNS, component: FilterCampaigns },
	{ path: CAMPAIGNS_DETAILS, component: CampaignDetails },
	{ path: CAMPAIGNS_CREATE, component: CreateCampaign }
];

export const ADMIN_DASHBOARD_ROUTES = [
	{ path: ELECTORES, component: Electores },
	{ path: EVENTOS, component: Eventos },
	{ path: ANALISIS, component: Analisis },
	{ path: REUNIONES, component: Reuniones },
	{ path: VOTERS, component: Voters },
	{ path: CARTOGRAPHY, component: Cartography },
	{ path: SMS, component: Sms },
	{ path: SMS_DESTINATARIES, component: SmsDestinataries },
	{ path: ELECTORES_DETAIL, component: VoterDetails },
	{ path: PYRAMIDS, component: PyramidList },
	{ path: PYRAMIDS_CREATE, component: CreatePyramid },
	{ path: PYRAMIDS_LEVELS, component: PyramidLevels },
	{ path: PYRAMIDS_AFFILIATES, component: PyramidAffiliates },
	{ path: PYRAMID_DETAILS, component: PyramidDetails },
	{ path: CREATE_EVENT_1, component: CreateEvent1 },
	{ path: CREATE_EVENT_2, component: CreateEvent2 },
	{ path: EVENT_DETAILS, component: EventDetails },
	{ path: ENCUESTAS, component: Encuestas },
	{ path: CREAR_ENCUESTAS_1, component: CreateSurvey1 },
	{ path: CREAR_ENCUESTAS_2, component: CreateSurvey2 },
	{ path: SUPPORTS, component: FilterSupports },
	{ path: SUPPORTS_CREATE, component: CreateSupport },
	{ path: SUPPORTS_DETAILS, component: SupportsDetails },
	{ path: CAMPAIGNS, component: FilterCampaigns },
	{ path: CAMPAIGNS_CREATE, component: CreateCampaign },
	{ path: CAMPAIGNS_DETAILS, component: CampaignDetails },
	{ path: CREAR_ENCUESTAS_2, component: CreateSurvey2 },
	{ path: CREATE_MEETING_1, component: CreateMeeting1 },
	{ path: MEETING_DETAILS, component: MeetingDetails }
];

export const ACCOUNT_ROUTES = [
	{ path: ACCOUNT, component: Account },
	{ path: TWO_FACTOR, component: TwoFactorConfig }
];

export const ADMIN_ACCOUNT_ROUTES = [
	{ path: CREATE_ACCOUNT, component: CreateAccount },
	{ path: ACCOUNTS_LIST, component: AccountsList },
	{ path: REUNIONES, component: Eventos },
	{ path: EDIT_ACCOUNT, component: EditAccount },
	{ path: NEEDS, component: Needs },
	{ path: GOALS, component: Goals },
	{ path: CSV_IMPORTER, component: CSV },
	{ path: TWO_FACTOR, component: TwoFactorConfig },
	{ path: VOTERS_GOALS, component: VotersGoals },
	{ path: CREATE_VOTER_GOALS, component: CreateVoterGoals },
	{ path: EDIT_VOTER_GOALS, component: CreateVoterGoals },
	{ path: ENCUESTAS, component: Encuestas },
	{ path: CREAR_ENCUESTAS_1, component: CreateSurvey1 },
	{ path: CREAR_ENCUESTAS_2, component: CreateSurvey2 }
];

export const CREATE_UPDATE_VOTER_ROUTES = [
	{ path: CREAR_ELECTOR_OCR, component: CrearElector, mode: CreateByOCR },
	{ path: CREAR_ELECTOR_MANUAL, component: CrearElector, mode: CreateManually },
	{ path: ACTUALIZAR_ELECTOR, component: CrearElector, mode: EditManually }
];

export const RECRUITED_LOGS_ROUTES = [{ path: RECRUITED_LOGS, component: Bitacora }];
