export const ROLE_VALUES = [
    {
        label: 'ADMIN',
        value: 'ADMIN'
    },
    {
        label: 'Electores',
        value: 'Electores'
    },
    {
        label: 'Eventos',
        value: 'Eventos'
    },
    {
        label: 'Pirámides',
        value: 'Piramides'
    },
    {
        label: 'SMS',
        value: 'SMS'
    },
    {
        label: 'Necesidades',
        value: 'Necesidades'
    },
    {
        label: 'Mapas',
        value: 'Mapas'
    }
];
