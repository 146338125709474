import MapContainerWrapper from 'components/dashboard/electores/MapContainerWrapper';
import { ModalComponent } from 'components/dashboard/modal';
import React, { useEffect, useState } from 'react';
import { Calendar, Check, Edit, PhoneOutgoing, Save, X } from 'react-feather';
import { Button, Input, Spinner, UncontrolledTooltip } from 'reactstrap';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useGetVoterCredentials } from '../../../../utils/fetch/credentials';

import Select from 'react-select';

import DatePicker from 'react-date-picker';

import { axiosInstance, axiosInstanceV1 } from '../../../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import {
	useEntities,
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from '../../../../utils/fetch/entities';
import { mapSetup } from '../../../../data/map-setup';
import RoleModal from './RoleModal';
import UpdateVoterModal from './UpdateVoterModal';
import { getLocalStorageItem } from 'services/localStorageService';
import { USER_DATA } from 'constant/localStorage';
import moment from 'moment';
import { getAgeWithMoment } from 'utils/getAgeFromDate';
import PhoneInput from 'react-phone-input-2';
import MaskedInput from 'react-maskedinput';
import { useGetRoles } from 'utils/fetch/roles';
import { gql, useMutation } from '@apollo/client';
import { graphQlClient } from 'store/graphql';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';

const INE_REVIEW = gql`
	mutation IneReview($record: ineReviewInputTCInput!) {
		ineReview(record: $record) {
			fields
			features
			ocr
			userId
			status
			socialMedia
			phoneNumber
			needId
		}
	}
`;

const userData = getLocalStorageItem(USER_DATA);

let conectores = ['de', 'a', 'en', 'con', 'por', 'para'];

const formatGender = (gender) => {
	if (!gender) return '';
	if (gender === 'Male') return 'Hombre';
	if (gender === 'Female') return 'Mujer';
	if (gender === 'Non-binary') return 'No binario';
};

const genderOptions = [
	{ label: 'Masculino', value: 'Male' },
	{ label: 'Femenino', value: 'Female' },
	{ label: 'No binario', value: 'Non-binary' }
];

const selectStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const InicialesRol = ({ rol, index }) => {
	const tooltipTarget = rol.split(' ').join('');
	const characters = rol
		.split(' ')
		.filter((word) => !conectores.includes(word))
		.slice(0, 2)
		.map((word) => word[0].toUpperCase())
		.join('');

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F04A5E',
					color: '#fdfeff',
					fontSize: '12px',
					fontWeight: '600',
					padding: '4px',
					minWidth: '32px',
					minHeight: '32px',
					borderRadius: '50%'
				}}
				id={tooltipTarget}
			>
				{characters}
			</div>
			<UncontrolledTooltip placement="top" target={tooltipTarget}>
				{rol}
			</UncontrolledTooltip>
		</>
	);
};

export default function GeneralTab({ scanLocation, dataCredentials, otherDataIne, personData, refetch }) {
	const { roles } = useGetRoles();

	const [updateInPage, setUpdateInPage] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openDemarcationModal, setOpenDemarcationModal] = useState(false);
	const [demarcationModalTitle, setDemarcationModalTitle] = useState('Title');
	const [stateLocation, setStateLocation] = useState('');
	const [stateDataObj, setStateDataObj] = useState({});
	const [demarcationObj, setDemarcationObj] = useState({});
	const [curp, setCurp] = useState(false);
	const [digitalId, setDigitalId] = useState('');
	const [clientId, setClientId] = useState('demo');
	const [municipalityName, setMunicipalityName] = useState('');
	const navigate = useNavigate();

	const { text_detect, ...otherDataCredentials } = dataCredentials;
	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmModalMsg, setConfirmModalMsg] = useState('');

	const [userRoles, setUserRoles] = useState([]);

	const [getImage, { loading, data }] = useGetVoterCredentials({ curp, clientId });

	/**
	 * States de demarcaciones
	 */
	const [selectedEntity, setSelectedEntity] = useState(null);
	const { loading: loadingEntities, data: entities } = useEntities();

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	const [sectionsOptions, setSectionsOptions] = useState([]);

	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedFDistrict, setSelectedFDistrict] = useState(null);
	const [selectedLDistrict, setSelectedLDistrict] = useState(null);
	const [selectedMunicipality, setSelectedMunicipality] = useState(null);

	const [selectedVerified, setSelectedVerified] = useState(false);

	const [blockTerritory, setBlockTerritory] = useState({
		section: false,
		muncipality: false,
		fDistrict: false,
		lDistrict: false
	});

	const [roleIsOpen, setRoleIsOpen] = useState(false);

	const voterData = text_detect?.fields || personData;

	const [updateForm, setUpdateForm] = useState({
		address: null,
		cic: null,
		date_birth: null,
		district: null,
		electoral_key: null,
		first_name: null,
		gender: null,
		id_state: null,
		last_name: null,
		local_district: null,
		municipality: null,
		ocr: null,
		phone: null,
		section: null,
		state: null,
		type_affiliate: null,
		validity: null,
		verified: false,
		passport_photo: null
	});

	const [aPaterno, setAPaterno] = useState('');
	const [aMaterno, setAMaterno] = useState('');

	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedGender, setSelectedGender] = useState(null);
	const [selectedTypeAffiliate, setSelectedTypeAffiliate] = useState(null);

	const [affiliateTypeOptions, setAffiliateTypeOptions] = useState([]);

	const [ineReview] = useMutation(INE_REVIEW, {
		errorPolicy: 'all',
		client: graphQlClient
	});

	const [loadingEdit, setLoadingEdit] = useState(false);

	const toggleRoleModal = () => setRoleIsOpen(!roleIsOpen);

	/**
	 * Update voter modal state and open function
	 */
	const [updateIsOpen, setUpdateIsOpen] = useState(false);
	const toggleUpdateModal = () => setUpdateIsOpen(!updateIsOpen);

	const consultDate = otherDataIne?.createdAt ? moment.utc(otherDataIne?.createdAt).format('DD/MM/YYYY') : '-';

	const consultTime = otherDataIne?.createdAt ? moment.utc(otherDataIne?.createdAt).format('HH:mm') : '-';

	const getFileByBase64 = (base64) => {
		const url = `data:application/pdf;base64, ${base64}`;
		fetch(url)
			.then((res) => res.blob())
			.then((blob) => {
				const file = new File([blob], `id-digital-${curp}`, { type: 'application/pdf' });
				saveAs(file);
			});
	};

	const handleDownloadDigitalId = async () => {
		if (digitalId && digitalId !== '') {
			getFileByBase64(digitalId);
		} else {
			toast.info('Descargando el archivo', {
				position: 'top-right',
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});

			if (voterData?.curp) {
				setCurp(voterData?.curp);
				getImage();
			} else {
				toast.error('Este votante no posse un CURP valido', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}
	};

	const handleDeleteVoter = () => {
		setConfirmModalMsg(`Esta seguro que desea borrar el registro seleccionado?`);
		setConfirmModal(true);
	};

	useEffect(() => {
		if (voterData && voterData?.date_birth) {
			setSelectedDate(moment(voterData?.date_birth, 'YYYY-MM-DD').toDate());
		}
	}, [voterData]);

	const toggle = (state) => {
		setConfirmModal(!confirmModal);

		if (state) {
			axiosInstanceV1({
				method: 'DELETE',
				url: `/credentials/${voterData?.curp}/`
			})
				.then(() => {
					toast.success('Se ha eliminado el votante con éxito', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
					navigate(`/electores`);
				})
				.catch(() => {
					toast.error('Error al eliminar el votante', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				});
		}
	};

	const handleOpenDemarcationModal = (type) => {
		const stateData = mapSetup.find((x) => Number(x.id) === Number(voterData?.id_state));

		setStateLocation(stateData.location);
		setStateDataObj(stateData);

		if (type === 'section') {
			setDemarcationModalTitle('Sección ' + voterData?.section);

			setDemarcationObj({
				demarcationsArr: Number(voterData?.section),
				demarcationType: 'SECCION'
			});
			setOpenDemarcationModal(true);
		} else if (type === 'd_local') {
			setDemarcationModalTitle('Distrito local ' + voterData?.local_district);

			setDemarcationObj({
				demarcationsArr: Number(voterData?.local_district),
				demarcationType: 'DISTRITO_L'
			});
			setOpenDemarcationModal(true);
		} else if (type === 'municipality') {
			setDemarcationModalTitle('Municipio ' + municipalityName);

			setDemarcationObj({
				demarcationsArr: Number(voterData?.municipality),
				demarcationType: 'MUNICIPIO'
			});
			setOpenDemarcationModal(true);
		} else if (type === 'district_f') {
			setDemarcationModalTitle('Distrito federal ' + voterData?.district);

			setDemarcationObj({
				demarcationsArr: Number(voterData?.district),
				demarcationType: 'DISTRITO'
			});
			setOpenDemarcationModal(true);
		}
	};

	const resetData = () => {
		if (voterData) {
			setSelectedGender(genderOptions.filter((gen) => gen.value === voterData?.gender));

			setAPaterno('');
			setAMaterno('');

			setUpdateForm((prev) => ({
				...prev,
				first_name: voterData?.first_name,
				last_name: voterData?.last_name,
				date_birth: voterData?.date_birth,
				gender: voterData?.gender,
				address: voterData?.address,
				electoral_key: voterData?.electoral_key,
				validity: voterData?.validity,
				cic: voterData?.cic,
				ocr: voterData?.ocr,
				section: { value: voterData?.section, label: voterData?.section },
				municipality: { value: voterData?.municipality, label: voterData?.municipality },
				local_district: { value: voterData?.local_district, label: voterData?.local_district },
				district: { value: voterData?.district, label: voterData?.district },
				state: { value: voterData?.id_state, label: voterData?.state }
			}));

			setSelectedDate(
				moment(voterData?.date_birth, 'YYYY-MM-DD').isValid()
					? moment(voterData?.date_birth, 'YYYY-MM-DD').toDate()
					: null
			);
		}

		if (otherDataIne) {
			setUpdateForm((prev) => ({
				...prev,
				phone: otherDataIne?.phoneNumber,
				type_affiliate: otherDataIne?.typeAffiliate,
				verified: otherDataIne?.verified ? true : false
			}));

			setSelectedVerified(otherDataIne?.verified ? { label: 'Sí', value: true } : { label: 'No', value: false });
		}
	};

	useEffect(() => {
		axiosInstance({ url: '/type-affiliate/', method: 'GET' })
			.then(({ data }) =>
				setAffiliateTypeOptions(data.results.map((aff) => ({ value: aff.id, label: aff.name })))
			)
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		const baseUrl = window.location.host;
		if (window.location) {
			if (baseUrl.indexOf('localhost') !== -1) {
				setClientId('demo');
			} else {
				let clientIdFound = baseUrl.split('.')[0];

				setClientId(clientIdFound);
			}
		}
	}, []);

	useEffect(() => {
		if (data) {
			setDigitalId(data);
			getFileByBase64(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (dataCredentials?.user_roles && dataCredentials?.user_roles !== null) {
			setUserRoles(dataCredentials.user_roles);
		}
	}, [dataCredentials]);

	useEffect(() => {
		if (affiliateTypeOptions.length > 0) {
			setSelectedTypeAffiliate(affiliateTypeOptions.filter((item) => item.label === otherDataIne?.typeAffiliate));
		}
	}, [affiliateTypeOptions, otherDataIne?.typeAffiliate]);

	useEffect(() => {
		if (voterData) {
			const { id_state } = voterData;

			if (id_state && entities && entities?.length > 0) {
				const ent = entities.find((option) => option.code === Number(id_state));

				if (ent) {
					setSelectedEntity({ label: ent.name, value: ent.code });
				}
			}
		}
	}, [entities, voterData]);

	useEffect(() => {
		if (voterData) {
			const { section, municipality, local_district, district } = voterData;

			if (section && sections && sections.length > 0) {
				if (!isNaN(section)) {
					const sect = sections.find((option) => option.value === Number(section));

					if (!sect) {
						const obj = { label: section, value: section };

						setSectionsOptions([...sections, obj]);
						setSelectedSection(obj);
						setBlockTerritory((prev) => ({ ...prev, section: true }));
					} else {
						setBlockTerritory((prev) => ({ ...prev, section: false }));
						setSectionsOptions(sections);
						setSelectedSection(sect);
					}
				} else {
					setBlockTerritory((prev) => ({ ...prev, section: true }));
					setSectionsOptions(sections);
				}
			}

			if (municipality && municipalities && municipalities.length > 0) {
				if (!isNaN(municipality)) {
					const mun = municipalities.find((option) => option.value === Number(municipality));
					setSelectedMunicipality(mun);
					setMunicipalityName(mun?.label || '-');
					setBlockTerritory((prev) => ({ ...prev, muncipality: false }));
				} else {
					setBlockTerritory((prev) => ({ ...prev, muncipality: true }));
				}
			}

			if (local_district && lDistrics && lDistrics.length > 0) {
				if (!isNaN(local_district)) {
					setSelectedLDistrict(lDistrics.find((option) => option.value === Number(local_district)));
					setBlockTerritory((prev) => ({ ...prev, lDistrict: false }));
				} else {
					setBlockTerritory((prev) => ({ ...prev, lDistrict: true }));
				}
			}

			if (district && fDistrics && fDistrics.length > 0) {
				if (!isNaN(district)) {
					setSelectedFDistrict(fDistrics.find((option) => option.value === Number(district)));
					setBlockTerritory((prev) => ({ ...prev, fDistrict: false }));
				} else {
					setBlockTerritory((prev) => ({ ...prev, fDistrict: true }));
				}
			}
		} else {
			setSectionsOptions(sections);
		}
	}, [fDistrics, lDistrics, municipalities, sections, voterData]);

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const handleSaveEdit = async () => {
		try {
			setLoadingEdit(true);

			const [emision, vigencia] = updateForm?.validity?.split('-');

			const last_name = `${aPaterno} ${aMaterno}`;

			const ocr = {
				...updateForm,
				date_birth: selectedDate,
				id_state: selectedEntity?.value || voterData?.id_state,
				state: selectedEntity?.label || voterData?.state,
				section: selectedSection?.value || voterData?.section,
				district: selectedFDistrict?.value || voterData?.district,
				local_district: selectedLDistrict?.value || voterData?.local_district,
				municipality: selectedMunicipality?.value || voterData?.municipality,
				aPaterno: aPaterno,
				aMaterno: aMaterno,
				curp: voterData?.curp,
				emision: emision.trim(),
				last_name: last_name,
				full_name: `${last_name} ${updateForm.first_name}`,
				vigencia: vigencia?.trim(),
				passport_photo: dataCredentials?.text_detect?.fields?.passport_photo || ''
			};

			const socialMedia = {};

			for (const platform in otherDataIne?.socialMedia) {
				const parts = otherDataIne.socialMedia[platform].split('/');
				socialMedia[platform] = parts[parts.length - 1];
			}

			const record = {
				status: 'REVIEWED',
				ocr: ocr,
				userId: userData.user_id,
				socialMedia: socialMedia,
				phoneNumber: updateForm?.phone,
				specialGroups: otherDataIne?.specialGroups,
				nameSpecialGroups: otherDataIne?.nameSpecialGroups,
				typeAffiliate: updateForm.type_affiliate,
				scanLocation: {
					lat: scanLocation?.lat,
					lng: scanLocation?.lng
				}
			};

			await ineReview({
				variables: {
					record: {
						...record,
						isManual: false
					}
				}
			});

			window.location.reload();
		} catch (error) {
			toast.error('Error al actualizar el elector', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		} finally {
			setLoadingEdit(false);
		}
	};

	return (
		<div className="details-view-container">
			<ModalComponent
				title="Punto de afiliación"
				open={openModal}
				handleClose={() => {
					setOpenModal(false);
				}}
			>
				{scanLocation ? (
					<MapContainerWrapper location={scanLocation} asDemarcation={false} />
				) : (
					<h4 className="font-primary m-3">No hay ubicación disponible</h4>
				)}
			</ModalComponent>
			<ModalComponent
				title={demarcationModalTitle}
				open={openDemarcationModal}
				handleClose={() => {
					setOpenDemarcationModal(false);
				}}
			>
				<MapContainerWrapper
					location={stateLocation}
					asDemarcation={true}
					stateData={stateDataObj}
					demarcationObj={demarcationObj}
				/>
			</ModalComponent>
			<div className="personal-info">
				{/* pending note -> create a react component with this html that receive items a title */}
				<div className="custom-detail-card">
					<div className="custom-title custom-buttons">
						<h3 className="m-0">Información personal</h3>
						{roles && roles?.length > 0 && roles?.includes('SuperAdmin') && (
							<div>
								{updateInPage ? (
									<>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start'
											}}
											color="primary"
											outline
											onClick={() => {
												setUpdateInPage(false);

												resetData();
											}}
										>
											<X size={16} />
										</Button>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start'
											}}
											color="primary"
											onClick={() => handleSaveEdit()}
										>
											<Save size={16} />
										</Button>
									</>
								) : (
									<Button
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start'
										}}
										color="primary"
										onClick={() => {
											setUpdateInPage(true);

											if (voterData) {
												setSelectedGender(
													genderOptions.filter((gen) => gen.value === voterData?.gender)
												);

												const [aPaterno, ...aMaterno] = voterData.last_name?.split(' ');

												setAPaterno(aPaterno);
												setAMaterno(aMaterno.join(' '));

												setUpdateForm((prev) => ({
													...prev,
													first_name: voterData?.first_name,
													last_name: voterData?.last_name,
													date_birth: voterData?.date_birth,
													gender: voterData?.gender,
													address: voterData?.address,
													electoral_key: voterData?.electoral_key,
													validity: voterData?.validity,
													cic: voterData?.cic,
													ocr: voterData?.ocr,
													section: { value: voterData.section, label: voterData.section },
													municipality: {
														value: voterData.municipality,
														label: voterData.municipality
													},
													local_district: {
														value: voterData.local_district,
														label: voterData.local_district
													},
													district: {
														value: voterData?.district,
														label: voterData?.district
													},
													state: { value: voterData?.id_state, label: voterData?.state }
												}));

												setSelectedDate(
													moment(voterData?.date_birth, 'YYYY-MM-DD').isValid()
														? moment(voterData?.date_birth, 'YYYY-MM-DD').toDate()
														: null
												);
											}

											if (otherDataIne) {
												setUpdateForm((prev) => ({
													...prev,
													phone: otherDataIne?.phoneNumber,
													type_affiliate: otherDataIne?.typeAffiliate,
													verified: otherDataIne?.verified ? true : false
												}));

												setSelectedVerified(
													otherDataIne?.verified
														? { label: 'Sí', value: true }
														: { label: 'No', value: false }
												);
											}
										}}
									>
										<Edit size={16} />
									</Button>
								)}
							</div>
						)}
					</div>
					<div>
						<p className="item-title">Nombre/s</p>
						{updateInPage ? (
							<Input
								type="text"
								name="first_name"
								id="first_name"
								placeholder="Nombre"
								value={updateForm.first_name}
								onChange={(e) => setUpdateForm((prev) => ({ ...prev, first_name: e.target.value }))}
								className="edit-event-info"
							/>
						) : (
							<div className="item-description">{voterData?.first_name}</div>
						)}
					</div>
					{updateInPage ? (
						<>
							<div>
								<p className="item-title">Apellido paterno</p>
								<Input
									type="text"
									name="aPaterno"
									id="aPaterno"
									placeholder="Apellido paterno"
									value={aPaterno}
									// onChange={(e) => setUpdateForm((prev) => ({ ...prev, last_name: e.target.value }))}
									onChange={(e) => setAPaterno(e.target.value)}
									className="edit-event-info"
								/>
							</div>
							<div>
								<p className="item-title">Apellido materno</p>
								<Input
									type="text"
									name="aMaterno"
									id="aMaterno"
									placeholder="Apellido materno"
									value={aMaterno}
									// onChange={(e) => setUpdateForm((prev) => ({ ...prev, last_name: e.target.value }))}
									onChange={(e) => setAMaterno(e.target.value)}
									className="edit-event-info"
								/>
							</div>
						</>
					) : (
						<div>
							<p className="item-title">Apellidos</p>
							<div className="item-description">{voterData?.last_name}</div>
						</div>
					)}
					<div>
						<p className="item-title">Fecha de Nacimiento</p>
						{updateInPage ? (
							<DatePicker
								clearIcon={false}
								value={selectedDate}
								onChange={(value) => {
									setUpdateForm((prev) => ({
										...prev,
										date_birth: moment(value, 'YYYY-MM-DD')
									}));

									setSelectedDate(moment(value, 'YYYY-MM-DD').toDate());
								}}
								format="dd/MM/yyyy"
								minDate={moment().subtract(100, 'years').startOf('year').toDate()}
								maxDate={moment().subtract(15, 'years').toDate()}
								onKeyDown={(e) => {
									e.preventDefault();
								}}
								calendarIcon={<Calendar size={16} stroke="#F04A5E" />}
							/>
						) : (
							<div className="item-description">
								{moment(selectedDate, 'YYYY-MM-DD').isValid()
									? moment(selectedDate, 'YYYY-MM-DD').format('DD/MM/YYYY').toString()
									: '-'}
							</div>
						)}
					</div>
					<div>
						<p className="item-title">Edad</p>
						<div className="item-description">{getAgeWithMoment(selectedDate)}</div>
					</div>
					<div>
						<p className="item-title">Sexo</p>
						{updateInPage ? (
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								name="gender"
								id="gender"
								options={genderOptions}
								onChange={(option) => {
									setUpdateForm((prev) => ({ ...prev, gender: option.value }));

									setSelectedGender(option);
								}}
								placeholder={'Sexo'}
								value={selectedGender}
								styles={selectStyles}
							/>
						) : (
							<div className="item-description">{formatGender(voterData?.gender)}</div>
						)}
					</div>
					{userRoles.length > 0 && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '24px'
							}}
						>
							<p className="item-title">Roles actuales</p>
							<div style={{ display: 'flex', gap: '8px', maxWidth: '300px', flexWrap: 'wrap' }}>
								{userRoles.map((rol, idx) => (
									<InicialesRol rol={rol} index={idx} key={idx} />
								))}
							</div>
						</div>
					)}
				</div>

				<div className="custom-detail-card mt-2">
					<div className="custom-title">
						<h3 className="m-0">Información de contacto</h3>
					</div>
					<div>
						<p className="item-title">Domicilio</p>
						{updateInPage ? (
							<Input
								type="text"
								name="address"
								id="address"
								placeholder="Domicilio"
								value={updateForm.address}
								onChange={(e) => setUpdateForm((prev) => ({ ...prev, address: e.target.value }))}
							/>
						) : (
							<div className="item-description">{voterData?.address}</div>
						)}
					</div>
					<div>
						<p className="item-title">Telefono</p>
						{updateInPage ? (
							<PhoneInput
								country={'mx'}
								inputStyle={{
									width: '100%',
									fontSize: '12px',
									borderRadius: '0.25rem',
									border: '1px solid #ccc',
									backgroundColor: '#fff'
								}}
								dropdownStyle={{
									borderRadius: '5px',
									boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
									backgroundColor: '#fff'
								}}
								value={updateForm.phone}
								onChange={(e) => setUpdateForm((prev) => ({ ...prev, phone: e }))}
							/>
						) : (
							<>
								{otherDataIne.phoneNumber ? (
									<a
										href={
											otherDataIne?.phoneNumber
												? `https://wa.me/${otherDataIne.phoneNumber.replace(
														/\D/g,
														''
												  )}?text=¡Hola! ${voterData?.first_name
														?.split(' ')
														?.map(
															(word) =>
																word.charAt(0).toUpperCase() +
																word.slice(1).toLowerCase()
														)
														?.join(' ')}`
												: ''
										}
										target="_blank"
										rel="noreferrer"
									>
										<div
											className="item-description"
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}
										>
											<p
												style={{
													margin: '0',
													color: '#F04A5E',
													fontWeight: '500',
													whiteSpace: 'break-spaces',
													overflowWrap: 'anywhere'
												}}
											>
												{otherDataIne?.phoneNumber}
											</p>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
													alignItems: 'center',
													backgroundColor: '#FFF1F1',
													padding: '8px',
													borderRadius: '50%'
												}}
												onClick={() => {}}
											>
												<PhoneOutgoing size={14} color="#F04A5E" />
											</div>
										</div>
									</a>
								) : (
									<div className="item-description">No capturado</div>
								)}
							</>
						)}
					</div>
					<div>
						<p className="item-title">Validación telefónica</p>
						{updateInPage ? (
							<Select
								name="verified"
								id="verified"
								options={[
									{ label: 'Sí', value: true },
									{ label: 'No', value: false }
								]}
								styles={selectStyles}
								onChange={(option) => {
									setUpdateForm((prev) => ({
										...prev,
										verified: option.value
									}));

									setSelectedVerified(option);
								}}
								value={selectedVerified}
								placeholder="Seleccione"
							/>
						) : (
							<div className="item-description">
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										gap: '10px'
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											backgroundColor: otherDataIne?.verified ? '#cbf7b8' : '#FFF1F1',
											padding: '8px',
											borderRadius: '50%'
										}}
									>
										{otherDataIne?.verified ? (
											<>
												<Check size={14} color="#51bb25" />
											</>
										) : (
											<>
												<X size={14} color="#f04a5d" />
											</>
										)}
									</div>
									{otherDataIne?.verified ? <span>Sí</span> : <span>No</span>}
								</div>
							</div>
						)}
					</div>
				</div>

				<div className="custom-detail-card mt-2">
					<div className="custom-title">
						<h3 className="m-0">Información de registro</h3>
					</div>
					<div>
						<p className="item-title">Recabación</p>
						<div className="item-description">{otherDataIne?.isManual ? 'Manual' : 'Escaneo'}</div>
					</div>
					<div>
						<p className="item-title">Tipo</p>
						{updateInPage ? (
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								name="type_affiliate"
								id="type_affiliate"
								options={affiliateTypeOptions}
								onChange={(option) => {
									setUpdateForm((prev) => ({
										...prev,
										type_affiliate: option ? option.label : ''
									}));

									setSelectedTypeAffiliate(option);
								}}
								placeholder="Tipo"
								value={selectedTypeAffiliate}
								isClearable
								styles={selectStyles}
							/>
						) : (
							<div className="item-description">{otherDataIne?.typeAffiliate || '-'}</div>
						)}
					</div>
					{otherDataIne?.specialGroups && (
						<div>
							<p className="item-title">Grupo especial</p>
							<div className="item-description">{otherDataIne?.nameSpecialGroups || '-'}</div>
						</div>
					)}
				</div>
			</div>

			<div className="election-info">
				<div className="custom-detail-card mb-3" style={{ backgroundColor: '#FFF1F1' }}>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignContent: 'center',
									justifyContent: 'start',
									gap: '40px'
								}}
							>
								{roles?.includes('SuperAdmin') && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignContent: 'center'
										}}
									>
										<p className="item-title">Borrar registro</p>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												flexDirection: 'row',
												gap: '12px'
											}}
										>
											<Button
												style={{
													fontWeight: '400',
													fontSize: '12px',
													lineHeight: '14px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'flex-start'
												}}
												color="primary"
												onClick={() => handleDeleteVoter()}
												disabled={updateInPage}
											>
												Borrar
											</Button>
										</div>
									</div>
								)}
								{roles?.includes('SuperAdmin') && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignContent: 'center'
										}}
									>
										<p className="item-title">Actualizar registro</p>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												flexDirection: 'row',
												gap: '12px'
											}}
										>
											<Button
												style={{
													fontWeight: '400',
													fontSize: '12px',
													lineHeight: '14px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'flex-start'
												}}
												color="primary"
												onClick={toggleUpdateModal}
												disabled={updateInPage}
											>
												Actualizar
											</Button>
										</div>
									</div>
								)}
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%'
								}}
							>
								<p className="item-title">
									<>
										Coordenadas de afiliación
										<Button
											size="sm"
											className="px-2 py-1"
											color="primary"
											onClick={() => {
												setOpenModal(true);
											}}
											disabled={updateInPage}
										>
											Ir al mapa
										</Button>
									</>
								</p>
							</div>
							<div className="item-description">
								{scanLocation?.lat} {scanLocation?.lng}
							</div>
						</div>
					</div>
					<div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										justifyContent: 'flex-start'
									}}
								>
									<p className="item-title">Fecha y hora de registro</p>
									<p className="item-description ml-2">{`${consultDate} | ${consultTime}`}</p>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '50%',
									gap: '15px'
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '50%',
										justifyContent: 'center',
										gap: '12px'
									}}
								>
									<p className="item-title">ID digital</p>
									<Button
										size="sm"
										className="px-2"
										color="primary"
										disabled={loading || updateInPage}
										onClick={() => handleDownloadDigitalId()}
									>
										{!loading ? 'Descargar' : 'Descargando'}
										{loading ? (
											<Spinner
												style={{ width: '0.7rem', height: '0.7rem' }}
												type="grow"
												color="light"
											/>
										) : null}
									</Button>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '50%',
										justifyContent: 'center',
										gap: '12px'
									}}
								>
									<p className="item-title">Roles</p>
									<Button
										size="sm"
										className="px-2"
										color="primary"
										disabled={loading}
										onClick={() => toggleRoleModal()}
									>
										Modificar
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="custom-detail-card">
					<div className="custom-title">
						<h3 className="m-0">Información electoral</h3>
					</div>
					<div>
						<p className="item-title">Clave del elector</p>
						{updateInPage ? (
							<MaskedInput
								name="electoral_key"
								id="electoral_key"
								placeholder="AAAAAA-111111-11-A-111"
								mask="AAAAAA-111111-11-A-111"
								value={updateForm.electoral_key}
								onChange={(e) => {
									const electoral_key = e.target.value;
									setUpdateForm((prev) => ({
										...prev,
										electoral_key: electoral_key.replace(/-/g, '').replace(/_/g, '')
									}));
								}}
								className="form-control"
							/>
						) : (
							<div className="item-description">{voterData?.electoral_key || '-'}</div>
						)}
					</div>
					<div>
						<p className="item-title">CURP</p>
						<div className="item-description">{voterData?.curp || '-'}</div>
					</div>
					<div>
						<p className="item-title">Validez INE</p>
						{updateInPage ? (
							<MaskedInput
								name="validity"
								id="validity"
								placeholder="Validación"
								mask="1111 - 1111"
								value={updateForm.validity}
								maxLength={4}
								onChange={(e) => setUpdateForm((prev) => ({ ...prev, validity: e.target.value }))}
								className="form-control"
							/>
						) : (
							<div className="item-description">{voterData?.validity || '-'}</div>
						)}
					</div>
					<div>
						<p className="item-title">CIC</p>
						{updateInPage ? (
							<MaskedInput
								name="cic"
								id="cic"
								placeholder="CIC"
								mask="1111111111"
								value={updateForm.cic}
								onChange={(e) => setUpdateForm((prev) => ({ ...prev, cic: e.target.value }))}
								className="form-control"
							/>
						) : (
							<div className="item-description">{voterData?.cic || '-'}</div>
						)}
					</div>
					<div>
						<p className="item-title">OCR</p>
						{updateInPage ? (
							<MaskedInput
								name="ocr"
								id="ocr"
								placeholder="OCR"
								mask="1111111111111"
								value={updateForm.ocr}
								onChange={(e) => setUpdateForm((prev) => ({ ...prev, ocr: e.target.value }))}
								className="form-control"
							/>
						) : (
							<div className="item-description">{voterData?.ocr || '-'}</div>
						)}
					</div>
					<div>
						<p className="item-title">Sección</p>
						{updateInPage ? (
							<Select
								name="section"
								id="section"
								options={sectionsOptions}
								placeholder={'Seleccionar sección'}
								styles={selectStyles}
								onChange={(option) => {
									setUpdateForm((prev) => ({
										...prev,
										section: option.value,
										municipality: option.municipality.value,
										local_district: option.lDistrict.value,
										district: option.district.value
									}));
									setSelectedMunicipality(option.municipality);
									setSelectedLDistrict(option.lDistrict);
									setSelectedFDistrict(option.district);
									setSelectedSection(option);
								}}
								value={selectedSection}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						) : (
							<div className="item-description">
								<p className="item-title-bold">
									<>
										{voterData?.section || '-'}
										<Button
											disabled={!voterData?.section || blockTerritory.section || loadingSections}
											size="sm"
											className="px-2 py-1"
											color="primary"
											onClick={() => handleOpenDemarcationModal('section')}
										>
											ver en mapa
										</Button>
									</>
								</p>
							</div>
						)}
					</div>
					<div>
						<p className="item-title">Municipio</p>
						{updateInPage ? (
							<Select
								name="municipality"
								id="municipality"
								options={municipalities}
								placeholder={'Seleccionar municipio'}
								styles={selectStyles}
								onChange={(option) => {
									setUpdateForm((prev) => ({ ...prev, municipality: option.value }));
									setSelectedMunicipality(option);
								}}
								value={selectedMunicipality}
								isLoading={loadingMunicipalities}
								loadingMessage={() => 'Cargando...'}
							/>
						) : (
							<div className="item-description">
								<div className="item-title-bold">
									<>
										{(voterData?.municipality || '-') + ' | ' + municipalityName}
										<Button
											disabled={!voterData?.municipality || blockTerritory.muncipality}
											size="sm"
											className="px-2 py-1"
											color="primary"
											onClick={() => handleOpenDemarcationModal('municipality')}
										>
											ver en mapa
										</Button>
									</>
								</div>
							</div>
						)}
					</div>
					<div>
						<p className="item-title">Distrito local</p>
						{updateInPage ? (
							<Select
								name="lDistrict"
								id="lDistrict"
								options={lDistrics}
								placeholder={'Seleccionar D. Local'}
								styles={selectStyles}
								onChange={(option) => {
									setUpdateForm((prev) => ({
										...prev,
										local_district: option.value,
										local_district_id: option.id
									}));
									setSelectedLDistrict(option);
								}}
								value={selectedLDistrict}
								isLoading={loadingLDistrics}
								loadingMessage={() => 'Cargando...'}
							/>
						) : (
							<div className="item-description">
								<div className="item-title-bold">
									<>
										{voterData?.local_district || '-'}
										<Button
											disabled={!voterData?.local_district || blockTerritory.lDistrict}
											size="sm"
											className="px-2 py-1"
											color="primary"
											onClick={() => handleOpenDemarcationModal('d_local')}
										>
											ver en mapa
										</Button>
									</>
								</div>
							</div>
						)}
					</div>
					<div>
						<p className="item-title">Distrito federal</p>
						{updateInPage ? (
							<Select
								name="fDistrict"
								id="fDistrict"
								options={fDistrics}
								placeholder={'Seleccionar D. Federal'}
								styles={selectStyles}
								onChange={(option) => {
									setUpdateForm((prev) => ({
										...prev,
										district: option.value
									}));
									setSelectedFDistrict(option);
								}}
								value={selectedFDistrict}
								isLoading={loadingFDistrics}
								loadingMessage={() => 'Cargando...'}
							/>
						) : (
							<div className="item-description">
								<div className="item-title-bold">
									<>
										{voterData?.district || '-'}
										<Button
											disabled={!voterData?.district || blockTerritory.fDistrict}
											size="sm"
											className="px-2 py-1"
											color="primary"
											onClick={() => handleOpenDemarcationModal('district_f')}
										>
											ver en mapa
										</Button>
									</>
								</div>
							</div>
						)}
					</div>
					<div>
						<p className="item-title">Estado</p>
						{updateInPage ? (
							<Select
								name="entity"
								id="entity"
								options={entitiesOptions}
								placeholder={'Seleccionar Estado'}
								styles={selectStyles}
								onChange={(option) => {
									setUpdateForm((prev) => ({
										...prev,
										state: option.label,
										id_state: option.value
									}));

									setSelectedEntity(option);
								}}
								value={selectedEntity}
								isLoading={loadingEntities}
								loadingMessage={() => 'Cargando...'}
								isDisabled={
									loadingSections || loadingMunicipalities || loadingFDistrics || loadingLDistrics
								}
							/>
						) : (
							<div className="item-description">{voterData?.state || '-'}</div>
						)}
					</div>
				</div>
			</div>
			<ConfirmModal isOpen={confirmModal} msg={confirmModalMsg} toggle={toggle} />
			<RoleModal
				isOpen={roleIsOpen}
				toggle={toggleRoleModal}
				userRoles={userRoles}
				userData={otherDataCredentials}
				refetch={refetch}
			/>
			<UpdateVoterModal
				closeModal={toggleUpdateModal}
				isOpen={updateIsOpen}
				curp={voterData?.curp}
				otherData={{ ...otherDataIne, ...scanLocation }}
			/>
			<LoadingAffiliation isOpen={loadingEdit} />
		</div>
	);
}
