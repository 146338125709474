import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from 'layout/breadcrumb';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Input,
	InputGroup,
	Row,
	UncontrolledTooltip
} from 'reactstrap';

import DataTable from 'react-data-table-component';
import InternalLoader from 'layout/internal-loader';
import { customStyles } from './tableStyles';
import { Filter, PlusCircle, Search, Slash } from 'react-feather';
import FilterElectorsModal from './FilterElectorsModal';
import { Link } from 'react-router-dom';
import { useElectoresWithPagination } from 'utils/fetch/electores';
import { getAgeWithMoment } from 'utils/getAgeFromDate';
import { axiosInstanceV1 } from 'utils/axiosInstance';
import { usePagination } from 'utils/fetch/pagination';
import womanAvatar from '../../../assets/images/avatars/woman-avatar.svg';
import nonBinaryAvatar from '../../../assets/images/avatars/nonbinary-avatar.svg';
import manAvatar from '../../../assets/images/avatars/man-avatar.svg';
import { useGetPoliticalParties } from '../../../utils/fetch/useGetPoliticalParties';
import { useGetStatesByCredentials } from '../../../utils/fetch/useGetStatesByCredentials';
import { useGetTypeAffiliate } from '../../../utils/fetch/useGetTypeAffiliate';
import { useGetSpecialGroups } from '../../../utils/fetch/useGetSpecialGroups';
import RegistrarElectorModal from './registrar/RegistrarElectorModal';
import moment from 'moment';
import { useVotersByClient } from 'utils/fetch/useVotersByClient';

import * as momentTz from 'moment-timezone';

const testUrl = (url) => {
	try {
		return Boolean(new URL(url));
	} catch (e) {
		return false;
	}
};

async function mapVotersData(voters) {
	return await Promise.all(
		voters.map(async (voter) => {
			const firstName = voter.ocr.first_name;
			const lastName = voter.ocr.last_name;
			const gender = voter.ocr.gender;
			const state = voter.ocr.state || '-';
			const electoralSection = voter.ocr.section || '-';

			let voterObj = {
				curp: voter.ocr.curp,
				userId: voter._id,
				image: voter.ocr.passport_photo,
				firstName: firstName,
				lastName: lastName,
				sexo: gender,
				age: getAgeWithMoment(voter?.ocr?.date_birth),
				estado: state,
				seccion: electoralSection
			};

			await axiosInstanceV1({
				method: 'GET',
				url: `/credentials/${voter?.ocr?.curp}`
			})
				.then(({ data, status }) => {
					voterObj = {
						...voterObj,
						militancia: data?.affiliate_details?.img_political_party || null,
						createdAt: momentTz(status === 200 ? data.creat_at : voter.createdAt, moment.ISO_8601)
							.tz('America/Mexico_City')
							.format('DD/MM/YYYY')
					};
				})
				.catch((err) => {
					voterObj = {
						...voterObj,
						createdAt: momentTz(voter.createdAt, moment.ISO_8601)
							.tz('America/Mexico_City')
							.format('DD/MM/YYYY')
					};
				});

			return voterObj;
		})
	)
		.then((res) => res)
		.catch((err) => console.log(err));
}

export function CellAvatar({ image, firstName, lastName, gender }) {
	const selectSrcImg = (image, gender) => {
		let src = '';
		if (image) {
			if (testUrl(image)) {
				src = image;
			} else {
				return (
					<div className="cell-gender" style={{ width: 50, height: 50 }}>
						<span>?</span>
					</div>
				);
			}
		} else {
			if (gender) {
				if (gender.toLocaleLowerCase() === 'male') {
					src = manAvatar;
				}
				if (gender.toLocaleLowerCase() === 'female') {
					src = womanAvatar;
				}
				if (gender.toLocaleLowerCase() === 'non-binary') {
					src = nonBinaryAvatar;
				}
			} else {
				return (
					<div className="cell-gender" style={{ width: 50, height: 50 }}>
						<span>?</span>
					</div>
				);
			}
		}

		return <img src={src} style={{ minWidth: 50, minHeight: 50, width: 50, height: 50 }} alt={'voterPic'} />;
	};

	return (
		<div className="cell-avatar">
			<div>{selectSrcImg(image, gender)}</div>
			<div className="ml-2 mb-0">
				<p className="mb-0">{firstName?.toLowerCase()}</p>
				<p>{lastName?.toLowerCase()}</p>
			</div>
		</div>
	);
}

export function CellGender({ gender }) {
	let letterGender = '?';
	if (gender === 'Male') {
		letterGender = 'M';
	} else if (gender === 'Female') {
		letterGender = 'F';
	} else if (gender === 'Non-binary') {
		letterGender = 'X';
	}
	return (
		<div className="cell-gender">
			<span>{letterGender}</span>
		</div>
	);
}

export function CellMilitancy({ srcImg }) {
	if (!srcImg) {
		return (
			<div
				style={{
					padding: '6px',
					width: '32px',
					height: '32px',
					background: '#AFAFAF',
					opacity: '0.4',
					borderRadius: '5px'
				}}
			>
				<Slash size={20} color="white" />
			</div>
		);
	} else {
		return (
			<div
				style={{
					width: '32px',
					height: '32px',
					background: '#AFAFAF',
					borderRadius: '5px'
				}}
			>
				<img src={srcImg} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="militancy" />
			</div>
		);
	}
}

const columns = [
	{
		name: 'Elector',
		selector: (row) => row.image,
		center: true,
		cell: (row, index, column, id) => (
			<CellAvatar image={row.image} firstName={row.firstName} lastName={row.lastName} gender={row.sexo} />
		),
		width: '220px'
	},
	{
		name: 'Sexo',
		selector: (row) => row.sexo,
		center: true,
		width: '80px',
		cell: (row, index, column, id) => <CellGender gender={row.sexo} />
	},
	{
		name: 'Edad',
		width: '80px',
		selector: (row) => row.age,
		center: true
	},
	{
		name: 'Estado',
		center: true,
		selector: (row) => row.estado
	},
	{
		name: 'Sección Electoral',
		center: true,
		selector: (row) => row.seccion
	},
	{
		name: 'Militancia',
		center: true,
		selector: (row) => row.militancia,
		cell: (row) => <CellMilitancy srcImg={row.militancia} />
	},
	{
		name: 'Fecha de registro',
		center: true,
		selector: (row) => row.createdAt
	},
	{
		cell: (row) => (
			<Link className="actionButton" to={row.curp ? `/electores/${row.curp}` : '#'}>
				<i className="fa fa-angle-right"></i>
			</Link>
		),
		width: '40px',
		ignoreRowClick: true,
		allowOverflow: true,
		button: true
	}
];

const filter = {
	ocr: {}
};

export default function Electores() {
	const [filterParams, setFilterParams] = useState(filter);
	const [sortObj, setSortObj] = useState({ NAME: 1 });

	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	const {
		loading,
		data: { items, count }
	} = useElectoresWithPagination({ perPage: perPage, page: page, filter: filterParams, sort: sortObj });
	const { data: politicalParties, loading: politicalPartiesLoading } = useGetPoliticalParties();
	const { data: typeAffiliate, loading: typeAffiliateLoading } = useGetTypeAffiliate();
	const { data: specialGroups, loading: specialGroupsLoading } = useGetSpecialGroups();
	const [votersData, setVotersData] = useState([]);
	const [loadingAffiliates, setLoadingAffiliates] = useState(false);

	const { data: dataLimit } = useVotersByClient();

	/**
	 * Filter states
	 */
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedMilitancy, setSelectedMilitancy] = useState(null);
	const [selectedState, setSelectedState] = useState();
	const [selectedLocaleDistrict, setSelectedLocaleDistrict] = useState([]);
	const [selectedFederalDistrict, setSelectedFederalDistrict] = useState([]);
	const [selectedMunicipality, setSelectedMunicipality] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);
	const [selectedRegisterType, setSelectedRegisterType] = useState([]);
	const [selectedTypeAffiliate, setSelectedTypeAffiliate] = useState([]);
	const [selectedSpecialGroup, setSelectedSpecialGroup] = useState([]);

	const { loading: loadingEntities, data: entities } = useGetStatesByCredentials();

	const [loadingSections, setLoadingSections] = useState(false);

	/**
	 * Manage the date state for filter
	 */
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [hasSelectedDate, setHasSelectedDate] = useState(false);

	/**
	 * Manage the age state for filter
	 */
	const initialAge = { min: 20, max: 40 };
	const [selectedAge, setSelectedAge] = useState(initialAge);
	const [hasSelectedAge, setHasSelectedAge] = useState(false);

	/**
	 * Manage the string for search from input
	 */
	const [search, setSearch] = useState('');

	/**
	 * Manage the options of multiselects
	 */
	const [localeDistrictsOptions, setLocaleDistrictsOptions] = useState([]);
	const [federalDistrictsOptions, setFederalDistrictsOptions] = useState([]);
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [sectionsOptions, setSectionsOptions] = useState([]);
	const [politicalPartiesOptions, setPoliticalPartiesOptions] = useState([]);
	const [typeAffiliateOptions, setTypeAffiliateOptions] = useState([]);
	const [specialGroupOptions, setSpecialGroupOptions] = useState([]);

	const [modal, setModal] = useState(false); // pending note -> use useReducer
	const toggle = () => setModal(!modal);

	const [modalCrear, setModalCrear] = useState(false);
	const toggleCrear = () => setModalCrear(!modalCrear);

	useEffect(() => {
		if (items) {
			setLoadingAffiliates(true);
			mapVotersData(items)
				.then((res) => {
					setVotersData(res || []);
				})
				.catch((err) => console.log(err.message))
				.finally(() => setLoadingAffiliates(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, filterParams]);

	useEffect(() => {
		if (politicalParties) {
			const mappedPoliticalParties = Object.values(politicalParties.results || [])?.map((politicalParty) => {
				return {
					value: politicalParty.name,
					label: (
						<div>
							<img
								src={politicalParty.img}
								height="20px"
								width="20px"
								className={'mr-2'}
								alt={`political-party-${politicalParty.id}`}
							/>
							{politicalParty.abbreviation}{' '}
						</div>
					)
				};
			});
			mappedPoliticalParties.push({
				value: '',
				label: (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '5px'
						}}
					>
						<div
							style={{
								width: '20px',
								height: '20px',
								background: '#AFAFAF',
								opacity: '0.4',
								borderRadius: '5px'
							}}
						>
							<Slash size={20} color="white" />
						</div>
						{'Sin militancia'}{' '}
					</div>
				)
			});
			setPoliticalPartiesOptions(mappedPoliticalParties);
		}
	}, [politicalParties]);

	useEffect(() => {
		if (typeAffiliate) {
			const mappedTypeAffiliate = Object.values(typeAffiliate.results || [])?.map((type) => {
				return {
					value: type.name,
					label: type.name
				};
			});
			setTypeAffiliateOptions(mappedTypeAffiliate);
		}
	}, [typeAffiliate]);

	useEffect(() => {
		if (specialGroups) {
			const mappedSpecialGroups = Object.values(specialGroups.results || [])?.map((type) => {
				return {
					value: type.name,
					label: type.name
				};
			});
			setSpecialGroupOptions(mappedSpecialGroups);
		}
	}, [specialGroups]);

	const handleSelectedStateChange = async (estado) => {
		setSectionsOptions([]);
		setMunicipalityOptions([]);
		setLocaleDistrictsOptions([]);
		setFederalDistrictsOptions([]);

		if (!estado) {
			setSelectedState();
			return;
		}

		setSelectedState(estado);

		setLoadingSections(true);

		const mappedSections = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-sections-by-credentials/?ordering=code&by_state_code=${estado.value}`
		})
			.then((res) =>
				res.data.map((sec) => {
					return {
						label: sec.code,
						value: sec.id_code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedMunicipalities = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-municipalities-by-credentials/?ordering=code&by_state_code=${estado.value}`
		})
			.then((res) =>
				res.data.map((mun) => {
					return {
						label: mun.nombre,
						value: mun.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedLDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-local-district-by-credentials/?ordering=code&by_state_code=${estado.value}`
		})
			.then((res) =>
				res.data.map((lD) => {
					return {
						label: lD.code,
						value: lD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedFDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-districts-by-credentials/?ordering=code&by_state_code=${estado.value}`
		})
			.then((res) =>
				res.data.map((fD) => {
					return {
						label: fD.code,
						value: fD.code
					};
				})
			)
			.catch((err) => console.log(err));

		setSectionsOptions(mappedSections);
		setMunicipalityOptions(mappedMunicipalities);
		setLocaleDistrictsOptions(mappedLDistricts);
		setFederalDistrictsOptions(mappedFDistricts);

		setLoadingSections(false);
	};

	const handleApplyFilter = () => {
		handlePageChange(1);

		let filter = {};

		let ocr = {};
		let order = {};

		if (selectedOrder) {
			let orderValue = selectedOrder.value;

			switch (orderValue) {
				case 'aToZ':
					order.NAME = 1;
					break;

				case 'zToA':
					order.NAME = -1;
					break;

				case 'majorToMinor':
					order.AGE = -1;
					break;

				case 'minorToMajor':
					order.AGE = 1;
					break;

				default:
					order.NAME = 1;
					break;
			}

			setSortObj(order);
		} else {
			setSortObj({ NAME: 1 });
		}

		ocr.full_name = search;

		if (selectedGender && selectedGender.length > 0)
			ocr.gender = selectedGender.map((gender) => gender.value.toString());

		if (selectedRegisterType.length === 1) {
			filter.isManual = selectedRegisterType[0].value;
		} else {
			filter.isManual = 'BOTH';
		}

		if (selectedMilitancy) filter.affiliate_details = selectedMilitancy.value.toUpperCase();

		if (selectedState) ocr.state = [selectedState.label];

		if (selectedTypeAffiliate) filter.typeAffiliate = selectedTypeAffiliate.value;

		if (selectedSpecialGroup) filter.nameSpecialGroups = selectedSpecialGroup.value;

		if (selectedLocaleDistrict.length > 0)
			ocr.local_district = selectedLocaleDistrict.map((lDistrict) => lDistrict.value.toString());

		if (selectedFederalDistrict.length > 0)
			ocr.district = selectedFederalDistrict.map((fDistrict) => fDistrict.value.toString());

		if (selectedMunicipality.length > 0)
			ocr.municipality = selectedMunicipality.map((municipality) => municipality.value.toString());

		if (selectedSection.length > 0) ocr.section = selectedSection.map((section) => section.value.toString());

		const age = hasSelectedAge ? [selectedAge.min.toString(), selectedAge.max.toString()] : null;

		const createdAt = hasSelectedDate ? new Date(selectedDate).toISOString().split('T')[0] : null;

		if (Object.keys(ocr).length > 0) filter.ocr = ocr;

		if (createdAt) filter.createdAt = createdAt;

		if (age) filter.age = age;

		setFilterParams(filter);

		setModal(false);
	};

	if (loadingEntities) {
		return <InternalLoader />;
	}

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const filterStates = {
		selectedOrder,
		setSelectedOrder,
		selectedGender,
		setSelectedGender,
		selectedMilitancy,
		setSelectedMilitancy,
		selectedState,
		setSelectedState,
		selectedSection,
		setSelectedSection,
		selectedAge,
		setSelectedAge,
		selectedDate,
		setSelectedDate,
		selectedMunicipality,
		setSelectedMunicipality,
		selectedLocaleDistrict,
		setSelectedLocaleDistrict,
		selectedFederalDistrict,
		setSelectedFederalDistrict,
		initialAge,
		setHasSelectedAge,
		setHasSelectedDate,
		selectedRegisterType,
		setSelectedRegisterType,
		selectedTypeAffiliate,
		setSelectedTypeAffiliate,
		selectedSpecialGroup,
		setSelectedSpecialGroup
	};

	const filterOptions = {
		loadingSections,
		entitiesOptions,
		localeDistrictsOptions,
		federalDistrictsOptions,
		municipalityOptions,
		politicalPartiesOptions,
		politicalPartiesLoading,
		sectionsOptions,
		typeAffiliateOptions,
		typeAffiliateLoading,
		specialGroupOptions,
		specialGroupsLoading
	};

	const filterHandlers = {
		handleApplyFilter,
		handleSelectedStateChange
	};

	return (
		<Fragment>
			<Breadcrumb title="Electores" />
			<Container fluid>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: '32px 40px' }}>
								<Row className="electores-header">
									<Col>
										<h5>Lista de electores </h5>
										<span
											style={{
												color: '#AFAFAF',
												fontWeight: '400',
												fontSize: '14px',
												lineHeight: '17px'
											}}
										>{`${count || '-'} registros`}</span>
									</Col>
									<Col className="filter-elector-headers">
										<Col
											lg="6"
											sm="6"
											style={{
												display: 'flex',
												justifyContent: 'normal',
												height: '100%',
												padding: '0px 4px'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'flex-end',
													alignItems: 'center',
													gap: '8px',
													width: '100%'
												}}
											>
												<Button
													outline
													color="primary"
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '4px',
														cursor: dataLimit === 0 && 'not-allowed'
													}}
													onClick={dataLimit !== 0 && toggleCrear}
													id="addVoterButton"
												>
													<span>Nuevo elector</span>
													<PlusCircle size={16} />
												</Button>
												{dataLimit === 0 && (
													<UncontrolledTooltip placement="top" target="addVoterButton">
														Llegaste al límite de tus registros en la plataforma
													</UncontrolledTooltip>
												)}
												<Button
													color="primary"
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '4px'
													}}
													onClick={toggle}
												>
													<span>Filtrar y ordenar</span>
													<Filter size={16} />
												</Button>
											</div>
										</Col>
										<Col lg="6" sm="6" style={{ height: '100%', padding: '0px 4px' }}>
											<InputGroup className="search-bar">
												<Input
													placeholder="Buscar por..."
													className="pl-4 search-input"
													onChange={(e) => setSearch(e.target.value)}
													value={search}
													onKeyDown={(e) => e.key === 'Enter' && handleApplyFilter()}
												/>
												<Search
													style={{
														position: 'absolute',
														left: '6px',
														bottom: '10px',
														color: 'gray',
														zIndex: 10
													}}
													size={16}
												/>
												<Button
													className="search-elector-button"
													color="primary"
													onClick={() => handleApplyFilter()}
												>
													Buscar
												</Button>
											</InputGroup>
										</Col>
									</Col>
								</Row>
							</CardHeader>
							<CardBody className="p-0">
								{loadingAffiliates ? (
									<InternalLoader />
								) : (
									<div className="table-responsive product-table">
										<DataTable
											noHeader
											customStyles={customStyles}
											columns={columns}
											data={votersData}
											progressComponent={<InternalLoader />}
											progressPending={loading}
											highlightOnHover
											paginationComponentOptions={{
												rowsPerPageText: 'Electores por página:',
												rangeSeparatorText: 'de'
											}}
											pagination
											paginationServer
											paginationTotalRows={count}
											onChangeRowsPerPage={handlePerRowsChange}
											onChangePage={handlePageChange}
											paginationDefaultPage={page}
											paginationPerPage={perPage}
										/>
									</div>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<FilterElectorsModal
				isOpen={modal}
				toggle={toggle}
				filterStates={filterStates}
				filterOptions={filterOptions}
				filterHandlers={filterHandlers}
			/>
			<RegistrarElectorModal isOpen={modalCrear} toggle={toggleCrear} />
		</Fragment>
	);
}