import React, { useEffect, useReducer } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Card, CardBody, CardHeader } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { EmailAddress } from '../../../constant';
import { getLocalStorageItem } from '../../../services/localStorageService';
import { USER_DATA } from '../../../constant/localStorage';
import userImage from '../../../assets/images/avtar/3.jpg';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../routes';


const Account = () => {
	const [userForm, setUserForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		picture: '',
		email: '',
		nombre: '',
		status: '',
		role: '',
		createdAt: ''
	});

	useEffect(() => {
		const userData = getLocalStorageItem(USER_DATA);

		if (userData) {
			setUserForm({
				picture: userData.picture,
				email: userData.email,
				name: userData.name,
				status: userData.blocked,
				role: userData.app_metadata.roles[0]
			});
		}
	}, []);

	return (
		<Container fluid={true}>
			<Breadcrumb parent="Configuración" title="Usuario" isAccount={true} />
			<Row>
				<Col sm={{ size: 8, offset: 2 }}>
					<Card>
						<CardHeader>
							<span>Información del usuario</span>
						</CardHeader>
						<CardBody>
							<div
								style={{
									backgroundColor: '#FFF',
									padding: 20,
									minHeight: '80vh',
									marginTop: 5,
									marginBottom: 5
								}}
							>
								<Form className="theme-form">
									<FormGroup>
										<Label className="col-form-label">Imagen de usuario</Label>
										<label
											htmlFor="avatar-picture"
											className="d-flex justify-content-center align-items-center"
										>
											<img
												src={userForm.picture === '' ? userImage : userForm.picture}
												alt="Generic placeholder"
												height={100}
												width={100}
												style={{ cursor: 'pointer' }}
											/>
										</label>
										<input type="file" id="avatar-picture" style={{ display: 'none' }} />
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">{EmailAddress}</Label>
										<Input
											className="form-control"
											type="email"
											disabled
											defaultValue={userForm.email}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">Nombre y Apellido</Label>
										<Input
											className="form-control"
											type="text"
											disabled
											defaultValue={userForm.name}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">Rol</Label>
										<Input
											className="form-control"
											type="text"
											disabled
											defaultValue={userForm.role}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">Estado</Label>
										<Input
											className="form-control"
											type="text"
											disabled
											defaultValue={userForm.status ? 'Bloqueado' : 'Activo'}
										/>
									</FormGroup>
									<FormGroup>
										<Link to={ROUTES.TWO_FACTOR}>
											<i className="fa fa-cog"></i>
											Configurar Clave de Segundo Factor
										</Link>
									</FormGroup>
								</Form>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Account;
