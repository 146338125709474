import React from 'react';
import { Row } from 'reactstrap';
import './appliedTerritories.scss';

const AppliedTerritories = ({ sections, municipalities, lDistricts, fDistrics, entities }) => {
	return (
		<div className="selected-sections">
			<Row className="selected-sections-header">
				<span>Territorios aplicados</span>
				{/* <span>0 personas</span> */}
			</Row>
			<Row className="selected-actions-body">
				{sections?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Sección</h5>
							{/* <h5 className="optionDetail">2 territorios</h5> */}
						</div>
						<div>
							<ul className="list-horizontal">
								{sections.map((section, index) => {
									return (
										<li key={`section-${section.label || section.section}`}>
											{`${section.label || section.section}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row className="selected-actions-body">
				{municipalities?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Municipios</h5>
							{/* <h5 className="optionDetail">2 territorios</h5> */}
						</div>
						<div>
							<ul className="list-horizontal">
								{municipalities.map((municipality, index) => {
									return (
										<li key={`municipalities-${municipality.label || municipality.municipality}`}>
											{`${municipality.label || municipality.municipality}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row className="selected-actions-body">
				{lDistricts?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Distritos Locales</h5>
							{/* <h5 className="optionDetail">2 territorios</h5> */}
						</div>
						<div>
							<ul className="list-horizontal">
								{lDistricts.map((lDistrict, index) => {
									return (
										<li key={`lDistrict-${lDistrict.label || lDistrict.local_district_code}`}>
											{`${lDistrict.label || lDistrict.local_district_code}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row className="selected-actions-body">
				{fDistrics?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Distritos Federales</h5>
							{/* <h5 className="optionDetail">2 territorios</h5> */}
						</div>
						<div>
							<ul className="list-horizontal">
								{fDistrics.map((fDistrict, index) => {
									return (
										<li key={`fDistrict-${fDistrict.label || fDistrict.district_code}`}>
											{`${fDistrict.label || fDistrict.district_code}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row className="selected-actions-body">
				{entities?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Entidades</h5>
							{/* <h5 className="optionDetail">2 territorios</h5> */}
						</div>
						<div>
							<ul className="list-horizontal">
								{entities.map((entity, index) => {
									return (
										<li key={`entity-${entity.entity_code || entity}`}>
											{`${entity.entity_name || entity}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
		</div>
	);
};

export default AppliedTerritories;
