import { ChevronRight } from "react-feather";
import './arrows.scss'

export default function HorizontalArrow() {
	return (
		<div className="arrow">
			<div className="horizontalDashedLine"></div>
			<ChevronRight className="chevron right" />
		</div>
	);
}
