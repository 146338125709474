import React, { useState, useRef } from 'react';
import Select from 'react-select';
import bbox from '@turf/bbox';

import { Button } from 'reactstrap';
import Accordion from '@material-ui/core/Accordion';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';

import { mapSetup } from '../../../data/map-setup';

export default function LocationsFilter({ map, filterSelectorValues, setFilterSelectorValues, resetInitialMapSetup }) {
	const [state, setSelectedState] = useState();
	const [filterProps, setFilterProps] = useState([]);
	const [selectedLayer, setSelectedLayer] = useState();
	const stateInput = useRef();
	const renglonInput = useRef();
	const renglonArray = mapSetup.find((renglon) => renglon.id === state)?.sources || [];

	const voterMapSelector = renglonArray.map((renglon) => {
		return { value: renglon.type, label: renglon.name };
	});

	const resetFilters = () => {
		map.setFilter(selectedLayer, null);
		map.setLayoutProperty(selectedLayer, 'visibility', 'none');
		const configState = mapSetup.find((city) => city.id === state);
		map.easeTo({
			zoom: configState.location?.zoom,
			center: [configState.location?.lng, configState.location?.lat]
		});
	};

	const advancedFilterProps = filterProps.map((prop) => {
		const currentStateConf = mapSetup.find((x) => x.id === state);
		const acceptedProps = [];
		currentStateConf.sources.forEach((source) => {
			source.acceptedProps.forEach((accepted) => {
				acceptedProps.push(accepted);
			});
		});

		if (!acceptedProps.includes(prop.name)) {
			return <React.Fragment key={'filterProp-' + prop.name}></React.Fragment>;
		}

		return (
			<div key={'filterProp-' + prop.name}>
				<label className="label-prop">{prop.name}:</label>
				<Select
					isMulti
					placeholder="Seleccione una o varias opciones"
					onChange={(e) => changeFilterInput(e, prop.name)}
					options={Array.from(prop.values).map((val) => {
						return { label: val, value: val };
					})}
				/>
			</div>
		);
	});

	const changeFilterInput = (filterValue, param) => {
		const newFilter = filterSelectorValues.map((filter) => {
			if (filter.name !== param) {
				return filter;
			}
			const newEntry = filter;
			newEntry.currValue = filterValue.map((field) => field.value);
			return newEntry;
		});

		setFilterSelectorValues(newFilter);
	};

	const stateMapSelector = mapSetup.map((m) => {
		return { label: m.name, value: m.id };
	});

	function setFilterSetup() {
		resetInitialMapSetup(); // test if it works
		const filters = filterSelectorValues.filter((name) => name.currValue.length > 0);
		if (!filters.length) {
			return;
		}
		const modelFilter = ['all'];
		filters.forEach((filter) => {
			modelFilter.push(['match', ['get', filter.name], filter.currValue, true, false]);
		});
		map.setFilter(selectedLayer, modelFilter);
		const features = map.queryRenderedFeatures().filter((x) => x.sourceLayer === selectedLayer);
		if (!features.length) {
			// setResults([]);
			return resetInitialMapSetup();
		}
		map.fitBounds(bbox(features[0].geometry), {
			padding: { top: 10, bottom: 25, left: 15, right: 5 },
			maxZoom: 8
		});

		// setResults(
		// 	voters.filter((elector) =>
		// 		filterSelectorValues[7].currValue.includes(parseInt(elector.fields?.seccion?.value, 10))
		// 	)
		// );
	}

	const setRenglonLayer = (type) => {
		const findState = mapSetup.find((el) => el.id === state);
		if (!findState) {
			return;
		}
		const sources = findState.sources;
		const renglon = sources.find((el) => el.type === type);
		const features = map.querySourceFeatures('composite', { sourceLayer: renglon.source });

		if (features.length > 0) {
			const props = Object.keys(features[0].properties);
			const propsMap = props.map((name) => {
				return { name, currValue: [], values: new Set(features.map((x) => x.properties[name])) };
			});
			setFilterSelectorValues(propsMap);
			setFilterProps(propsMap);
		}
		if (selectedLayer) {
			map.setLayoutProperty(selectedLayer, 'visibility', 'none');
		}
		map.setLayoutProperty(renglon.source, 'visibility', 'visible');
		setSelectedLayer(renglon.source);
		map.resize();
	};

	function changeSelectedState(stateNumber) {
		const filteredState = mapSetup.find((city) => city.id === stateNumber);
		if (!filteredState) {
			return;
		}

		setSelectedState(filteredState.id);
		map.setStyle(filteredState.style);
		map.easeTo({
			zoom: filteredState.location?.zoom,
			center: [filteredState.location?.lng, filteredState.location?.lat]
		});
	}

	return (
		<>
			<div className="analisis-panel">
				<div>
					<Select
						ref={stateInput}
						placeholder="Seleccione un estado"
						onChange={(e) => changeSelectedState(e.value)}
						options={stateMapSelector}
					/>
				</div>
				<div>
					<Select
						ref={renglonInput}
						placeholder="Seleccione un mapa electoral"
						onChange={(e) => setRenglonLayer(e.value)}
						options={voterMapSelector}
					/>
				</div>
			</div>
			<Accordion disabled={!filterProps.length}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<div className="filter-header">
						<FilterListIcon />
						<div>Filtros</div>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div className="advanced-filters-container">
						<div className="label-container">{advancedFilterProps}</div>
						<Button onClick={setFilterSetup} type="primary" className="mt-2">
							Aplicar filtro
						</Button>
						<Button onClick={resetFilters} color="light" className="mt-2 ml-2">
							Remover Filtros
						</Button>
					</div>
				</AccordionDetails>
			</Accordion>
		</>
	);
}
