import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './CustomLoader.scss';

const LoadingAffiliation = ({ isOpen }) => {
	return (
		<Modal isOpen={isOpen} size="sm" centered>
			<ModalBody style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
				<div className="contenedor-loader">
					<div className="loader1"></div>
					<div className="loader2"></div>
					<div className="loader3"></div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default LoadingAffiliation;
