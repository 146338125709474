export const customStyles = {
	cells: {
		style: {
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '0'
		}
	},
	rows: {
		style: {
			'&:hover': {
				backgroundColor: '#FFF1F1 !important',
				'.actionButton': {
					color: '#F04A5E !important'
				}
			}
		}
	}
};
