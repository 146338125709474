import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmModal = ({ isOpen, toggle, msg }) => {
	return (
		<Modal isOpen={isOpen} centered>
			<ModalBody>{msg}</ModalBody>
			<ModalFooter>
				<Button color="light-2x txt-dark" onClick={() => toggle(false)}>
					Cancelar
				</Button>{' '}
				<Button color="primary" onClick={() => toggle(true)}>
					Aceptar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ConfirmModal;
