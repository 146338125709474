import { graphQlClient } from 'store/graphql';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { axiosInstance } from 'utils/axiosInstance';

const getAndCountVotersSms = gql`
	query GetAndCountVotersSMS($filter: InputVotersSMSInput) {
		getAndCountVotersSMS(filter: $filter) {
			count
			items
		}
	}
`;

const getSmsAvailableQuery = gql`
	query Query($clientId: String) {
		getSMSAvailable(clientId: $clientId)
	}
`;

const getElectoresQuery = gql`
	query IneMany {
		ineMany {
			ocr
			status
			phoneNumber
			scanLocation {
				lat
				lng
			}
			updatedAt
			createdAt
			user
			need
			_id
		}
	}
`;

const getElector = gql`
	query IneOne($filter: FilterFindOneMexican_VoterInput) {
		ineOne(filter: $filter) {
			ocr
			userId
			status
			phoneNumber
			scanLocation {
				lat
				lng
			}
			socialMedia
			updatedAt
			createdAt
			user
			need
			fields
			specialGroups
			typeAffiliate
			nameSpecialGroups
		}
	}
`;

const getGoalGetAll = gql`
	query Query($page: Int, $perPage: Int, $filter: GetAllFilterTCInput) {
		goalGetAll(page: $page, perPage: $perPage, filter: $filter)
	}
`;

export function useGetVotersDetails({ curp } = {}) {
	const [getVoterDetails, { loading, data, error, refetch }] = useLazyQuery(getElector, {
		client: graphQlClient,
		variables: {
			filter: {
				ocr: {
					curp: curp
				}
			}
		}
	});
	return [getVoterDetails, { loading, data: data?.ineOne || {}, error, refetch }];
}

export function useGetAndCountVotersSms({ filter } = {}) {
	const [getVotersSms, { loading, data, error, refetch }] = useLazyQuery(getAndCountVotersSms, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			filter: filter
		}
	});
	return [getVotersSms, { loading, data: data?.getAndCountVotersSMS || {}, error, refetch }];
}

export function useGetSmsAvailable({ clientId } = {}) {
	const [getSmsAvailable, { loading, data, error, refetch }] = useLazyQuery(getSmsAvailableQuery, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			clientId: clientId
		}
	});
	return [getSmsAvailable, { loading, data: data?.getSMSAvailable || {}, error, refetch }];
}

export function useElectores() {
	const { loading, data, error, refetch } = useQuery(getElectoresQuery, {
		fetchPolicy: 'network-only',
		client: graphQlClient
	});

	return { loading, data: data?.ineMany, error, refetch };
}

const getElectoresPaginated = gql`
	query InePagination(
		$page: Int
		$perPage: Int
		$filter: FilterPaginationMexican_VoterInputInput
		$sort: SortMexican_VoterInputInput
	) {
		inePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
			count
			items {
				_id
				fields
				features
				model {
					name
					confidence
					type
				}
				nameSpecialGroups
				specialGroups
				typeAffiliate
				ocr
				userId
				needId
				status
				phoneNumber
				scanLocation {
					lat
					lng
					_id
				}
				updatedAt
				createdAt
				user
				need
			}
			pageInfo {
				currentPage
				perPage
				pageCount
				itemCount
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;

export function useElectoresWithPagination({ perPage, page, filter, sort } = {}) {
	const [searchParams] = useSearchParams();
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	const { loading, data, error, refetch } = useQuery(getElectoresPaginated, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			perPage: currentPerPage,
			page: currentPage,
			filter: filter,
			sort: sort
		}
	});

	return { loading, data: data?.inePagination || {}, error, refetch };
}

export function useLazyElectoresWithPagination({ perPage, page, filter, sort } = {}) {
	const [searchParams] = useSearchParams();
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	const [getElectores, { loading, data, error, refetch }] = useLazyQuery(getElectoresPaginated, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			perPage: currentPerPage,
			page: currentPage,
			filter: filter,
			sort: sort
		}
	});

	return [getElectores, { loading, data: data?.inePagination || {}, error, refetch }];
}

export function useEventosElector(voterId) {
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (voterId) {
			setLoading(true);

			axiosInstance({
				method: 'GET',
				url: `/events/?participant_id=${voterId}`
			})
				.then((res) => {
					setData(res.data.results);
					setCount(res.data.count);
				})
				.catch((err) => console.log(err))
				.finally(() => setLoading(false));
		}
	}, [voterId]);

	return { data, loading, count };
}

export function useVoterGoalsWithPagination({ perPage, page, filter = {} } = {}) {
	const [searchParams] = useSearchParams();
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	const { loading, data, error, refetch } = useQuery(getGoalGetAll, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			perPage: currentPerPage,
			page: currentPage,
			filter
		}
	});

	return { loading, data: data?.goalGetAll || {}, error, refetch };
}

export function useLazyVoterGoalsWithPagination({ perPage, page, filter = {} } = {}) {
	const [searchParams] = useSearchParams();
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	const [getVoterGoals, { loading, data, error, refetch }] = useLazyQuery(getGoalGetAll, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			perPage: currentPerPage,
			page: currentPage,
			filter
		}
	});

	return [getVoterGoals, { loading, data: data?.goalGetAll || {}, error, refetch }];
}

export function useVoterDetails({ curp, skip } = {}) {
	const { loading, data } = useQuery(getElector, {
		skip: skip,
		client: graphQlClient,
		variables: {
			filter: {
				ocr: {
					curp: curp
				}
			}
		}
	});
	return { loading, data: data?.ineOne };
}
