import { useEffect, useState } from 'react';
import { axiosInstanceV1 } from '../axiosInstance';

export function useGetStatesByCredentials() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		setLoading(true);
		axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-state-by-credentials/`
		})
			.then((response) => {
				setData(response?.data.map((entity) => ({ ...entity, label: entity.name, value: entity.code })));
			})
			.catch((error) => {
				console.error({ error });
			})
			.finally(() => setLoading(false));
	}, []);

	return { loading, data };
}
