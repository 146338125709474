import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { Filter, PlusCircle, Search } from 'react-feather';
import FilterEventModal from './filter-modal';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../routes';

import DataTable from 'react-data-table-component';
import { ChevronRight } from 'react-feather';

import InternalLoader from 'layout/internal-loader';
import { useEntities } from 'utils/fetch/entities';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import { usePagination } from 'utils/fetch/pagination';
import { customStyles } from '../electores/tableStyles';
import { useMeetingsWithPagination } from 'utils/fetch/meetings';

/**
 * Parameters to use in the data table
 */
const CustomEventInfoButton = ({ id }) => {
	return (
		<Link to={`${process.env.PUBLIC_URL}/reuniones/${id}`}>
			<ChevronRight />
		</Link>
	);
};

const getStatusOptions = [
	{ label: 'Programado', value: 'Programado' },
	{ label: 'En progreso', value: 'En progreso' },
	{ label: 'Finalizado', value: 'Finalizado' }
];

const tableColumns = [
	{
		name: 'Nombre',
		selector: (row) => row.name,
		//sortable: true,
		width: '15%'
	},
	{
		name: 'Promotor',
		selector: (row) => row.promotor,
		//sortable: true,
		width: '15%'
	},
	{
		name: 'Lugar',
		selector: (row) => row.place,
		//sortable: true,
		width: '15%'
	},

	{
		name: 'Anfitrion',
		selector: (row) => row.host,
		width: '15%',
		center: true
	},
	{
		name: 'Demarcación',
		selector: (row) => row.demarcation,
		width: '15%',
		center: true
	},
	{
		name: 'Fecha de reunión',
		selector: (row) => row.date,
		width: '15%',
		//sortable: true,
		center: true
	},
	{
		cell: ({ id }) => <CustomEventInfoButton id={id} />,
		allowOverflow: true,
		button: true,
		width: '10%',
		center: true
	}
];

const StatusBadge = (status) => {
	switch (status) {
		case 'Programado':
			return <div className="event-status not-started">{status}</div>;
		case 'En progreso':
			return <div className="event-status in-progress">{status}</div>;
		default:
			return <div className="event-status finished">{status}</div>;
	}
};

const Reuniones = () => {
	const [modal, setModal] = useState(false);
	const { loading: loadingEntities, data: entities } = useEntities();

	/**
	 * States to filter
	 */
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedState, setSelectedState] = useState();
	const [selectedStatus, setSelectedStatus] = useState([]);

	const [selectedDate, setSelectedDate] = useState();

	const [loadingFilter, setLoadingFilter] = useState(false);

	const [meetings, setMeetings] = useState([]);
	const [count, setCount] = useState(0);

	/**
	 * States for the table pagination
	 */
	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	/**
	 * Manage the string for search from input
	 */
	const [search, setSearch] = useState('');

	const { data: meetingsData, loading } = useMeetingsWithPagination({ page: page, perPage: perPage });

	useEffect(() => {
		setCount(meetingsData?.count);
		setMeetings(meetingsData?.items);
	}, [meetingsData]);

	const toggle = () => setModal(!modal);

	const handleFilterEvent = async () => {
		setLoadingFilter(true);
		let params = '';

		if (search) {
			const finalQuery = search.replace(/\s/, '%20');
			params += `&name__icontains=${finalQuery}`;
		}

		if (selectedState) {
			params += `&entities=${selectedState.value}`;
		}

		if (selectedDate) {
			const finalDate = new Date(selectedDate).toISOString().replace(/:/g, '%3A');
			params += `&start_time__icontains=${finalDate}`;
		}

		if (selectedStatus.length > 0) {
			selectedStatus.forEach((sta) => {
				const finalStatus = sta.value.replace(/\s/, '%20');
				params += `&status__icontains=${finalStatus}`;
				return;
			});
		}

		if (selectedOrder) {
			switch (selectedOrder.value) {
				case 'aToZ':
					params += '&ordering=name';
					break;
				case 'zToA':
					params += '&ordering=-name';
					break;
				case 'older':
					params += '&ordering=start_time';
					break;
				default:
					params += '&ordering=-start_time';
					break;
			}
		}

		console.log(params);
	};

	if (loading || loadingEntities) return <InternalLoader />;

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const meetingsItems = Object.values(meetings || {})?.map((meeting) => {
		return {
			id: meeting._id,
			name: meeting.name,
			host: meeting.host,
			promotor: meeting.promotor,
			demarcation: meeting.demarcation,
			date: meeting.date
				? new Date(meeting.date).toLocaleDateString('es-ES', {
						month: 'long',
						day: 'numeric'
				  })
				: '-',
			place: meeting.place,

			status: StatusBadge(meeting.status)
		};
	});

	return (
		<Fragment>
			<Breadcrumb title="Reuniones" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: '32px 40px' }}>
								<Row className="events-header">
									<Col lg={4}>
										<h5 style={{ order: '0' }}>Lista de reuniones</h5>
										<span
											style={{
												color: '#AFAFAF',
												fontWeight: '400',
												fontSize: '14px',
												lineHeight: '17px'
											}}
										>{`${meetingsItems.length} reuniones`}</span>
									</Col>
									<Col className="filter-events-header">
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<Button
												className="button-content headers-button"
												style={{ width: '161px' }}
												color="primary"
												onClick={toggle}
											>
												<span>Filtrar y Ordenar</span>
												<Filter size={16} />
											</Button>
										</div>
										<div
											style={{
												display: 'flex',
												height: '33PX',
												width: '320px'
											}}
										>
											<InputGroup>
												<InputGroupText
													style={{
														height: '33px',
														borderRadius: '6px 0px 0px 6px',
														backgroundColor: 'transparent',
														borderRight: '0px',
														padding: '2px 6px'
													}}
												>
													<Search
														style={{
															color: 'gray'
														}}
														size={16}
													/>
												</InputGroupText>
												<Input
													style={{
														height: '33px',
														borderRadius: '0px',
														backgroundColor: 'transparent',
														borderRight: '0px',
														borderLeft: '0px'
													}}
													placeholder="Buscar por..."
													className="pl-0"
													onChange={(e) => setSearch(e.target.value)}
												/>
												<Button
													className="button-content"
													style={{
														height: '33px',
														width: '78px',
														borderRadius: '0px 6px 6px 0px',
														borderLeft: '0px',
														padding: '2px 6px'
													}}
													color="primary"
													onClick={handleFilterEvent}
												>
													Buscar
												</Button>
											</InputGroup>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<Link to={ROUTES.CREATE_MEETING_1}>
												<Button
													className="button-content headers-button"
													style={{ width: '131px' }}
													color="primary"
												>
													<PlusCircle size={16} />
													<span>Crear nuevo</span>
												</Button>
											</Link>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<DataTable
									noHeader
									customStyles={customStyles}
									columns={tableColumns}
									data={meetingsItems}
									progressComponent={<InternalLoader />}
									progressPending={loading}
									highlightOnHover={true}
									paginationComponentOptions={{
										rowsPerPageText: 'Reuniones por página:',
										rangeSeparatorText: 'de'
									}}
									paginationRowsPerPageOptions={[2, 3, 10, 15, 20, 25, 30, 50]}
									pagination
									paginationServer
									paginationTotalRows={count}
									onChangeRowsPerPage={handlePerRowsChange}
									onChangePage={handlePageChange}
									paginationDefaultPage={page}
									paginationPerPage={perPage}
									noDataComponent={'No se han encontrado reuniones'}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<FilterEventModal
				isOpen={modal}
				toggle={toggle}
				selectedOrder={selectedOrder}
				setSelectedOrder={setSelectedOrder}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				selectedState={selectedState}
				setSelectedState={setSelectedState}
				selectedStatus={selectedStatus}
				setSelectedStatus={setSelectedStatus}
				statesOptions={entitiesOptions}
				statusOptions={getStatusOptions}
				handleFilterEvent={handleFilterEvent}
			/>
			<LoadingAffiliation isOpen={loadingFilter} />
		</Fragment>
	);
};

export default Reuniones;
