import { CREATE_NEED_SUCCESS } from './actionTypes';
import { EDIT_NEED_LOADING } from './actionTypes';
import { EDIT_NEED_SUCCESS } from './actionTypes';
import { EDIT_NEED_ERROR } from './actionTypes';
import { EDIT_NEED } from './actionTypes';
import { CREATE_NEED_ERROR } from './actionTypes';
import { CREATE_NEED } from './actionTypes';
import {
	GET_VOTER_LIST_ERROR,
	GET_VOTER_LIST_SUCCESS,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGIN,
	LOGOUT_SUCCESS,
	LOGOUT,
	IS_LOADING,
	CREATE_USER,
	CREATE_USER_SUCCESS,
	CREATE_USER_ERROR,
	CLEAN_CREATE_USER_STORE,
	GET_ME,
	GET_ME_SUCCESS,
	GET_ME_ERROR,
	GET_USER_LIST,
	GET_USER_LIST_SUCCESS,
	GET_USER_LIST_ERROR,
	EDIT_USER,
	EDIT_USER_SUCCESS,
	EDIT_USER_ERROR,
	CLEAN_EDIT_USER_STORE,
	GET_WEEKLY_REPORT,
	GET_WEEKLY_REPORT_SUCCESS,
	GET_WEEKLY_REPORT_ERROR,
	CREATE_GOAL_LOADING,
	CREATE_GOAL_SUCCESS,
	CREATE_GOAL,
	CREATE_GOAL_ERROR,
	GET_GOALS,
	GET_GOALS_SUCCESS,
	GET_GOALS_ERROR,
	GET_CURRENT_GOAL,
	GET_CURRENT_GOAL_SUCCESS,
	GET_CURRENT_GOAL_ERROR,
	REMOVE_2FA_TOKEN,
	REMOVE_2FA_TOKEN_SUCCESS,
	GENERATE_QR_CODE,
	GENERATE_QR_CODE_SUCCESS,
	GENERATE_QR_CODE_ERROR,
	VERIFY_2FA_CODE,
	VERIFY_2FA_CODE_SUCCESS,
	VERIFY_2FA_CODE_ERROR,
	REMOVE_2FA_TOKEN_ERROR,
	MOVE_2FA,
	GET_NEEDS,
	GET_NEEDS_SUCCESS,
	GET_NEEDS_ERROR
} from './actionTypes';

// Voters
export const fecthVotersListSuccess = (voters) => ({
	type: GET_VOTER_LIST_SUCCESS,
	payload: voters
});

export const fetchVotersError = (error) => ({
	type: GET_VOTER_LIST_ERROR,
	payload: error
});

// Auth
export const login = (data) => ({
	type: LOGIN,
	payload: data
});

export const fetchLoginSuccess = (data) => ({
	type: LOGIN_SUCCESS,
	payload: data
});

export const fetchLoginError = (error) => ({
	type: LOGIN_ERROR,
	payload: error
});

export const logout = () => ({
	type: LOGOUT
});

export const fetchLogoutSuccess = () => ({
	type: LOGOUT_SUCCESS
});

export const getMeUser = () => ({
	type: GET_ME
});

export const fetchGetMeSuccess = (data) => ({
	type: GET_ME_SUCCESS,
	payload: data
});

export const fetchGetMeError = (error) => ({
	type: GET_ME_ERROR,
	payload: error
});

//create-users
export const createUser = (data) => ({
	type: CREATE_USER,
	payload: data
});

export const fetchCreateUserSuccess = (data) => ({
	type: CREATE_USER_SUCCESS,
	payload: data
});

export const fetchCreateUserError = (error) => ({
	type: CREATE_USER_ERROR,
	payload: error
});

export const fetchCleanCreateUserState = () => ({
	type: CLEAN_CREATE_USER_STORE
});

//edit-user
export const editUser = (data) => ({
	type: EDIT_USER,
	payload: data
});

export const fetchEditUserSuccess = (data) => ({
	type: EDIT_USER_SUCCESS,
	payload: data
});

export const fetchEditUserError = (error) => ({
	type: EDIT_USER_ERROR,
	payload: error
});

export const fetchCleanEditUserState = () => ({
	type: CLEAN_EDIT_USER_STORE
});

//get-users

export const getUsersList = (data) => ({
	type: GET_USER_LIST,
	payload: data
});

export const fetchGetUsersListSuccess = (data) => ({
	type: GET_USER_LIST_SUCCESS,
	payload: data
});

export const fetchUsersListError = (error) => ({
	type: GET_USER_LIST_ERROR,
	payload: error
});

// UI

export const isLoading = (bool) => ({
	type: IS_LOADING,
	payload: bool
});

//reports

export const getWeeklyReport = () => ({
	type: GET_WEEKLY_REPORT
});

export const fetchWeeklyReportSuccess = (data) => ({
	type: GET_WEEKLY_REPORT_SUCCESS,
	payload: data
});

export const fetchWeeklyReportError = (error) => ({
	type: GET_WEEKLY_REPORT_ERROR,
	payload: error
});

//create goals
export const loadingCreateGoal = (bool) => {
	return {
		type: CREATE_GOAL_LOADING,
		payload: bool
	};
};

export const createGoal = (data) => ({
	type: CREATE_GOAL,
	payload: data
});

export const fetchCreateGoalSuccess = (data) => ({
	type: CREATE_GOAL_SUCCESS,
	payload: data
});

export const fetchCreateGoalError = (error) => ({
	type: CREATE_GOAL_ERROR,
	payload: error
});

//get goals
export const getGoals = (data) => ({
	type: GET_GOALS,
	payload: data
});

export const fetchGetGoalsSuccess = (data) => ({
	type: GET_GOALS_SUCCESS,
	payload: data
});

export const fetchGetGoalsError = (error) => ({
	type: GET_GOALS_ERROR,
	payload: error
});

//get current goal
export const getCurrentGoal = (data) => ({
	type: GET_CURRENT_GOAL,
	payload: data
});

export const fetchGetCurrentGoalSuccess = (data) => ({
	type: GET_CURRENT_GOAL_SUCCESS,
	payload: data
});

export const fetchGetCurrentGoalError = (error) => ({
	type: GET_CURRENT_GOAL_ERROR,
	payload: error
});

// 2FA
export const remove2FAToken = () => ({
	type: REMOVE_2FA_TOKEN
});

export const remove2FATokenError = () => ({
	type: REMOVE_2FA_TOKEN_ERROR
});

export const remove2FATokenSuccess = () => ({
	type: REMOVE_2FA_TOKEN_SUCCESS
});

export const verify2FACode = ({ code, token, isLogin = false }) => ({
	type: VERIFY_2FA_CODE,
	payload: { code, token, isLogin }
});

export const verify2FACodeSuccess = (bool) => ({
	type: VERIFY_2FA_CODE_SUCCESS,
	payload: bool
});

export const verify2FACodeError = (bool) => ({
	type: VERIFY_2FA_CODE_ERROR,
	payload: bool
});

export const generateQRCode = () => ({
	type: GENERATE_QR_CODE
});

export const generateQRCodeSuccess = (data) => ({
	type: GENERATE_QR_CODE_SUCCESS,
	payload: data
});

export const generateQRCodeError = (bool) => ({
	type: GENERATE_QR_CODE_ERROR,
	payload: bool
});

export const move2FA = ({ tokens }) => ({
	type: MOVE_2FA,
	payload: tokens
});

//create needs
export const loadingCreateNeed = (bool) => {
	return {
		type: CREATE_GOAL_LOADING,
		payload: bool
	};
};

export const createNeed = (data) => ({
	type: CREATE_NEED,
	payload: data
});

export const fetchCreateNeedSuccess = (data) => ({
	type: CREATE_NEED_SUCCESS,
	payload: data
});

export const fetchCreateNeedError = (error) => ({
	type: CREATE_NEED_ERROR,
	payload: error
});

//edit needs
export const loadingEditNeed = (bool) => {
	return {
		type: EDIT_NEED_LOADING,
		payload: bool
	};
};

export const editNeed = (data) => ({
	type: EDIT_NEED,
	payload: data
});

export const fetchEditNeedSuccess = (data) => ({
	type: EDIT_NEED_SUCCESS,
	payload: data
});

export const fetchEditNeedError = (error) => ({
	type: EDIT_NEED_ERROR,
	payload: error
});

//get needs
export const getNeeds = (data) => ({
	type: GET_NEEDS,
	payload: data
});

export const fetchGetNeedsSuccess = (data) => ({
	type: GET_NEEDS_SUCCESS,
	payload: data
});

export const fetchGetNeedsError = (error) => ({
	type: GET_NEEDS_ERROR,
	payload: error
});
