export const mapSetup = [
	{
		id: '1',
		name: 'Aguascalientes',
		location: {
			lat: 22.033,
			lng: -102.084,
			zoom: 8.08
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'aguascalientes-seccion-56948w',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'aguascalientes-local-5au1nc',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'aguascalientes-municipio-dt1j0l',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'aguascalientes-entidad-78r639',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'aguascalientes-distrito-0qzhti',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckksedf820ys117mp609fr0nq'
	},
	{
		id: '2',
		name: 'Baja California',
		location: {
			lat: 30.231,
			lng: -115.62,
			zoom: 6.21
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'baja-california-seccion-betwr8',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'municipio',
				source: 'baja-california-municipio-5d9jgm',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's3',
				type: 'entidad',
				source: 'baja-california-entidad-3wgzl1',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's4',
				type: 'distrito',
				source: 'baja-california-distrito-5cy5op',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmicnr6y3qwj17p86eo1da9e'
	},
	{
		id: '3',
		name: 'Baja California Sur',
		location: {
			lat: 25.504,
			lng: -111.795,
			zoom: 6.11
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'baja-california-sur-seccion-2n6n4p',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'baja-california-sur-distrito-4ekkek',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'baja-california-sur-municipio-6npsu5',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'baja-california-sur-entidad-4adi6l',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'baja-california-sur-distrito-7jx32l',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiolh4t42hv17p8p7n1irqg'
	},
	{
		id: '4',
		name: 'Campeche',
		location: {
			lat: 18.666,
			lng: -89.941,
			zoom: 7.13
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'campeche-seccion-9cq3fb',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'campeche-distrito-local-7ds31v (1)',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'campeche-municipio-bt8j7y',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'campeche-entidad-47m48a',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'campeche-distrito-local-7ds31v',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmip4ehz1hse17ogwdtlnd2r'
	},
	{
		id: '5',
		name: 'Coahuila',
		location: {
			lat: 27.19,
			lng: -100.837,
			zoom: 6.22
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'campeche-seccion-9cq3fb',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'coahuila-distrito-local-1xn4dn',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'coahuila-municipio-c1rr6t',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'coahuila-entidad-aegqza',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'coahuila-distrito-dd4ky2',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmip4ehz1hse17ogwdtlnd2r'
	},
	{
		id: '6',
		name: 'Colima',
		location: {
			lat: 19.256,
			lng: -103.706,
			zoom: 11.02
		},
		sources: [
			{ id: 's1', type: 'seccion', source: 'colima-seccion-3olz6t', name: 'Sección', acceptedProps: ['seccion'] },
			{
				id: 's2',
				type: 'distrito_local',
				source: 'colima-distrito-local-5sy8d9',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'colima-municipio-116cy1',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'colima-entidad-85r2bn',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'colima-distrito-4m8bc1',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiq10q1039w18jsuux2jx6a'
	},
	{
		id: '7',
		name: 'Chiapas',
		location: {
			lat: 16.628,
			lng: -92.372,
			zoom: 6.99
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'chiapas-seccion-7sniq0',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'chiapas-distrito-local-b26qg5',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'chiapas-municipio-drh1xt',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'chiapas-entidad-a02z2n',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'chiapas-distrito-6fpnb6',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmip8ksg5n6817n4hlu3u4lf'
	},
	{
		id: '8',
		name: 'Chihuahua',
		location: {
			lat: 28.78,
			lng: -105.747,
			zoom: 5.91
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'chihuahua-seccion-csrj6w',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'chihuahua-distrito-local-ba5q9c',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'chihuahua-municipio-6jgn4z',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'chihuahua-entidad-1r99ax',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'chihuahua-distrito-3vtm3v',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiplpu51icl17qrf1ky4mba'
	},
	{
		id: '9',
		name: 'Distrito Federal / CDMX',
		location: {
			lat: 19.435,
			lng: -99.083,
			zoom: 9.75
		},
		sources: [
			{ id: 's1', type: 'seccion', source: 'cdmx-seccion-4m56en', name: 'Sección', acceptedProps: ['seccion'] },
			{
				id: 's2',
				type: 'distrito_local',
				source: 'cdmx-distrito-local-5ufaqp',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'cdmx-municipio-2t5dkq',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'cdmx-entidad-31cpbh',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'cdmx-distrito-bzq7vr',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmitx89s5rpx17n4u9rtfr09'
	},
	{
		id: '10',
		name: 'Durango',
		location: {
			lat: 24.02,
			lng: -104.645,
			zoom: 11.66
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'durango-seccion-css4c7',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'durango-distrito-local-6y7nvt',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'durango-municipio-dx2f68',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'durango-entidad-4k0d48',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'durango-distrito-7ardyy',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiq7u1n59d117olryrq3xim'
	},
	{
		id: '11',
		name: 'Guanajuato',
		location: {
			lat: 21.019,
			lng: -101.244,
			zoom: 11.22
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'guanajuato-seccion-acaqls',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'guanajuato-distrito-local-3yuenk',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'guanajuato-municipio-1rrax9',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'guanajuato-entidad-573en1',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'guanajuato-distrito-4i1tu4',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiqcqu41j0p17ogzwdh1m3w'
	},
	{
		id: '12',
		name: 'Guerrero',
		location: {
			lat: 17.716,
			lng: -99.803,
			zoom: 7.6
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'guerrero-seccion-anewoo',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'guerrero-distrito-local-8j5vrb',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'guerrero-municipio-60hg4x',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'guerrero-entidad-8mi022',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'guerrero-distrito-ck04ms',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiqn2xw44is17p82fevabna'
	},
	{
		id: '13',
		name: 'Hidalgo',
		location: {
			lat: 20.501,
			lng: -98.922,
			zoom: 8.36
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'hidalgo-seccion-dk1724',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'hidalgo-distrito-local-3oc6de',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'hidalgo-municipio-7jgvnd',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'hidalgo-entidad-7xnpnd',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'hidalgo-distrito-6fgizx',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiqrt911jgo17qr2pekiujv'
	},
	{
		id: '14',
		name: 'Jalisco',
		location: {
			lat: 20.85,
			lng: -103.653,
			zoom: 7.27
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'jalisco-seccion-aciy5o',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'jalisco-distrito-local-2mg1xy',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'jalisco-municipio-4du2as',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'jalisco-entidad-3hegw5',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'jalisco-distrito-4717oh',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmiqybef00wk17ntyhqaeuz6'
	},
	{
		id: '15',
		name: 'México',
		location: {
			lat: 19.329,
			lng: -99.605,
			zoom: 8.28
		},
		sources: [
			{ id: 's1', type: 'seccion', source: 'mexico-seccion-2keot4', name: 'Sección', acceptedProps: ['seccion'] },
			{
				id: 's2',
				type: 'distrito_local',
				source: 'mexico-distrito-local-9ghw4m',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'mexico-municipio-donhvk',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'mexico-entidad-3njlxe',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'mexico-distrito-8tr0tw',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmitmeft1iuv17mijia8idg9'
	},
	{
		id: '16',
		name: 'Michoacán',
		location: {
			lat: 19.112,
			lng: -101.949,
			zoom: 7.61
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'michoacan-seccion-74gehr',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'michoacan-distrito-local-b1stb7',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'michoacan-municipio-b62urh',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'michoacan-entidad-700fa8',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'michoacan-distrito-canity',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmir3dsk011m17potqv5cjw9'
	},
	{
		id: '17',
		name: 'Morelos',
		location: {
			lat: 18.733,
			lng: -99.064,
			zoom: 9.54
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'morelos-seccion-b0jaji',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'morelos-distrito-local-7heust',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'morelos-municipio-07k1is',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'morelos-entidad-cae7gb',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'morelos-distrito-4beuo1',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmirci2z04j817po2j25npju'
	},
	{
		id: '18',
		name: 'Nayarit',
		location: {
			lat: 21.844,
			lng: -105.254,
			zoom: 7.87
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'nayarit-seccion-4lhzln',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'nayarit-distrito-local-5kfmze',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'nayarit-municipio-1vu6ci',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'nayarit-entidad-8q0dcl',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'nayarit-distrito-95bizo',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmirioyg01pw17qldopxvu44'
	},
	{
		id: '19',
		name: 'Nuevo León',
		location: {
			lat: 25.749,
			lng: -99.417,
			zoom: 6.12
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'nuevo-leon-seccion-70grjg',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'nuevo-leon-distrito-local-ca3bpw',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'nuevo-leon-municipio-cx2ut3',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'nuevo-leon-entidad-d1tkd1',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'nuevo-leon-distrito-d0g9rs',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmirmxoh01ky17mx3funvsvf'
	},
	{
		id: '20',
		name: 'Oaxaca',
		location: {
			lat: 16.986,
			lng: -96.409,
			zoom: 7.37
		},
		sources: [
			{ id: 's1', type: 'seccion', source: 'oaxaca-seccion-drqhk3', name: 'Sección', acceptedProps: ['seccion'] },
			{
				id: 's2',
				type: 'distrito_local',
				source: 'oaxaca-distrito-local-ch87xg',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'oaxaca-municipio-8zmvp1',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'oaxaca-entidad-4tuuwu',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'oaxaca-distrito-54w01n',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmjdmlzl29nu17piz975ldzs'
	},
	{
		id: '21',
		name: 'Puebla',
		location: {
			lat: 19.577,
			lng: -97.856,
			zoom: 6.3
		},
		sources: [
			{
				id: 'Puebla_2024_Seccion-7kiiy5',
				type: 'SECCION',
				source: 'puebla-2024-seccion-7kiiy5',
				name: 'Sección',
				acceptedProps: ['SECCION']
			},
			{
				id: 'Puebla_2024_Municipio-0e36pk',
				type: 'MUNICIPIO',
				source: 'puebla-2024-municipio-0e36pk',
				name: 'Municipio',
				acceptedProps: ['MUNICIPIO']
			},
			{
				id: 'Puebla_DL_2024-3oqkay',
				type: 'DISTRITO_L',
				source: 'puebla-dl-2024-3oqkay',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 'Puebla_2024_Entidad-75a005',
				type: 'ENTIDAD',
				source: 'puebla-2024-entidad-75a005',
				name: 'Entidad',
				acceptedProps: ['ENTIDAD']
			},
			{
				id: 'Puebla_2024_DF-blquye',
				type: 'DISTRITO',
				source: 'puebla-2024-df-blquye',
				name: 'Distrito Federal',
				acceptedProps: ['DISTRITO']
			}
		],
		style: 'mapbox://styles/nunezalan/ckl2trzh21ce817o2dyt81qo6'
	},
	{
		id: '22',
		name: 'Querétaro',
		location: {
			lat: 20.845,
			lng: -99.82,
			zoom: 8.47
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'queretaro-seccion-8xvcoc',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'queretaro-distrito-local-2bqc81',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'queretaro-municipio-bc7nwu',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'queretaro-entidad-1mbjn1',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'queretaro-distrito-45k1iz',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmis1zra09f117momo8ako6i'
	},
	{
		id: '23',
		name: 'Quintana Roo',
		location: {
			lat: 19.807,
			lng: -87.954,
			zoom: 7.28
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'quintana-roo-seccion-dsk2v4',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'quintana-roo-distrito-local-7cshe8',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'quintana-roo-municipio-806mm7',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'quintana-roo-entidad-5rlt6u',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'quintana-roo-distrito-84xqqg',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmis1zra09f117momo8ako6i'
	},
	{
		id: '24',
		name: 'San Luis Potosí',
		location: {
			lat: 22.836,
			lng: -100.311,
			zoom: 7.45
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'san-luis-potosi-seccion-18ak51',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'san-luis-potosi-distrito-loca-2gvrzh',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'san-luis-potosi-municipio-1amaha',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'san-luis-potosi-entidad-6apw6x',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'san-luis-potosi-distrito-1dqr06',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmis83nd5dsj17o2j6aq4mdt'
	},
	{
		id: '25',
		name: 'Sinaloa',
		location: {
			lat: 24.776,
			lng: -107.47,
			zoom: 6.97
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'sinaloa-seccion-5344h9',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'sinaloa-distrito-local-50tc69',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'sinaloa-municipio-9h9lgg',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'sinaloa-entidad-05pqi1',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'sinaloa-distrito-43v9qt',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmismmod02sz17ql7u85p8mx'
	},
	{
		id: '26',
		name: 'Sonora',
		location: {
			lat: 29.445,
			lng: -111.739,
			zoom: 6.47
		},
		sources: [
			{ id: 's1', type: 'seccion', source: 'sonora-seccion-1p0f18', name: 'Sección', acceptedProps: ['seccion'] },
			{
				id: 's2',
				type: 'distrito_local',
				source: 'sonora-distrito-local-dvr4hd',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'sonora-municipio-5ftry4',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'sonora-entidad-c8mk0f',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'sonora-distrito-8n7s3l',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmismmod02sz17ql7u85p8mx'
	},
	{
		id: '27',
		name: 'Tabasco',
		location: {
			lat: 17.998,
			lng: -92.594,
			zoom: 7.84
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'tabasco-seccion-6f0jk3',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'tabasco-distrito-local-5bescw',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'tabasco-municipio-1de9qx',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'tabasco-entidad-awi5zv',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'tabasco-distrito-dz0arg',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmist1ze1lf717ogcb7ye8mu'
	},
	{
		id: '28',
		name: 'Tamaulipas',
		location: {
			lat: 24.974,
			lng: -98.595,
			zoom: 6.71
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'tamaulipas-seccion-6wh00m',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'tamaulipas-distrito-local-ciy042',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'tamaulipas-municipio-2dwwrp',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'tamaulipas-entidad-angxit',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'tamaulipas-distrito-0komjb',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmisz3x11lll17qrcg2loz9m'
	},
	{
		id: '29',
		name: 'Tlaxcala',
		location: {
			lat: 19.435,
			lng: -97.924,
			zoom: 7.94
		},
		sources: [
			{
				id: 'Tlaxcala_2024_Seccion-9dul42',
				type: 'SECCION',
				source: 'tlaxcala-2024-seccion-9dul42',
				name: 'Sección',
				acceptedProps: ['SECCION']
			},
			{
				id: 'Tlaxcala_2024_Municipio-aetv3d',
				type: 'MUNICIPIO',
				source: 'tlaxcala-2024-municipio-aetv3d',
				name: 'Municipio',
				acceptedProps: ['MUNICIPIO']
			},
			{
				id: 'Tlaxcala_2024_DL-7tf04k',
				type: 'DISTRITO_L',
				source: 'tlaxcala-2024-dl-7tf04k',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 'Tlaxcala_2024_Entidad-0ja2yx',
				type: 'ENTIDAD',
				source: 'tlaxcala-2024-entidad-0ja2yx',
				name: 'Entidad',
				acceptedProps: ['ENTIDAD']
			},
			{
				id: 'Tlaxcala_2024_DF-48o9bs',
				type: 'DISTRITO',
				source: 'tlaxcala-2024-df-48o9bs',
				name: 'Distrito Federal',
				acceptedProps: ['DISTRITO']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmi7021x2jlh18qvhewm5m5a'
	},
	{
		id: '30',
		name: 'Veracruz',
		location: {
			lat: 19.62,
			lng: -95.296,
			zoom: 6.54
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'veracruz-seccion-7i3dxk',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'veracruz-distrito-local-3nf7rq',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'veracruz-municipio-6omz7l',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'veracruz-entidad-8vb0fr',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'veracruz-distrito-basf19',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmit4cei46xl17p8g3j2m8w8'
	},
	{
		id: '31',
		name: 'Yucatán',
		location: {
			lat: 20.49,
			lng: -88.89,
			zoom: 8.06
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'yucatan-seccion-4o80hj',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'yucatan-distrito-local-a9wqfv',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'yucatan-municipio-94v5g0',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'yucatan-entidad-4nh01b',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'yucatan-distrito-309gek',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmit96cw0al717mojiuhdle5'
	},
	{
		id: '32',
		name: 'Zacatecas',
		location: {
			lat: 23.419,
			lng: -102.152,
			zoom: 6.39
		},
		sources: [
			{
				id: 's1',
				type: 'seccion',
				source: 'zacatecas-seccion-cm17hk',
				name: 'Sección',
				acceptedProps: ['seccion']
			},
			{
				id: 's2',
				type: 'distrito_local',
				source: 'zacatecas-distrito-local-aczd2b',
				name: 'Distrito Local',
				acceptedProps: ['DISTRITO_L']
			},
			{
				id: 's3',
				type: 'municipio',
				source: 'zacatecas-municipio-4uuxnl',
				name: 'Municipio',
				acceptedProps: ['municipio', 'nombre']
			},
			{
				id: 's4',
				type: 'entidad',
				source: 'zacatecas-entidad-5adm8l',
				name: 'Entidad',
				acceptedProps: ['nombre', 'circunscri']
			},
			{
				id: 's5',
				type: 'distrito',
				source: 'zacatecas-distrito-4w5esz',
				name: 'Distrito Federal',
				acceptedProps: ['distrito']
			}
		],
		style: 'mapbox://styles/nunezalan/ckmitegux5r8a17n4it7t7vlj'
	}
];
