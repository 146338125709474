import {
	CREATE_USER_ERROR,
	CREATE_USER_SUCCESS,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	GET_ME_SUCCESS,
	GET_ME_ERROR,
	GET_USER_LIST_SUCCESS,
	GET_USER_LIST_ERROR,
	CLEAN_CREATE_USER_STORE,
	EDIT_USER_SUCCESS,
	EDIT_USER_ERROR,
	CLEAN_EDIT_USER_STORE,
	REMOVE_2FA_TOKEN_SUCCESS,
	REMOVE_2FA_TOKEN_ERROR,
	GENERATE_QR_CODE_SUCCESS,
	GENERATE_QR_CODE_ERROR,
	VERIFY_2FA_CODE_SUCCESS,
	VERIFY_2FA_CODE_ERROR,
	MOVE_2FA
} from '../actionTypes';

const initialState = {
	user: null,
	getMeError: '',
	getMeSucceded: false,
	token: null,
	refreshToken: null,
	loginError: '',
	loginSucceded: false,
	createUserSucceded: false,
	createUserError: '',
	editUserSucceded: false,
	editUserError: '',
	usersList: [],
	usersListSucceded: false,
	usersListError: '',
	remove2FATokenError: false,
	remove2FATokenSuccess: false,
	QRCode: '',
	QRError: false,
	base32: '',
	verify2FACodeSuccesfull: null,
	verify2FACodeError: false,
	move2FA: false
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				token: action.payload?.access_token,
				refreshToken: action.payload?.refresh_token,
				loginSucceded: true
			};
		case LOGIN_ERROR:
			return { ...state, loginError: action.payload };
		case LOGOUT_SUCCESS:
			return {
				...state,
				token: null,
				refreshToken: null,
				loginSucceded: false,
				getMeSucceded: false,
				user: null,
				remove2FATokenError: false,
				remove2FATokenSuccess: false,
				QRCode: '',
				QRError: false,
				base32: '',
				verify2FACodeSuccesfull: null,
				verify2FACodeError: false,
				move2FA: false
			};
		case CREATE_USER_SUCCESS:
			return {
				...state,
				createUserError: null,
				createUserSucceded: true
			};
		case CREATE_USER_ERROR:
			return {
				...state,
				createUserError: action.payload
			};
		case EDIT_USER_SUCCESS:
			return {
				...state,
				editUserError: null,
				editUserSucceded: true
			};
		case EDIT_USER_ERROR:
			return {
				...state,
				editUserError: action.payload
			};
		case GET_ME_SUCCESS:
			return {
				...state,
				user: action.payload,
				getMeError: '',
				getMeSucceded: true
			};
		case GET_ME_ERROR:
			return {
				...state,
				user: null,
				getMeError: action.payload,
				getMeSucceded: false
			};
		case GET_USER_LIST_SUCCESS:
			return {
				...state,
				usersList: action.payload,
				usersListError: '',
				usersListSucceded: true
			};
		case GET_USER_LIST_ERROR:
			return {
				...state,
				usersList: null,
				usersListError: action.payload,
				usersListSucceded: false
			};
		case CLEAN_CREATE_USER_STORE:
			return {
				...state,
				createUserSucceded: false,
				createUserError: ''
			};
		case CLEAN_EDIT_USER_STORE:
			return {
				...state,
				editUserSucceded: false,
				editUserError: ''
			};
		case REMOVE_2FA_TOKEN_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					two_factor_enabled: false
				},
				remove2FATokenSuccess: true
			};
		case REMOVE_2FA_TOKEN_ERROR:
			return {
				...state,
				remove2FATokenError: action.payload
			};
		case GENERATE_QR_CODE_SUCCESS:
			return {
				...state,
				QRCode: action.payload?.otpauthUrl,
				base32: action.payload?.base32
			};
		case GENERATE_QR_CODE_ERROR:
			return {
				...state,
				QRError: action.payload
			};
		case VERIFY_2FA_CODE_SUCCESS:
			return {
				...state,
				verify2FACodeSuccesfull: action.payload,
				move2FA: false,
				loginSucceded: true,
				user: {
					...state.user,
					two_factor_enabled: action.payload
				}
			};
		case VERIFY_2FA_CODE_ERROR:
			return {
				...state,
				verify2FACodeError: action.payload
			};
		case MOVE_2FA:
			return {
				...state,
				move2FA: true,
				token: action.payload
			};
		default:
			return { ...state };
	}
};

export default userReducer;
