import { useEffect, useState } from 'react';
import { axiosInstance } from 'utils/axiosInstance';

export const useGetRoles = () => {
	const [loading, setLoading] = useState(false);
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		setLoading(true);
		try {
			axiosInstance('user-info/me', { method: 'GET' }).then((res) => setRoles(res.data.roles));
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}, []);

	return { loading, roles };
};
