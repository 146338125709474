import React, { useEffect, useState } from 'react';
import { Plus, X } from 'react-feather';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import Select, { components } from 'react-select';

import DataTable from 'react-data-table-component';
import InternalLoader from 'layout/internal-loader';
import { axiosInstance } from 'utils/axiosInstance';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';

let conectores = ['de', 'a', 'en', 'con', 'por', 'para'];

const ADMIN_ROL = 7;
const ADMINISTRADOR_ROL = 1;
const OPERADOR_ROL = 2;

const datatableCustomStyle = {
	cells: {
		style: {
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '0'
		}
	},
	rows: {
		style: {
			'&:hover': {
				backgroundColor: '#FFF1F1 !important',
				'.actionButton': {
					color: '#F04A5E !important'
				}
			}
		}
	}
};

const customStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E',
		overflowX: 'auto',
		minHeight: 'auto'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const DropdownIndicator = (props) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<Plus size={14} color="#F04F62" />
			</components.DropdownIndicator>
		)
	);
};

const RoleModal = ({ isOpen, toggle, userRoles, userData, refetch }) => {
	const [selectedRol, setSelectedRol] = useState([]);

	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [saveLoading, setSaveLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		axiosInstance({
			url: '/users/rols',
			method: 'GET'
		})
			.then(({ data }) => {
				setSelectedRol(
					data.results
						.filter((rol) => userRoles.includes(rol.name))
						.map((rol) => ({
							label: rol.name,
							value: rol.id
						}))
				);

				setRoles(
					data.results
						.filter(
							(rol) => rol.id !== ADMIN_ROL && rol.id !== ADMINISTRADOR_ROL && rol.id !== OPERADOR_ROL
						)
						.map((rol) => ({
							label: rol.name,
							value: rol.id
						}))
				);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [userRoles]);

	const removeRol = (id) => {
		setSelectedRol((prev) => prev.filter((sr) => sr.value !== id));
	};

	const saveRoles = () => {
		const { user_profile, id } = userData;

		setSaveLoading(true);

		axiosInstance({
			url: user_profile ? `/users/recruiter-responsible/${user_profile}/` : '/users/recruiter-responsible/',
			method: user_profile ? 'PUT' : 'POST',
			data: user_profile
				? {
						role_user: selectedRol.map((rol) => rol.label)
				  }
				: { role_user: selectedRol.map((rol) => rol.label), vote_credential: id }
		})
			.then(() => {
				toggle();
			})
			.catch((err) => {
				console.log(err.response);
			})
			.finally(() => {
				setSaveLoading(false);
				refetch();
			});

		// setSaveLoading(true);

		// axiosInstance({
		// 	url: `/users/recruiter-responsible/${userId}/`,
		// 	method: 'PUT',
		// 	data: {
		// 		role_user: selectedRol.map((rol) => rol.label)
		// 	}
		// })
		// 	.then(() => {
		// 		toggle();
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	})
		// 	.finally(() => {
		// 		setSaveLoading(false);
		// 		refetch();
		// 	});
	};

	const rolesColumns = [
		{
			name: 'Roles asignados',
			center: true,
			width: '100%',
			cell: (row) => {
				const characters = row.label
					.split(' ')
					.filter((word) => !conectores.includes(word))
					.slice(0, 2)
					.map((word) => word[0].toUpperCase())
					.join('');

				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								alignContent: 'center',
								gap: '12px'
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: '#F04A5E',
									color: '#fdfeff',
									fontSize: '12px',
									fontWeight: '600',
									padding: '4px',
									width: '32px',
									height: '32px',
									borderRadius: '50%'
								}}
							>
								{characters}
							</div>
							<p style={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '0' }}>
								{row.label}
							</p>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center'
							}}
						>
							<X
								size={18}
								color="#F04A5E"
								style={{ cursor: 'pointer' }}
								onClick={() => removeRol(row.value)}
							/>
						</div>
					</div>
				);
			}
		}
	];

	return (
		<>
			<Modal isOpen={isOpen}>
				<ModalBody className="electors-filter-modal">
					<Row className="m-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<p style={{ fontSize: '16px' }}>Seleccionar Roles</p>
						<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
					</Row>
					<Row className="m-0">
						<Container>
							<Row className="my-2">
								<Col>
									<Select
										isSearchable
										isMulti
										name="selectedRol"
										id="selectedRol"
										options={roles}
										onChange={(option) => {
											setSelectedRol(option);
										}}
										placeholder={'Añadir nuevo rol'}
										value={selectedRol}
										styles={customStyles}
										hideSelectedOptions={true}
										components={{ DropdownIndicator }}
										noOptionsMessage={() => 'Ingresar rol'}
										isLoading={isLoading}
									/>
								</Col>
							</Row>
							<Row className="my-2" style={{ maxHeight: '250px', overflow: 'auto' }}>
								<DataTable
									columns={rolesColumns}
									data={selectedRol}
									progressComponent={<InternalLoader />}
									customStyles={datatableCustomStyle}
									noDataComponent={'No se han encontrado roles asignados'}
								/>
							</Row>
						</Container>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button outline color="light-2x txt-dark" onClick={toggle}>
						Cancelar
					</Button>
					<Button color="primary" onClick={saveRoles}>
						Guardar
					</Button>
				</ModalFooter>
			</Modal>
			<LoadingAffiliation isOpen={saveLoading} />
		</>
	);
};

export default RoleModal;
