import Select, {components} from 'react-select';
import {CheckSquare} from 'react-feather';

const InputOption = ({getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest}) => {
    let bg = '#FCFCFC';
    let color = '#AFAFAF';

    if (isSelected) {
        bg = '#FFF1F1';
        color = '#F04A5E';
    } else if (isFocused) {
        bg = 'inherit';
    }

    if (isDisabled) {
        bg = '#fadada';
        color = '#F04A5E'
    }

    const style = {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        backgroundColor: bg,
        color,
        borderBottom: '0.5px solid #CED4DA'
        // ':nth-child(odd)': {
        // 	backgroundColor: 'red'
        // }
    };

    // prop assignment
    const props = {
        ...innerProps,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            {children}
            {isSelected && <CheckSquare size={16}/>}
        </components.Option>
    );
};
//backgroundColor: provided.isDisabled ? 'black' : 'white',

const colourStyles = {
    menu: (styles) => ({...styles, position: 'inherit'}),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#F04A5E'
        };
    }
};
// const colourStyles = {
// 	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
// 	option: (styles, { isDisabled, isFocused, isSelected }) => {
// 		return {
// 			...styles,
// 			alignItems: 'center',
// 			justifyContent: 'space-between',
// 			display: 'flex',
// 			backgroundColor: isDisabled ? undefined : isSelected ? 'red' : isFocused ? 'black' : 'green',
// 			// color: isDisabled ? '#ccc' : isSelected ? 'blue' : 'gray',
// 			cursor: isDisabled ? 'not-allowed' : 'default',

// 			':active': {
// 				...styles[':active'],
// 				backgroundColor: !isDisabled ? (isSelected ? 'orange' : 'yellow') : undefined
// 			}
// 		};
// 	}
// 	// multiValue: (styles, { data }) => {

// 	//   return {
// 	//     ...styles,
// 	//     backgroundColor: color.alpha(0.1).css(),
// 	//   };
// 	// },
// 	// multiValueLabel: (styles, { data }) => ({
// 	//   ...styles,
// 	//   color: data.color,
// 	// }),
// 	// multiValueRemove: (styles, { data }) => ({
// 	//   ...styles,
// 	//   color: 'green',
// 	//   ':hover': {
// 	//     backgroundColor: data.color,
// 	//     color: 'white',
// 	//   },
// 	// }),
// };

export default function Multiselect({
                                        options,
                                        onChange,
                                        loading,
                                        disabled = false,
                                        defaultValue = [],
                                        noOptionMessage = 'Debe seleccionar un estado',
                                        isMulti = true
                                    }) {
    return (
        <div className="multiSelectContainer">
            <Select
                placeholder="Buscar por..."
                minMenuHeight={200}
                maxMenuHeight={300}
                // menuPosition='fixed'
                defaultValue={defaultValue}
                isMulti={isMulti}
                isSearchable
                defaultMenuIsOpen
                menuIsOpen
                isLoading={loading}
                // closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // menuShouldScrollIntoView={true}
                // closeMenuOnScroll={false}
                onChange={onChange}
                options={loading ? [] : options}
                components={{
                    Option: InputOption
                }}
                styles={colourStyles}
                loadingMessage={() => 'Cargando...'}
                noOptionsMessage={() => noOptionMessage}
                isDisabled={disabled}
            />
            {/* <pre>{JSON.stringify({ selected: selectedOptions }, null, 2)}</pre> */}
        </div>
    );
}
