import { gql, useQuery } from '@apollo/client';
import { graphQlClient } from 'store/graphql';

const getVotersByClientQuery = gql`
	query Query($clientId: String) {
		getVotersByClient(clientId: $clientId)
	}
`;

export function useVotersByClient() {
	const baseUrl = window.location.host;

	const clientId = baseUrl.indexOf('localhost') !== -1 ? 'demo' : baseUrl.split('.')[0];

	const { loading, data, error } = useQuery(getVotersByClientQuery, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			clientId
		}
	});

	return { loading, data: data?.getVotersByClient?.available, error };
}
