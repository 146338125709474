import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, Row } from 'reactstrap';
import Breadcrumb from '../../../../layout/breadcrumb';
import { Filter, Search, Slash } from 'react-feather';
import { useMap } from '../../../../utils/useMap';
import FilterVotersModal from './FilterVotersModal';
import { axiosInstanceV1 } from '../../../../utils/axiosInstance';
import { useGetStatesByCredentials } from '../../../../utils/fetch/useGetStatesByCredentials';
import InternalLoader from '../../../../layout/internal-loader';
import { useGetPoliticalParties } from '../../../../utils/fetch/useGetPoliticalParties';
import { useGetTypeAffiliate } from '../../../../utils/fetch/useGetTypeAffiliate';
import { useGetSpecialGroups } from '../../../../utils/fetch/useGetSpecialGroups';
import { useLazyElectoresWithPagination, useLazyVoterGoalsWithPagination } from '../../../../utils/fetch/electores';
import { usePagination } from '../../../../utils/fetch/pagination';
import { getAgeFromDate } from '../../../../utils/getAgeFromDate';
import { mapSetup } from '../../../../data/map-setup';
import bbox from '@turf/bbox';
import PopupMap from '../../analisis/popup-map';
import mapboxgl from 'mapbox-gl';
import womanAvatar from '../../../../assets/images/avatars/woman-avatar.svg';
import manAvatar from '../../../../assets/images/avatars/man-avatar.svg';
import nonBinaryAvatar from '../../../../assets/images/avatars/nonbinary-avatar.svg';
import filterDemarcations from '../../../../utils/filterDemarcations';
import { customStyles } from '../../electores/tableStyles';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { getDemarcationType } from 'utils/getDemarcationType';

async function mapVotersData(voters, order) {
	return await Promise.all(
		voters.map(async (voter) => {
			let age;

			if (voter.ocr.date_birth) {
				const [dob, mob, yob] = voter.ocr.date_birth.split('/');
				age = getAgeFromDate(dob, mob, yob);
			} else {
				age = '-';
			}

			const firstName = voter.ocr.first_name;
			const lastName = voter.ocr.last_name;
			const gender = voter.ocr.gender;
			const state = voter.ocr.state;
			const district = voter.ocr.district;
			const local_district = voter.ocr.local_district;
			const municipality = voter.ocr.municipality;
			const electoralSection = voter.ocr.section;
			const createdAt = new Date(voter.createdAt).toISOString().split('T')[0];
			const [yyyy, mm, dd] = createdAt.split('-');
			const formattedCreatedAt = dd + '/' + mm + '/' + yyyy;

			return {
				curp: voter.ocr.curp,
				userId: voter._id,
				image: voter.ocr.passport_photo,
				firstName,
				lastName,
				sexo: gender,
				age,
				estado: state,
				seccion: electoralSection,
				district,
				local_district,
				municipality,
				createdAt: formattedCreatedAt
			};
		})
	)
		.then((res) => res)
		.catch((err) => console.log(err));
}

export function CellAvatar({ image, firstName, lastName, gender }) {
	const selectSrcImg = (image, gender) => {
		if (image) {
			return image;
		}
		if (gender.toLocaleLowerCase() === 'male') {
			return manAvatar;
		}
		if (gender.toLocaleLowerCase() === 'female') {
			return womanAvatar;
		}
		if (gender.toLocaleLowerCase() === 'non-binary') {
			return nonBinaryAvatar;
		}
	};

	return (
		<div className="cell-avatar">
			<div>
				<img
					src={selectSrcImg(image, gender)}
					style={{ width: 50, height: 50 }}
					alt={`${firstName} ${lastName}`}
				/>
			</div>
			<div className="ml-2 mb-0">
				<p className="mb-0">{firstName?.toLowerCase()}</p>
				<p>{lastName?.toLowerCase()}</p>
			</div>
		</div>
	);
}

export function CellGender({ gender }) {
	let letterGender = '?';
	if (gender.toLowerCase() === 'Male'.toLowerCase()) {
		letterGender = 'M';
	} else if (gender.toLowerCase() === 'Female'.toLowerCase()) {
		letterGender = 'F';
	}

	return (
		<div className="cell-gender">
			<span>{letterGender}</span>
		</div>
	);
}

const columns = [
	{
		name: 'Elector',
		selector: (row) => row.image,
		center: true,
		cell: (row) => (
			<CellAvatar image={row.image} firstName={row.firstName} lastName={row.lastName} gender={row.sexo} />
		),
		width: '220px'
	},
	{
		name: 'Sexo',
		selector: (row) => row.sexo,
		center: true,
		width: '80px',
		cell: (row) => <CellGender gender={row.sexo} />
	},
	{
		name: 'Edad',
		width: '80px',
		selector: (row) => row.age,
		center: true
	},
	{
		name: 'Estado',
		center: true,
		selector: (row) => row.estado
	},
	{
		name: 'Sección Electoral',
		center: true,
		selector: (row) => row.seccion
	},
	{
		name: 'Distrito Federal',
		center: true,
		selector: (row) => row.district
	},
	{
		name: 'Distrito Local',
		center: true,
		selector: (row) => row.local_district
	},
	{
		name: 'Municipio',
		center: true,
		selector: (row) => row.municipality
	},
	{
		cell: (row) => (
			<Link className="actionButton" to={row.curp ? `/electores/${row.curp}` : '#'}>
				<i className="fa fa-angle-right"></i>
			</Link>
		),
		width: '40px',
		ignoreRowClick: true,
		allowOverflow: true,
		button: true
	}
];

const filter = {
	ocr: {}
};
const Voters = () => {
	const mapContainer = useRef();
	const initialAge = { min: 20, max: 40 };
	const { map } = useMap(mapContainer);
	const [modal, setModal] = useState(false);
	const [filterParamsVoterGoals, setFilterParamsVoterGoals] = useState({});
	const [sortObj, setSortObj] = useState({});

	const [filterParams, setFilterParams] = useState(filter);

	const { loading: loadingStates, data: states } = useGetStatesByCredentials();
	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	const [
		getElectores,
		{
			data: { items, loading, count }
		}
	] = useLazyElectoresWithPagination({ perPage: perPage, page: page, filter: filterParams, sort: sortObj });

	const { data: politicalParties } = useGetPoliticalParties();
	const { data: typeAffiliate } = useGetTypeAffiliate();
	const { data: specialGroups } = useGetSpecialGroups();

	const [getVoterGoals] = useLazyVoterGoalsWithPagination({
		perPage: perPage,
		page: page,
		filter: filterParamsVoterGoals
	});

	const [stateOptions, setStateOptions] = useState([]);
	const [localeDistrictOptions, setLocaleDistrictOptions] = useState([]);
	const [federalDistrictOptions, setFederalDistrictOptions] = useState([]);
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [sectionOptions, setSectionOptions] = useState([]);
	const [politicalPartiesOptions, setPoliticalPartiesOptions] = useState([]);
	const [typeAffiliateOptions, setTypeAffiliateOptions] = useState([]);
	const [specialGroupOptions, setSpecialGroupOptions] = useState([]);

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	const [selectedLocaleDistrict, setSelectedLocaleDistrict] = useState([]);
	const [selectedFederalDistrict, setSelectedFederalDistrict] = useState([]);
	const [selectedMunicipality, setSelectedMunicipality] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);

	const [loadingDemarcations, setLoadingDemarcations] = useState(false);

	const [selectedAge, setSelectedAge] = useState(initialAge);
	const [hasSelectedAge, setHasSelectedAge] = useState(false);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedPoliticalParty, setSelectedPoliticalParty] = useState(null);
	const [selectedTypeAffiliate, setSelectedTypeAffiliate] = useState([]);
	const [selectedSpecialGroup, setSelectedSpecialGroup] = useState([]);

	const [votersData, setVotersData] = useState([]);
	const [loadingAffiliates, setLoadingAffiliates] = useState(false);

	const [search, setSearch] = useState('');
	const [selectedDate] = useState(new Date());
	const [hasSelectedDate] = useState(false);

	const handleSelectedStateChange = async (state) => {
		setMunicipalityOptions([]);
		setLocaleDistrictOptions([]);
		setFederalDistrictOptions([]);
		setSectionOptions([]);

		if (!state) {
			setSelectedState(null);
			return;
		}

		setSelectedState(state);

		setLoadingDemarcations(true);

		const mappedLDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-local-district-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((lD) => {
					return {
						label: lD.code,
						value: lD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedFDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-districts-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((fD) => {
					return {
						label: fD.code,
						value: fD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedSections = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-sections-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((sec) => {
					return {
						label: sec.code,
						value: sec.id_code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedMunicipalities = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-municipalities-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((mun) => {
					return {
						label: mun.nombre,
						value: mun.code
					};
				})
			)
			.catch((err) => console.log(err));

		setSectionOptions(filterDemarcations(mappedSections));
		setMunicipalityOptions(filterDemarcations(mappedMunicipalities));
		setLocaleDistrictOptions(filterDemarcations(mappedLDistricts));
		setFederalDistrictOptions(filterDemarcations(mappedFDistricts));

		setLoadingDemarcations(false);
	};

	const filterStates = {
		selectedOrder,
		setSelectedOrder,
		selectedState,
		setSelectedState,
		selectedSection,
		setSelectedSection,
		selectedMunicipality,
		setSelectedMunicipality,
		selectedLocaleDistrict,
		setSelectedLocaleDistrict,
		selectedFederalDistrict,
		setSelectedFederalDistrict,
		initialAge,
		selectedAge,
		setSelectedAge,
		setHasSelectedAge,
		selectedPoliticalParty,
		setSelectedPoliticalParty,
		selectedGender,
		setSelectedGender,
		selectedTypeAffiliate,
		setSelectedTypeAffiliate,
		selectedSpecialGroup,
		setSelectedSpecialGroup,
		setLocaleDistrictOptions,
		setFederalDistrictOptions,
		setMunicipalityOptions,
		setSectionOptions
	};

	const filterOptions = {
		loadingDemarcations,
		stateOptions,
		localeDistrictOptions,
		federalDistrictOptions,
		municipalityOptions,
		sectionOptions,
		politicalPartiesOptions,
		typeAffiliateOptions,
		specialGroupOptions
	};

	const toggle = () => setModal(!modal);

	const handleApplyFilter = () => {
		setVotersData([]);
		let filter = {};
		let order = {};

		if (selectedOrder) {
			let orderValue = selectedOrder.value;

			switch (orderValue) {
				case 'aToZ':
					order.NAME = 1;
					break;

				case 'zToA':
					order.NAME = -1;
					break;

				case 'majorToMinor':
					order.AGE = -1;
					break;

				case 'minorToMajor':
					order.AGE = 1;
					break;

				default:
					order.NAME = 1;
					break;
			}

			setSortObj(order);
		} else {
			setSortObj({ NAME: 1 });
		}

		let ocr = {};

		if (search && search !== '') ocr.full_name = search;

		if (selectedGender && selectedGender.length > 0)
			ocr.gender = selectedGender.map((gender) => gender.value.toString());

		if (selectedState) ocr.state = [selectedState.label];

		if (selectedTypeAffiliate) filter.typeAffiliate = selectedTypeAffiliate.value;

		if (selectedSpecialGroup) filter.nameSpecialGroups = selectedSpecialGroup.value;

		if (selectedLocaleDistrict.length > 0)
			ocr.local_district = selectedLocaleDistrict.map((lDistrict) => lDistrict.value.toString());

		if (selectedFederalDistrict.length > 0)
			ocr.district = selectedFederalDistrict.map((fDistrict) => fDistrict.value.toString());

		if (selectedMunicipality.length > 0)
			ocr.municipality = selectedMunicipality.map((municipality) => municipality.value.toString());

		if (selectedSection.length > 0) ocr.section = selectedSection.map((section) => section.value.toString());

		const age = hasSelectedAge ? [selectedAge.min.toString(), selectedAge.max.toString()] : null;

		const createdAt = hasSelectedDate ? new Date(selectedDate).toISOString().split('T')[0] : null;

		if (Object.keys(ocr).length > 0) filter.ocr = ocr;

		if (createdAt) filter.createdAt = createdAt;

		if (age) filter.age = age;

		if (selectedPoliticalParty && selectedPoliticalParty !== null)
			filter.affiliate_fields = selectedPoliticalParty.value;

		setFilterParams(filter);

		setModal(false);

		handlePerRowsChange(perPage, 1);

		getElectores();
		if (selectedState) {
			drawOnMap();
		}
	};

	const filterHandlers = {
		handleApplyFilter,
		handleSelectedStateChange
	};

	const drawOnMap = () => {
		const stateCode = selectedState.value.toString();
		const stateData = mapSetup.find((x) => x.id === stateCode);

		let demarcationsArr = [];
		let demarcationType = '';

		if (selectedLocaleDistrict.length > 0) {
			demarcationsArr = selectedLocaleDistrict.map((demarcation) => demarcation.value);
			demarcationType = 'distrito_local';
		}

		if (selectedFederalDistrict.length > 0) {
			demarcationsArr = selectedFederalDistrict.map((demarcation) => demarcation.value);
			demarcationType = 'distrito';
		}

		if (selectedMunicipality.length > 0) {
			demarcationsArr = selectedMunicipality.map((demarcation) => demarcation.value);
			demarcationType = 'municipio';
		}

		if (selectedSection.length > 0) {
			demarcationsArr = selectedSection.map((demarcation) => demarcation.value);
			demarcationType = 'seccion';
		}

		if (stateCode && demarcationsArr.length > 0) {
			drawDemarcationOnMap({ demarcations: demarcationsArr, demarcationType: demarcationType }, stateData);
		}
	};

	const popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		anchor: 'bottom'
	});

	const getVoterPercentageColor = (votersPercentage) => {
		switch (true) {
			default:
			case votersPercentage >= 0 && votersPercentage <= 10:
				return { fillColor: 'rgba(218, 59, 58, 0.3)', outlineColor: '#333' };
			case votersPercentage > 11 && votersPercentage <= 20:
				return { fillColor: 'rgba(224, 98, 46, 0.3)', outlineColor: '#333' };
			case votersPercentage > 21 && votersPercentage <= 30:
				return { fillColor: 'rgba(230, 137, 35, 0.3)', outlineColor: '#333' };
			case votersPercentage > 31 && votersPercentage <= 40:
				return { fillColor: 'rgba(235, 177, 23, 0.3)', outlineColor: '#333' };
			case votersPercentage > 41 && votersPercentage <= 50:
				return { fillColor: 'rgba(241, 216, 12, 0.3)', outlineColor: '#333' };
			case votersPercentage > 51 && votersPercentage <= 60:
				return { fillColor: 'rgba(217, 224, 44, 0.3)', outlineColor: '#333' };
			case votersPercentage > 61 && votersPercentage <= 70:
				return { fillColor: 'rgba(193, 232, 75, 0.3)', outlineColor: '#333' };
			case votersPercentage > 71 && votersPercentage <= 80:
				return { fillColor: 'rgba(168, 239, 107, 0.3)', outlineColor: '#333' };
			case votersPercentage > 81 && votersPercentage <= 90:
				return { fillColor: 'rgba(144, 247, 138, 0.3)', outlineColor: '#333' };
			case votersPercentage > 91 && votersPercentage <= 100:
				return { fillColor: 'rgba(120, 255, 170, 0.3)', outlineColor: '#333' };
		}
	};

	let root;

	const drawDemarcationOnMap = (demarcationObj, stateData) => {
		const demarcation =
			demarcationObj.demarcationType === 'distrito_local' ? 'DISTRITO_L' : demarcationObj.demarcationType;
		const uniqueDemarcationsArr = [...new Set(demarcationObj.demarcations)];
		const modelFilter = ['all', ['match', ['get', demarcation], uniqueDemarcationsArr, true, false]];
		const sourceObj = stateData?.sources?.find((x) => x.type === demarcationObj.demarcationType);

		map.setStyle(stateData.style)
			.once('styledata', () => {
				map.setLayoutProperty(sourceObj.source, 'visibility', 'visible')
					.setFilter(sourceObj.source, modelFilter)
					.easeTo({
						zoom: 7,
						center: [stateData.location?.lng, stateData.location?.lat],
						curve: 1.5,
						speed: 0.5
					});
			})
			.once('moveend', () => {
				setTimeout(() => {
					let filterGoal = {};
					filterGoal.state = selectedState.value.toString();
					setFilterParamsVoterGoals(filterGoal);

					getVoterGoals().then((response) => {
						let goals = response.data.goalGetAll.items;

						let features = map.querySourceFeatures('composite', {
							filter: modelFilter,
							sourceLayer: sourceObj.source,
							validate: false
						});

						if (features.length) {
							const bboxPolygons = bbox({
								type: 'FeatureCollection',
								features: features
							});

							features.forEach((feature) => {
								let demarcation;
								if (demarcationObj.demarcationType === 'seccion') {
									demarcation = feature.properties.seccion;
								} else if (demarcationObj.demarcationType === 'distrito') {
									demarcation = feature.properties.distrito;
								} else if (demarcationObj.demarcationType === 'DISTRITO_L') {
									demarcation = feature.properties.DISTRITO_L;
								} else if (demarcationObj.demarcationType === 'municipio') {
									demarcation = feature.properties.municipio;
								}

								let votersPercentage = 0;
								let recordFound;

								if (goals && goals.length > 0) {
									recordFound = goals.find((goal) => Number(goal.indicator) === demarcation);
								}

								if (recordFound) {
									const avg = recordFound?.avg?.slice(0, -1) || 0;
									votersPercentage = Number(avg);
								}

								const { fillColor, outlineColor } = getVoterPercentageColor(votersPercentage);

								map.setFeatureState(
									{ source: 'composite', sourceLayer: sourceObj.source, id: feature.id },
									{ population: votersPercentage }
								)
									.setPaintProperty(sourceObj.source, 'fill-color', fillColor)
									.setPaintProperty(sourceObj.source, 'fill-outline-color', outlineColor);
							});

							map.fitBounds(bboxPolygons, {
								padding: { top: 10, bottom: 25, left: 15, right: 5 },
								maxZoom: uniqueDemarcationsArr.length > 1 ? 10.7 : 12
							});

							const ref = React.createRef();
							ref.current = document.createElement('div');

							map.on('mousemove', sourceObj.source, (e) => {
								let votersPercentage = 0;
								let target = null;
								let qtyVoters = null;
								let recordFound;

								if (goals && goals.length > 0) {
									recordFound = goals.find((goal) => Number(goal.indicator) === demarcation);
								}

								if (recordFound) {
									const avg = recordFound?.avg?.slice(0, -1) || 0;
									votersPercentage = Number(avg);
									target = recordFound.qtyElectors;
									qtyVoters = (votersPercentage / 100) * target;
								}

								const coordinates = e.lngLat;

								let territory;

								if (demarcation === 'entidad' || demarcation === 'municipio') {
									territory = e.features[0].properties.nombre;
								} else {
									territory = e.features[0].properties[demarcation];
								}

								if (!root) root = createRoot(ref.current);

								const closePopup = () => {
									popup.remove();
								};

								root.render(
									<PopupMap
										color={e.features[0].layer.paint['fill-color']}
										territory={territory}
										demarcationType={getDemarcationType(demarcationObj.demarcationType)}
										voters={qtyVoters}
										target={target}
										percentage={votersPercentage}
										closePopup={closePopup}
									/>
								);

								map.getCanvas().style.cursor = 'pointer';

								popup.setLngLat(coordinates).setDOMContent(ref.current).addTo(map);
							});

							map.on('mouseleave', sourceObj.source, () => {
								popup.remove();
								map.getCanvas().style.cursor = '';
							});

							map.fitBounds(bboxPolygons, {
								padding: { top: 10, bottom: 25, left: 15, right: 5 },
								maxZoom: uniqueDemarcationsArr.length > 1 ? 10.7 : 12
							});
						}
					});
				}, 1000);
			});
	};

	useEffect(() => {
		if (states) {
			const mappedStates = Object.values(states || [])?.map((entity) => {
				return {
					label: entity.name,
					value: entity.code
				};
			});
			setStateOptions(mappedStates);
		}
	}, [states]);

	useEffect(() => {
		if (politicalParties) {
			const mappedPoliticalParties = Object.values(politicalParties.results || [])?.map((politicalParty) => {
				return {
					value: politicalParty.name,
					label: (
						<div>
							<img
								src={politicalParty.img}
								height="20px"
								width="20px"
								className={'mr-2'}
								alt={`political-party-${politicalParty.id}`}
							/>
							{politicalParty.abbreviation}{' '}
						</div>
					)
				};
			});
			mappedPoliticalParties.push({
				value: '',
				label: (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '5px'
						}}
					>
						<div
							style={{
								width: '20px',
								height: '20px',
								background: '#AFAFAF',
								opacity: '0.4',
								borderRadius: '5px'
							}}
						>
							<Slash size={20} color="white" />
						</div>
						{'Sin militancia'}{' '}
					</div>
				)
			});
			setPoliticalPartiesOptions(mappedPoliticalParties);
		}
	}, [politicalParties]);

	useEffect(() => {
		if (typeAffiliate) {
			const mappedTypeAffiliate = Object.values(typeAffiliate.results || [])?.map((type) => {
				return {
					value: type.name,
					label: type.name
				};
			});
			setTypeAffiliateOptions(mappedTypeAffiliate);
		}
	}, [typeAffiliate]);

	useEffect(() => {
		if (specialGroups) {
			const mappedSpecialGroups = Object.values(specialGroups.results || [])?.map((type) => {
				return {
					value: type.name,
					label: type.name
				};
			});
			setSpecialGroupOptions(mappedSpecialGroups);
		}
	}, [specialGroups]);

	useEffect(() => {
		if (items) {
			setLoadingAffiliates(true);
			mapVotersData(items, selectedOrder)
				.then((res) => setVotersData(res || []))
				.finally(() => setLoadingAffiliates(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, filterParams]);

	return (
		<Fragment>
			<Breadcrumb parent="Mapas" title="Electores" />
			<Container fluid={true}>
				<Row>
					{loadingStates ? (
						<InternalLoader />
					) : (
						<Col sm="12">
							<Card>
								<CardHeader style={{ padding: '32px 40px' }}>
									<Row className="electores-header">
										<Col>
											{loadingAffiliates ? (
												<h5>Calculando...</h5>
											) : (
												<h5>
													{count && count !== 0
														? count + ' Resultados'
														: 'Seleccione filtros para buscar'}{' '}
												</h5>
											)}
										</Col>
										<Col className="filter-elector-headers">
											<Col
												lg="5"
												sm="6"
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													height: '100%',
													padding: '0px 4px'
												}}
											>
												<div>
													<button
														className="btn btn-primary"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															fontWeight: '400',
															fontSize: '12px',
															lineHeight: '14px',
															padding: '8px 16px'
														}}
														onClick={toggle}
													>
														<span>Filtrar y Ordenar</span>
														<Filter size={16} />
													</button>
												</div>
											</Col>
											<Col lg="7" sm="6" style={{ height: '100%', padding: '0px 4px' }}>
												<InputGroup className="search-bar">
													<Input
														placeholder="Buscar por..."
														className="pl-4 search-input"
														onChange={(e) => setSearch(e.target.value)}
														value={search}
													/>
													<Search
														style={{
															position: 'absolute',
															left: '6px',
															bottom: '10px',
															color: 'gray',
															zIndex: 10
														}}
														size={16}
													/>
													<Button
														className="search-elector-button"
														color="primary"
														onClick={() => handleApplyFilter()}
													>
														Buscar
													</Button>
												</InputGroup>
											</Col>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					)}
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="mapContainer" ref={mapContainer} />
								{loadingAffiliates ? (
									<InternalLoader />
								) : (
									<div className="table-responsive product-table">
										<DataTable
											noHeader
											customStyles={customStyles}
											columns={columns}
											data={votersData}
											progressComponent={<InternalLoader />}
											progressPending={loading}
											highlightOnHover
											paginationComponentOptions={{
												rowsPerPageText: 'Electores por página:',
												rangeSeparatorText: 'de'
											}}
											pagination
											paginationServer
											paginationTotalRows={count}
											onChangeRowsPerPage={handlePerRowsChange}
											onChangePage={handlePageChange}
											paginationDefaultPage={page}
											paginationPerPage={perPage}
											noDataComponent={'No se han encontrado electores'}
										/>
									</div>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<FilterVotersModal
				isOpen={modal}
				toggle={toggle}
				filterStates={filterStates}
				filterOptions={filterOptions}
				filterHandlers={filterHandlers}
			/>
		</Fragment>
	);
};

export default Voters;
