import { put, takeLatest } from 'redux-saga/effects';
import { GET_VOTER_LIST } from '../actionTypes';
import { fecthVotersListSuccess, fetchVotersError, isLoading } from '../actions';
import { gql } from '@apollo/client';
import { graphQlClient } from '../../store/graphql';
import { ErrorHandler } from '../errorHandler';

function* fetchVoters({limit = 100, skip = 0}) {
	try {
		yield put(isLoading(true));
		const res = yield graphQlClient.query({
			query: gql`
				query ineMany {
					ineMany(limit:${limit}, skip:${skip}) {
						_id
						fields
						features
						model {
							name
							confidence
							type
						}
						ocr
						userId
						user
						status
						phoneNumber
						scanLocation {
						  lat
						  lng
						}
						updatedAt
						createdAt
						need
					}
				}
			`
		});

		yield put(isLoading(false));
		yield put(fecthVotersListSuccess(res.data.ineMany));
	} catch (e) {
		ErrorHandler(e);
		yield put(isLoading(false));
		yield put(fetchVotersError());
	}
}

export function* Voters() {
	yield takeLatest(GET_VOTER_LIST, fetchVoters);
}
