/* CUSTOMIZER */
export const ADD_COSTOMIZER = '[CUSTOMIZER] ADD_COSTOMIZER';
export const ADD_LAYOUT = '[CUSTOMIZER] ADD_LAYOUT';
export const ADD_SIDEBAR_TYPES = '[CUSTOMIZER] ADD_SIDEBAR_TYPES';
export const ADD_SIDEBAR_SETTINGS = '[CUSTOMIZER] ADD_SIDEBAR_SETTINGS';
export const ADD_COLOR = '[CUSTOMIZER] ADD_COLOR';
export const ADD_MIX_BACKGROUND_LAYOUT = '[CUSTOMIZER] ADD_MIX_BACKGROUND_LAYOUT';
export const ROUTER_ANIMATION = '[CUSTOMIZER] ROUTER_ANIMATION';

// Voters
export const GET_VOTER_LIST = '[VOTERS] GET_VOTER_LIST';
export const GET_VOTER_LIST_ERROR = '[VOTERS] GET_VOTER_LIST_ERROR';
export const GET_VOTER_LIST_SUCCESS = '[VOTERS] GET_VOTER_LIST_SUCCESS';

// User
export const LOGIN = '[USER] LOGIN';
export const LOGIN_SUCCESS = '[USER] LOGIN_SUCCESS';
export const LOGIN_ERROR = '[USER] LOGIN_ERROR';
export const LOGOUT = '[USER] LOGOUT';
export const LOGOUT_SUCCESS = '[USER] LOGOUT_SUCCESS';
export const GET_ME = '[USER] GET_ME';
export const GET_ME_SUCCESS = '[USER] GET_ME_SUCCESS';
export const GET_ME_ERROR = '[USER] GET_ME_ERROR';

// UI
export const IS_LOADING = '[UI] IS_LOADING';

//users
export const CREATE_USER = '[USERS] CREATE_USER';
export const CREATE_USER_SUCCESS = '[USERS] CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = '[USERS] CREATE_USER_ERROR';
export const CLEAN_CREATE_USER_STORE = '[USERS] CLEAN_CREATE_USER_STORE';
export const GET_USER_LIST = '[USERS] GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = '[USERS] GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_ERROR = '[USERS] GET_USER_LIST_ERROR';
export const EDIT_USER = '[USERS] EDIT_USER';
export const EDIT_USER_SUCCESS = '[USERS] EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = '[USERS] EDIT_USER_ERROR';
export const CLEAN_EDIT_USER_STORE = '[USERS] CLEAN_EDIT_USER_STORE';

//reports
export const GET_WEEKLY_REPORT = '[REPORTS] GET_WEEKLY_REPORT';
export const GET_WEEKLY_REPORT_SUCCESS = '[REPORTS] GET_WEEKLY_REPORT_SUCCESS';
export const GET_WEEKLY_REPORT_ERROR = '[REPORTS] GET_WEEKLY_REPORT_ERROR';

//create goal
export const CREATE_GOAL_LOADING = '[REPORTS] CREATE_GOAL_LOADING';
export const CREATE_GOAL = '[REPORTS] CREATE_GOAL';
export const CREATE_GOAL_SUCCESS = '[REPORTS] CREATE_GOAL_SUCCESS';
export const CREATE_GOAL_ERROR = '[REPORTS] CREATE_GOAL_ERROR';

//getGoals
export const GET_GOALS = '[REPORTS] GET_GOALS';
export const GET_GOALS_SUCCESS = '[REPORTS] GET_GOALS_SUCCESS';
export const GET_GOALS_ERROR = '[REPORTS] GET_GOALS_ERROR';

//get current goal
export const GET_CURRENT_GOAL = '[REPORTS] GET_CURRENT_GOAL';
export const GET_CURRENT_GOAL_SUCCESS = '[REPORTS] GET_CURRENT_GOAL_SUCCESS';
export const GET_CURRENT_GOAL_ERROR = '[REPORTS] GET_CURRENT_GOAL_ERROR';

// 2FA
export const MOVE_2FA = '[2FA] MOVE_2FA';
export const REMOVE_2FA_TOKEN = '[2FA] REMOVE_2FA_TOKEN';
export const REMOVE_2FA_TOKEN_ERROR = '[2FA] REMOVE_2FA_TOKEN_ERROR';
export const REMOVE_2FA_TOKEN_SUCCESS = '[2FA] REMOVE_2FA_TOKEN_SUCCESS';
export const GENERATE_QR_CODE = '[2FA] GENERATE_QR_CODE';
export const GENERATE_QR_CODE_SUCCESS = '[2FA] GENERATE_QR_CODE_SUCCESS';
export const GENERATE_QR_CODE_ERROR = '[2FA] GENERATE_QR_CODE_ERROR';
export const VERIFY_2FA_CODE = '[2FA] VERIFY_2FA_TOKEN';
export const VERIFY_2FA_CODE_SUCCESS = '[2FA] VERIFY_2FA_CODE_SUCCESS';
export const VERIFY_2FA_CODE_ERROR = '[2FA] VERIFY_2FA_CODE_ERROR';

//create need
export const CREATE_NEED_LOADING = '[REPORTS] CREATE_NEED_LOADING';
export const CREATE_NEED = '[REPORTS] CREATE_NEED';
export const CREATE_NEED_SUCCESS = '[REPORTS] CREATE_NEED_SUCCESS';
export const CREATE_NEED_ERROR = '[REPORTS] CREATE_NEED_ERROR';

//edit need
export const EDIT_NEED_LOADING = '[REPORTS] EDIT_NEED_LOADING';
export const EDIT_NEED = '[REPORTS] EDIT_NEED';
export const EDIT_NEED_SUCCESS = '[REPORTS] EDIT_NEED_SUCCESS';
export const EDIT_NEED_ERROR = '[REPORTS] EDIT_NEED_ERROR';

//getNeeds
export const GET_NEEDS = '[REPORTS] GET_NEEDS';
export const GET_NEEDS_SUCCESS = '[REPORTS] GET_NEEDS_SUCCESS';
export const GET_NEEDS_ERROR = '[REPORTS] GET_NEEDS_ERROR';
