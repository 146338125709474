import React, { Fragment, useEffect } from 'react';
import Header from '../../layout/header';
// import SidebarAccount from '../../layout/sidebar-account';
import Sidebar from 'layout/sidebar';
// import Footer from '../../layout/footer';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getMeUser } from 'redux/actions';

const Account = ({ children }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getMeUser());
	}, [dispatch]);

	return (
		<Fragment>
			<div className="page-wrapper compact-wrapper" id="pageWrapper">
				<Header />
				<div className="page-body-wrapper sidebar-icon">
					{/* <SidebarAccount />  */}
					<Sidebar />
					<div className="page-body">{children}</div>
					{/* <Footer /> */}
				</div>
			</div>
			<ToastContainer />
		</Fragment>
	);
};

export default Account;
