import React from 'react';
import { X } from 'react-feather';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const DemarcacionModal = ({ isOpen, toggle, data }) => {
	const { entities, sections, fDistricts, lDistricts, municipalities } = data;

	const entitiesInfo = [...new Set(Object.values(entities || {})?.map((entity) => entity.entity_name))];

	const municipalitiesInfo = [
		...new Set(Object.values(municipalities || {})?.map((municipality) => municipality.municipality))
	];

	const lDistrictsInfo = [
		...new Set(Object.values(lDistricts || {})?.map((lDistrict) => lDistrict.local_district_code))
	];

	const fDistrctsInfo = [...new Set(Object.values(fDistricts || {})?.map((fDistrict) => fDistrict.district_code))];

	const sectionsInfo = [...new Set(Object.values(sections || {})?.map((section) => section.section))];

	return (
		<Modal isOpen={isOpen} toggle={toggle} centered>
			<ModalHeader toggle={toggle}>Demarcación de territorio</ModalHeader>
			<ModalBody className="electors-filter-modal">
				<div className="selected-sections">
					{entitiesInfo.length > 0 ||
					sections?.length > 0 ||
					municipalities?.length > 0 ||
					fDistricts?.length > 0 ||
					lDistricts?.length > 0 ? (
						<Container fluid className="p-0">
							<Row className="selected-actions-body m-0">
								{entitiesInfo?.length > 0 && (
									<div className="selected-items">
										<div className="items-header">
											<h5 className="optionTitle">Estado</h5>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<Col lg={8} className="p-0">
												<ul className="list-horizontal">
													{entitiesInfo.map((entity, index) => {
														return (
															<li key={`section-${index}`}>
																{`${entity}`} <X size="14" />
															</li>
														);
													})}
												</ul>
											</Col>
											<Col
												lg={4}
												style={{
													display: 'flex',
													justifyContent: 'end',
													width: '100%'
												}}
												className="p-0"
											>
												<span
													style={{
														fontWeight: '400',
														fontSize: '12px',
														lineHeight: '14px',
														color: '#AFAFAF'
													}}
												>{`${entitiesInfo.length} demarcaciones`}</span>
											</Col>
										</div>
									</div>
								)}
							</Row>
							<Row className="selected-actions-body m-0">
								{sectionsInfo?.length > 0 && (
									<div className="selected-items">
										<div className="items-header">
											<h5 className="optionTitle">Sección</h5>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<Col lg={8} className="p-0">
												<ul className="list-horizontal">
													{sectionsInfo.map((section, index) => {
														return (
															<li key={`section-${index}`}>
																{`${section}`} <X size="14" />
															</li>
														);
													})}
												</ul>
											</Col>
											<Col
												lg={4}
												style={{
													display: 'flex',
													justifyContent: 'end',
													width: '100%'
												}}
												className="p-0"
											>
												<span
													style={{
														fontWeight: '400',
														fontSize: '12px',
														lineHeight: '14px',
														color: '#AFAFAF'
													}}
												>{`${sectionsInfo.length} demarcaciones`}</span>
											</Col>
										</div>
									</div>
								)}
							</Row>
							<Row className="selected-actions-body m-0">
								{municipalitiesInfo?.length > 0 && (
									<div className="selected-items">
										<div className="items-header">
											<h5 className="optionTitle">Municipios</h5>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<Col lg={8} className="p-0">
												<ul className="list-horizontal">
													{municipalitiesInfo.map((municipality, index) => {
														return (
															<li key={`municipalities-${index}`}>
																{`${municipality}`} <X size="14" />
															</li>
														);
													})}
												</ul>
											</Col>
											<Col
												lg={4}
												style={{
													display: 'flex',
													justifyContent: 'end',
													width: '100%'
												}}
												className="p-0"
											>
												<span
													style={{
														fontWeight: '400',
														fontSize: '12px',
														lineHeight: '14px',
														color: '#AFAFAF'
													}}
												>{`${municipalitiesInfo.length} demarcaciones`}</span>
											</Col>
										</div>
									</div>
								)}
							</Row>
							<Row className="selected-actions-body m-0">
								{lDistrictsInfo?.length > 0 && (
									<div className="selected-items">
										<div className="items-header">
											<h5 className="optionTitle">Distritos Locales</h5>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<Col lg={8} className="p-0">
												<ul className="list-horizontal">
													{lDistrictsInfo.map((lDistrict, index) => {
														return (
															<li key={`lDistrict-${index}`}>
																{`${lDistrict}`} <X size="14" />
															</li>
														);
													})}
												</ul>
											</Col>
											<Col
												lg={4}
												style={{
													display: 'flex',
													justifyContent: 'end',
													width: '100%'
												}}
												className="p-0"
											>
												<span
													style={{
														fontWeight: '400',
														fontSize: '12px',
														lineHeight: '14px',
														color: '#AFAFAF'
													}}
												>{`${lDistrictsInfo.length} demarcaciones`}</span>
											</Col>
										</div>
									</div>
								)}
							</Row>
							<Row className="selected-actions-body m-0">
								{fDistrctsInfo?.length > 0 && (
									<div className="selected-items">
										<div className="items-header">
											<h5 className="optionTitle">Distritos Federales</h5>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<Col lg={8} className="p-0">
												<ul className="list-horizontal">
													{fDistrctsInfo.map((fDistrict, index) => {
														return (
															<li key={`fDistrict-${index}`}>
																{`${fDistrict}`} <X size="14" />
															</li>
														);
													})}
												</ul>
											</Col>
											<Col
												lg={4}
												style={{
													display: 'flex',
													justifyContent: 'end',
													width: '100%'
												}}
												className="p-0"
											>
												<span
													style={{
														fontWeight: '400',
														fontSize: '12px',
														lineHeight: '14px',
														color: '#AFAFAF'
													}}
												>{`${fDistrctsInfo.length} demarcaciones`}</span>
											</Col>
										</div>
										{/* <Row>
											<Col lg={8}>
												<ul className="list-horizontal">
													{fDistricts.map((fDistrict) => {
														return (
															<li key={`fDistrict-${fDistrict.fDistrict}`}>
																{`${fDistrict.fDistrict}`} <X size="14" />
															</li>
														);
													})}
												</ul>
											</Col>
											<Col style={{ display: 'flex', justifyContent: 'end' }}>
												<span
													style={{
														fontWeight: '400',
														fontSize: '12px',
														lineHeight: '14px',
														color: '#AFAFAF'
													}}
												>{`${fDistricts.length} demarcaciones`}</span>
											</Col>
										</Row> */}
									</div>
								)}
							</Row>
						</Container>
					) : (
						<span style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
							No tiene demarcaciones asignadas
						</span>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
};

export default DemarcacionModal;
