import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from 'utils/axiosInstance';

// pending note ->All this logic needs a refactor

export function useStructure(structureId) {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState();
	const [levels, setLevels] = useState([]);

	useEffect(() => {
		if (structureId) {
			setLoading(true);
			axiosInstance({ method: 'GET', url: `/activism/estructure/${structureId}/` })
				.then((response) => {
					setData(response.data);
					for (const i of response.data.levels) {
						axiosInstance({ method: 'GET', url: `/activism/level/${i.id}/` })
							.then((res) => setLevels((prev) => [...prev, res.data]))
							.catch((err) => console.log(err));
					}
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => setLoading(false));
		}
	}, [structureId]);

	return { loading, data, levels };
}

export function useAffiliatesByLevel({ levelId, page, perPage } = {}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (levelId) {
			setLoading(true);
			axiosInstance({
				method: 'GET',
				url: `/activism/associate-affiliates/?fk_level=${levelId}&page=${page}&size=${perPage}`
			})
				.then((res) => setData(res.data))
				.catch((err) => console.log(err))
				.finally(() => setLoading(false));
		}
	}, [levelId, page, perPage]);

	return { loading, data };
}

export function usePyramids({ page, perPage } = {}) {
	const [loading, setLoading] = useState(true);
	const [searchParams] = useSearchParams();
	const [data, setData] = useState({});
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	useEffect(() => {
		setLoading(true);

		axiosInstance({ method: 'GET', url: `/activism/estructure/?page=${currentPage}&size=${currentPerPage}` })
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error({ error });
			})
			.finally(() => setLoading(false));
	}, [currentPage, currentPerPage]);

	return { loading, data };
}

export const createPyramid = ({
	levels_number,
	name,
	description,
	entities,
	districts,
	districts_local,
	municipalities,
	sections
}) =>
	axiosInstance({
		// example submit data
		// {
		//   "levels_number": 0,
		//   "name": "string",
		//   "description": "string",
		//   "entities": [ 0 ],
		//   "districts": [ 0 ],
		//   "districts_local": [ 0 ],
		//   "municipalities": [ 0 ],
		//   "sections": [ 0 ]
		// }
		method: 'post',
		url: `/activism/estructure/`,
		data: {
			levels_number,
			name,
			description,
			entities,
			districts,
			districts_local,
			municipalities,
			sections
		}
		// example response data
		// {
		//   "id": 0,
		//   "related_sections": "string",
		//   "related_municipality": "string",
		//   "related_districts_local": "string",
		//   "related_districts": "string",
		//   "related_entities": "string",
		//   "meta_total": "string",
		//   "total_registered": "string",
		//   "total_percent_registered": "string",
		//   "levels_number": 0,
		//   "name": "string",
		//   "description": "string",
		//   "created_at": "2023-02-24T03:28:18.961Z",
		//   "updated_at": "2023-02-24T03:28:18.961Z",
		//   "entities": [  0 ],
		//   "districts": [  0 ],
		//   "districts_local": [ 0 ],
		//   "municipalities": [ 0  ],
		//   "sections": [ 0 ]
		// }
	});
