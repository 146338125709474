import React, { Fragment, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import bbox from '@turf/bbox';

import Accordion from '@material-ui/core/Accordion';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';

import Breadcrumb from '../../../layout/breadcrumb';
import LocationsFilter from './locations-filter';
import CustomFilter from './custom-filter';
import SelectElectorFilter from './select-elector-filter';
import { GET_VOTER_LIST } from '../../../redux/actionTypes';
import { mapSetup } from '../../../data/map-setup';

import getVotersData from '../../../utils/mapVoters';
import { getINETableHeaders } from '../../../utils/getINETableHeaders';
import { useMap } from '../../../utils/useMap';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import PopupMap from './popup-map';

const Analisis = () => {
	const mapContainer = useRef();
	const dispatch = useDispatch();
	const { map, resetInitialMapSetup } = useMap(mapContainer);
	const [filterSelectorValues, setFilterSelectorValues] = useState([]);
	const [results, setResults] = useState([]);

	const loading = useSelector((state) => state.UI.isLoading);
	const voters = useSelector((state) => state.Voters.voters);

	const defaultColor = '#A1CDE1';

	const target = 50;

	useEffect(() => {
		dispatch({ type: GET_VOTER_LIST });
	}, [dispatch]);

	const getVotersBySection = () => {
		return results.map((section) => {
			if (section.voters.length > 0) {
				return (
					<Accordion key={section.section} className="mt-2">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<span className="section-title">{`Sección: ${section.section} `}</span>
							<span className="voters-by-section">{`Número de registros: ${section.voters.length}`}</span>
						</AccordionSummary>
						<AccordionDetails>
							<DataTable noHeader columns={getINETableHeaders()} data={getVotersData(section.voters)} />
						</AccordionDetails>
					</Accordion>
				);
			}
			return (
				<Accordion className="mt-2">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<span className="section-title no-result">{`Sección: ${section.section} `}</span>
						<span className="voters-by-section no-result">{`Sin registros`}</span>
					</AccordionSummary>
				</Accordion>
			);
		});
	};

	const popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		anchor: 'bottom'
	});

	const drawSectionOnMap = (sections, estadoData, votersBySection) => {
		const uniqueSections = [...new Set(sections)];
		const modelFilter = ['all', ['match', ['get', 'seccion'], uniqueSections, true, false]];
		const sourceObj = estadoData?.sources?.find((x) => x.type === 'seccion');

		map.setStyle(estadoData.style)
			.once('styledata', () => {
				map.setLayoutProperty(sourceObj.source, 'visibility', 'visible')
					.setFilter(sourceObj.source, modelFilter)
					.easeTo({
						zoom: 9,
						center: [estadoData.location?.lng, estadoData.location?.lat],
						curve: 1.5,
						speed: 0.5
					});
			})
			.once('moveend', () => {
				setTimeout(() => {
					const features = map.querySourceFeatures('composite', {
						filter: modelFilter,
						sourceLayer: sourceObj.source,
						validate: false
					});
					if (features.length) {
						const bboxPolygons = bbox({
							type: 'FeatureCollection',
							features: features
						});

						map.fitBounds(bboxPolygons, {
							padding: { top: 10, bottom: 25, left: 15, right: 5 },
							maxZoom: uniqueSections.length > 1 ? 10.7 : 12
						}).setPaintProperty(sourceObj.source, 'fill-color', defaultColor);

						let hoveredSection = null;

						map.on('mousemove', sourceObj.source, (e) => {
							const section = e.features[0].properties.seccion;
							const sectionVoters = votersBySection.find((seccion) => seccion.section === section).voters;
							const porcentajeVotantes = (sectionVoters / target) * 100;
							const coordinates = e.lngLat;
							let color;

							const ref = React.createRef();
							ref.current = document.createElement('div');

							const closePopup = () => {
								popup.remove();
							};

							ReactDOM.render(
								<PopupMap
									demarcation={section}
									demarcationType={'Sección'}
									voters={sectionVoters}
									target={target}
									percentage={porcentajeVotantes}
									closePopup={closePopup}
								/>,
								ref.current
							);

							switch (true) {
								case porcentajeVotantes < 10:
									color = '#78FFAA';
									break;
								case porcentajeVotantes < 20:
									color = '#90F78A';
									break;
								case porcentajeVotantes < 30:
									color = '#A8EF6B';
									break;
								case porcentajeVotantes < 40:
									color = '#C1E84B';
									break;
								case porcentajeVotantes < 50:
									color = '#D9E02C';
									break;
								case porcentajeVotantes < 60:
									color = '#F1D80C';
									break;
								case porcentajeVotantes < 70:
									color = '#EBB117';
									break;
								case porcentajeVotantes < 80:
									color = '#E68923';
									break;
								case porcentajeVotantes < 90:
									color = '#E0622E';
									break;
								default:
									color = '#DA3B3A';
									break;
							}

							if (hoveredSection !== null) {
								map.setFeatureState(
									{ source: 'composite', sourceLayer: sourceObj.source, id: hoveredSection },
									{ hover: false }
								).setPaintProperty(sourceObj.source, 'fill-color', [
									'case',
									['boolean', ['feature-state', 'hover'], false],
									defaultColor,
									defaultColor
								]);
							}
							hoveredSection = e.features[0].id;
							map.setFeatureState(
								{ source: 'composite', sourceLayer: sourceObj.source, id: hoveredSection },
								{ hover: true }
							).setPaintProperty(sourceObj.source, 'fill-color', [
								'case',
								['boolean', ['feature-state', 'hover'], false],
								color,
								defaultColor
							]);

							map.getCanvas().style.cursor = 'pointer';

							map.on('click', sourceObj.source, (e) => {
								popup.setLngLat(coordinates).setDOMContent(ref.current).addTo(map);
							});
						});

						map.on('mouseleave', sourceObj.source, () => {
							if (hoveredSection !== null) {
								map.setFeatureState(
									{ source: 'composite', sourceLayer: sourceObj.source, id: hoveredSection },
									{ hover: false }
								).setPaintProperty(sourceObj.source, 'fill-opacity', 0.6);
							}
							hoveredSection = null;
							map.getCanvas().style.cursor = '';
						});
					}
				}, 1000);
			});
	};

	const changeSelectedVoter = (voter) => {
		const estadoCode = voter.ocr?.id_state;
		const estadoData = mapSetup.find((x) => x.id === estadoCode);
		const seccion = parseInt(voter.ocr?.section);
		const votersBySeccion = [
			{
				section: seccion,
				voters: voters.filter((voter) => seccion === parseInt(voter.ocr.section)).length
			}
		];

		if (estadoData) {
			drawSectionOnMap([Number(seccion)], estadoData, votersBySeccion);
		} else {
			resetInitialMapSetup();
		}
	};

	return (
		<Fragment>
			<Breadcrumb parent="Dashboard" title="Análisis" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<LocationsFilter
									map={map}
									filterSelectorValues={filterSelectorValues}
									setFilterSelectorValues={setFilterSelectorValues}
									resetInitialMapSetup={resetInitialMapSetup}
								/>
								<Accordion className="mt-2">
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<div className="filter-header">
											<AccountBoxIcon />
											<div>Ubicar a un elector</div>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<SelectElectorFilter
											changeSelectedVoter={changeSelectedVoter}
											loading={loading}
											voters={voters}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion className="mt-2">
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<div className="filter-header">
											<GroupIcon />
											<div>Filtrar Electores</div>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										{/* custom filter */}
										<CustomFilter
											drawSectionOnMap={drawSectionOnMap}
											resetInitialMapSetup={resetInitialMapSetup}
											setTableResults={setResults}
											voters={voters}
										/>
									</AccordionDetails>
								</Accordion>
							</CardHeader>
						</Card>
					</Col>

					<Col sm="12">
						<Card>
							<CardBody>
								<div className="mapContainer" ref={mapContainer} />
								{!!results.length && getVotersBySection()}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Analisis;
