import {useEffect, useState} from "react";
import {axiosInstance} from "../axiosInstance";


export function useGetTypeAffiliate() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        axiosInstance({
            method: 'GET',
            url: `/type-affiliate/`
        })
            .then((response) => {
                setData(response?.data);
            })
            .catch((error) => {
                console.error({ error });
            })
            .finally(() => setLoading(false));
    }, []);

    return { loading, data };
}
