import { Nav, NavItem, NavLink } from 'reactstrap';
import './tab.scss';

// const tabs = ['level 1', 'level 2']
// const tabs = [{key, label}]
export default function Tab({ activeTab, setActiveTab, tabs, small, gray }) {
	const className = small ? 'small' : '';

	return (
		<Nav tabs className={`tabsContainer ${gray ? 'grayBackground' : ''}`}>
			{tabs.map((tabItem, idx) => {
				const tabId = typeof tabItem === 'string' ? tabItem : tabItem?.key;
        const tabLavel = typeof tabItem === 'string' ? tabItem : tabItem?.label;
				return (
					<NavItem key={tabId}>
						<NavLink
							className={`${className} ${activeTab === tabId ? 'active' : ''}`}
							onClick={() => setActiveTab(tabId)}
						>
							{tabLavel}
						</NavLink>
					</NavItem>
				);
			})}
		</Nav>
	);
}
