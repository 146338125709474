import { useState } from 'react';

export function useMultiSelectState(defaultValue = [], setOnlyValue = false) {
	const [selectedOptions, setSelectedOptions] = useState([...defaultValue]);

	const handleChange = (options) => {
		if (Array.isArray(options)) {
			setSelectedOptions(
				options.map((opt) =>
					setOnlyValue
						? opt.value
						: {
								value: opt.value,
								label: opt.label,
								id: opt.id
						  }
				)
			);
		} else {
			setSelectedOptions([options]);
		}
	};

	return { selectedOptions, handleChange };
}
