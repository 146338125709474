import { Input, Label } from 'reactstrap';
import './checkbox.scss';
export default function Checkbox({ label, id, onChange,checked, disabled }) {
	const handleChange = (evt) => {
		onChange && onChange(evt.target.checked);
	};

	return (
		<div className="checkbox checkbox-solid-primary">
			<Input id={id || label} type="checkbox" onChange={handleChange} disabled={disabled} checked={!!checked}/>
			<Label className="custom-checkbox" for={id || label}>
				<span className="f-14 font-primary">{label}</span>
			</Label>
		</div>
	);
}
