import React from 'react';
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row} from 'reactstrap';
import Select from 'react-select';
import {X} from 'react-feather';
import InputRange from 'react-input-range';

const orderByOptions = [
    {label: 'Nombre A-Z', value: 'aToZ'},
    {label: 'Nombre Z-A', value: 'zToA'},
    {label: 'Mayor Edad', value: 'majorToMinor'},
    {label: 'Menor Edad', value: 'minorToMajor'}
];

const genders = [
    {label: 'Masculino', value: 'Male'},
    {label: 'Femenino', value: 'Female'}
];

const colourStyles = {
    option: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: 'white',
        '&:hover': {backgroundColor: '#F04A5E22'}
    }),
    control: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: '#FFF1F1'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: '#FFF1F1'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#F04A5E'
        };
    }
};

const FilterVotersModal = ({isOpen, toggle, filterStates, filterOptions, filterHandlers}) => {
    const {
        selectedOrder,
        setSelectedOrder,
        selectedState,
        setSelectedState,
        selectedMunicipality,
        setSelectedMunicipality,
        selectedLocaleDistrict,
        setSelectedLocaleDistrict,
        selectedFederalDistrict,
        setSelectedFederalDistrict,
        selectedSection,
        setSelectedSection,
        initialAge,
        selectedAge,
        setSelectedAge,
        setHasSelectedAge,
        selectedGender,
        setSelectedGender,
        selectedPoliticalParty,
        setSelectedPoliticalParty,
        selectedTypeAffiliate,
        setSelectedTypeAffiliate,
        selectedSpecialGroup,
        setSelectedSpecialGroup,
        setLocaleDistrictOptions,
        setFederalDistrictOptions,
        setMunicipalityOptions,
        setSectionOptions
    } = filterStates;

    const {
        loadingDemarcations,
        stateOptions,
        localeDistrictOptions,
        federalDistrictOptions,
        municipalityOptions,
        sectionOptions,
        politicalPartiesOptions,
        typeAffiliateOptions,
        specialGroupOptions
    } = filterOptions;

    const {handleApplyFilter, handleSelectedStateChange} = filterHandlers;

    const handleDemarcations = (type, value) => {

        if (type === "federalDistricts") {
            setSelectedFederalDistrict(value)
            setSelectedLocaleDistrict([])
            setSelectedMunicipality([])
            setSelectedSection([])
        }

        if (type === "localDistricts") {
            setSelectedLocaleDistrict(value)
            setSelectedFederalDistrict([])
            setSelectedMunicipality([])
            setSelectedSection([])
        }

        if (type === "municipality") {
            setSelectedMunicipality(value)
            setSelectedFederalDistrict([])
            setSelectedLocaleDistrict([])
            setSelectedSection([])
        }

        if (type === "sections") {
            setSelectedSection(value)
            setSelectedFederalDistrict([])
            setSelectedLocaleDistrict([])
            setSelectedMunicipality([])
        }

    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody className="electors-filter-modal">
                <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <X style={{cursor: 'pointer', color: 'gray'}} className="mr-2" onClick={toggle}/>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Ordenar por'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isClearable
                                name="selectedOrder"
                                id="selectedOrder"
                                options={orderByOptions}
                                onChange={(option) => {
                                    setSelectedOrder(option);
                                }}
                                placeholder={orderByOptions[0].label}
                                value={selectedOrder}
                                styles={colourStyles}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Estado'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isSearchable
                                isClearable
                                name="selectedState"
                                id="selectedState"
                                options={stateOptions}
                                onChange={handleSelectedStateChange}
                                placeholder={'Todos los estados'}
                                value={selectedState || ''}
                                styles={colourStyles}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'D. Federal'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedFederalDistrict}
                                closeMenuOnSelect={false}
                                options={federalDistrictOptions}
                                placeholder="Todas los D. Federales"
                                onChange={(e) => handleDemarcations("federalDistricts", e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'D. Local'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedLocaleDistrict}
                                closeMenuOnSelect={false}
                                options={localeDistrictOptions}
                                placeholder="Todas los D. Locales"
                                onChange={(e) => handleDemarcations("localDistricts", e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Municipio'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedMunicipality}
                                closeMenuOnSelect={false}
                                options={municipalityOptions}
                                placeholder="Todos los municipios"
                                onChange={(e) => handleDemarcations("municipality", e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Sección'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedSection}
                                closeMenuOnSelect={false}
                                options={sectionOptions}
                                placeholder="Todas las secciones"
                                onChange={(e) => handleDemarcations("sections", e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" sm="12">
                        <FormGroup>
                            <Label>{'Edad'}</Label>
                            <InputRange
                                maxValue={100}
                                minValue={16}
                                value={selectedAge}
                                onChange={(value) => {
                                    setSelectedAge(value);
                                    setHasSelectedAge(true);
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Militancia'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isClearable
                                name="selectedMilitancy"
                                id="selectedMilitancy"
                                options={politicalPartiesOptions}
                                onChange={(option) => {
                                    setSelectedPoliticalParty(option);
                                }}
                                placeholder={'Todas las militancias'}
                                value={selectedPoliticalParty || ''}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Sexo'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isClearable
                                isMulti
                                name="selectedGender"
                                id="selectedGender"
                                options={genders}
                                onChange={(option) => {
                                    setSelectedGender(option);
                                }}
                                placeholder={'Sexo'}
                                value={selectedGender || ''}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Tipo de simpatizante'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isSearchable
                                value={selectedTypeAffiliate}
                                closeMenuOnSelect={false}
                                options={typeAffiliateOptions}
                                placeholder="Todos los simpatizantes"
                                onChange={(e) => setSelectedTypeAffiliate(e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Grupos especiales'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isSearchable
                                value={selectedSpecialGroup}
                                closeMenuOnSelect={false}
                                options={specialGroupOptions}
                                placeholder="Todos los grupos"
                                onChange={(e) => setSelectedSpecialGroup(e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="light-2x txt-dark"
                    onClick={() => {
                        /**
                         * Clears parameter of filter
                         */
                        setSelectedOrder(null);
                        setSelectedState();
                        setSelectedLocaleDistrict([]);
                        setSelectedFederalDistrict([]);
                        setSelectedMunicipality([]);
                        setSelectedSection([]);
                        setSelectedAge(initialAge);
                        setHasSelectedAge(false);
                        setSelectedPoliticalParty([])
                        setSelectedTypeAffiliate([])

                        /**
                         * Clears multiselect options
                         */
                        setLocaleDistrictOptions([]);
                        setFederalDistrictOptions([]);
                        setMunicipalityOptions([]);
                        setSectionOptions([]);
                    }}
                    disabled={loadingDemarcations}
                >
                    Restablecer
                </Button>{' '}
                <Button color="primary" onClick={() => handleApplyFilter()} disabled={loadingDemarcations}>
                    Aplicar Filtro
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FilterVotersModal;
