import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';
import Levels from 'components/dashboard/piramides/Levels';
import PyramidDetailsHeader from 'components/dashboard/piramides/PyramidDetailsHeader';
import TabContentContainer from 'components/tab/TabContent';
import { useTab } from 'components/tab/useTab';
import InternalLoader from 'layout/internal-loader';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container, TabPane } from 'reactstrap';
import { axiosInstance } from 'utils/axiosInstance';
import BasicPyramidDetails from './BasicPyramidDetails';
import { useEntities } from 'utils/fetch/entities';
import DetailNotFound from 'pages/detailNotFound';

export default function PyramidDetails() {
	const { pyramidId } = useParams();

	const [data, setData] = useState();
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [levels, setLevels] = useState([]);

	const { loading: loadingEntities, data: entities } = useEntities();

	// Allows editing of the event
	const [loadingEdit, setLoadingEdit] = useState(false);

	// States of pyramid details
	const [pyramidLevels, setPyramidLevels] = useState([]);

	const fetchPyramidDetails = async () => {
		setLoading(true);

		setLevels([]);

		await axiosInstance({
			url: `/activism/estructure/${pyramidId}/`,
			method: 'get'
		})
			.then(({ data: pyramidData }) => {
				setData(pyramidData);
				setPyramidLevels(pyramidData.levels);

				for (const i of pyramidData.levels) {
					axiosInstance({ method: 'GET', url: `/activism/level/${i.id}/` })
						.then((res) => setLevels((prev) => [...prev, res.data]))
						.catch((err) => console.log(err));
				}

				axiosInstance({
					url: `/activism/level/?no_paginate=true&estructure=${pyramidId}&size=20`,
					method: 'GET'
				})
					.then(({ data }) => {
						setItems(data?.results?.sort((a, b) => a.id - b.id));
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		setLoading(true);

		axiosInstance({
			url: `/activism/estructure/${pyramidId}/`,
			method: 'get'
		})
			.then(({ data: pyramidData }) => {
				setData(pyramidData);
				setPyramidLevels(pyramidData.levels);

				for (const i of pyramidData.levels) {
					axiosInstance({ method: 'GET', url: `/activism/level/${i.id}/` })
						.then((res) => setLevels((prev) => [...prev, res.data]))
						.catch((err) => console.log(err));
				}

				axiosInstance({
					url: `/activism/level/?no_paginate=true&estructure=${pyramidId}&size=20`,
					method: 'GET'
				})
					.then(({ data }) => {
						setItems(data?.results?.sort((a, b) => a.id - b.id));
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err))
			.finally(() => setLoading(false));
	}, [pyramidId]);

	let TABS = ['Pirámide'];
	const { activeTab, handleChangeTab } = useTab(TABS[0]);

	TABS = [...TABS, ...pyramidLevels];

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	if (loading || loadingEntities || entities?.length === 0) return <InternalLoader />;

	if (!data) return <DetailNotFound moduleTitle={'Pirámide'} />;

	return (
		<Container className="page-pyramid-details" fluid>
			<PyramidDetailsHeader
				name={data?.name}
				levels={TABS}
				activeTab={activeTab}
				setActiveTab={handleChangeTab}
			/>

			<TabContentContainer activeTab={activeTab}>
				<TabPane tabId={TABS[0]} style={{ padding: '24px' }}>
					<BasicPyramidDetails
						pyramidData={data}
						levels={items}
						setLoadingEdit={setLoadingEdit}
						fetchPyramidDetails={fetchPyramidDetails}
						entitiesOptions={entitiesOptions}
					/>
				</TabPane>

				{levels.map((level, i) => {
					return (
						<TabPane tabId={level.id} style={{ padding: '24px' }} key={i}>
							<Levels level={level} fetchPyramidDetails={fetchPyramidDetails} />
						</TabPane>
					);
				})}
			</TabContentContainer>

			<ToastContainer />
			<LoadingAffiliation isOpen={loadingEdit} />
		</Container>
	);
}
