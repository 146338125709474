import { useState } from 'react';
import { Minus, Plus } from 'react-feather';
import { Button, Input, InputGroup } from 'reactstrap';
import './inputPlusMinus.scss';

export default function InputPlusMinus({ value, onChange, placeholder, disabled, noNegativeNumbers }) {
	const [number, setNumber] = useState(value);
	const handleMinus = () => {
		const newNumber = Number(number - 1 || 0);
		newNumber > 0 && handleChange(newNumber);
	};

	const handlePlus = () => {
		handleChange(number + 1 || 1);
	};

	const handleChange = (newValue) => {
		setNumber(Number(newValue));
		onChange && onChange(Number(newValue));
	};

	return (
		<InputGroup className="plusMinusComponent">
			<Button className="change-number-button" onClick={handleMinus} disabled={disabled}>
				<Minus size={16} />
			</Button>
			<Input
				type="number"
				name="selectedSection"
				id="selectedSection"
				placeholder={placeholder || 'Número'}
				onChange={(evt) => handleChange(evt.target.value)}
				value={number}
				disabled={disabled}
				className="inputNumber"
				min={noNegativeNumbers ? 1 : undefined}
			/>
			<Button className="change-number-button" onClick={handlePlus} disabled={disabled}>
				<Plus size={16} />
			</Button>
		</InputGroup>
	);
}
