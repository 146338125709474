import { ChevronDown } from 'react-feather';
import './arrows.scss';

export default function VerticalArrow({ noLine }) {
	return (
		<div className="arrowVertical">
			{!noLine && <div className="verticalDashedLine "></div>}
			<ChevronDown className={`chevron down ${noLine ? 'noLine' : ''}`} />
		</div>
	);
}
