import React, { useState } from 'react';
import './star-rating.scss';

const StarRating = ({ handleSetQuestionRate, initialValue = 0 }) => {
	const [rating, setRating] = useState(initialValue);
	const [hover, setHover] = useState(null);

	return (
		<div style={{ whiteSpace: 'nowrap' }}>
			{[...Array(5)].map((star, index) => {
				index += 1;
				return (
					<button
						type="button"
						key={index}
						style={{
							backgroundColor: 'transparent',
							border: 'none',
							outline: 'none',
							cursor: 'pointer',
							color: index <= (hover || rating) ? '#ffd91c' : '#ccc'
						}}
						onClick={() => {
							setRating(index);
							handleSetQuestionRate(index);
						}}
						onMouseEnter={() => setHover(index)}
						onMouseLeave={() => setHover(null)}
					>
						<span className="star" style={{ fontSize: '24px' }}>
							&#9733;
						</span>
					</button>
				);
			})}
		</div>
	);
};

export default StarRating;
