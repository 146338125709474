import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from 'utils/axiosInstance';

export function useEventos({ page, perPage } = {}) {
	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const [data, setData] = useState([]);
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	useEffect(() => {
		setLoading(true);

		axiosInstance({
			method: 'GET',
			url: `/events/?page=${currentPage}&size=${currentPerPage}&ordering=-start_time`
		})
			.then((response) => {
				setData(response?.data);
			})
			.catch((err) => console.log({ err }))
			.finally(() => setLoading(false));
	}, [currentPage, currentPerPage]);

	return { loading, data };
}

export function useEventoDetail(id) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		setLoading(true);

		axiosInstance({
			method: 'GET',
			url: `/events/${id}`
		})
			.then((response) => {
				setData(response?.data);
			})
			.catch((err) => console.log({ err }))
			.finally(() => setLoading(false));
	}, [id]);

	return { loading, data };
}

export function useAssistanceToEvent(id, page, perPage) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	useEffect(() => {
		setLoading(true);

		axiosInstance({
			method: 'GET',
			url: `/assistance/?page=${page}&size=${perPage}&fk_event_id=${id}`
		})
			.then((response) => {
				setData(response?.data.results);
				setCount(response?.data.count);
			})
			.catch((err) => console.log({ err }))
			.finally(() => setLoading(false));
	}, [id, page, perPage]);

	return { loading, data, count };
}
