export function getAgefromDate(date) {
    if (!date) {
        return null;
    }
    const [day, month, year] = date.split('/');

    const birthDate = new Date(+year, +month - 1, +day);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getParseDateAndHours(date) {

    if (!date) {
        return null;
    }

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    let h = addZero(date.getHours());
    let m = addZero(date.getMinutes());
    let s = addZero(date.getSeconds());
    let time = h + ":" + m + ":" + s;

    return day + " de " + months[month] + " de " + year + " - " + time;

}

function addZero(i) {
    if (i < 10) {
        i = "0" + i
    }
    return i;
}
