import {
	GET_WEEKLY_REPORT_SUCCESS,
	GET_WEEKLY_REPORT_ERROR,
	CREATE_GOAL_LOADING,
	CREATE_GOAL_ERROR,
	CREATE_GOAL_SUCCESS,
	GET_GOALS_SUCCESS,
	GET_GOALS_ERROR,
	GET_CURRENT_GOAL_SUCCESS,
	GET_CURRENT_GOAL_ERROR,
	CREATE_NEED_LOADING,
	CREATE_NEED_ERROR,
	CREATE_NEED_SUCCESS,
	GET_NEEDS_SUCCESS,
	GET_NEEDS_ERROR
} from '../actionTypes';

const initial_state = {
	weeklyReport: null,
	weeklyReportSucceded: false,
	weeklyReportError: false,
	loadingCreateGoal: false,
	createGoalSuccess: false,
	createGoalError: '',
	goalsList: [],
	getGoalsSucceded: true,
	getGoalsError: '',
	currentGoal: null,
	getCurrentGoalSucceded: false,
	getCurrentGoalError: '',
	loadingCreateNeed: false,
	createNeedSuccess: false,
	createNeedError: '',
	needsList: [],
	getNeedsSucceded: true,
	getNeedsError: ''
};

const metricsReducer = (state = initial_state, action) => {
	switch (action.type) {
		case GET_WEEKLY_REPORT_SUCCESS:
			return {
				...state,
				weeklyReport: action.payload,
				weeklyReportSucceded: true,
				weeklyReportError: ''
			};

		case GET_WEEKLY_REPORT_ERROR:
			return {
				...state,
				weeklyReport: null,
				weeklyReportSucceded: false,
				weeklyReportError: action.payload
			};
		case CREATE_GOAL_LOADING:
			return {
				...state,
				loadingCreateGoal: action.payload,
				createGoalSuccess: false,
				createGoalError: ''
			};
		case CREATE_GOAL_SUCCESS:
			return {
				...state,
				loadingCreateGoal: false,
				createGoalSuccess: true,
				createGoalError: ''
			};
		case CREATE_GOAL_ERROR:
			return {
				...state,
				loadingCreateGoal: false,
				createGoalSuccess: false,
				createGoalError: action.payload
			};
		case GET_GOALS_SUCCESS:
			return {
				...state,
				goalsList: action.payload,
				getGoalsSucceded: true,
				getGoalsError: ''
			};
		case GET_GOALS_ERROR:
			return {
				...state,
				goalsList: [],
				getGoalsSucceded: false,
				getGoalsError: action.payload
			};
		case GET_CURRENT_GOAL_SUCCESS:
			return {
				...state,
				currentGoal: action.payload,
				getCurrentGoalSucceded: true,
				getCurrentGoalError: ''
			};
		case GET_CURRENT_GOAL_ERROR:
			return {
				...state,
				currentGoal: null,
				getCurrentGoalSucceded: true,
				getCurrentGoalError: action.payload
			};
		case CREATE_NEED_LOADING:
			return {
				...state,
				loadingCreateNeed: action.payload,
				createNeedSuccess: false,
				createNNeedError: ''
			};
		case CREATE_NEED_SUCCESS:
			return {
				...state,
				loadingCreateNeed: false,
				createNeedSuccess: true,
				createNeedError: ''
			};
		case CREATE_NEED_ERROR:
			return {
				...state,
				loadingCreateNeed: false,
				createNeedSuccess: false,
				createNeedError: action.payload
			};
		case GET_NEEDS_SUCCESS:
			return {
				...state,
				needsList: action.payload,
				getNeedsSucceded: true,
				getNeedsError: ''
			};
		case GET_NEEDS_ERROR:
			return {
				...state,
				needsList: [],
				getNeedsSucceded: false,
				getNeedsError: action.payload
			};
		default:
			return { ...state };
	}
};

export default metricsReducer;
