import DataTable from 'react-data-table-component';
import { Badge } from 'reactstrap';
import { customStyles } from 'components/dashboard/electores/tableStyles';
import moment from 'moment';
import { ShowMessage } from './DetailMessage';

export function CellStatus({ status }) {
	let colorState = 'warning';
	let labelState = 'Programado';

	if (status === 'SEND') {
		colorState = 'success';
		labelState = 'Enviado';
	} else if (status === 'ERROR') {
		colorState = 'danger';
		labelState = 'Error';
	}
	return (
		<Badge color={colorState} pill>
			{labelState}
		</Badge>
	);
}

// unction ShowMessage({ id, children }) {
// 	const [modal, setModal] = useState(false);
// 	const toggle = () => setModal(!modal);

// 	return (
// 		<div className="d-flex align-items-center">
// 			<p className="m-0" id={'ID' + id} onClick={toggle}>
// 				<span className="d-flex">
// 					<Eye className="font-primary" />
// 				</span>
// 			</p>

// 			<Modal isOpen={modal} toggle={toggle}>
// 				<div>{children}</div>
// 			</Modal>
// 		</div>
// 	);
// }

const getColumns = ({ historyTable = false, personData } = {}) => {
	const columns = [
		{
			name: 'ID de mensaje',
			selector: (row) => row._id,
			cell: (row) => <span className="font-primary">{row._id}</span>
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			center: true,
			cell: (row) => <CellStatus status={row.status} />
		},
		{
			name: 'Tipo de mensaje',
			selector: (row) => (row.destinataries.length > 1 ? 'Masivo' : 'Único')
		},
		{
			name: 'Fecha y hora',
			selector: (row) => moment(row?.dateTimeToSend).format('DD-MM-YYYY - HH:mm'),
			center: true
		},
		{
			cell: (row) => <ShowMessage id={row._id} data={row} />,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	if (historyTable) {
		columns.splice(0, 0, {
			name: 'Cantidad',
			selector: (row) => row._id,
			cell: (row) => <span className="font-primary">{row?.destinataries?.length || 0}</span>
		});
	}

	return columns;
};

export default function SmsTable({
	data,
	personData,
	historyTable,
	handlePerRowsChange,
	handlePageChange,
	count,
	page,
	perPage
}) {
	const columns = getColumns({ historyTable, personData });
	return (
		<div className="table-responsive product-table">
			<DataTable
				customStyles={customStyles}
				columns={columns}
				data={data}
				paginationComponentOptions={{
					rowsPerPageText: 'Mensajes por página:',
					rangeSeparatorText: 'de'
				}}
				paginationTotalRows={count}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				paginationDefaultPage={page}
				paginationPerPage={perPage}
				pagination
				paginationServer
				highlightOnHover={true}
			/>
		</div>
	);
}
