import { combineReducers } from 'redux';
import Customizer from './customizer/reducer';
import Voters from './voters/reducer';
import User from './user/reducer';
import UI from './UI/reducer';
import Metrics from './metrics/reducer';

const reducers = combineReducers({
	Customizer,
	Voters,
	User,
	UI,
	Metrics
});

export default reducers;
