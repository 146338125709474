import axios from 'axios';
import { getLocalStorageItem } from 'services/localStorageService';
import { TOKEN_DATA } from 'constant/localStorage';

export const axiosInstance = axios.create({
	baseURL: `https://${process.env.REACT_APP_DJANGO_URL}/api/v2`,
	headers: {
		Accept: 'application/json',
		authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
			? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
			: ''
	}
});

export const axiosInstanceFiles = axios.create({
	baseURL: `https://${process.env.REACT_APP_DJANGO_URL}/api/v2`,
	headers: {
		Accept: 'application/json',
		authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
			? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
			: '',
		'Content-Type': 'multipart/form-data'
	}
});

export const axiosInstanceV1 = axios.create({
	baseURL: `https://${process.env.REACT_APP_DJANGO_URL}/api`,
	headers: {
		Accept: 'application/json',
		authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
			? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
			: ''
	}
});

export const axiosInstanceCSV = axios.create({
	baseURL: `https://${process.env.REACT_APP_DJANGO_URL}/api/v2`,
	headers: {
		authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
			? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
			: ''
	}
});
