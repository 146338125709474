import React, {Fragment} from 'react';
import {
    Button,
    Col,
    Container,
    Row,
} from 'reactstrap';
import moment from "moment";
import empty from "../../../../assets/images/other-images/empty.jpg";

export default function UninstallTab({selectedSupport}) {

    return (
        <Fragment>
            <Container className="page-voter-details" fluid>
                <Row>
                    <Col sm="12">

                        <Row>
                            <Col sm="12" md="6">
                                <div style={{margin: '10px'}}>
                                    <div className="custom-detail-card">
                                        <div className="custom-title">
                                            <h3 className="m-0">Historial</h3>
                                        </div>
                                        <div>
                                            <p className="item-title">Última actualización</p>
                                            <div
                                                className="item-description">{selectedSupport?.status && (selectedSupport?.status.toLowerCase() === 'created' || selectedSupport?.status.toLowerCase() === 'installed') ? '-' : moment(selectedSupport?.updatedAt).format('LL')}</div>
                                        </div>
                                        <div>
                                            <p className="item-title">Ubicación</p>
                                            <div className="item-description">
                                                <p className="item-title-bold">
                                                    <>
                                                        {selectedSupport?.status && (selectedSupport?.status.toLowerCase() === 'created' || selectedSupport?.status.toLowerCase() === 'installed') ? '-' : selectedSupport?.evidence?.localization}
                                                        <Button
                                                            disabled={selectedSupport?.status && (selectedSupport?.status.toLowerCase() === 'created' || selectedSupport?.status.toLowerCase() === 'installed')}
                                                            size="sm"
                                                            className="px-2 py-1"
                                                            color="primary"
                                                        >
                                                            ver en mapa
                                                        </Button>
                                                    </>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="item-title">Comentarios</p>
                                            <div
                                                className="item-description">{selectedSupport?.status && (selectedSupport?.status.toLowerCase() === 'created' || selectedSupport?.status.toLowerCase() === 'installed') ? '-' : selectedSupport?.evidence?.comments}</div>
                                        </div>
                                        <div>
                                            <p className="item-title">fecha pautada de desinstalación</p>
                                            <div
                                                className="item-description">{moment(selectedSupport?.updatedAt).format('LL')}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="6">
                                <div style={{margin: '10px'}}>
                                    <div className="custom-detail-card">
                                        <div className="custom-title">
                                            <h3 className="m-0">Evidencia</h3>
                                        </div>
                                        <div>
                                            <div className="item-title">Fotografía</div>

                                            <div>
                                                <div className="phone-preview">
                                                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                                    <img src={empty} alt="evidence photo"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
