import { useSearchParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { graphQlClient } from '../../store/graphql';

const GET_SMSs = gql`
	query SmsPagination($filter: FilterFindManysmsInput, $page: Int, $perPage: Int, $sort: SortFindManysmsInput) {
		smsPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
			items {
				message
				smsQuantity
				status
				dateTimeToSend
				repeatUntilDate
				destinataries {
					firstname
					lastname
					phoneNumber
					gender
					state
					section
					age
					curp
					photo
					_id
				}
				clientId
				_id
				updatedAt
				createdAt
				peopleContacted
				territoriesReached
				filter {
					necessity
					city
					state
					sections
					localDistrict
					federalDistrict
					gender
				}
			}
			count
		}
	}
`;

export function useSmsWithPagination({ perPage, page, filter, sort } = {}) {
	const [searchParams] = useSearchParams();
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	const { loading, data, error, refetch } = useQuery(GET_SMSs, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			perPage: currentPerPage,
			page: currentPage,
			filter: filter,
			sort: sort
		}
	});

	return { loading, data: data?.smsPagination || {}, error, refetch };
}
