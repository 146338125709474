import React, {Fragment, useState} from 'react';
import Breadcrumb from 'layout/breadcrumb';
import {Button, Container, Row, Input, InputGroup, Col, Card, CardHeader, CardBody, InputGroupText} from 'reactstrap';

import DataTable from 'react-data-table-component';
import InternalLoader from 'layout/internal-loader';
import {customStyles} from '../electores/tableStyles';
import {ChevronRight, Filter, PlusCircle, Search} from 'react-feather';
import {useVoterGoalsWithPagination} from 'utils/fetch/electores';
import {useEntities} from 'utils/fetch/entities';
import {usePagination} from 'utils/fetch/pagination';
import moment from "moment/moment";
import {transformDemarcationType} from "../../../utils/stringTransforms";
import {Link} from "react-router-dom";

const CustomInfoButton = ({id}) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/configuracion/voter-goals/edit/${id}`}>
            <ChevronRight/>
        </Link>
    );
};
const convertDate = (date) => {

    const dateMomentObject = moment(date, "YYYY/MM/DD");
    if (date) {
        return new Date(dateMomentObject.toDate()).toLocaleString('es-ES', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    } else {
        return '-';
    }
};

const columns = [

    {
        name: 'Estado',
        selector: (row) => row.stateName,
        center: true
    },
    {
        name: 'Tipo',
        selector: (row) => transformDemarcationType(row.demarcation),
        center: true
    },
    {
        name: 'Indicador',
        center: true,
        selector: (row) => row.indicator
    },
    {
        name: 'Votantes',
        center: true,
        selector: (row) => row.qtyElectors
    },
    {
        name: 'Porcentaje',
        center: true,
        selector: (row) => row.avg,
    },
    {
        name: 'Fecha de creación',
        center: true,
        selector: (row) => convertDate(row.updatedAt)
    },
    {
        cell: (row) => <CustomInfoButton id={row._id}/>,
        allowOverflow: true,
        button: true,
        width: '10%',
        center: true
    }
];

export default function VotersGoals() {

    const {handlePageChange, handlePerRowsChange, page, perPage} = usePagination();

    const {
        loading,
        data: {items, count}
    } = useVoterGoalsWithPagination({perPage: perPage, page: page});
    const [loadingAffiliates] = useState(false);

    const {loading: loadingEntities} = useEntities();

    /**
     * Manage the string for search from input
     */

    if (loadingEntities) {
        return <InternalLoader/>;
    }
    return (
        <Fragment>
            <Breadcrumb title="Meta de electores"/>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{padding: '32px 40px'}}>
                                <Row className="events-header">
                                    <Col lg={4}>
                                        <h5>Lista de metas </h5>
                                        <span
                                            style={{
                                                color: '#AFAFAF',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '17px'
                                            }}
                                        >{`${count || '-'} registros`}</span>
                                    </Col>
                                    <Col className="filter-events-header">
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                height: '33px',
                                                padding: '0px 4px'
                                            }}
                                        >
                                            <Button
                                                className="button-content headers-button"
                                                style={{width: '161px'}}
                                                color="primary"
                                            >
                                                <span>Filtrar y Ordenar</span>
                                                <Filter size={16}/>
                                            </Button>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '33PX',
                                                width: '320px'
                                            }}
                                        >
                                            <InputGroup>
                                                <InputGroupText
                                                    style={{
                                                        height: '33px',
                                                        borderRadius: '6px 0px 0px 6px',
                                                        backgroundColor: 'transparent',
                                                        borderRight: '0px',
                                                        padding: '2px 6px'
                                                    }}
                                                >
                                                    <Search
                                                        style={{
                                                            color: 'gray'
                                                        }}
                                                        size={16}
                                                    />
                                                </InputGroupText>
                                                <Input
                                                    style={{
                                                        height: '33px',
                                                        borderRadius: '0px',
                                                        backgroundColor: 'transparent',
                                                        borderRight: '0px',
                                                        borderLeft: '0px'
                                                    }}
                                                    placeholder="Buscar por..."
                                                    className="pl-0"
                                                />
                                                <Button
                                                    className="button-content"
                                                    style={{
                                                        height: '33px',
                                                        width: '78px',
                                                        borderRadius: '0px 6px 6px 0px',
                                                        borderLeft: '0px',
                                                        padding: '2px 6px'
                                                    }}
                                                    color="primary"
                                                >
                                                    Buscar
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                height: '33px',
                                                padding: '0px 4px'
                                            }}
                                        >
                                            <Link to={'/configuracion/voter-goals/create'}>
                                                <Button
                                                    className="button-content headers-button"
                                                    style={{width: '131px'}}
                                                    color="primary"
                                                >
                                                    <PlusCircle size={16}/>
                                                    <span>Crear nuevo</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="p-0">
                                {loadingAffiliates ? (
                                    <InternalLoader/>
                                ) : (
                                    <div className="table-responsive product-table">
                                        <DataTable
                                            noHeader
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={items}
                                            progressComponent={<InternalLoader/>}
                                            progressPending={loading}
                                            highlightOnHover
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Electores por página:',
                                                rangeSeparatorText: 'de'
                                            }}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={count}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            paginationDefaultPage={page}
                                            paginationPerPage={perPage}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
