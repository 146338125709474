export const model = [
	{ id: 'image', name: 'Elector' },
	// { id: 'claveDeElector', name: 'Clave de Elector' },
	// { id: 'curp', name: 'CURP' },
	{ id: 'age', name: 'Edad' },
	{ id: 'estado', name: 'Estado' },
	{ id: 'anoDeRegistro', name: 'Año de registro' },
	{ id: 'domicilio', name: 'Domicilio' },
	{ id: 'estadoCode', name: 'Código de Estado' },
	{ id: 'localidadCode', name: 'Cógido de Localidad' },
	{ id: 'emision', name: 'Emisión' },
	{ id: 'firstName', name: 'Nombre' },
	{ id: 'lastName', name: 'Apellidos' },
	{ id: 'dateOfBirth', name: 'Fecha nacimiento' },
	{ id: 'expirationDate', name: 'Fecha expiración' },
	{ id: 'seccion', name: 'Sección' },
];

export function getINETableHeaders() {
  return model.map((x) => {
    return {
		name: x.name,
		selector: (row) => row[x.id],
		sortable: true,
		center: true
	};
  });
}
