import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

const PageNotFound = () => {
	const navigate = useNavigate();

	return (
		<Container fluid={true} className="p-0">
			<Row>
				<Col xs="12">
					<div className="login-card page-not-found">
						<p style={{ fontSize: '128px', color: '#F04A5E', margin: '0px' }}>{'¡OH!'}</p>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<p
								style={{
									fontSize: '30px',
									fontWeight: '500',
									lineHeight: '35.55px',
									margin: '5px 0px'
								}}
							>
								Estamos en mantenimiento
							</p>
							<p
								style={{
									fontSize: '20px',
									margin: '5px 0px'
								}}
							>
								En breve podrás volver a usar la plataforma
							</p>
						</div>
						<div>
							<Button color="primary" className="btn-block" onClick={() => navigate(-1)}>
								Volver
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
export default PageNotFound;
