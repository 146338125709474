import Breadcrumbs from 'layout/breadcrumb';
import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle, Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

import * as ROUTES from '../../../routes/index';
import DataTable from 'react-data-table-component';
import { customStyles } from '../electores/tableStyles';
import InternalLoader from 'layout/internal-loader';
import { usePagination } from 'utils/fetch/pagination';
import SurveyDetails from './details';
import { axiosInstance } from 'utils/axiosInstance';
import LoadingAffiliation from '../CustomLoader/CustomLoader';

const Encuestas = () => {
	/**
	 * Loaders
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [detailsLoader, setDetailsLoader] = useState(false);

	/**
	 * Polls data state
	 */
	const [polls, setPolls] = useState([]);
	const [totalPolls, setTotalPolls] = useState(0);

	/**
	 * States for filtering
	 */
	const [search, setSearch] = useState('');

	/**
	 * States for pagination
	 */
	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	/**
	 * Handle details in survey
	 */
	const [surveyViewIsOpen, setSurveyViewIsOpen] = useState(false);
	const toggleOpenSurveyView = () => setSurveyViewIsOpen(!surveyViewIsOpen);

	/**
	 * Survey detail
	 */
	const [surveyDetails, setSurveyDetails] = useState(null);

	useEffect(() => {
		setIsLoading(true);

		axiosInstance({ url: `/polls/poll/?page=${page}&size=${perPage}`, method: 'GET' })
			.then(({ data }) => {
				setTotalPolls(data.count);
				setPolls(data.results);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [page, perPage]);

	/**
	 * Table columns
	 */
	const tableColumns = [
		{
			name: 'Nombre',
			selector: ({ name }) => (
				<span style={{ fontSize: '14px', fontWeight: 500, textTransform: 'capitalize' }}>{name}</span>
			)
			//sortable: true,
		},
		{
			name: 'Número',
			selector: ({ num_poll }) => (
				<span style={{ fontSize: '14px', fontWeight: 500, textTransform: 'capitalize' }}>{num_poll}</span>
			),
			width: '20%',
			//sortable: true,
			center: true
		},
		{
			name: 'Descripción',
			selector: ({ description }) => (
				<span style={{ fontSize: '14px', fontWeight: 500, textTransform: 'capitalize' }}>{description}</span>
			),
			//sortable: true,
			width: '50%'
		},
		{
			cell: ({ id }) => (
				<Link to={`#`}>
					<i className="fa fa-pencil"></i>
				</Link>
			),
			allowOverflow: true,
			button: true,
			width: '4%',
			center: true
		},
		{
			cell: ({ id }) => (
				<div
					style={{ cursor: 'pointer', color: '#F04A5E' }}
					onClick={() => {
						setDetailsLoader(true);

						axiosInstance({ url: `/polls/poll/${id}/`, method: 'GET' })
							.then(({ data }) => setSurveyDetails(data))
							.then(() => toggleOpenSurveyView())
							.catch((err) => console.log(err))
							.finally(() => setDetailsLoader(false));
					}}
				>
					<i className="fa fa-table"></i>
				</div>
			),
			allowOverflow: true,
			button: true,
			width: '4%',
			center: true
		}
	];

	return (
		<Fragment>
			<Breadcrumbs title="Encuestas" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: '32px 40px' }}>
								<Row className="events-header">
									<Col lg={4}>
										<h5 style={{ order: '0' }}>Lista de encuestas</h5>
										<span
											style={{
												color: '#AFAFAF',
												fontWeight: '400',
												fontSize: '14px',
												lineHeight: '17px'
											}}
										>{`${totalPolls} encuestas`}</span>
									</Col>
									<Col className="filter-events-header">
										<div
											style={{
												display: 'flex',
												height: '33PX',
												width: '320px'
											}}
										>
											<InputGroup>
												<InputGroupText
													style={{
														height: '33px',
														borderRadius: '6px 0px 0px 6px',
														backgroundColor: 'transparent',
														borderRight: '0px',
														padding: '2px 6px'
													}}
												>
													<Search
														style={{
															color: 'gray'
														}}
														size={16}
													/>
												</InputGroupText>
												<Input
													style={{
														height: '33px',
														borderRadius: '0px',
														backgroundColor: 'transparent',
														borderRight: '0px',
														borderLeft: '0px'
													}}
													placeholder="Buscar por..."
													className="pl-0"
													value={search}
													onChange={(e) => setSearch(e.target.value)}
												/>
												<Button
													className="button-content"
													style={{
														height: '33px',
														width: '78px',
														borderRadius: '0px 6px 6px 0px',
														borderLeft: '0px',
														padding: '2px 6px'
													}}
													color="primary"
													// onClick={handleFilterEvent}
												>
													Buscar
												</Button>
											</InputGroup>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<Link to={ROUTES.CREAR_ENCUESTAS_1}>
												<Button
													className="button-content headers-button"
													style={{ width: '131px' }}
													color="primary"
												>
													<PlusCircle size={16} />
													<span>Crear nueva</span>
												</Button>
											</Link>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<DataTable
									noHeader
									customStyles={customStyles}
									columns={tableColumns}
									data={polls}
									progressComponent={<InternalLoader />}
									progressPending={isLoading}
									highlightOnHover={true}
									paginationComponentOptions={{
										rowsPerPageText: 'Encuestas por página:',
										rangeSeparatorText: 'de'
									}}
									paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
									pagination
									paginationServer
									paginationTotalRows={totalPolls}
									onChangeRowsPerPage={handlePerRowsChange}
									onChangePage={handlePageChange}
									paginationDefaultPage={page}
									paginationPerPage={perPage}
									noDataComponent={'No se han encontrado encuestas'}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<SurveyDetails isOpen={surveyViewIsOpen} toggle={toggleOpenSurveyView} surveyDetails={surveyDetails} />
			<LoadingAffiliation isOpen={detailsLoader} />
		</Fragment>
	);
};

export default Encuestas;
