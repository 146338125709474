import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import './pyramidLevels.scss';
import { X } from 'react-feather';
import HorizontalArrow from 'components/arrow/HorizontalArrow';
import VerticalArrow from 'components/arrow/VerticalArrow';

const PyramidDetailsPreviewModal = ({ levels, isOpen, toggle }) => {
	return (
		<Modal isOpen={isOpen} toggle={toggle} centered fullscreen style={{ minWidth: '1000px' }}>
			<ModalBody>
				<Row className="px-3 py-1">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							backgroundColor: '#FFF1F1',
							width: '100%',
							height: '100%',
							borderRadius: '4px',
							padding: '16px 24px'
						}}
					>
						<h4 style={{ color: '#F04A5E' }}>Vista previa de pirámide</h4>
						<X size={20} style={{ cursor: 'pointer' }} onClick={toggle} />
					</div>
				</Row>
				<Row className="px-3 py-1">
					<Col sm="12">
						<div className="containerLevelsPreview">
							{levels?.map?.((itemLevel, idx) => {
								return (
									<>
										{idx !== 0 && <HorizontalArrow />}
										<div className="levelItem">
											<div className="levelName">{itemLevel.name}</div>

											<VerticalArrow />
											<div className="levelOptionDescription">{itemLevel.number_managers}</div>
											{itemLevel.recruiter ? (
												<>
													<VerticalArrow noLine />
													<div className="levelIsRecruiter font-primary">Es reclutador</div>
													<VerticalArrow noLine />
													<div className="levelOptionDescription">
														{itemLevel.number_people_recruit}
													</div>
													{itemLevel.number_people_recruit && itemLevel.number_managers && (
														<>
															<VerticalArrow />
															<div className="levelOptionDescription">
																{Number(itemLevel.number_managers) *
																	Number(itemLevel.number_people_recruit)}
															</div>
														</>
													)}
												</>
											) : null}
										</div>
									</>
								);
							})}
						</div>
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default PyramidDetailsPreviewModal;
