import React, {useState} from 'react';
import {Container, Row, Col, FormGroup, Label, Button, Input, Card, CardBody, TabPane} from 'reactstrap';
import {Link, useNavigate} from "react-router-dom";
import Select from "react-select";
import * as ROUTES from "../../../../routes";
import Multiselect from "../../../multiselect/Multiselect";
import "../../../appliedTerritories/appliedTerritories.scss";
import DatePicker from "react-date-picker";
import moment from "moment/moment";
import {Calendar} from "react-feather";
import Tab from "../../../tab/Tab.";
import TabContentContainer from "../../../tab/TabContent";
import AppliedTerritories from "../../../appliedTerritories/appliedTerritories";
import {useTab} from "../../../tab/useTab";
import {
    useEntities,
    useFDistrictsWithFilters,
    useLocalDistricsWithFilters, useMunicipalitiesWithFilters,
    useSectionsWithFilters
} from "../../../../utils/fetch/entities";
import {useMultiSelectState} from "../../../multiselect/useMultiSelectState";
import InternalLoader from "../../../../layout/internal-loader";
import {toast, ToastContainer} from "react-toastify";
import {gql, useMutation} from "@apollo/client";
import {graphQlClient} from "../../../../store/graphql";

const colourStyles = {
    option: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: 'white',
        '&:hover': {backgroundColor: '#F04A5E22'}
    }),
    control: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#F04A5E'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: '#FFF1F1'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#F04A5E',
        backgroundColor: '#FFF1F1'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#F04A5E'
        };
    },
    zIndex: (defaultStyles) => {
        return {
            ...defaultStyles,
            zIndex: 9999
        };
    },
};

const CREATE_CAMPAIGN = gql`
	mutation CampaignCreateOne($record: CreateCampaignInputTCInput) {
  campaignCreateOne(record: $record)
}
`;

const TABS = ['Sección', 'Municipio', 'D. Local', 'D. Federal'];

function CreateCampaign() {

    const navigate = useNavigate();
    const [selectedEntity, setSelectedEntity] = useState(null);
    const {activeTab, handleChangeTab} = useTab(TABS[0], {replaceUrl: false});

    const {loading: loadingEntities, data: entities} = useEntities();

    const {data: sections, loading: loadingSections} = useSectionsWithFilters(selectedEntity);
    const {data: lDistrics, loading: loadingLDistrics} = useLocalDistricsWithFilters(selectedEntity);
    const {data: fDistrics, loading: loadingFDistrics} = useFDistrictsWithFilters(selectedEntity);
    const {data: municipalities, loading: loadingMunicipalities} = useMunicipalitiesWithFilters(selectedEntity);

    const {selectedOptions: selectedSections, handleChange: setSelectedSections} = useMultiSelectState();
    const {selectedOptions: selectedFDistrics, handleChange: setSelectedFDistrics} = useMultiSelectState();
    const {selectedOptions: selectedLDistrics, handleChange: setSelectedLDistrics} = useMultiSelectState();
    const {selectedOptions: selectedMunicipalities, handleChange: setSelectedMunicipalities} = useMultiSelectState();

    const [campaignName, setCampaignName] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [createCampaign] = useMutation(
        CREATE_CAMPAIGN,

        {
            errorPolicy: 'all',
            client: graphQlClient,
            onCompleted: (response) => {
                if (response.data) {
                    toast.success('Se ha creado la campaña con exito!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            },
            onError: () => {
                toast.error('Error al crear campaña', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    );


    if (loadingEntities) {
        return <InternalLoader/>;
    }

    const entitiesOptions = Object.values(entities || {})?.map((entity) => {
        return {
            label: entity.name,
            value: entity.code
        };
    });

    const handleSubmit = () => {
        if (
            campaignName &&
            selectedEntity &&
            startDate &&
            endDate &&
            (selectedLDistrics.length > 0 ||
                selectedFDistrics.length > 0 ||
                selectedMunicipalities.length > 0 ||
                selectedSections.length > 0)
        ) {

            console.log(campaignName)
            console.log(selectedEntity)
            console.log(startDate)
            console.log(endDate)

            console.log(selectedLDistrics)
            console.log(selectedFDistrics)
            console.log(selectedMunicipalities)
            console.log(selectedSections)

            let sections = selectedSections.map((section) => section.id.toString());
            let municipalities = selectedMunicipalities.map((municipality) => municipality.id.toString());
            let lDistricts = selectedLDistrics.map((lDistric) => lDistric.id.toString());
            let fDistricts = selectedFDistrics.map((fDistrics) => fDistrics.id.toString())


            const campaignRecord = {
                demarcation: sections.length > 0 ? sections[0] : municipalities.length > 0 ? municipalities[0] : lDistricts.length > 0 ? lDistricts[0] : fDistricts.length > 0 ? fDistricts[0] : '',
                name: campaignName,
                startDate: moment(startDate).format(),
                finishDate: moment(endDate).format()

            };

            createCampaign({
                variables: {
                    record: campaignRecord
                }
            }).then((response) => {
                console.log(response)
                if (response.data) {
                    navigate(ROUTES.CAMPAIGNS)
                }
            });

        } else {
            toast.error('Faltan datos en el formulario', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
        // navigate(ROUTES.SUPPORTS_CREATE_TWO)
    }

    return (
        <div className="create-pyramid-page">
            <Container fluid>
                <Row className="">
                    <Col className="mr-auto">
                        <h3>Crear Campaña</h3>
                    </Col>
                </Row>
                <div className="containerForm">
                    <Row>
                        <Col sm="12" xs="12">
                            <h5 className="title">Datos de campaña</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="8" xs="12">
                            <FormGroup>
                                <Label>{'Nombre'}</Label>
                                <Input
                                    type="text"
                                    name="campaignname"
                                    id="campaignname"
                                    placeholder="Nombre de campaña"
                                    value={campaignName}
                                    onChange={(e) => setCampaignName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <FormGroup>
                                <Label>{'Fecha de inicio'}</Label>
                                <DatePicker
                                    clearIcon={false}
                                    value={startDate}
                                    onChange={(value) => {
                                        if (moment(value).isBefore(moment(new Date()))) {
                                            setStartDate(new Date());
                                        } else {
                                            setStartDate(value);
                                        }
                                    }}
                                    format="dd/MM/yyyy"
                                    calendarIcon={<Calendar size={16} stroke="#F04A5E"/>}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="8" xs="12">
                            <FormGroup>
                                <Label>{'Estado'}</Label>
                                <Select
                                    isClearable
                                    name="state"
                                    id="selectedState"
                                    options={entitiesOptions}
                                    placeholder={'Seleccionar Estado'}
                                    styles={colourStyles}
                                    onChange={(option) => {
                                        setSelectedEntity(option);
                                    }}
                                    value={selectedEntity}
                                />

                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <FormGroup>
                                <Label>{'Fecha fin'}</Label>
                                <DatePicker
                                    clearIcon={false}
                                    value={endDate}
                                    onChange={(value) => {
                                        if (moment(value).isBefore(moment(new Date()))) {
                                            setEndDate(new Date());
                                        } else {
                                            setEndDate(value);
                                        }
                                    }}
                                    format="dd/MM/yyyy"
                                    calendarIcon={<Calendar size={16} stroke="#F04A5E"/>}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" xs="12">
                            <h5 className="title m-t-30">Demarcación de campaña</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody className="py-3 px-3">
                                    <Row>
                                        <Col lg="6" sm="12" className="pr-4">
                                            <Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS}
                                                 small/>
                                            <TabContentContainer activeTab={activeTab} noPadding>
                                                <TabPane tabId={TABS[0]}>
                                                    <Multiselect
                                                        options={!selectedEntity ? [] : sections}
                                                        onChange={setSelectedSections}
                                                        loading={loadingSections}
                                                        disabled={
                                                            selectedMunicipalities.length > 0 ||
                                                            selectedLDistrics.length > 0 ||
                                                            selectedFDistrics.length > 0
                                                        }
                                                        isMulti={false}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={TABS[1]}>
                                                    <Multiselect
                                                        options={!selectedEntity ? [] : municipalities}
                                                        onChange={setSelectedMunicipalities}
                                                        loading={loadingMunicipalities}
                                                        disabled={
                                                            selectedSections.length > 0 ||
                                                            selectedLDistrics.length > 0 ||
                                                            selectedFDistrics.length > 0
                                                        }
                                                        isMulti={false}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={TABS[2]}>
                                                    <Multiselect
                                                        options={!selectedEntity ? [] : lDistrics}
                                                        onChange={setSelectedLDistrics}
                                                        loading={loadingLDistrics}
                                                        disabled={
                                                            selectedSections.length > 0 ||
                                                            selectedMunicipalities.length > 0 ||
                                                            selectedFDistrics.length > 0
                                                        }
                                                        isMulti={false}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={TABS[3]}>
                                                    <Multiselect
                                                        options={!selectedEntity ? [] : fDistrics}
                                                        onChange={setSelectedFDistrics}
                                                        loading={loadingFDistrics}
                                                        disabled={
                                                            selectedSections.length > 0 ||
                                                            selectedMunicipalities.length > 0 ||
                                                            selectedLDistrics.length > 0
                                                        }
                                                        isMulti={false}
                                                    />
                                                </TabPane>
                                            </TabContentContainer>
                                        </Col>
                                        <Col lg="6" sm="12" className="pl-4">
                                            <AppliedTerritories
                                                sections={selectedSections}
                                                municipalities={selectedMunicipalities}
                                                lDistricts={selectedLDistrics}
                                                fDistrics={selectedFDistrics}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mr-auto">
                            <Link to="/apoyos/filtrar-apoyos">
                                <Button size="md" className="" color="primary">
                                    Volver atras
                                </Button>
                            </Link>
                        </Col>

                        <Col sm="auto">
                            <Button size="md" className="" color="primary"
                                    onClick={() => handleSubmit()}>
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>
            <ToastContainer/>
        </div>
    );
}

export default CreateCampaign;
