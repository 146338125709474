import { gql, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { graphQlClient } from 'store/graphql';

const getMeetingsPaginated = gql`
	query ReunionGetAll($page: Int, $perPage: Int, $filter: GetReunionAllFilterTCInput) {
		reunionGetAll(page: $page, perPage: $perPage, filter: $filter)
	}
`;

const getMeetingDetails = gql`
	query ReunionGetOne($id: String!) {
		reunionGetOne(_id: $id)
	}
`;

const getMeetingsByCURP = gql`
	query ReunionesGetAllReunionByCurp($curp: String!) {
		reunionGetAllReunionByCurp(curp: $curp)
	}
`;

export function useMeetingsWithPagination({ perPage, page, filter } = {}) {
	const [searchParams] = useSearchParams();
	const currentPage = Number(searchParams.get('page') || page || 1);
	const currentPerPage = Number(searchParams.get('perPage') || perPage || 10);

	const { loading, data, error, refetch } = useQuery(getMeetingsPaginated, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			perPage: currentPerPage,
			page: currentPage,
			filter: filter
		}
	});
	return { loading, data: data?.reunionGetAll || {}, error, refetch };
}

export function useMeetingDetails({ id } = {}) {
	const { loading, data, error } = useQuery(getMeetingDetails, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			id
		}
	});

	return { loading, data: data?.reunionGetOne || null, error };
}

export function useMeetingsByCURP({ curp } = {}) {
	const { loading, data, error } = useQuery(getMeetingsByCURP, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			curp
		}
	});

	return { loading, data: data?.reunionGetAllReunionByCurp || {}, error };
}
