import React, { Fragment, useState } from 'react';
import { Calendar } from 'react-feather';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
	TabPane
} from 'reactstrap';
import Select from 'react-select';

import InternalLoader from 'layout/internal-loader';
import {
	useEntities,
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import DatePicker from 'react-date-picker';
import CreateEventHeader from './create-meeting-header';
import Tab from 'components/tab/Tab.';
import { useTab } from 'components/tab/useTab';
import TabContentContainer from 'components/tab/TabContent';
import Multiselect from 'components/multiselect/Multiselect';
import { useMultiSelectState } from 'components/multiselect/useMultiSelectState';
import AppliedTerritories from 'components/appliedTerritories/appliedTerritories';

import * as ROUTES from '../../../routes';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { gql, useMutation } from '@apollo/client';
import { graphQlClient } from 'store/graphql';
import {axiosInstance} from "../../../utils/axiosInstance";
import AsyncSelect from "react-select/async";

const TABS = ['Sección', 'Municipio', 'D. Local', 'D. Federal'];

const meetingCreation = gql`
  mutation ReunionCreateOne($record: CreateReunionInputTCInput) {
    reunionCreateOne(record: $record)
  }
`;

const colourStyles = {
	menu: (styles) => ({...styles, position: 'inherit'}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const CreateMeeting1 = ({ eventInfo } = {}) => {

	const [eventName, setEventName] = useState(eventInfo ? eventInfo.name : '');
	const [selectedEntity, setSelectedEntity] = useState(eventInfo ? eventInfo.entity : null);
	const [eventPlace, setEventPlace] = useState(eventInfo ? eventInfo.place : '');
	const [eventDate, setEventDate] = useState(eventInfo ? eventInfo.date : new Date());
	const [promotor, setPromotor] = useState(eventInfo ? eventInfo.promotor : '');
	const [hostName, setHostName] = useState(eventInfo ? eventInfo.host : '');

	const { loading: loadingEntities, data: entities } = useEntities();

	const { activeTab, handleChangeTab } = useTab(TABS[0], { replaceUrl: false });

	const navigate = useNavigate();

	const [callMeetingCreation] = useMutation(meetingCreation, {
		client: graphQlClient,
		onCompleted: (data) => {
			toast.success('Reunión creada con éxito', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
			navigate(ROUTES.REUNIONES);
		},
		onError: (error) => {
			toast.error('Error al crear la reunión', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		},
		variables: {
			record: {
				name: eventName,
				demarcation: selectedEntity?.label,
				promotor: promotor[0]?.voter?.info_voter?.info?.full_name,
				host: hostName,
				place: eventPlace,
				date: eventDate,
				state: 'CREATED',
			}
		}
	});

	const handleFilterVoters = async (query) => {
		try {
			const {data} = await axiosInstance({
				url: `users/profiles/?full_name=${query}&user__groups__name=Reuniones`,
				method: 'GET'
			});

			return data.results.map((voter) => {
				const label = voter?.info_voter
					? voter.info_voter?.info?.full_name
					: voter?.user_extra_info?.first_name + ' ' + voter?.user_extra_info?.last_name;

				return {
					label,
					value: voter.id,
					voter: voter
				};
			});
		} catch (err) {
			return console.log(err);
		}
	};
	const handleChangeFilterVoters = (option) => {
		setPromotor(
			[option].map((opt) => {
				const {
					value,
					voter: {
						info_voter: {
							info: {first_name, last_name, curp}
						}
					},
					voter
				} = opt;

				return {
					id: value,
					name: first_name,
					lastName: last_name,
					electoralKey: curp,
					voter
				};
			})
		);
	};


	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	const { selectedOptions: selectedSections, handleChange: setSelectedSections } = useMultiSelectState();
	const { selectedOptions: selectedFDistrics, handleChange: setSelectedFDistrics } = useMultiSelectState();
	const { selectedOptions: selectedLDistrics, handleChange: setSelectedLDistrics } = useMultiSelectState();
	const { selectedOptions: selectedMunicipalities, handleChange: setSelectedMunicipalities } = useMultiSelectState();

	if (loadingEntities) {
		return <InternalLoader />;
	}

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const HandleMeetingCreation = () => {
		if (
			eventName &&
			selectedEntity &&
			promotor &&
			hostName &&
			eventPlace &&
			eventDate &&
			(selectedLDistrics.length > 0 ||
				selectedFDistrics.length > 0 ||
				selectedMunicipalities.length > 0 ||
				selectedSections.length > 0)
		) {
			callMeetingCreation();
		} else {
			toast.error('Faltan datos en el formulario', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	return (
		<Fragment>
			<CreateEventHeader step={1} lastStep={1} />
			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Datos de reunión</Row>
							</CardHeader>
							<CardBody className="py-3 px-3">
								<Row className="mb-2">
									<Col lg="12" sm="12">

										<FormGroup>
											<Label className="col-form-label">Promotor de reunión</Label>
											<AsyncSelect
												styles={colourStyles}
												name="eventName"
												placeholder="Elegir promotor"
												valueKey="value"
												labelKey="label"
												matchPos="any"
												loadOptions={handleFilterVoters}
												hideSelectedOptions={true}
												onChange={(option) => handleChangeFilterVoters(option)}
												loadingMessage={() => 'Cargando...'}
												isMulti={false}
												noOptionsMessage={() => 'Ingresar nombre o clave de elector'}
											/>
										</FormGroup>
											{/*<FormGroup>*/}
											{/*	<Label>{'Promotor de reunión'}</Label>*/}
											{/*	<Input*/}
											{/*		type="text"*/}
											{/*		name="eventName"*/}
											{/*		id="eventName"*/}
											{/*		placeholder="Elegir promotor"*/}
											{/*		value={promotor}*/}
											{/*		onChange={(e) => setPromotor(e.target.value)}*/}
											{/*	/>*/}
											{/*</FormGroup>*/}
											<FormGroup>
												<Label>{'Anfitrión'}</Label>
												<Input
													type="text"
													name="eventName"
													id="eventName"
													placeholder="Nombre de anfitrión"
													value={hostName}
													onChange={(e) => setHostName(e.target.value)}
												/>
											</FormGroup>
											<FormGroup>
												<Label>{'Reunión'}</Label>
												<Input
													type="text"
													name="eventName"
													id="eventName"
													placeholder="Nombre de reunión"
													value={eventName}
													onChange={(e) => setEventName(e.target.value)}
												/>
											</FormGroup>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col lg="5" sm="12">
										<FormGroup>
											<Label>{'Entidad'}</Label>
											<Select
												isClearable
												name="selectedPlace"
												options={entitiesOptions}
												id="selectedPlace"
												onChange={(option) => {
													setSelectedEntity(option);
												}}
												placeholder="Seleccionar ubicación"
												value={selectedEntity}
											></Select>
										</FormGroup>
									</Col>
									<Col lg="5" sm="12">
										<FormGroup>
											<Label>{'Lugar de reunión'}</Label>
											<Input
												type="text"
												name="eventPlaceDetail"
												id="eventPlaceDetail"
												placeholder="Ubicación"
												value={eventPlace}
												onChange={(e) => setEventPlace(e.target.value)}
											/>
										</FormGroup>
									</Col>
									<Col lg="2" sm="12">
										<FormGroup>
											<Label>{'Fecha'}</Label>
											<DatePicker
												value={eventDate}
												onChange={(value) => {
													//if (value.toLocaleDateString() < new Date().toLocaleDateString()) {
													if (moment(value).isBefore(moment(new Date()))) {
														setEventDate(new Date());
													} else {
														setEventDate(value);
													}
												}}
												clearIcon={false}
												format="dd/MM/yyyy"
												calendarIcon={<Calendar size={16} stroke="#F04A5E" />}
											/>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">{`Demarcaciones objectivo${
									selectedEntity ? `: ${selectedEntity.label}` : ''
								}`}</Row>
							</CardHeader>
							<CardBody className="py-3 px-3">
								<Row>
									<Col lg="6" sm="12" className="pr-4">
										<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} small />
										<TabContentContainer activeTab={activeTab} noPadding>
											<TabPane tabId={TABS[0]}>
												<Multiselect
													options={!selectedEntity ? [] : sections}
													onChange={setSelectedSections}
													loading={loadingSections}
													disabled={
														selectedMunicipalities.length > 0 ||
														selectedLDistrics.length > 0 ||
														selectedFDistrics.length > 0
													}
												/>
											</TabPane>
											<TabPane tabId={TABS[1]}>
												<Multiselect
													options={!selectedEntity ? [] : municipalities}
													onChange={setSelectedMunicipalities}
													loading={loadingMunicipalities}
													disabled={
														selectedSections.length > 0 ||
														selectedLDistrics.length > 0 ||
														selectedFDistrics.length > 0
													}
												/>
											</TabPane>
											<TabPane tabId={TABS[2]}>
												<Multiselect
													options={!selectedEntity ? [] : lDistrics}
													onChange={setSelectedLDistrics}
													loading={loadingLDistrics}
													disabled={
														selectedSections.length > 0 ||
														selectedMunicipalities.length > 0 ||
														selectedFDistrics.length > 0
													}
												/>
											</TabPane>
											<TabPane tabId={TABS[3]}>
												<Multiselect
													options={!selectedEntity ? [] : fDistrics}
													onChange={setSelectedFDistrics}
													loading={loadingFDistrics}
													disabled={
														selectedSections.length > 0 ||
														selectedMunicipalities.length > 0 ||
														selectedLDistrics.length > 0
													}
												/>
											</TabPane>
										</TabContentContainer>
									</Col>
									<Col lg="6" sm="12" className="pl-4">
										<AppliedTerritories
											sections={selectedSections}
											municipalities={selectedMunicipalities}
											lDistricts={selectedLDistrics}
											fDistrics={selectedFDistrics}
										/>
									</Col>
								</Row>
								<Row
									className="pt-2 px-3"
									style={{ display: 'flex', flex: 'row', justifyContent: 'flex-end' }}
								>
									<Link to={ROUTES.EVENTOS}>
										<Button outline color="primary" style={{ marginRight: '4px' }}>
											Cancelar
										</Button>{' '}
									</Link>
									<Button color="primary" style={{ marginLeft: '4px' }} onClick={HandleMeetingCreation}>
										Crear reunión
									</Button>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer />
		</Fragment>
	);
};

export default CreateMeeting1;
