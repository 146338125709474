import React, {useState} from 'react';
import {Container, Row, Col, FormGroup, Label, Button} from 'reactstrap';
import AsyncSelect from "react-select/async";
import DataTable from "react-data-table-component";
import {customStyles} from "../../electores/tableStyles";
import * as ROUTES from "../../../../routes";
import {useNavigate} from "react-router-dom";
import {axiosInstance} from "../../../../utils/axiosInstance";
import {toast} from "react-toastify";
import {gql, useMutation} from "@apollo/client";
import {graphQlClient} from "../../../../store/graphql";

const CREATE_SUPPORT = gql`
mutation SupportCreateOne($record: CreateSupportInputTCInput) {
  supportCreateOne(record: $record)
}
`;

const colourStyles = {
    menu: (styles) => ({...styles, position: 'inherit'}),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#F04A5E'
        };
    }
};

const columns = [
    {
        name: 'Nombre',
        selector: (row) => row.label,
        width: '50%'
    },
    {
        name: 'Clave de elector',
        selector: (row) => row.electoralKey
    },
];
export default function CreateSupportStepTwo({
                                                 setStep,
                                                 dataStepOne,
                                                 dataStepTwo,
                                                 setDataStepTwo
                                             }) {

    const navigate = useNavigate();

    const [votersData, setVotersData] = useState([]);

    const [createSupport] = useMutation(
        CREATE_SUPPORT,
        {
            errorPolicy: 'all',
            client: graphQlClient,
            onCompleted: (response) => {
                if (response.data) {
                    toast.success('Se ha creado el apoyo con exito!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }

            },
            onError: () => {
                toast.error('Error al crear el apoyo', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    );

    const handleSubmit = () => {
        console.log(votersData)
        console.log(dataStepOne)
        if (
            votersData.length > 0
        ) {
            setDataStepTwo(votersData)

            const supportRecord = {
                campaingId: dataStepOne.selectedCampaign.id,
                evidence: {
                    comments: 'Comentario de prueba',
                    localization: 'Puebla',
                    photo: 'no foto',
                    reference: 'No referencia'
                },
                leaderId: votersData[0].id.toString(),
                promotor: 'Joe Doe',
                status: 'Creado',
                subType: dataStepOne.selectedType[0].value,
                type: 'test'

            };

            createSupport({
                variables: {
                    record: supportRecord
                }
            }).then((response) => {
                console.log(response)
                if (response.data) {
                    navigate(ROUTES.SUPPORTS)
                }
            });

        } else {
            toast.error('Faltan datos en el formulario', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }
    const handleFilterVoters = async (query) => {
        try {
            const {data} = await axiosInstance({
                url: `users/profiles/?full_name=${query}&user__groups__name=Apoyos`,
                method: 'GET'
            });

            return data.results.map((voter) => {
                const label = voter?.info_voter
                    ? voter.info_voter?.info?.full_name
                    : voter?.user_extra_info?.first_name + ' ' + voter?.user_extra_info?.last_name;

                return {
                    label,
                    value: voter.id,
                    voter: voter
                };
            });
        } catch (err) {
            return console.log(err);
        }
    };

    const handleChangeFilterVoters = (option) => {
        setVotersData(
            [option].map((opt) => {
                const {
                    value,
                    voter: {
                        info_voter: {
                            info: {first_name, last_name, curp}
                        }
                    },
                    voter
                } = opt;

                return {
                    id: value,
                    name: first_name,
                    lastName: last_name,
                    electoralKey: curp,
                    voter
                };
            })
        );
    };

    return (
        <>
            <div className="create-pyramid-page">
                <Container fluid>
                    <Row className="">
                        <Col className="mr-auto">
                            <h3>Crear pirámide</h3>
                        </Col>
                        <Col sm="auto" className="ml-auto">
                            <h3 className="font-primary">Paso 2 de 2</h3>
                        </Col>
                    </Row>

                    <div className="containerForm">
                        <Row>
                            <Col sm="12" xs="12">
                                <h5 className="title">Asignar lider</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="col-form-label">Nombre o clave de elector</Label>
                                    <AsyncSelect
                                        styles={colourStyles}
                                        name="pyramidAffiliatedVoters"
                                        placeholder="Ingresar nombre"
                                        valueKey="value"
                                        labelKey="label"
                                        matchPos="any"
                                        loadOptions={handleFilterVoters}
                                        hideSelectedOptions={true}
                                        onChange={(option) => handleChangeFilterVoters(option)}
                                        loadingMessage={() => 'Cargando...'}
                                        isMulti={false}
                                        noOptionsMessage={() => 'Ingresar nombre o clave de elector'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DataTable
                                    onRowClicked
                                    noHeader
                                    customStyles={customStyles}
                                    columns={columns}
                                    data={[]}
                                    highlightOnHover={true}
                                    noDataComponent={'No se han asignado electores'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="8" xs="12">
                                <Button
                                    onClick={() => setStep(0)}
                                    color="primary"
                                    style={{marginLeft: '4px'}}>
                                    Volver atrás
                                </Button>
                            </Col>

                            <Col sm="4" xs="12">
                                <Row style={{justifyContent: 'end', gap: '5px'}}>
                                    <Button
                                        onClick={() => navigate(ROUTES.SUPPORTS)}
                                        color="primary"
                                        style={{marginLeft: '4px'}}>
                                        Cancelar
                                    </Button>
                                    <Button color="primary" style={{marginLeft: '4px'}} onClick={() => handleSubmit()}>
                                        Crear Apoyo
                                    </Button>
                                </Row>

                            </Col>

                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}
