import AppliedTerritories from 'components/appliedTerritories/appliedTerritories';
import { Pyramid } from 'components/pyramid/Pyramid';
import React, { useCallback, useEffect, useState } from 'react';
import { Edit, Minus, Plus, Save, X } from 'react-feather';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Input, InputGroup, Row, TabPane } from 'reactstrap';
import { axiosInstance } from 'utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { useTab } from 'components/tab/useTab';
import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import Multiselect from 'components/multiselect/Multiselect';

import Select from 'react-select';
import {
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '14px',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '14px',
		borderRadius: '6px'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '14px'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '14px'
		};
	}
};

const TABS = ['Sección', 'Municipio', 'D. Local', 'D. Federal'];

const BasicPyramidDetails = ({ pyramidData, levels, setLoadingEdit, fetchPyramidDetails, entitiesOptions }) => {
	const getEntityValueByName = (entity) => {
		return entity && entity.length > 0
			? entitiesOptions.filter((opt) => opt.label.toLowerCase() === entity[0].entity_name.toLowerCase())
			: null;
	};

	const [editPyramid, setEditPyramid] = useState(false);

	const { activeTab, handleChangeTab } = useTab(TABS[0], { replaceUrl: false });

	const [pyramidName, setPyramidName] = useState(pyramidData?.name || '');
	const [pyramidNumberLevels, setPyramidNumberLevels] = useState(pyramidData?.levels_number || 0);
	const [pyramidDescription, setPyramidDescription] = useState(pyramidData?.description || '');
	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmModalMsg, setConfirmModalMsg] = useState('');
	const navigate = useNavigate();

	const [pyramidEntity, setPyramidEntity] = useState(getEntityValueByName(pyramidData?.related_entities));

	// Fetch territories by entity
	const { data: sections, loading: loadingSections } = useSectionsWithFilters(pyramidEntity[0]);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(pyramidEntity[0]);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(pyramidEntity[0]);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(pyramidEntity[0]);

	// Handlers for the territories selected for the event to have them as default values for the multiselect component
	const [defaultSections, setDefaultSections] = useState([]);
	const [defaultMunicipality, setDefaultMunicipality] = useState([]);
	const [defaultLDistrict, setDefaultLDistrict] = useState([]);
	const [defaultFDistrict, setDefaultFDistrict] = useState([]);

	// Handle the selected territories
	const [selectedSections, setSelectedSections] = useState([]);
	const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
	const [selectedLDistricts, setSelectedLDistricts] = useState([]);
	const [selectedFDistricts, setSelectedFDistricts] = useState([]);

	const [loadingDefaultTerritories, setLoadingDefaultTerritories] = useState(false);

	const setDefaultTerritories = useCallback(() => {
		try {
			setLoadingDefaultTerritories(true);

			const {
				sections: dataSections,
				related_districts,
				related_districts_local,
				related_municipality
			} = pyramidData;
			if (sections && sections.length > 0) {
				const defaultS = sections.filter((section) =>
					dataSections.some((ds) => section.value === parseInt(ds.section))
				);
				setDefaultSections(defaultS);
				setSelectedSections(defaultS);
			}

			if (lDistrics && lDistrics.length > 0) {
				const defaultLD = lDistrics.filter((lDistrict) =>
					related_districts_local.some((ld) => lDistrict.value === parseInt(ld.local_district_code))
				);
				setDefaultLDistrict(defaultLD);
				setSelectedLDistricts(defaultLD);
			}

			if (fDistrics && fDistrics.length > 0) {
				const defaultFD = fDistrics.filter((fDistrict) =>
					related_districts.some((fd) => fDistrict.value === parseInt(fd.district_code))
				);
				setDefaultFDistrict(defaultFD);
				setSelectedFDistricts(defaultFD);
			}

			if (municipalities && municipalities.length > 0) {
				const defaultM = municipalities.filter((municipality) =>
					related_municipality.some((m) => municipality.value === parseInt(m.municipality_code))
				);
				setDefaultMunicipality(defaultM);
				setSelectedMunicipalities(defaultM);
			}
		} catch (e) {
		} finally {
			setLoadingDefaultTerritories(false);
		}
	}, [sections, lDistrics, fDistrics, municipalities, pyramidData]);

	useEffect(() => {
		setDefaultTerritories();
	}, [setDefaultTerritories]);

	const handleEditPyramid = () => {
		const params = {
			levels_number: pyramidNumberLevels,
			name: pyramidName,
			description: pyramidDescription,
			entities: [pyramidEntity[0].value],
			districts: selectedFDistricts.map((item) => item.id),
			districts_local: selectedLDistricts.map((item) => item.id),
			municipalities: selectedMunicipalities.map((item) => item.id),
			sections: selectedSections.map((section) => section.id)
		};

		setLoadingEdit(true);

		axiosInstance({
			method: 'PUT',
			url: `/activism/estructure/${pyramidData.id}/`,
			data: params
		})
			.then(() => {
				fetchPyramidDetails();

				toast.success('Se ha modificado la pirámide con éxito', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			})
			.catch(() => {
				toast.error('Error al modificar la pirámide', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			})
			.finally(() => {
				setLoadingEdit(false);
				setEditPyramid(false);
			});
	};

	const handleDeletePyramid = () => {
		setConfirmModalMsg(`Esta seguro que desea borrar el registro seleccionado?`);
		setConfirmModal(true);
	};

	const toggle = (state) => {
		setConfirmModal(!confirmModal);

		if (state) {
			axiosInstance({
				method: 'DELETE',
				url: `/activism/estructure/${pyramidData.id}/`
			})
				.then(() => {
					toast.success('Se ha eliminado la pirámide con éxito', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
					navigate(`/piramides`);
				})
				.catch(() => {
					toast.error('Error al eliminar la pirámide', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				});
		}
	};

	const entityLabel = pyramidEntity ? pyramidEntity[0].label : '-';

	return (
		<>
			<Row noGutters style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Col sm="auto" lg={7}>
					<Card style={{ width: '100%' }}>
						<CardHeader className="p-3">
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between'
								}}
							>
								<p
									style={{
										fontWeight: '500',
										fontSize: '16px',
										lineHeight: '19px',
										color: '#F04A5E',
										margin: 0
									}}
								>
									Detalle de pirámide
								</p>
								{!editPyramid ? (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											flexDirection: 'row',
											gap: '12px'
										}}
									>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											outline
											color="primary"
											onClick={() => handleDeletePyramid()}
										>
											Borrar
											<X size={16} />
										</Button>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											color="primary"
											onClick={() => setEditPyramid(true)}
											disabled={
												loadingSections ||
												loadingMunicipalities ||
												loadingFDistrics ||
												loadingLDistrics
											}
										>
											Editar
											<Edit size={16} />
										</Button>
									</div>
								) : (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											flexDirection: 'row',
											gap: '12px'
										}}
									>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											outline
											color="primary"
											onClick={() => handleDeletePyramid()}
										>
											Borrar
											<X size={16} />
										</Button>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											outline
											color="primary"
											onClick={() => {
												setPyramidName(pyramidData?.name);
												setPyramidNumberLevels(pyramidData?.levels_number);
												setPyramidDescription(pyramidData?.description);
												setPyramidEntity(getEntityValueByName(pyramidData?.related_entities));

												setEditPyramid(false);
											}}
										>
											Cancelar
											<X size={16} />
										</Button>
										<Button
											style={{
												fontWeight: '400',
												fontSize: '12px',
												lineHeight: '14px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
												gap: '4px'
											}}
											color="primary"
											disabled={
												loadingDefaultTerritories ||
												loadingSections ||
												loadingMunicipalities ||
												loadingFDistrics ||
												loadingLDistrics
											}
											onClick={() => {
												handleEditPyramid();
											}}
										>
											Guardar
											<Save size={16} />
										</Button>
									</div>
								)}
							</div>
						</CardHeader>
						<CardBody className="p-3">
							<Row
								className="py-2 event-info-line"
								style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
							>
								<Col>
									<Row className="event-info-titles">
										<span>Nombre</span>
									</Row>
									<Row>
										{editPyramid ? (
											<Input
												type="text"
												name="pyramidName"
												id="pyramidName"
												placeholder="Nombre de la pirámide"
												value={pyramidName}
												onChange={(e) => setPyramidName(e.target.value)}
												className="edit-event-info"
											/>
										) : (
											<span>{pyramidName}</span>
										)}
									</Row>
								</Col>
								<Col>
									<Row className="event-info-titles">
										<span>Número de niveles</span>
									</Row>
									<Row>
										{editPyramid ? (
											<InputGroup>
												<button
													className="increment-decrement-voters decrement"
													onClick={() => {
														if (pyramidNumberLevels === 0) {
															setPyramidNumberLevels(0);
															return;
														}
														setPyramidNumberLevels((count) => count - 1);
													}}
												>
													<Minus size={12} />
												</button>
												<Input
													style={{
														borderLeft: '0',
														borderRight: '0',
														textAlign: 'center'
													}}
													placeholder="Ingresar número"
													type="number"
													min={0}
													value={pyramidNumberLevels}
													pattern="[0-9]*"
													onChange={(e) =>
														setPyramidNumberLevels((v) =>
															Number(e.target.validity.valid ? e.target.value : v)
														)
													}
													className="edit-event-info"
												/>
												<button
													className="increment-decrement-voters increment"
													onClick={() => {
														if (pyramidNumberLevels === 10) {
															setPyramidNumberLevels(10);
															return;
														}
														setPyramidNumberLevels((count) => count + 1);
													}}
												>
													<Plus size={12} />
												</button>
											</InputGroup>
										) : (
											<span>{`${pyramidNumberLevels} niveles`}</span>
										)}
									</Row>
								</Col>
							</Row>
							<Row
								className="py-2 event-info-line"
								style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
							>
								<Col>
									<Row className="event-info-titles">
										<span>Descripción</span>
									</Row>
									<Row>
										{editPyramid ? (
											<Input
												type="text"
												name="pyramidDescription"
												id="pyramidDescription"
												placeholder="Descripción de la pirámide"
												value={pyramidDescription}
												onChange={(e) => setPyramidDescription(e.target.value)}
												className="edit-event-info"
											/>
										) : (
											<span>{pyramidDescription}</span>
										)}
									</Row>
								</Col>

								<Col>
									<Row className="event-info-titles">
										<span>Entidad</span>
									</Row>
									<Row>
										<Col className="p-0">
											{editPyramid ? (
												<Select
													name="selectedPlace"
													options={entitiesOptions}
													id="selectedPlace"
													onChange={(option) => {
														setPyramidEntity([option]);

														setDefaultSections([]);
														setDefaultMunicipality([]);
														setDefaultLDistrict([]);
														setDefaultFDistrict([]);
													}}
													placeholder="Seleccionar ubicación"
													value={pyramidEntity[0]}
													styles={colourStyles}
												></Select>
											) : (
												<span>{entityLabel}</span>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row
								className="py-2 event-info-line"
								style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
							>
								<Col>
									<Row className="event-info-titles">
										<span>Total reclutados</span>
									</Row>
									<Row>
										<span>{pyramidData?.total_registered || 0}</span>
									</Row>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<Pyramid items={levels} />
				</Col>
				<Col sm="4" lg={4}>
					{editPyramid ? (
						<>
							<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} small />
							<TabContentContainer activeTab={activeTab} noPadding>
								<TabPane tabId={TABS[0]}>
									<Multiselect
										options={!pyramidEntity ? [] : sections}
										onChange={(options) =>
											setSelectedSections(
												options.map(({ value, label, id }) => ({
													value,
													label,
													id
												}))
											)
										}
										loading={loadingSections || loadingDefaultTerritories}
										defaultValue={defaultSections}
										disabled={
											selectedMunicipalities.length > 0 ||
											selectedLDistricts.length > 0 ||
											selectedFDistricts.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[1]}>
									<Multiselect
										options={!pyramidEntity ? [] : municipalities}
										onChange={(options) =>
											setSelectedMunicipalities(
												options.map(({ value, label, id }) => ({
													value,
													label,
													id
												}))
											)
										}
										loading={loadingMunicipalities || loadingDefaultTerritories}
										defaultValue={defaultMunicipality}
										disabled={
											selectedSections.length > 0 ||
											selectedLDistricts.length > 0 ||
											selectedFDistricts.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[2]}>
									<Multiselect
										options={!pyramidEntity ? [] : lDistrics}
										onChange={(options) =>
											setSelectedLDistricts(
												options.map(({ value, label, id }) => ({
													value,
													label,
													id
												}))
											)
										}
										loading={loadingLDistrics || loadingDefaultTerritories}
										defaultValue={defaultLDistrict}
										disabled={
											selectedSections.length > 0 ||
											selectedMunicipalities.length > 0 ||
											selectedFDistricts.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[3]}>
									<Multiselect
										options={!pyramidEntity ? [] : fDistrics}
										onChange={(options) =>
											setSelectedFDistricts(
												options.map(({ value, label, id }) => ({
													value,
													label,
													id
												}))
											)
										}
										loading={loadingFDistrics || loadingDefaultTerritories}
										defaultValue={defaultFDistrict}
										disabled={
											selectedSections.length > 0 ||
											selectedMunicipalities.length > 0 ||
											selectedLDistricts.length > 0
										}
									/>
								</TabPane>
							</TabContentContainer>
						</>
					) : (
						<AppliedTerritories
							sections={pyramidData?.related_sections || []}
							municipalities={pyramidData?.related_municipality || []}
							fDistrics={pyramidData?.related_districts || []}
							lDistricts={pyramidData?.related_districts_local || []}
							entities={pyramidData?.related_entities || []}
						/>
					)}
				</Col>
			</Row>
			<ConfirmModal isOpen={confirmModal} msg={confirmModalMsg} toggle={toggle} />
		</>
	);
};

export default BasicPyramidDetails;
