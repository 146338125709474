import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import Navigator from './navigator/navigator';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const HOST = process.env.REACT_APP_APOLLO_URL;

const link = new GraphQLWsLink(
	createClient({
		url: `wss://${HOST}`,
		reconnect: true,
		connectionParams: {
			headers: {
				Authorization: ''
			}
		}
	})
);

const client = new ApolloClient({
	link,
	cache: new InMemoryCache()
});

const Root = () => {
	return (
		<ApolloProvider client={client}>
			<Provider store={store}>
				<Router>
					<Navigator />
				</Router>
			</Provider>
		</ApolloProvider>
	);
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);

serviceWorker.unregister();
