import React, { Fragment, useEffect, useState, useReducer } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	Alert
} from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import InternalLoader from '../../../layout/internal-loader';
import { useDispatch, useSelector } from 'react-redux';
import { getGoals, createGoal } from '../../../redux/actions';

const model = [
	{ id: 'descripcion', name: 'Descripción' },
	{ id: 'dateStart', name: 'Fecha de inicio' },
	{ id: 'dateFinish', name: 'Fecha final' },
	{ id: 'meta', name: 'Meta' },
	{ id: 'active', name: 'Estado' },
	{ id: 'createdAt', name: 'Fecha de creación' }
];

const Goals = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);
	const [defaultPage, setDefaultPage] = useState(1);
	const loading = useSelector((state) => state.UI.isLoading);
	const metrics = useSelector((state) => state.Metrics);
	const goals = useSelector((state) => state.Metrics.goalsList);

	const [goalForm, setGoalForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		dateStart: moment().format('YYYY-MM-DD'),
		dateFinish: moment().add(1, 'days').format('YYYY-MM-DD'),
		descripcion: '',
		goal: 1
	});

	useEffect(() => {
		dispatch(
			getGoals({
				perPage: 20,
				page: 1
			})
		);
	}, [dispatch]);

	useEffect(() => {
		if (metrics.createGoalSuccess) {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		}
	}, [metrics.createGoalSuccess]);

	function getUsersTableHeaders(model) {
		return model.map((x) => {
			return {
				name: x.name,
				selector: (row) => row[x.id],
				sortable: true,
				center: true
			};
		});
	}

	const handlePagination = (page, totalRows) => {
		setCurrentPage(page);

		if (page > currentPage) {
			setDefaultPage(page + 1);
			dispatch(
				getGoals({
					perPage: 20,
					page: page + 1,
					lastItems: goals.items
				})
			);
		} else {
			setDefaultPage(defaultPage - 1);
		}
	};

	const doCreateGoal = () => {
		if (goalForm.descripcion !== '') {
			dispatch(
				createGoal({
					record: {
						descripcion: goalForm.descripcion,
						dateStart: moment(goalForm.dateStart).startOf('day').utc().toISOString(),
						dateFinish: moment(goalForm.dateFinish).endOf('day').utc().toISOString(),
						meta: Number(goalForm.goal),
						active: true
					}
				})
			);
		}
	};

	const goalsData = goals.items?.map((goal) => {
		const today = moment();
		const end = moment(goal?.dateFinish);
		const isAfter = today.isAfter(end);
		const status = isAfter ? 'Terminada' : 'Activa';
		return {
			descripcion: goal?.descripcion,
			dateStart: moment(goal?.dateStart).format('DD-MM-YYYY'),
			dateFinish: moment(goal?.dateFinish).format('DD-MM-YYYY'),
			meta: goal?.meta,
			active: status,
			createdAt: moment(goal?.createdAt).format('DD-MM-YYYY')
		};
	});

	return (
		<Fragment>
			<Breadcrumb parent="Configuración" title="Lista de metas" isAccount={true} />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<Form className="theme-form d-flex justify-content-around p-2">
								<Row>
									<Col xs="12" lg="3">
										<FormGroup>
											<Label className="col-form-label">
												Nombre de la meta <span className="text-danger">*</span>
											</Label>
											<Input
												className="form-control"
												type="text"
												required=""
												onChange={(e) => {
													setGoalForm({
														descripcion: e.target.value
													});
												}}
												value={goalForm.description}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" lg="3">
										<FormGroup>
											<Label className="col-form-label">
												Meta <span className="text-danger">*</span>
											</Label>
											<Input
												className="form-control"
												type="number"
												min={1}
												onChange={(e) => {
													setGoalForm({
														goal: e.target.value
													});
												}}
												value={goalForm.goal}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" lg="3">
										<FormGroup>
											<Label className="col-form-label">Fecha Inicial</Label>
											<Input
												className="form-control"
												type="date"
												min={moment().format('YYYY-MM-DD')}
												onChange={(e) => {
													setGoalForm({
														dateStart: e.target.value,
														dateFinish: moment(e.target.value)
															.add(1, 'days')
															.format('YYYY-MM-DD')
													});
												}}
												value={goalForm.dateStart}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" lg="3">
										<FormGroup>
											<Label className="col-form-label">Fecha Final</Label>
											<Input
												className="form-control"
												type="date"
												min={moment(goalForm.dateStart).add(1, 'days').format('YYYY-MM-DD')}
												onChange={(e) => {
													setGoalForm({
														dateFinish: e.target.value
													});
												}}
												value={goalForm.dateFinish}
											/>
										</FormGroup>
									</Col>
									<Col sm={{ size: 4, offset: 4 }} className="pt-2 pb-2">
										<Button color="primary" className="btn-block" onClick={doCreateGoal}>
											Crear Meta
										</Button>
										{metrics.loadingCreateGoal && (
											<div className="d-flex justify-content-center m-4">
												<CircularProgress style={{ color: '#165739' }} />
											</div>
										)}
										{metrics.createGoalError && (
											<Alert style={{ marginTop: '2em' }} color="danger">
												{metrics.createGoalError}
											</Alert>
										)}
										{metrics.createGoalSuccess && (
											<Alert style={{ marginTop: '2em' }} color="success">
												Meta creada satisfactoriamente
											</Alert>
										)}
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<span>Lista de metas</span>
							</CardHeader>
							<CardBody>
								<div className="table-responsive product-table">
									<DataTable
										noHeader
										columns={getUsersTableHeaders(model)}
										data={goalsData}
										progressPending={loading}
										progressComponent={<InternalLoader />}
										pagination
										paginationTotalRows={goals.pageInfo?.itemCount}
										paginationPerPage={20}
										paginationRowsPerPageOptions={[20]}
										onChangePage={(page, totalRows) => handlePagination(page, totalRows)}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Goals;
