import React, { useState } from 'react';
import { Minus, Plus, X } from 'react-feather';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';

const DropdownIndicator = (props) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<Plus size={14} color="#F04F62" />
			</components.DropdownIndicator>
		)
	);
};

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const ratingValue = [
	{ value: 1, label: 1 },
	{ value: 2, label: 2 },
	{ value: 3, label: 3 },
	{ value: 4, label: 4 },
	{ value: 5, label: 5 },
	{ value: 6, label: 6 },
	{ value: 7, label: 7 },
	{ value: 8, label: 8 },
	{ value: 9, label: 9 },
	{ value: 10, label: 10 }
];

const ValoracionRespuestas = ({
	answerTitle,
	handleSetAnswerValue,
	handleCancelAnswerValue,
	categoriesOptions,
	subcategoriesOptions,
	isOpen,
	toggle
}) => {
	/**
	 * States for rate
	 */
	const [selectedCat, setSelectedCat] = useState(null);
	const [selectedRate, setSelectedRate] = useState(null);

	const handleSaveRate = async () => {
		if (selectedRate && selectedCat) {
			const rate = await selectedRate.value;
			const cat_subcat = await selectedCat.value;

			setSelectedRate(null);
			setSelectedCat(null);
			handleSetAnswerValue(rate, cat_subcat);
		} else {
			if (!selectedCat)
				toast.error('Debe seleccionar una categoría o subcategoría', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});

			if (!selectedRate)
				toast.error('Debe asignar un valor', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
		}
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalBody>
				<Row style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row style={{ padding: '4px 16px', gap: '12px' }}>
					<Row className="create-event-title" style={{ width: '512px' }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
							<span style={{ color: '#F04A5E', fontSize: '16px' }}>Valoración para respuesta</span>
							<span style={{ color: '#333', fontSize: '14px', fontWeight: 400 }}>{answerTitle}</span>
						</div>
					</Row>
					<Row style={{ padding: '15px 17px', margin: '0 4px', width: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
							<FormGroup className="m-0">
								<Label>{'Añadir categoría o subcategoría'}</Label>
								<Select
									components={{ DropdownIndicator }}
									noOptionsMessage={() => 'No hay opciones'}
									isClearable
									name="selectedCategory"
									id="selectedCategory"
									options={[
										{ label: 'Categorías', options: categoriesOptions },
										{ label: 'Subcategorías', options: subcategoriesOptions }
									]}
									onChange={(option) => {
										setSelectedCat(option);
									}}
									value={selectedCat}
									placeholder={'Buscar y añadir'}
									styles={colourStyles}
								/>
							</FormGroup>
						</div>
					</Row>
					<Row style={{ padding: '15px 17px', margin: '0 4px', width: '100%' }}>
						<Row style={{ width: '100%' }}>
							<Col lg="6" sm="12">
								<Label>Lista de modificadores</Label>
							</Col>
							<Col lg="6" sm="12">
								<Label>Valor asignado</Label>
							</Col>
						</Row>
						<Row style={{ width: '100%' }}>
							{selectedCat ? (
								<>
									<Col lg="6" sm="12">
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center',
												gap: '24px'
											}}
										>
											<span
												style={{
													color: '#333',
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: 'normal'
												}}
											>
												{selectedCat.label}
											</span>
											<Minus
												size={24}
												color="#F04A5E"
												style={{ cursor: 'pointer' }}
												onClick={() => setSelectedCat(null)}
											/>
										</div>
									</Col>
									<Col lg="6" sm="12">
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											isClearable
											name="selectedCategory"
											id="selectedCategory"
											options={ratingValue}
											onChange={(option) => {
												setSelectedRate(option);
											}}
											placeholder={'Asignar valor'}
											styles={colourStyles}
										/>
									</Col>
								</>
							) : (
								<Col
									lg={12}
									md={12}
									sm={12}
									style={{
										justifyContent: 'center',
										alignItems: 'center',
										fontSize: '14px',
										fontWeight: '400',
										textAlign: 'center'
									}}
								>
									Sin categorías o subcategorías añadidas, añade la primera y asigna un valor
								</Col>
							)}
						</Row>
					</Row>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						setSelectedRate(null);
						setSelectedCat(null);
						handleCancelAnswerValue();
					}}
				>
					Cancelar
				</Button>
				<Button color="primary" onClick={() => handleSaveRate()}>
					Guardar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ValoracionRespuestas;
