import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import InputRange from 'react-input-range';
import { axiosInstance } from 'utils/axiosInstance';

const orderByOptions = [
	{ label: 'Nombre A-Z', value: 'aToZ' },
	{ label: 'Nombre Z-A', value: 'zToA' }
];

const genders = [
	{ label: 'Masculino', value: 'Hombre' },
	{ label: 'Femenino', value: 'Mujer' }
];

const correctSection = [
	{ label: 'Sí cumple', value: 'cumple' },
	{ label: 'No cumple', value: 'noCumple' }
];

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const FilterModalEventDetails = ({ isOpen, toggle, filterStates, handleApplyFilter }) => {
	const handleGenderCheckChange = (event) => {
		if (event.target.checked) {
			filterStates.setSelectedGender([...filterStates.selectedGender, event.target.value]);
		} else {
			filterStates.setSelectedGender(
				filterStates.selectedGender.filter((gender) => gender !== event.target.value)
			);
		}
	};

	const handleCorrectSectionCheckChange = (event) => {
		if (event.target.checked) {
			filterStates.setSelectedSection([...filterStates.selectedSection, event.target.value]);
		} else {
			filterStates.setSelectedSection(
				filterStates.selectedSection.filter((gender) => gender !== event.target.value)
			);
		}
	};

	/* States options */
	const [politicalPartiesOptions, setPoliticalPartiesOptions] = useState([]);
	const [eventResponsibles, setEventResponsibles] = useState([]);

	/* States loaders */
	const [loadingParties, setLoadingParties] = useState(false);
	const [loadingResponsibles, setLoadingResponsibles] = useState([]);

	useEffect(() => {
		setLoadingParties(true);
		setLoadingResponsibles(true);

		axiosInstance({
			url: `activism/political-party/`,
			method: 'GET'
		})
			.then(({ data: { results } }) => {
				setPoliticalPartiesOptions(
					results.map((militancy) => {
						return {
							value: militancy.id,
							label: militancy.abbreviation
						};
					})
				);
			})
			.catch((err) => console.log(err))
			.finally(() => setLoadingParties(false));

		axiosInstance({
			url: `users/profiles/?user__groups__name=Responsable+de+eventos`,
			method: 'GET'
		})
			.then(({ data: { results } }) => {
				setEventResponsibles(
					results.map((responsible) => {
						return {
							value: responsible.id,
							label: responsible.first_name
						};
					})
				);
			})
			.catch((err) => console.log(err))
			.finally(() => setLoadingResponsibles(false));
	}, []);

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalBody className="electors-filter-modal">
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row className="mb-2">
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Ordenar por'}</Label>
							<Select
								isClearable
								name="selectedOrder"
								id="selectedOrder"
								options={orderByOptions}
								onChange={(option) => {
									filterStates.setSelectedOrder(option);
								}}
								placeholder={orderByOptions[0].label}
								value={filterStates.selectedOrder}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
							<Label>{'Sexo'}</Label>
							{genders.map((gender, index) => {
								return (
									<li key={index}>
										<Input
											type="checkbox"
											onChange={handleGenderCheckChange}
											name={gender.value}
											value={gender.value}
											style={{ accentColor: 'rgb(236, 22, 48)' }}
											checked={filterStates.selectedGender.includes(gender.value)}
										/>
										<Label check>{gender.label}</Label>
									</li>
								);
							})}
						</FormGroup>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Militancia'}</Label>
							<Select
								isClearable
								isMulti
								name="selectedMilitancy"
								id="selectedMilitancy"
								options={politicalPartiesOptions}
								onChange={(option) => {
									filterStates.setSelectedMilitancy(option);
								}}
								placeholder={'Todas las militancias'}
								value={filterStates.selectedMilitancy || ''}
								styles={colourStyles}
								noOptionsMessage={() => 'No hay militancias para seleccionar'}
								isLoading={loadingParties}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Validado por'}</Label>
							<Select
								isSearchable
								isClearable
								name="selectedValidatedBy"
								id="selectedValidatedBy"
								options={eventResponsibles}
								onChange={(option) => {
									filterStates.setSelectedValidatedBy(option);
								}}
								placeholder={'Todos los responsable'}
								value={filterStates.selectedValidatedBy || ''}
								styles={colourStyles}
								noOptionsMessage={() => 'No hay responsables para seleccionar'}
								isLoading={loadingResponsibles}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col lg="6" sm="12">
						<FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
							<Label>{'Sección electoral'}</Label>
							{correctSection.map((option, index) => {
								return (
									<li key={index}>
										<Input
											type="checkbox"
											onChange={handleCorrectSectionCheckChange}
											name={option.value}
											value={option.value}
											style={{ accentColor: 'rgb(236, 22, 48)' }}
											checked={filterStates.selectedSection.includes(option.value)}
										/>
										<Label check>{option.label}</Label>
									</li>
								);
							})}
						</FormGroup>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col lg="12" sm="12">
						<FormGroup>
							<Label>{'Edad'}</Label>
							<InputRange
								maxValue={100}
								minValue={18}
								value={filterStates.selectedAge}
								onChange={(value) => {
									filterStates.setSelectedAge(value);
									filterStates.setHasSelectedAge(true);
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						filterStates.setSelectedOrder(null);
						filterStates.setSelectedGender([]);
						filterStates.setSelectedMilitancy();
						filterStates.setSelectedValidatedBy([]);
						filterStates.setSelectedSection([]);
						filterStates.setSelectedAge(filterStates.initialAge);
						filterStates.setHasSelectedAge(false);
					}}
				>
					Restablecer
				</Button>{' '}
				<Button color="primary" onClick={handleApplyFilter}>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default FilterModalEventDetails;
