import React, {useState} from 'react';
import "../../../appliedTerritories/appliedTerritories.scss";
import CreateSupportStepOne from "./CreateSupportStepOne";
import CreateSupportStepTwo from "./CreateSupportStepTwo";
function CreateSupport() {

    const [step, setStep] = useState(0);
    const [dataStepOne, setDataStepOne] = useState({});
    const [dataStepTwo, setDataStepTwo] = useState({});

    return (
        <>
            {
                step === 0 && <CreateSupportStepOne setStep={setStep} setDataStepOne={setDataStepOne}/>
            }

            {
                step === 1 && <CreateSupportStepTwo setStep={setStep} dataStepOne={dataStepOne} dataStepTwo={dataStepTwo}
                                                    setDataStepTwo={setDataStepTwo}/>
            }
        </>
    );
}

export default CreateSupport;
