import { FilterComponent } from 'components/dashboard/voterDetails/sms-tab/SmsTab';
import { CellStatus } from 'components/dashboard/voterDetails/sms-tab/SmsTable';
import InternalLoader from 'layout/internal-loader';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import FilterSmsModal from '../send-tab/FilterSmsModal';
import { usePagination } from '../../../../utils/fetch/pagination';
import { useSmsWithPagination } from '../../../../utils/fetch/sms';
import { useGetStatesByCredentials } from '../../../../utils/fetch/useGetStatesByCredentials';
import { axiosInstanceV1 } from '../../../../utils/axiosInstance';
import filterDemarcations from '../../../../utils/filterDemarcations';
import DataTable from 'react-data-table-component';
import { customStyles } from 'components/dashboard/electores/tableStyles';
import moment from 'moment';

import './history-tab.scss';
import { ShowMessage } from 'components/dashboard/voterDetails/sms-tab/DetailMessage';

const columns = [
	{
		name: 'Cantidad',
		selector: (row) => row._id,
		cell: (row) => <span className="font-primary">{row?.destinataries?.length || 0}</span>,
		width: '10%'
	},
	{
		name: 'ID de mensaje',
		selector: (row) => row._id,
		cell: (row) => <span className="font-primary">{row._id}</span>
	},
	{
		name: 'Status',
		selector: (row) => row.status,
		center: true,
		cell: (row) => <CellStatus status={row.status} />
	},
	{
		name: 'Tipo de mensaje',
		selector: (row) => (row.destinataries.length > 1 ? 'Masivo' : 'Único')
	},
	{
		name: 'Fecha y hora',
		selector: (row) => moment(row?.dateTimeToSend).format('DD-MM-YYYY - HH:mm'),
		center: true
	},
	{
		cell: (row) => <ShowMessage id={row._id} data={row} isDetail />,
		ignoreRowClick: true,
		allowOverflow: true,
		button: true
	}
];

export default function HistoryTab() {
	const [modal, setModal] = useState(false);
	const [filterParams, setFilterParams] = useState({});
	const [filterText, setFilterText] = useState('');

	const [stateOptions, setStateOptions] = useState([]);
	const [localeDistrictOptions, setLocaleDistrictOptions] = useState([]);
	const [federalDistrictOptions, setFederalDistrictOptions] = useState([]);
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [sectionOptions, setSectionOptions] = useState([]);

	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	const [selectedLocaleDistrict, setSelectedLocaleDistrict] = useState([]);
	const [selectedFederalDistrict, setSelectedFederalDistrict] = useState([]);
	const [selectedMunicipality, setSelectedMunicipality] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);

	const [search, setSearch] = useState('');

	const [loadingDemarcations, setLoadingDemarcations] = useState(false);
	const [sortObj, setSortObj] = useState('CREATED_AT_DESC');

	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();
	const {
		loading,
		data: { items, count }
	} = useSmsWithPagination({ perPage: perPage, page: page, filter: filterParams, sort: sortObj });

	const { data: states } = useGetStatesByCredentials();

	const handleSelectedStateChange = async (state) => {
		setMunicipalityOptions([]);
		setLocaleDistrictOptions([]);
		setFederalDistrictOptions([]);
		setSectionOptions([]);

		if (!state) {
			setSelectedState(null);
			return;
		}

		setSelectedState(state);

		setLoadingDemarcations(true);

		const mappedLDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-local-district-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((lD) => {
					return {
						label: lD.code,
						value: lD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedFDistricts = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-districts-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((fD) => {
					return {
						label: fD.code,
						value: fD.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedSections = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-sections-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((sec) => {
					return {
						label: sec.code,
						value: sec.code
					};
				})
			)
			.catch((err) => console.log(err));

		const mappedMunicipalities = await axiosInstanceV1({
			method: 'GET',
			url: `/credentials/get-municipalities-by-credentials/?ordering=code&by_state_code=${state.value}`
		})
			.then((res) =>
				res.data.map((mun) => {
					return {
						label: mun.nombre,
						value: mun.code
					};
				})
			)
			.catch((err) => console.log(err));

		setSectionOptions(filterDemarcations(mappedSections));
		setMunicipalityOptions(filterDemarcations(mappedMunicipalities));
		setLocaleDistrictOptions(filterDemarcations(mappedLDistricts));
		setFederalDistrictOptions(filterDemarcations(mappedFDistricts));

		setLoadingDemarcations(false);
	};

	const toggle = () => setModal(!modal);

	const filterSms = {
		selectedOrder,
		setSelectedOrder,
		selectedDate,
		setSelectedDate,
		selectedStatus,
		setSelectedStatus,
		selectedGender,
		setSelectedGender,
		selectedState,
		setSelectedState,
		selectedLocaleDistrict,
		setSelectedLocaleDistrict,
		selectedFederalDistrict,
		setSelectedFederalDistrict,
		selectedMunicipality,
		setSelectedMunicipality,
		selectedSection,
		setSelectedSection,
		setLocaleDistrictOptions,
		setFederalDistrictOptions,
		setMunicipalityOptions,
		setSectionOptions
	};

	const filterOptions = {
		loadingDemarcations,
		stateOptions,
		localeDistrictOptions,
		federalDistrictOptions,
		municipalityOptions,
		sectionOptions
	};

	const handleApplyFilter = () => {
		let filterObj = {};
		let filter = {};
		let order = {};

		if (selectedOrder) {
			let orderValue = selectedOrder.value;

			switch (orderValue) {
				case 'newer':
					order = 'CREATED_AT_DESC';
					break;

				case 'older':
					order = 'CREATED_AT_ASC';
					break;

				default:
					order = 'CREATED_AT_ASC';
					break;
			}

			setSortObj(order);
		} else {
			setSortObj('CREATED_AT_DESC');
		}

		if (search) filterObj._id = search;

		if (selectedGender && selectedGender.length > 0)
			filter.gender = selectedGender.map((gender) => gender.value.toString());

		if (selectedState) filter.state = selectedState.label;

		if (selectedStatus) filterObj.status = selectedStatus.value;

		if (selectedLocaleDistrict.length > 0)
			filter.localDistrict = selectedLocaleDistrict.map((lDistrict) => lDistrict.value.toString());

		if (selectedFederalDistrict.length > 0)
			filter.federalDistrict = selectedFederalDistrict.map((fDistrict) => fDistrict.value.toString());

		// if (selectedMunicipality.length > 0)
		//     filter.municipality = selectedMunicipality.map((municipality) => municipality.value.toString());

		if (selectedSection.length > 0) filter.sections = selectedSection.map((section) => section.value.toString());

		if (selectedDate) filterObj.createdAt = new Date(selectedDate).toISOString().split('T')[0];

		if (Object.keys(filter).length > 0) filterObj.filter = filter;

		setFilterParams(filterObj);

		setModal(false);
	};

	const filterHandlers = {
		handleApplyFilter,
		handleSelectedStateChange
	};

	useEffect(() => {
		if (states) {
			const mappedStates = Object.values(states || [])?.map((entity) => {
				return {
					label: entity.name,
					value: entity.code
				};
			});
			setStateOptions(mappedStates);
		}
	}, [states]);

	if (loading) {
		return <InternalLoader />;
	}
	return (
		<Row className="mt-2">
			<FilterSmsModal
				isOpen={modal}
				toggle={toggle}
				filterSms={filterSms}
				filterOptions={filterOptions}
				filterHandlers={filterHandlers}
			/>
			<Col xs="12">
				<FilterComponent
					title="Historial de SMS enviados"
					withoutBackground
					onShowModal={toggle}
					onFilter={(e) => setFilterText(e.target.value)}
					filterText={filterText}
					search={search}
					setSearch={setSearch}
					handleApplyFilter={handleApplyFilter}
				/>
			</Col>

			<Col>
				{/* <SmsTable
					data={items}
					personData={{}}
					historyTable={true}
					handlePerRowsChange={handlePerRowsChange}
					handlePageChange={handlePageChange}
					count={count}
					page={page}
					perPage={perPage}
				/> */}
				<div className="table-responsive product-table">
					<DataTable
						customStyles={customStyles}
						columns={columns}
						data={items}
						paginationComponentOptions={{
							rowsPerPageText: 'Mensajes por página:',
							rangeSeparatorText: 'de'
						}}
						paginationTotalRows={count}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						paginationDefaultPage={page}
						paginationPerPage={perPage}
						pagination
						paginationServer
						highlightOnHover={true}
						noDataComponent={'No se han encontrado mensajes'}
					/>
				</div>
			</Col>
		</Row>
	);
}
