import { X } from 'react-feather';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';

import { useGetStatesByCredentials } from 'utils/fetch/useGetStatesByCredentials';
import {
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import { useState } from 'react';

const selectStyles = {
	option: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#F04A5E22' }
	}),
	control: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#F04A5E'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#F04A5E',
		backgroundColor: '#FFF1F1'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#F04A5E'
		};
	}
};

const FilterLogs = ({ isOpen, toggle, states, handleFilter, range }) => {
	const { selectedDemarcation, setSelectedDemarcation, demarcationType, setDemarcationType } = states;

	const [selectedEntity, setSelectedEntity] = useState(null);
	const { loading: loadingEntities, data: entities } = useGetStatesByCredentials();
	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	return (
		<Modal isOpen={isOpen} toggle={toggle} centered>
			<ModalBody style={{ width: '500px' }}>
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>{'Entidad'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedEntity"
								id="selectedEntity"
								options={entities}
								onChange={(option) => {
									setSelectedEntity(option);
									setSelectedDemarcation(option);
									setDemarcationType('state');
								}}
								placeholder={'Entidad'}
								value={selectedEntity}
								styles={selectStyles}
								isLoading={loadingEntities}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg={6} sm={12}>
						<FormGroup>
							<Label>{'Sección'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedSection"
								id="selectedSection"
								options={sections}
								onChange={(option) => {
									setSelectedDemarcation(option || selectedEntity);
									setDemarcationType(option ? 'section' : 'state');
								}}
								placeholder={'Sección'}
								value={demarcationType === 'section' ? selectedDemarcation : null}
								styles={selectStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
								isSearchable
							/>
						</FormGroup>
					</Col>
					<Col lg={6} sm={12}>
						<FormGroup>
							<Label>{'Municipio'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedMunicipality"
								id="selectedMunicipality"
								options={municipalities}
								onChange={(option) => {
									setSelectedDemarcation(option || selectedEntity);
									setDemarcationType(option ? 'municipality' : 'state');
								}}
								placeholder={'Municipio'}
								value={demarcationType === 'municipality' ? selectedDemarcation : null}
								styles={selectStyles}
								isLoading={loadingMunicipalities}
								loadingMessage={() => 'Cargando...'}
								isSearchable
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg={6} sm={12}>
						<FormGroup>
							<Label>{'D.Local'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedLDistrict"
								id="selectedLDistrict"
								options={lDistrics}
								onChange={(option) => {
									setSelectedDemarcation(option || selectedEntity);
									setDemarcationType(option ? 'local_district' : 'state');
								}}
								placeholder={'D. Local'}
								value={demarcationType === 'local_district' ? selectedDemarcation : null}
								styles={selectStyles}
								isLoading={loadingLDistrics}
								loadingMessage={() => 'Cargando...'}
								isSearchable
							/>
						</FormGroup>
					</Col>
					<Col lg={6} sm={12}>
						<FormGroup>
							<Label>{'D. Federal'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedFDistrict"
								id="selectedFDistrict"
								options={fDistrics}
								onChange={(option) => {
									setSelectedDemarcation(option || selectedEntity);
									setDemarcationType(option ? 'district' : 'state');
								}}
								placeholder={'D. Federal'}
								value={demarcationType === 'district' ? selectedDemarcation : null}
								styles={selectStyles}
								isLoading={loadingFDistrics}
								loadingMessage={() => 'Cargando...'}
								isSearchable
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						setSelectedEntity(null);
						setSelectedDemarcation(null);
						setDemarcationType(null);
					}}
					disabled={loadingEntities || loadingLDistrics}
				>
					Restablecer
				</Button>{' '}
				<Button
					color="primary"
					onClick={() => handleFilter(range.firstDay, range.lastDay)}
					disabled={loadingEntities || !selectedDemarcation}
				>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default FilterLogs;
