import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Home, User } from 'react-feather';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../routes';

const Breadcrumbs = (props) => {
	const { isAccount } = props;

	let icon = <Home />;
	let route = ROUTES.DASHBOARD;

	if (isAccount) {
		icon = <User />;
		route = ROUTES.ACCOUNT;
	}

	return (
		<Fragment>
			<Container fluid={true}>
				<div className="page-title">
					<Row>
						<Col xs="6">
							<h3>{props.title}</h3>
						</Col>
						<Col xs="6">
							<Breadcrumb>
								<BreadcrumbItem>
									<Link to={route}>{icon}</Link>
								</BreadcrumbItem>
              {props.parent && <BreadcrumbItem>{props.parent}</BreadcrumbItem>}
								<BreadcrumbItem active>{props.title}</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};

export default Breadcrumbs;
