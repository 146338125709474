import React from 'react';
import { CheckCircle, User, UserCheck } from 'react-feather';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

const EventStats = ({ assistance, percWithCorrectSection, cantResponsables, abrirModal }) => {
	return (
		<Container fluid style={{ display: 'flex' }}>
			<Row className="info-stats">
				<Col className="p-1">
					<Card className="mb-0" style={{ height: '100%' }}>
						<CardBody className="info-card assistence">
							<span className="badge-icon">
								<User size={32} color="#ffffff" />
							</span>

							<span className="stats-text">Asistencia de personas</span>
							<span className="stats-number">{assistance}</span>
						</CardBody>
					</Card>
				</Col>
				<Col className="p-1">
					<Card className="mb-0" style={{ height: '100%' }}>
						<CardBody className="info-card right-section">
							<span className="badge-icon">
								<CheckCircle size={32} color="#ffffff" />
							</span>
							<span className="stats-text">Personas de secciones objetivo</span>
							<span className="stats-number">{percWithCorrectSection}%</span>
						</CardBody>
					</Card>
				</Col>
				<Col className="p-1">
					<Card className="mb-0" style={{ height: '100%' }}>
						<CardBody className="info-card compliance">
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									width: '100%'
								}}
							>
								<span className="badge-icon">
									<UserCheck size={32} color="#ffffff" />
								</span>
								<Button outline color="warning" onClick={abrirModal}>
									Ver lista
								</Button>
							</div>
							<span className="stats-text">Responsables del evento</span>
							<span className="stats-number">{cantResponsables.length}</span>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EventStats;
