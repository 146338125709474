import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';

const CreateEventHeader = (props) => {
	const { step, lastStep } = props;

	return (
		<Fragment>
			<Container fluid={true}>
				<div className="page-title">
					<Row>
						<Col xs="6">
							<h3>Crear evento</h3>
						</Col>
						<Col xs="6" style={{ textAlign: 'end' }}>
							<span
								style={{
									fontWeight: '500',
									fontSize: '24px',
									lineHeight: '28px',
									color: '#F04A5E',
									justifyContent: 'right'
								}}
							>{`${step} de ${lastStep}`}</span>
						</Col>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};

export default CreateEventHeader;
