import React, { Fragment } from 'react';
import { Col, Container, Nav, NavItem, Row } from 'reactstrap';
import '../../tab/tab.scss';

const PyramidDetailsHeader = ({ name, levels, activeTab, setActiveTab }) => {
	return (
		<Fragment>
			<Container fluid={true}>
				<Row className="pyramid-header">
					<Col lg={3}>
						<h3>{name}</h3>
					</Col>
					<Col lg={9} style={{ display: 'flex', justifyContent: 'end' }} className="">
						<Nav tabs className="tabs-container">
							{levels.map((tabItem, idx) => {
								const tabId = typeof tabItem === 'string' ? tabItem : tabItem?.id;
								return (
									<NavItem
										key={tabId}
										className={`${activeTab === tabId ? 'active' : ''}`}
										onClick={() => setActiveTab(tabId)}
									>
										{idx === 0 ? tabItem : `Nivel ${idx}`}
									</NavItem>
								);
							})}
						</Nav>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default PyramidDetailsHeader;
