import InternalLoader from 'layout/internal-loader';
import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useMeetingsByCURP } from 'utils/fetch/meetings';

const MeetingsTab = ({ curp, roles }) => {
	const [promotor, setPromotor] = useState(false);

	const { data: meetingsData, loading } = useMeetingsByCURP({ curp });

	useEffect(() => {
		if (roles && roles !== null) {
			setPromotor(roles.includes('Promotor'));
		}
	}, [roles]);

	if (loading) return <InternalLoader />;

	return (
		<Container fluid className="bg-white">
			<Row>
				<Col>
					<div className="custom-detail-card" style={{ display: 'flex', flexDirection: 'column' }}>
						<div
							className="bg-primary-light-gray"
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<div>
								<span className="item-title">Rol</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										gap: '10px'
									}}
								>
									{promotor ? <span>Promotor</span> : <span>Invitado</span>}
								</div>
							</div>
						</div>
						{meetingsData.map((meet) => {
							return (
								<div
									className="bg-white"
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center'
									}}
									key={meet._id}
								>
									<div>
										<span className="item-title">Nombre de reunión</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'flex-start',
												alignItems: 'center',
												gap: '10px'
											}}
										>
											{meet.name}
										</div>
									</div>
									<div>
										<div className="d-flex justify-content-center flex-column bg-primary-light-gray">
											<Link to={`/reuniones/${meet._id}`}>
												<Button
													size="sm"
													className="px-2 d-inline-flex align-items-center"
													color="primary"
												>
													Ir a reunión <ChevronRight size={16} />
												</Button>
											</Link>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default MeetingsTab;
