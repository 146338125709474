import Checkbox from 'components/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

function capitalizeFirstLetters(value) {
	return value
		?.split(' ')
		?.map((word) => word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase())
		?.join(' ');
}

export default function SendMessageForm({
	firstName = '',
	lastName = '',
	text,
	setText,
	setDateToSend,
	dateToSend,
	timeToSend,
	setTimeToSend,
	onSubmitMessage,
	disabledText,
	isScheduledSms,
	setIsScheduledSms,
	oneVoterMessage = false
}) {
	useEffect(() => {
		if (!isScheduledSms) {
			setDateToSend('');
			setTimeToSend('');
		}
		// eslint-disable-next-line
	}, [isScheduledSms]);

	const first_name = oneVoterMessage && capitalizeFirstLetters(firstName);
	const last_name = oneVoterMessage && capitalizeFirstLetters(lastName);

	const [addName, setAddName] = useState(false);
	const [addLastName, setAddLastName] = useState(false);

	const toggleAddName = () => {
		if (addName) {
			setText((prev) => prev.replace(first_name, ''));
			setAddName(false);
		} else {
			setText((prev) => `${prev} ${first_name}`);
			setAddName(true);
		}
	};

	const toggleAddLastName = () => {
		if (addLastName) {
			setText((prev) => prev.replace(last_name, ''));
			setAddLastName(false);
		} else {
			setText((prev) => `${prev} ${last_name}`);
			setAddLastName(true);
		}
	};

	return (
		<Container fluid>
			<Row>
				<Col className="p-0">
					<FormGroup className="container-textArea">
						<Label for="exampleText" className="font-primary">
							Texto del mensaje
						</Label>
						<Input
							className="bg-primary-light-gray"
							placeholder="Insertar mensaje..."
							onChange={(e) => setText(e.target.value)}
							value={text}
							rows={5}
							id="message"
							name="message"
							type="textarea"
							disabled={disabledText}
						/>
						{oneVoterMessage && (
							<div className="text-area-options">
								<Button
									size="xs"
									className="px-2 bg-secundario-alert-100"
									color="none"
									onClick={toggleAddName}
								>
									Añadir nombre
								</Button>
								<Button
									size="xs"
									className="ml-2 px-2 bg-secundario-alert-100"
									color="none"
									onClick={toggleAddLastName}
								>
									Añadir apellido
								</Button>
							</div>
						)}
					</FormGroup>
				</Col>
			</Row>
			<Row className="">
				<Col className="mr-auto pl-0">
					<FormGroup className="mb-1">
						<Checkbox
							label="Programar mensaje"
							checked={isScheduledSms}
							onChange={() => setIsScheduledSms(!isScheduledSms)}
						/>
					</FormGroup>
				</Col>
				<Col sm="auto">
					<FormGroup>
						<Input
							disabled={disabledText || !isScheduledSms}
							value={dateToSend}
							type="date"
							name="date"
							id="date"
							placeholder="Indique fecha"
							onChange={(evt) => setDateToSend(evt.target.value)}
						/>
					</FormGroup>
				</Col>
				<Col sm="auto">
					<FormGroup>
						<Input
							disabled={disabledText || !isScheduledSms}
							value={timeToSend}
							type="time"
							name="time"
							id="time"
							onChange={(evt) => setTimeToSend(evt.target.value)}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row className="justify-content-end">
				<Col sm="auto">
					<Button size="sm" className="px-3 f-14" color="light">
						Cancelar
					</Button>
				</Col>
				<Col sm="auto">
					{disabledText ? (
						<Spinner style={{ width: '0.7rem', height: '0.7rem' }} type="grow" color="red" />
					) : (
						<Button
							size="sm"
							className="px-2 f-14"
							color="primary"
							onClick={onSubmitMessage}
							disabled={disabledText}
						>
							{isScheduledSms ? 'Programar Mensaje' : 'Enviar Ahora'}
						</Button>
					)}
				</Col>
			</Row>
		</Container>
	);
}
