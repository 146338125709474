import React, { useState } from 'react';
import { ChevronRight, Minus, Plus } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Input, Row, UncontrolledTooltip } from 'reactstrap';
import {} from 'react-feather';
import { axiosInstance } from 'utils/axiosInstance';
import ValoracionRespuestas from './valoracion-respuestas';
import LoadingAffiliation from '../CustomLoader/CustomLoader';

const QuestionRow = ({ setQuestions, categories, subcategories, stageId, poll, setAllQuestions }) => {
	/**
	 * Question values
	 */
	const [questionValue, setQuestionValue] = useState('');
	const [questionId, setQuestionId] = useState(null);

	/**
	 * Question value conditional
	 */
	const [questionIsSaved, setQuestionIsSaved] = useState(false);

	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Open answer value modal
	 */
	const [answerValueIsOpen, setAnswerValueIsOpen] = useState(false);
	const toggleAnswerValue = () => setAnswerValueIsOpen(!answerValueIsOpen);

	/**
	 * Handle the answers of multiple options question
	 */
	const [answers, setAnswers] = useState([]);
	const [newAnswer, setNewAnswer] = useState('');

	/**
	 * Handle selected answer tod set value
	 */
	const [selectedAnswerToValue, setSelectedAnswerToValue] = useState(null);
	const [answerTitle, setAnswerTitle] = useState('');

	/**
	 * Handle editable question
	 */
	const [editableQuestion, setEditableQuestion] = useState(false);
	const [auxQuestionValue, setAuxQuestionValue] = useState('');

	/**
	 * Handle editable answer
	 */
	const [editableAnswer, setEditableAnswer] = useState(null);
	const [auxEditableAnswer, setAuxEditableAnswer] = useState('');

	/**
	 * Save question
	 */
	const handleSaveQuestion = () => {
		if (questionValue) {
			const params = {
				question: questionValue,
				question_score: 0,
				type_question: 2,
				poll,
				stage: stageId,
				category: categories[0].value,
				is_conditional: false,
				conditionals_questions: [],
				option_conditional: []
			};

			setIsLoading(true);

			axiosInstance({
				url: '/polls/question/',
				method: 'POST',
				data: params
			})
				.then(({ data }) => {
					setQuestionId(data.id);
					setQuestionIsSaved(true);
				})
				.catch((err) => {
					toast.error(err.response.data.stage[0], {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				})
				.finally(() => setIsLoading(false));
		} else {
			toast.error(`Debe escribir a la pregunta.`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	/**
	 * Handle add new answer to question
	 */
	const handleAddAnswer = () => {
		if (newAnswer) {
			setAnswers((prev) => [
				...prev,
				{
					option: newAnswer,
					option_score: null,
					question: questionId,
					category_subcategory: null
				}
			]);
			setNewAnswer('');
		} else {
			toast.error('La respuesta no puede estar vacía', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	const handleSetAnswerValue = (rate, cat_subcat) => {
		const auxAnswers = answers.map((obj, index) => {
			if (index === selectedAnswerToValue) {
				setSelectedAnswerToValue(null);

				return { ...obj, option_score: rate, category_subcategory: [cat_subcat] };
			}

			return obj;
		});

		setAnswers(auxAnswers);
		setAnswerTitle('');

		toggleAnswerValue();
	};

	const handleCancelAnswerValue = () => {
		setSelectedAnswerToValue(null);
		setAnswerTitle('');

		toggleAnswerValue();
	};

	const handleAddQuestion = async () => {
		/**
		 * Boolean to check if any answer has not value
		 */
		const notAllValue = answers.some((obj) => obj.option_score === null || obj.category_subcategory.length === 0);

		if (!notAllValue) {
			setIsLoading(true);

			try {
				let auxQuestion = { id: questionId, question: questionValue, type: 2 };

				let auxAnswers = [];

				for (let i in answers) {
					const answer = answers[i];

					await axiosInstance({
						url: '/polls/option/',
						method: 'POST',
						data: {
							option: answer.option,
							option_score: answer.option_score,
							question: questionId,
							category_subcategory: answer.category_subcategory
						}
					}).then(({ data }) => {
						auxAnswers.push({ ...answer, id: data.id });
					});
				}

				auxQuestion.answers = auxAnswers;

				setQuestions((prev) => [...prev, auxQuestion]);
				setAllQuestions((prev) => [...prev, auxQuestion]);
			} catch (err) {
				toast.error(err.response.data.question[0], {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			} finally {
				setQuestionValue('');
				setQuestionId(null);
				setQuestionIsSaved(false);

				setAnswers([]);

				setIsLoading(false);
			}
		} else {
			toast.error('Alguna de las respuestas no tiene un valor asignado.', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	};

	const handleRemoveAnswer = (idx) => {
		setAnswers((prev) => prev.filter((_, index) => idx !== index));
	};

	const removeQuestion = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'DELETE'
		})
			.then(() => {
				setQuestionValue('');
				setQuestionId(null);
				setQuestionIsSaved(false);
				setAnswerValueIsOpen(false);
				setAnswers([]);
				setNewAnswer('');
				setSelectedAnswerToValue(null);
				setAnswerTitle('');
				setEditableQuestion(false);
				setAuxQuestionValue('');
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleUpdateQuestion = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'PUT',
			data: {
				question: auxQuestionValue,
				question_score: 0,
				type_question: 2,
				poll,
				stage: stageId,
				category: categories[0].value,
				is_conditional: false,
				conditionals_questions: [],
				option_conditional: []
			}
		})
			.then(({ data }) => {
				setQuestionValue(auxQuestionValue);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setAuxQuestionValue('');
				setEditableQuestion(false);
			});
	};

	const handleUpdateAnswer = () => {
		try {
			const auxAnswers = answers.map((answer, index) => {
				if (index === editableAnswer) {
					return { ...answer, option: auxEditableAnswer };
				}

				return answer;
			});

			setAnswers(auxAnswers);
		} catch (e) {
			console.log(e);
		} finally {
			setEditableAnswer(false);
		}
	};

	return (
		<div
			className="mb-2"
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#F8F8F8',
				padding: '8px 16px',
				gap: '8px',
				alignItems: 'start',
				width: '100%',
				borderRadius: '5px'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					width: '100%',
					gap: '8px'
				}}
			>
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '24px'
						}}
					>
						{!questionIsSaved ? (
							<>
								<Input
									bsSize="sm"
									placeholder="Ingrese pregunta múltiple"
									value={questionValue}
									onChange={(e) => setQuestionValue(e.target.value)}
									onKeyDown={({ key }) => key === 'Enter' && handleSaveQuestion()}
								/>
								<ChevronRight
									size={24}
									color="#F04A5E"
									style={{ cursor: 'pointer' }}
									onClick={handleSaveQuestion}
								/>
							</>
						) : (
							<>
								{editableQuestion ? (
									<>
										<Input
											bsSize="sm"
											placeholder="Ingrese pregunta múltiple"
											value={auxQuestionValue}
											onChange={(e) => setAuxQuestionValue(e.target.value)}
											onKeyDown={({ key }) => {
												if (key === 'Enter') handleUpdateQuestion();
												if (key === 'Escape') setEditableQuestion(false);
											}}
										/>
									</>
								) : (
									<>
										<span
											style={{
												color: '#333',
												fontSize: '14px',
												fontWeight: '700',
												lineHeight: 'normal'
											}}
											onDoubleClick={() => {
												setEditableQuestion(true);
												setAuxQuestionValue(questionValue);
											}}
										>
											{questionValue}
										</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'flex-end',
												alignItems: 'center',
												gap: '8px'
											}}
										>
											<Plus
												size={24}
												color="#F04A5E"
												style={{ cursor: 'pointer' }}
												id="addNewQuestion"
												onClick={handleAddQuestion}
											/>
											<UncontrolledTooltip target="addNewQuestion">
												Agregar nueva pregunta
											</UncontrolledTooltip>
											<Minus
												size={24}
												color="#F04A5E"
												style={{ cursor: 'pointer' }}
												id="removeQuestion"
												onClick={removeQuestion}
											/>
											<UncontrolledTooltip target="removeQuestion">
												Eliminar pregunta
											</UncontrolledTooltip>
										</div>
									</>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					gap: '8px',
					width: '100%'
				}}
			>
				{questionIsSaved && (
					<>
						{answers.map((answer, index) => {
							if (editableAnswer === index) {
								return (
									<Input
										bsSize="sm"
										placeholder="Ingrese la respuesta"
										value={auxEditableAnswer}
										onChange={(e) => setAuxEditableAnswer(e.target.value)}
										onKeyDown={({ key }) => {
											if (key === 'Enter') handleUpdateAnswer();
											if (key === 'Escape') setEditableAnswer(null);
										}}
									/>
								);
							} else {
								return (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											height: '32px',
											gap: '24px'
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '100%'
											}}
										>
											<span
												style={{
													color: '#333',
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: 'normal'
												}}
												onDoubleClick={() => {
													setEditableAnswer(index);
													setAuxEditableAnswer(answer.option);
												}}
											>
												{answer.option}
											</span>
											<span
												style={{
													color: '#333',
													fontSize: '12px',
													fontWeight: '400',
													lineHeight: 'normal',
													cursor: 'pointer',
													textDecoration: 'underline'
												}}
												onClick={() => {
													setAnswerTitle(answer.option);
													setSelectedAnswerToValue(index);
													toggleAnswerValue();
												}}
											>
												Valorar respuesta
											</span>
										</div>
										<Minus
											size={24}
											color="#F04A5E"
											style={{ cursor: 'pointer' }}
											onClick={() => handleRemoveAnswer(index)}
										/>
									</div>
								);
							}
						})}
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								height: '32px',
								gap: '24px'
							}}
						>
							<Input
								bsSize="sm"
								id={`new-subcategoryName`}
								name={`new-subcategoryName`}
								placeholder="Ingrese la respuesta"
								value={newAnswer}
								onChange={(e) => setNewAnswer(e.target.value)}
								onKeyDown={({ key }) => key === 'Enter' && handleAddAnswer()}
							/>
							<Plus size={24} color="#F04A5E" style={{ cursor: 'pointer' }} onClick={handleAddAnswer} />
						</div>
					</>
				)}
			</div>
			<ValoracionRespuestas
				answerTitle={answerTitle}
				handleSetAnswerValue={handleSetAnswerValue}
				handleCancelAnswerValue={handleCancelAnswerValue}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				isOpen={answerValueIsOpen}
				toggle={toggleAnswerValue}
			/>
			<LoadingAffiliation isOpen={isLoading} />
		</div>
	);
};

const PreguntasOpcionMultiple = ({
	questions,
	setQuestions,
	categories,
	subcategories,
	stageId,
	poll,
	setAllQuestions
}) => {
	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Update value to existing answers
	 */
	const [answerToUpdateIsOpen, setAnswerToUpdateIsOpen] = useState(false);
	const toggleAnswerToUpdate = () => setAnswerToUpdateIsOpen(!answerToUpdateIsOpen);

	/**
	 * Answer to uodate values
	 */
	const [answerIdToUpdate, setAnswerIdToUpdate] = useState(null);
	const [answerTitleToUpdate, setAnswerTitleToUpdate] = useState('');

	/**
	 * Handlers to modify existing question/answers
	 */
	const [selectedId, setSelectedId] = useState(null);
	const [modifyValue, setModifyValue] = useState('');

	const handleUpdateAnswerValue = (rate, cat_subcat) => {
		setIsLoading(true);

		const auxAnswer = questions
			.flatMap((question) => question.answers)
			.filter((answer) => answer.id === answerIdToUpdate)[0];

		axiosInstance({
			url: `/polls/option/${answerIdToUpdate}/`,
			method: 'PUT',
			data: {
				option: auxAnswer.option,
				option_score: rate,
				question: auxAnswer.question,
				category_subcategory: [cat_subcat]
			}
		})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));

		setAnswerIdToUpdate(null);
		setAnswerTitleToUpdate('');

		toggleAnswerToUpdate();
	};

	const handleCancelUpdateAnswerValue = () => {
		setAnswerIdToUpdate(null);
		setAnswerTitleToUpdate('');

		toggleAnswerToUpdate();
	};

	const handleRemoveExistingQuestion = (questionId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'DELETE'
		})
			.then(() => {
				const newQuestions = questions.filter((question) => question.id !== questionId);

				setQuestions(newQuestions);
				setAllQuestions((prev) => prev.filter((question) => question.id !== questionId));
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleRemoveExistingAnswer = (questionId, answerId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/option/${answerId}/`,
			method: 'DELETE'
		})
			.then(() => {
				const newQuestions = questions.map((question) => {
					if (question.id === questionId) {
						const newAnswers = question.answers.filter((answer) => answer.id !== answerId);

						return { ...question, answers: newAnswers };
					}

					return question;
				});

				setQuestions(newQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleModifyQuestion = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${selectedId}/`,
			method: 'PUT',
			data: {
				question: modifyValue,
				question_score: 0,
				type_question: 2,
				poll,
				stage: stageId,
				category: categories[0].value,
				is_conditional: false,
				conditionals_questions: [],
				option_conditional: []
			}
		})
			.then(({ data }) => {
				const auxQuestions = questions.map((question) => {
					if (selectedId === question.id) {
						return { ...question, question: modifyValue };
					}

					return question;
				});

				setQuestions(auxQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setModifyValue('');
				setSelectedId(null);
			});
	};

	const handleModifyAnswer = (answer) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/option/${selectedId}/`,
			method: 'PUT',
			data: {
				option: modifyValue,
				option_score: answer.option_score,
				question: answer.question,
				category_subcategory: answer.category_subcategory
			}
		})
			.then(() => {
				const auxQuestions = questions.map((question) => {
					if (question.id === answer.question) {
						const auxAnswers = question.answers.map((answer) => {
							if (answer.id === selectedId) {
								return { ...answer, option: modifyValue };
							}

							return answer;
						});

						return { ...question, answers: auxAnswers };
					}

					return question;
				});

				setQuestions(auxQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setModifyValue('');
				setSelectedId(null);
			});
	};

	return (
		<Container fluid style={{ padding: '16px', display: 'flex', gap: '12px', flexDirection: 'column' }}>
			<Row>
				<Col lg={6}>
					<span className="survey-question-type-title">Pregunta opción múltiple</span>
				</Col>
				<Col lg={6}>
					<span className="survey-question-type-title">Respuestas</span>
				</Col>
			</Row>
			<Row>
				{questions.length > 0 &&
					questions.map((qstn) => {
						const { id: questionId, question, answers } = qstn;

						return (
							<div
								className="mb-2"
								style={{
									display: 'flex',
									flexDirection: 'row',
									backgroundColor: '#F8F8F8',
									padding: '8px 16px',
									gap: '8px',
									alignItems: 'start',
									width: '100%',
									borderRadius: '5px'
								}}
								key={questionId}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										padding: '8px 16px',
										width: '100%'
									}}
								>
									{selectedId === questionId ? (
										<>
											<Input
												bsSize="sm"
												id={`modifyValue`}
												name={`modifyValue`}
												placeholder="Ingrese pregunta múltiple"
												value={modifyValue}
												onChange={(e) => setModifyValue(e.target.value)}
												onKeyDown={({ key }) => {
													if (key === 'Enter') handleModifyQuestion();
													if (key === 'Escape') setSelectedId(null);
												}}
											/>
										</>
									) : (
										<>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
													alignItems: 'center',
													gap: '24px'
												}}
											>
												<span
													style={{
														color: '#333',
														fontSize: '14px',
														fontWeight: '700',
														lineHeight: 'normal'
													}}
													onDoubleClick={() => {
														setSelectedId(questionId);
														setModifyValue(question);
													}}
												>
													{question}
												</span>
												<Minus
													size={24}
													color="#F04A5E"
													style={{ cursor: 'pointer' }}
													onClick={() => handleRemoveExistingQuestion(questionId)}
												/>
											</div>
										</>
									)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										padding: '8px 16px',
										gap: '4px',
										width: '100%'
									}}
								>
									{answers.map((answer) => {
										// const { id: answerId, option } = answer;

										return (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													width: '100%'
												}}
											>
												{answer.id === selectedId ? (
													<>
														<Input
															bsSize="sm"
															placeholder="Ingrese la respuesta"
															value={modifyValue}
															onChange={(e) => setModifyValue(e.target.value)}
															onKeyDown={({ key }) => {
																if (key === 'Enter') handleModifyAnswer(answer);
																if (key === 'Escape') setSelectedId(null);
															}}
														/>
													</>
												) : (
													<>
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'space-between',
																alignItems: 'center',
																height: '32px',
																gap: '24px'
															}}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	width: '100%'
																}}
															>
																<span
																	style={{
																		color: '#333',
																		fontSize: '14px',
																		fontWeight: '400',
																		lineHeight: 'normal'
																	}}
																	onDoubleClick={() => {
																		setSelectedId(answer.id);
																		setModifyValue(answer.option);
																	}}
																>
																	{answer.option}
																</span>
																<span
																	style={{
																		color: '#333',
																		fontSize: '12px',
																		fontWeight: '400',
																		lineHeight: 'normal',
																		cursor: 'pointer',
																		textDecoration: 'underline'
																	}}
																	onClick={() => {
																		setAnswerIdToUpdate(answer.id);
																		setAnswerTitleToUpdate(answer.option);

																		toggleAnswerToUpdate();
																	}}
																>
																	Valorar respuesta
																</span>
															</div>
															<Minus
																size={24}
																color="#F04A5E"
																style={{ cursor: 'pointer' }}
																onClick={() =>
																	handleRemoveExistingAnswer(questionId, answer.id)
																}
															/>
														</div>{' '}
													</>
												)}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				<QuestionRow
					setQuestions={setQuestions}
					categories={categories}
					subcategories={subcategories}
					stageId={stageId}
					poll={poll}
					setAllQuestions={setAllQuestions}
				/>
			</Row>
			<ToastContainer />
			<ValoracionRespuestas
				answerTitle={answerTitleToUpdate}
				handleSetAnswerValue={handleUpdateAnswerValue}
				handleCancelAnswerValue={handleCancelUpdateAnswerValue}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				isOpen={answerToUpdateIsOpen}
				toggle={toggleAnswerToUpdate}
			/>
			<LoadingAffiliation isOpen={isLoading} />
		</Container>
	);
};

export default PreguntasOpcionMultiple;
