import { useEffect, useState } from 'react';
import { axiosInstanceV1 } from 'utils/axiosInstance';
import { gql, useLazyQuery } from '@apollo/client';
import { graphQlClient } from '../../store/graphql';

const getVoterCredentials = gql`
	query Query($curp: String!, $clientId: String!) {
		getVoterCredentials(curp: $curp, clientId: $clientId)
	}
`;

export function useCredentials(curp) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		setLoading(true);

		axiosInstanceV1({
			method: 'GET',
			url: `/credentials/${curp}`
		})
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => console.log(err))
			.finally(() => setLoading(false));
	}, [curp]);

	return { loading, data };
}

export function useGetVoterCredentials({ curp, clientId } = {}) {
	const [getImage, { loading, data, error, refetch }] = useLazyQuery(getVoterCredentials, {
		fetchPolicy: 'network-only',
		client: graphQlClient,
		variables: {
			curp,
			clientId
		}
	});

	return [getImage, { loading, data: data?.getVoterCredentials || null, error, refetch }];
}
